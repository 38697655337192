import React, {useEffect, useState} from "react";
import {Button, Dialog, DialogContent, DialogActions} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {isEmpty} from "ramda";
import {observer} from "mobx-react-lite";
import TestsListTable from "@core/components/TestsListTable";
import AddTestsOrDeclarationForm from "@core/components/AddTestsOrDeclarationForm";
import useStores from "../../../useStores";
import styles from "./styles";

const TestsList = ({onClose, types, setTypes, certificates, qcps, testsWithRequiredWitnesses, createTests, createTestsWithData}) => (
  <AddTestsOrDeclarationForm
    setTypes={setTypes}
    certificates={certificates}
    onClose={onClose}
    types={types}
    qcps={qcps}
    testsWithRequiredWitnesses={testsWithRequiredWitnesses}
    createTests={createTests}
    createTestsWithData={createTestsWithData}
  />
);

const TestsTypesList = ({classes, certificates, types, setTypes, setTestsTypesSelected, qcps, setQcps}) => (
  <>
    <DialogContent>
      <TestsListTable
        certificates={certificates}
        types={types}
        setTypes={(types) => setTypes(types)}
        qcps={qcps}
        setQcps={setQcps}
      />
    </DialogContent>
    <DialogActions classes={{root: classes.dialogActions}}>
      <Button
        onClick={() => setTestsTypesSelected(true)}
        color="primary"
        variant="contained"
        disabled={isEmpty(types) && isEmpty(qcps)}
      >
        Next
      </Button>
    </DialogActions>
  </>
);

const StyledTestTypesList = withStyles(TestsTypesList, styles);

const AddTests = ({classes, certificates = [], testsWithRequiredWitnesses, createTests, createTestsWithData}) => {
  const [testsTypesSelected, setTestsTypesSelected] = useState(false);
  const [open, setOpen] = useState(false);
  const [types, setTypes] = useState({});
  const [qcps, setQcps] = useState([]);

  const {CompanyStore, QcpStore} = useStores();

  useEffect(() => {
    CompanyStore.getCompaniesMetaData();
    QcpStore.qcps.load({limit: 100, offset: 0});
  }, []);

  useEffect(() => {
    if (open) return;

    setTypes({});
    setTestsTypesSelected(false);
    setQcps([]);
  }, [open]);

  const Component = testsTypesSelected ? TestsList : StyledTestTypesList;

  return (
    <>
      <Button
        color="primary"
        size="medium"
        variant="contained"
        onClick={() => setOpen(true)}
      >
        Add test / Declaration
      </Button>
      {open && (
        <Dialog
          open
          onClose={() => {
            setOpen(false);
            setTypes({});
          }}
          maxWidth="md"
          fullWidth
          classes={{paperWidthMd: classes.paperWidth}}
        >
          <Component
            certificates={certificates}
            types={types}
            setTestsTypesSelected={setTestsTypesSelected}
            onClose={() => setOpen(false)}
            setTypes={setTypes}
            qcps={qcps}
            setQcps={setQcps}
            testsWithRequiredWitnesses={testsWithRequiredWitnesses}
            createTests={createTests}
            createTestsWithData={createTestsWithData}
          />
        </Dialog>
      )}
    </>
  );
};

export default withStyles(observer(AddTests), styles);
