import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {TextField, Button, Typography} from "@mui/material";
import {withStyles} from "tss-react/mui";
import userService from "@core/api/user-service";
import styles from "./styles";

const AuthAppLogin = observer(({classes, login}) => {
  const [totp, setTotp] = useState("");

  const onSubmit = async () => {
    if (!totp) return;

    const {data} = await userService.verifyTotp({totp});
    login(data);
  };

  return (
    <>
      <Typography className={classes.description} align="left">
        Insert the one-time code from your authenticator app.
      </Typography>
      <TextField
        onKeyDown={(e) => {
          if (e.key !== "Enter") return;

          onSubmit();
        }}
        autoFocus
        className={classes.input}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        autoComplete="off"
        name="verificationCode"
        label="Verification code"
        onChange={(e) => setTotp(e.target.value)}
        value={totp}
        InputLabelProps={{
          shrink: true
        }}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        size="large"
        className={classes.submit}
        onClick={onSubmit}
      >
        Login
      </Button>
    </>
  );
});

export default withStyles(AuthAppLogin, styles);