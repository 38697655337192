import AcceptanceCriteriaLabel from "@core/components/AcceptanceCriteriaLabel";
import {TEST_RESULTS} from "@core/constants/testResults";
import React, {useCallback, useState} from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  MenuItem,
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import * as yup from "yup";
import {times, values, map, prop, isNil, keys, omit, isEmpty, uniq} from "ramda";
import {FieldArray, Formik} from "formik";
import {TEST_UNITS} from "@core/constants/test";
import {Input, MultipleSelect} from "@core/components/Form";
import EditIcon from "@mui/icons-material/Edit";
import ValueCell from "./components/ValueCell";
import ClientField from "../../../../Tests/Test/components/ClientField";
import TextField from "@core/components/FormikTextField";
import SelectField from "@core/components/FormikSelect";
import File from "@core/components/File";
import {Uploader} from "@core/components/Uploaders";
import EditPopup from "./components/EditPopup";
import AcceptanceRow from "./components/AcceptanceRow";
import TableAcceptanceRow from "./components/TableAcceptanceRow";
import LabTestFooter from "@core/components/LabTestFooter";
import ProducerTestFooter from "@core/components/ProducerTestFooter";
import {
  getIsAcceptable,
  isValueAcceptable,
  getUnitsFromMeasurements,
  getCalculatedAcceptanceCriteria
} from "./services";
import {getPoItemNumber} from "@core/helpers";
import {CUSTOM_EXCEPTIONS} from "./exceptions";
import {LOCATIONS, MEASUREMENTS_CONFIG} from "./data";
import styles from "./styles";

const DimensionalTest = ({classes, closeNewTest, isProducerTest, client, formRef, saveTest, test, user, ...props}) => {
  const certificate = props.inspectedCertificates ? props.inspectedCertificates[0] : props.certificate || {};
  const defaultQuantity = certificate ? certificate.items?.reduce((acc, curr) => acc + Number(curr.quantity), 0) : "";
  const defaultMeasurements = test?.properties.elements ? keys(omit(["productId", "file"], test.properties.elements[0])) : [];

  const [measurementToEdit, setMeasurementToEdit] = useState(null);
  const [quantity, setQuantity] = useState(test?.properties.elements ? test.properties.elements.length : defaultQuantity);
  const [measurements, setMeasurements] = useState(defaultMeasurements);

  const validationSchema = yup.object().shape({
    client: !isProducerTest ? yup.string().required("This field is required!") : yup.string(),
    lab: !isProducerTest ? yup.string().required("This field is required!") : yup.string(),
    elements: yup.array().of(yup.object().shape({
      productId: yup.string().required("This field is required!"),
      file: yup.string(),
    })).required(),
    acceptanceCriteria: yup.object(),
    acceptance: yup.string(),
    units: yup.object().required(),
    witnesses: yup.array().of(yup.object()),
    inspectionDate: yup.string(),
    inspectorJobNumber: yup.string(),
    file: yup.string(),
    notes: yup.string(),
    tags: yup.array().of(yup.string()),
  });

  const initialValues = {
    client: !isProducerTest ? (test?.properties.client || client.name || "") : "",
    lab: !isProducerTest ? (test?.properties.lab || user.company.name || "") : "",
    elements: test?.properties.elements ? test.properties.elements : defaultQuantity ? times(() => ({productId: ""}), defaultQuantity) : [],
    units: [TEST_UNITS.IMPERIAL, TEST_UNITS.METRIC].includes(test?.properties.units) ? getUnitsFromMeasurements(test?.properties.units, defaultMeasurements) : test?.properties.units || {},
    acceptanceCriteria: test?.properties.acceptanceCriteria || {},
    acceptance: test?.properties.acceptance || "",
    witnesses: test?.witnesses ? test.witnesses.map((witness) => witness.company) : [],
    inspectionDate: test?.inspectionDate || "",
    inspectorJobNumber: test?.inspectorJobNumber || "",
    tags: test?.properties.tags || [],
    file: test?.properties.file || "",
    notes: test?.properties.notes || "",
  };

  const poItemNumber = getPoItemNumber(certificate?.lineItem);

  const onSubmit = (values) => {
    if (isProducerTest) saveTest(omit(["witnesses"], values), {witnesses: values.witnesses});
    else saveTest(values);

    if (!closeNewTest) return;

    closeNewTest();
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={onSubmit}
      isInitialValid={validationSchema.isValidSync(initialValues)}
    >
      {(props) => {
        const onQuantity = useCallback((e) => {
          const currentNumber = quantity || 0;
          const value = Number(e.target.value);
          const newNumber = value || 1;

          const element = measurements.reduce((element, measurement) => {
            const locations = MEASUREMENTS_CONFIG[measurement].locations;
            element[measurement] = locations ? Object.fromEntries(locations.map((location) => [location, ""])) : "";

            return element;
          }, {productId: ""});

          const missingElements = newNumber > currentNumber ? times(() => element, newNumber - currentNumber) : [];
          const newElements = props.values.elements.concat(missingElements);

          props.setFieldValue("elements", newElements.slice(0, newNumber));

          setQuantity(value || "");
        }, [quantity, measurements, props.values.elements]);

        const onMeasurements = useCallback((values) => {
          const acceptanceMeasurements = keys(CUSTOM_EXCEPTIONS[props.values.acceptance]?.data) || [];
          const measurements = values.map((measurement) => {
            const [name] = Object.entries(MEASUREMENTS_CONFIG).find(([, {title}]) => title === measurement);

            return name;
          });

          const measurementsToShow = uniq([...acceptanceMeasurements, ...measurements]);
          setMeasurements(measurementsToShow);

          const elements = props.values.elements.length ?  props.values.elements : times(() => ({productId: ""}), quantity || 0);

          const newElements = elements.map((element) => ({
            productId: element.productId,
            ...measurementsToShow.reduce((acc, measurement) => {
              const locations = MEASUREMENTS_CONFIG[measurement].locations;

              acc[measurement] = locations ?
                Object.fromEntries(locations.map((location) => [location, element[measurement] ? element[measurement][location] : ""])) :
                element[measurement] || "";

              return acc;
            }, {})
          }));

          props.setFieldValue("elements", newElements);

          const units = getUnitsFromMeasurements(TEST_UNITS.METRIC, measurementsToShow);
          props.setFieldValue("units", units);
        }, [props.values.elements, props.values.acceptance]);
        
        const onAcceptance = (acceptance) => {
          if (!acceptance) {
            props.setFieldValue("acceptanceCriteria", {});
          } else {
            const {units: defaultUnits, data: acceptanceCriteria} = CUSTOM_EXCEPTIONS[acceptance];
            const measurements = keys(acceptanceCriteria);

            const calculatedAcceptanceCriteria = getCalculatedAcceptanceCriteria(acceptanceCriteria, poItemNumber);

            const element = measurements.reduce((element, measurement) => {
              const locations = MEASUREMENTS_CONFIG[measurement].locations || [];
              element[measurement] = locations.length ?
                Object.fromEntries(locations.map((location) => [location, element[measurement] ? element[measurement][location] : ""])) :
                element[measurement] || "";

              return element;
            }, {productId: ""});

            const units = measurements.reduce((acc, measurement) => {
              acc[measurement] = acceptanceCriteria[measurement].units || MEASUREMENTS_CONFIG[measurement].units[defaultUnits];

              return acc;
            }, {});

            props.setFieldValue("elements", times(() => element, quantity));
            props.setFieldValue("acceptanceCriteria", calculatedAcceptanceCriteria);
            props.setFieldValue("units", units);
            setMeasurements(measurements);
          }
        };

        const filteredAcceptances = Object.keys(CUSTOM_EXCEPTIONS).reduce((acc, acceptance) => {
          if (!CUSTOM_EXCEPTIONS[acceptance].company || CUSTOM_EXCEPTIONS[acceptance].company.includes(user.company.name)) {
            acc.push(acceptance);
          }

          return acc;
        }, []);

        const showFileCell = props.values.elements.some((element) => element.file);

        const isAcceptable = getIsAcceptable(props.values, measurements, poItemNumber) && props.isValid;
        const result = isAcceptable ? TEST_RESULTS.ACCEPTABLE : TEST_RESULTS.NOT_ACCEPTABLE;

        return (
          <Grid container spacing={3}>
            {isProducerTest && (
              <Grid item xs={12}>
                <Grid item>
                  <h1>Dimensional test</h1>
                </Grid>
              </Grid>
            )}
            {!isProducerTest && (
              <Grid item container spacing={3}>
                <Grid item xs={3}>
                  <ClientField isFromProducer={!!client.name} />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    name="lab"
                    label="Laboratory"
                    required
                  />
                </Grid>
              </Grid>
            )}
            <Grid item container spacing={3}>
              <Grid item xs={isProducerTest ? 4 : 3}>
                <Input
                  type="number"
                  inputProps={{min: 1, max: defaultQuantity && defaultQuantity}}
                  value={quantity}
                  label="Quantity inspected"
                  required
                  onChange={onQuantity}
                />
              </Grid>
              {!!defaultQuantity && (
                <Grid item xs={isProducerTest ? 4 : 3}>
                  <Input
                    value={quantity && Math.round(quantity / defaultQuantity * 100) || "Less than 1%"}
                    label="Percentage"
                    disabled
                    endAdornment="%"
                  />
                </Grid>
              )}
              {!isEmpty(filteredAcceptances) && (
                <Grid item xs={isProducerTest ? 4 : 3}>
                  <Grid item xs={12}>
                    <SelectField
                      name="acceptance"
                      label="Load requirements"
                      onChange={onAcceptance}
                    >
                      <MenuItem key="N/A" value={undefined}>N/A</MenuItem>
                      {filteredAcceptances.map((name) => (
                        <MenuItem key={name} value={name}>{name}</MenuItem>
                      ))}
                    </SelectField>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item container spacing={5} alignItems="flex-end">
              <Grid item xs={isProducerTest ? 8 : 6}>
                <MultipleSelect
                  required
                  label="Measurements"
                  value={measurements.map((measurement) => MEASUREMENTS_CONFIG[measurement].title)}
                  elements={map(prop("title"), values(MEASUREMENTS_CONFIG))}
                  onChange={onMeasurements}
                />
              </Grid>
            </Grid>
            {measurements.map((measurement) => (
              <AcceptanceRow
                measurement={measurement}
                poItemNumber={poItemNumber}
              />
            ))}
            {Boolean(measurements.length && props.values.elements.length) && (
              <Grid item xs={12}>
                <div className={classes.tableContainer}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="none" align="center" width="150" rowSpan={2}
                          className={classes.stickyColumn}>
                        Product ID
                        </TableCell>
                        {measurements.map((measurement) => (
                          <TableCell
                            padding="none"
                            colSpan={MEASUREMENTS_CONFIG[measurement].locations ? MEASUREMENTS_CONFIG[measurement].locations.length : 1}
                            rowSpan={MEASUREMENTS_CONFIG[measurement].locations ? 1 : 2}
                            align="center"
                          >
                            {MEASUREMENTS_CONFIG[measurement].title}&nbsp;
                          [{props.values.units[measurement]}]
                          </TableCell>
                        ))}
                        {showFileCell && (
                          <TableCell padding="none" align="center" rowSpan={3}>File</TableCell>
                        )}
                        <TableCell padding="none" align="center" rowSpan={3}>Actions</TableCell>
                      </TableRow>
                      <TableRow>
                        {measurements.map((measurement) => {
                          if (!MEASUREMENTS_CONFIG[measurement].locations) {
                            return null;
                          }

                          return MEASUREMENTS_CONFIG[measurement].locations.map((location) =>
                            <TableCell
                              align="center"
                              padding="none" 
                              style={{borderRight: "1px solid #E0E0E0"}}
                            >
                              {LOCATIONS[location]}
                            </TableCell>
                          );
                        })}
                        {props.values.acceptance && <TableCell align="center" padding="none" />}
                      </TableRow>
                      {props.values.acceptance && (
                        <TableRow>
                          <TableCell padding="none" align="center" width="150" rowSpan={2} className={classes.stickyColumn}>
                            <AcceptanceCriteriaLabel />
                          </TableCell>
                          <TableAcceptanceRow
                            acceptance={props.values.acceptance}
                            measurements={measurements}
                            units={props.values.units}
                            acceptanceCriteria={props.values.acceptanceCriteria}
                            poItem={poItemNumber}
                          />
                          <TableCell align="center" padding="none" />
                        </TableRow>
                      )}
                    </TableHead>
                    <TableBody>
                      <FieldArray name="elements">
                        {() => props.values.elements.map((element, index) => {
                          return (
                            <TableRow>
                              <TableCell
                                align="center"
                                padding="none"
                                width="150"
                                classes={{root: classes.inputCell}}
                                className={classes.stickyColumn}
                              >
                                <TextField
                                  name={`elements.${index}.productId`}
                                  required
                                />
                              </TableCell>
                              {measurements.map((measurement) => {
                                const standardUnits = props.values.units[measurement];
                                const acceptanceConfig = props.values.acceptanceCriteria && props.values.acceptanceCriteria[measurement] || {};
                              
                                if (!MEASUREMENTS_CONFIG[measurement].locations) {
                                  const value = element[measurement];
                                  const exceptionsFormulas = CUSTOM_EXCEPTIONS[props.values.acceptance]?.data[measurement] || {};

                                  const isAcceptable = isValueAcceptable({
                                    element,
                                    value,
                                    standardUnits,
                                    ...acceptanceConfig,
                                    ...exceptionsFormulas,
                                    poItem: poItemNumber,
                                    acceptanceCriteria: props.values.acceptanceCriteria,
                                  });

                                  return (
                                    <ValueCell isAcceptable={isAcceptable}>
                                      {value || "-"}
                                    </ValueCell>
                                  );
                                }

                                return MEASUREMENTS_CONFIG[measurement].locations.map((location) => {
                                  const name = location === "body" ? "body" : "ends";

                                  const locationAcceptanceConfig = acceptanceConfig[name] || {};
                                  const measurementException = CUSTOM_EXCEPTIONS[props.values.acceptance]?.data[measurement] || {};
                                  const locationExceptionsFormulas = measurementException[name] || {};
                                  const value = element[measurement] && element[measurement][location];
                                  const valueMin = element[measurement] && element[measurement][`${location}Min`];
                                  const valueMax = element[measurement] && element[measurement][`${location}Max`];

                                  const isAcceptable = isValueAcceptable({
                                    element,
                                    value,
                                    valueMin,
                                    valueMax,
                                    location,
                                    standardUnits,
                                    ...locationAcceptanceConfig,
                                    ...locationExceptionsFormulas,
                                    poItem: poItemNumber,
                                    acceptanceCriteria: props.values.acceptanceCriteria,
                                  });
                                
                                  if (location === "body" && element[measurement] && (element[measurement]["bodyMin"] || element[measurement]["bodyMax"])) return (
                                    <ValueCell isAcceptable={isAcceptable}>
                                      {`${element[measurement]["bodyMin"] || "min"} - ${element[measurement]["bodyMax"] || "max"}`}
                                    </ValueCell>
                                  );

                                  if (location === "leftEnd" && element[measurement] && (element[measurement]["leftEndMin"] || element[measurement]["leftEndMax"])) return (
                                    <ValueCell isAcceptable={isAcceptable}>
                                      {`${element[measurement]["leftEndMin"] || "min"} - ${element[measurement]["leftEndMax"] || "max"}`}
                                    </ValueCell>
                                  );

                                  if (location === "rightEnd" && element[measurement] && (element[measurement]["rightEndMin"] || element[measurement]["rightEndMax"])) return (
                                    <ValueCell isAcceptable={isAcceptable}>
                                      {`${element[measurement]["rightEndMin"] || "min"} - ${element[measurement]["rightEndMax"] || "max"}`}
                                    </ValueCell>
                                  );

                                  return (
                                    <ValueCell isAcceptable={isAcceptable}>
                                      {element[measurement] && element[measurement][location] || "-"}
                                    </ValueCell>
                                  );
                                });
                              })}
                              {showFileCell && (
                                <TableCell padding="none" align="center">
                                  {element.file && <File file={element.file} />}
                                </TableCell>
                              )}
                              <TableCell padding="none" align="center">
                                <IconButton onClick={() => setMeasurementToEdit(index)} color="primary" size="large">
                                  <EditIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </FieldArray>
                    </TableBody>
                  </Table>
                </div>
              </Grid>
            )}
            <Grid item xs={12}>
              <Uploader
                fileType="image/*, application/pdf, .csv"
                file={props.values.file}
                buttonText="Choose file"
                handleUploadedFile={(file) => {
                  props.setFieldValue("file", file.file.dir + file.file.name);
                }}
                onRemove={() => props.setFieldValue("file", "")}
              />
            </Grid>
            <Grid item container spacing={3}>
              <Grid item xs={isProducerTest ? 8 : 6}>
                <TextField
                  name="notes"
                  label="Notes"
                  multiline
                  rows={5}
                />
              </Grid>
            </Grid>
            {!isNil(measurementToEdit) && (
              <EditPopup
                onClose={() => setMeasurementToEdit(null)}
                index={measurementToEdit}
                measurements={measurements}
              />
            )}
            {isProducerTest ? (
              <ProducerTestFooter />
            ) : (
              <Grid item xs={12}>
                <LabTestFooter
                  onSubmit={() => onSubmit({...props.values, result})}
                  result={result}
                />
              </Grid>
            )}
          </Grid>
        );
      }}
    </Formik>
  );
};

export default withStyles(DimensionalTest, styles);
