import React, {useState, useMemo, useCallback, useRef, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {indexBy, omit, prop} from "ramda";
import {useTheme} from "@mui/material/styles";
import {
  Grid,
  Divider,
  Box,
} from "@mui/material";
import WeldsList from "../../../Welding/components/WeldsList";
import TestsList from "../TestsList";
import RawMaterialsTable from "../RawMaterialsTable";
import TestDrawerNavigation from "../TestDrawerNavigation";
import useStores from "../../../../../../useStores";
import {FILTERS} from "@core/constants/filters";
import {CAMPAIGN_SPECIMEN_LABELS} from "@core/constants/campaign";
import {ENTITIES} from "../../../../constants";
import {filterTests, getWeldDefects} from "../../../../helpers";
import {REPAIR_REGEX} from "../../../Welding/helpers";
import qs from "qs";
import {useHistory, useLocation} from "react-router-dom";
import DefectLog from "../DefectLog";

const WELDS_PER_PAGE = 5;

const WeldTestsDrawer = observer(({title, close, index, welds = [], pipes = [], navigate, withRepairs = true}) => {
  const [filteredTestIds, setFilteredTestIds] = useState([]);
  const [tests, setTests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const testsRefs = useRef({});

  const {CampaignStore} = useStores();

  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();

  const campaign = CampaignStore.campaign;

  const weld = useMemo(() => {
    const weld = welds[index] || {};

    return weld;
  }, [index]);

  const weldsByPosition = useMemo(() => {
    if(weld.position) return indexBy(prop("position"), welds);

    return welds;
  }, [welds]);

  const prevWeld = useMemo(() => {
    const position = weld.position || index;
    const prevWeld = weldsByPosition[position - 1] || {};

    return prevWeld;
  }, [index]);

  const nextWeld = useMemo(() => {
    const position = weld.position || index;
    const nextWeld = weldsByPosition[position + 1] || {};

    return nextWeld;
  }, [index]);

  const prevPipe = useMemo(() => {
    const prevPipe = pipes[index] || {};

    return prevPipe;
  }, [index]);

  const nextPipe = useMemo(() => {
    const nextPipe = pipes[index + 1] || {};

    return nextPipe;
  }, [index]);

  useEffect(() => {
    if(!weld._id) return;

    const tests = filterTests({[FILTERS.WELD_ID]: [weld.weldNumber]}, campaign.tests, campaign.rawMaterials);
    setTests(tests);
    setIsLoading(false);
  }, [weld._id]);

  const rawMaterials = useMemo(() => campaign.rawMaterials.filter((rawMaterial) => rawMaterial.pipes.includes(weld.weldNumber)), [weld.weldNumber]);

  const onTestClick = useCallback((_, testId) => {
    testsRefs.current[testId].scrollIntoView({behavior: "smooth", block: "start"});
  }, []);

  const testsById = useMemo(() => indexBy(prop("_id"), tests), [tests]);
  const filteredTests = useMemo(() => filteredTestIds.length ? filteredTestIds.map((id) => testsById[id]) : tests,  [filteredTestIds, tests]);

  const weldRepairs = useMemo(() => {
    const originalWeldNumber = weld.weldNumber?.replace(REPAIR_REGEX, "");

    return welds.filter((weld) => weld.weldNumber.includes(originalWeldNumber));
  }, [weld._id]);

  const search = useMemo(() => qs.parse(location.search, {ignoreQueryPrefix: true}), [location.search]);

  const onWeldClick = useCallback((weld) => {
    history.push({
      pathname: history.pathname,
      search: qs.stringify({
        ...omit([ENTITIES.WELD, ENTITIES.PIPE], search),
        [ENTITIES.WELD]: weld.weldNumber
      }),
    });
  }, [search]);

  return (
    <>
      <TestDrawerNavigation
        close={close}
        text={title || `${weld.weldNumber}: Tests Reports (${tests.length})`}
        secondPrevItem={prevWeld}
        prevItem={prevPipe}
        nextItem={nextPipe}
        secondNextItem={nextWeld}
        index={weld.position || index}
        welds={welds}
        pipes={pipes}
        entity={ENTITIES.WELD}
        navigate={navigate}
      />
      <Box
        sx={{
          padding: theme.spacing(2),
        }}
      >
        <WeldsList
          header="Weld Information"
          welds={withRepairs ? weldRepairs : welds}
          currentWeld={weld}
          onWeldClick={onWeldClick}
          limit={WELDS_PER_PAGE}
        />
      </Box>
      <Box
        sx={{
          overflowY: "scroll",
          overflowX: "hidden",
          padding: theme.spacing(2),
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <DefectLog
            getDefects={getWeldDefects}
            welds={[weld.weldNumber]}
          />
          <RawMaterialsTable
            id={weld.weldNumber}
            rawMaterials={rawMaterials}
            label="Weld Consumable(s)"
            type={CAMPAIGN_SPECIMEN_LABELS.WELD}
          />
          <Grid item xs={12}>
            <TestsList
              tests={filteredTests}
              isLoading={isLoading}
              testsRefs={testsRefs}
              setFilteredTestIds={setFilteredTestIds}
              onTestClick={onTestClick}
              allTests={tests}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
});

export default WeldTestsDrawer;