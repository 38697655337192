export const columns = [{
  isSorted: true,
  dataIndex: "name",
  title: "Name",
}, {
  isSorted: true,
  dataIndex: "certificationType",
  title: "Certification Type",
}, {
  isSorted: true,
  dataIndex: "properties.product.productType.name",
  title: "Product Type",
}, {
  isSorted: true,
  dataIndex: "properties.product.qcp.name",
  title: "Qcp Name",
}];