import React from "react";
import {TYPES, TEST_TYPES, CUSTOM_TEST_TYPES} from "@core/constants/test";
import {PLOT_TYPES} from "@core/components/Chart/сonstants";
import modules from "@core/constants/modules";

import CertViewChemical from "@core/components/TestViews/ChemicalComposition";
import CertViewImpact from "@core/components/TestViews/ImpactTest";
import CertViewTensile from "@core/components/TestViews/TensileTest";
import CertViewHardness from "@core/components/TestViews/HardnessTest";
import CertViewHydrostatic from "@core/components/TestViews/HydrostaticTest";
import CertViewCorrosion from "@core/components/TestViews/CorrosionTest";
import CertViewRadiographic from "@core/components/TestViews/RadioGraphic";
import CertViewLiquid from "@core/components/TestViews/LiquidePenetrant";
import CertViewBend from "@core/components/TestViews/BendTest";
import CertViewNotes from "@core/components/TestViews/NotesTest";
import CertViewTreatment from "@core/components/TestViews/TreatmentTest";
import CertViewOther from "@core/components/TestViews/OtherTest";
import CertViewUltrasonic from "@core/components/TestViews/UltrasonicTest";
import CertViewMagnetic from "@core/components/TestViews/MagneticParticle";
import CertViewAustenite from "@core/components/TestViews/AusteniteSpacing";
import CertViewFerrite from "@core/components/TestViews/FerriteContent";
import CertViewHeatTreatment from "@core/components/TestViews/HeatTreatment";
import CertViewMicrographic from "@core/components/TestViews/Micrographic";
import CertViewMacrographic from "@core/components/TestViews/Macrographic";
import CertViewSplit from "@core/components/TestViews/Split";
import CertViewSupplement from "@core/components/TestViews/SupplementTest";
import CertViewStatement from "@core/components/TestViews/StatementTest";
import CertViewSupplierCertificates from "@core/components/TestViews/SupplierCertificates";
import CertViewProofload from "@core/components/TestViews/ProofloadTest";
import CertViewVisual from "@core/components/TestViews/VisualTest";
import CertViewSulphideStressCorrosionCracking from "@core/components/TestViews/SulphideStressCorrosionCracking";
import CertViewHydrogenInducedCracking from "@core/components/TestViews/HydrogenInducedCracking";
import CertViewCrackTipOpeningDisplacement from "@core/components/TestViews/CrackTipOpeningDisplacement";
import CertViewElectromagneticInduction from "@core/components/TestViews/ElectromagneticInduction";
import CertViewInspectionReleaseNote from "@core/components/TestViews/InspectionReleaseNote";
import CertViewDimensionalTest from "@core/components/TestViews/DimensionalTest";
import CertViewFlatteningTest from "@core/components/TestViews/FlatteningTest";
import CertViewDropWeightTear from "@core/components/TestViews/DropWeightTear";
import CertViewCustomTest from "@core/components/TestViews/CustomTest";
import CertViewCustomReport from "@core/components/TestViews/CustomReport";
import CertViewPorosityTest from "@core/components/TestViews/PorosityTest";
import CertViewTransverseRuptureStrength from "@core/components/TestViews/TransverseRuptureStrength";
import CertViewErosionTest from "@core/components/TestViews/ErosionTest";
import CertViewPalmqvistTest from "@core/components/TestViews/PalmqvistTest";
import CertViewDensityTest from "@core/components/TestViews/DensityTest";
import CertViewPositiveMaterialIdentification from "@core/components/TestViews/PositiveMaterialIdentification";

import AddDataFormHeatTreatment from "@core/components/TestAddDataForms/HeatTreatment";
import AddDataFormRadioGraphic from "@core/components/TestAddDataForms/RadioGraphicReport";
import AddDataFormNotes from "@core/components/TestAddDataForms/NotesTest";
import AddDataFormTreatment from "@core/components/TestAddDataForms/TreatmentTest";
import AddDataFormSupplement from "@core/components/TestAddDataForms/SupplementTest";
import AddDataFormStatement from "@core/components/TestAddDataForms/StatementTest";
import AddDataFormProofload from "@core/components/TestAddDataForms/ProofloadTest";
import AddDataFormInspectionReleaseNote from "@core/components/TestAddDataForms/InspectionReleaseNote";

import TestFormChemical from "@core/components/TestForms/ChemicalComposition";
import TestFormImpact from "@core/components/TestForms/ImpactTest";
import TestFormTensile from "@core/components/TestForms/TensileTest";
import TestFormHardness from "@core/components/TestForms/HardnessTest";
import TestFormHydrostatic from "@core/components/TestForms/HydrostaticTest";
import TestFormCorrosion from "@core/components/TestForms/CorrosionTest";
import TestFormRadioGraphic from "@core/components/TestForms/RadioGraphic";
import TestFormLiquid from "@core/components/TestForms/LiquidePenetrant";
import TestFormBend from "@core/components/TestForms/BendTest";
import TestFormTreatment from "@core/components/TestForms/TreatmentTest";
import TestFormOther from "@core/components/TestForms/OtherTest";
import TestFormUltrasonic from "@core/components/TestForms/UltrasonicTest";
import TestFormAustenite from "@core/components/TestForms/AusteniteSpacing";
import TestFormFerrite from "@core/components/TestForms/FerriteContent";
import TestFormMagnetic from "@core/components/TestForms/MagneticParticle";
import TestFormMicrographic from "@core/components/TestForms/Micrographic";
import TestFormMacrographic from "@core/components/TestForms/Macrographic";
import TestFormProofload from "@core/components/TestForms/ProofloadTest";
import TestFormVisual from "@core/components/TestForms/VisualTest";
import TestFormSulphideStressCorrosionCracking from "@core/components/TestForms/SulphideStressCorrosionCracking";
import TestFormHydrogenInducedCracking from "@core/components/TestForms/HydrogenInducedCracking";
import TestFormCrackTipOpeningDisplacement from "@core/components/TestForms/CrackTipOpeningDisplacement";
import TestFormElectromagneticInduction from "@core/components/TestForms/ElectromagneticInduction";
import TestFormDimensionalTest from "@core/components/TestForms/DimensionalTest";
import TestFormFlatteningTest from "@core/components/TestForms/FlatteningTest";
import TestFormDropWeightTear from "@core/components/TestForms/DropWeightTear";
import TestFormPorosity from "@core/components/TestForms/PorosityTest";
import TransverseRuptureStrength from "@core/components/TestForms/TransverseRuptureStrength";
import TestFormErosionTest from "@core/components/TestForms/ErosionTest";
import TestFormPalmqvist from "@core/components/TestForms/PalmqvistTest";
import TestFormDensity from "@core/components/TestForms/DensityTest";
import TestFormPositiveMaterialIdentification from "@core/components/TestForms/PositiveMaterialIdentification";

import themeSettings from "../../theme-settings";

export default {
  [TYPES.CHEMICAL]: {
    certificateView: <CertViewChemical />,
    testForm: <TestFormChemical />,
    type: TYPES.CHEMICAL,
    color: "#d02d52",
    icon: "/images/cert-icons/chem-comp.svg",
    title: "Chemical Composition",
    titlePlural: "Chemical Compositions",
    responsible: [modules.LAB_INTERNAL, modules.PRODUCER],
    testType: TEST_TYPES.TEST,
  },

  [TYPES.IMPACT]: {
    certificateView: <CertViewImpact />,
    testForm: <TestFormImpact />,
    type: TYPES.IMPACT,
    color: "#9c27b0",
    icon: "/images/cert-icons/impact-test.svg",
    title: "Impact Test",
    titlePlural: "Impact Tests",
    hasPlot: true,
    responsible: [modules.LAB_INTERNAL, modules.PRODUCER],
    testType: TEST_TYPES.TEST,
    plots: [PLOT_TYPES.HISTOGRAM, PLOT_TYPES.SCATTER],
  },

  [TYPES.TENSILE]: {
    certificateView: <CertViewTensile />,
    testForm: <TestFormTensile />,
    type: TYPES.TENSILE,
    color: "#9c27b0",
    icon: "/images/cert-icons/tensile-test.svg",
    title: "Tensile Test",
    responsible: [modules.LAB_INTERNAL, modules.PRODUCER],
    testType: TEST_TYPES.TEST,
    plots: [PLOT_TYPES.HISTOGRAM, PLOT_TYPES.SCATTER],
  },

  [TYPES.HOT_TENSILE]: {
    certificateView: <CertViewTensile />,
    testForm: <TestFormTensile />,
    type: TYPES.HOT_TENSILE,
    color: "#9c27b0",
    icon: "/images/cert-icons/tensile-test.svg",
    title: "Hot Tensile Test",
    responsible: [modules.LAB_INTERNAL],
  },

  [TYPES.HARDNESS]: {
    certificateView: <CertViewHardness />,
    testForm: <TestFormHardness />,
    type: TYPES.HARDNESS,
    color: "#9c27b0",
    icon: "/images/cert-icons/hardness-test.svg",
    title: "Hardness Test",
    titlePlural: "Hardness Tests",
    plots: [PLOT_TYPES.BOX_PLOT, PLOT_TYPES.VIOLIN, PLOT_TYPES.HISTOGRAM, PLOT_TYPES.SCATTER],
    responsible: [modules.LAB_INTERNAL, modules.PRODUCER],
    testType: TEST_TYPES.TEST,
  },

  [TYPES.HYDROSTATIC]: {
    certificateView: <CertViewHydrostatic />,
    testForm: <TestFormHydrostatic />,
    addDataForm: <TestFormHydrostatic />,
    type: TYPES.HYDROSTATIC,
    color: "#9c27b0",
    icon: "/images/cert-icons/hydrostatic_test.png",
    title: "Hydrostatic Test",
    responsible: [modules.LAB_INTERNAL, modules.PRODUCER, modules.END_OWNER],
    testType: TEST_TYPES.TEST,
  },

  [TYPES.CORROSION]: {
    certificateView: <CertViewCorrosion />,
    testForm: <TestFormCorrosion />,
    type: TYPES.CORROSION,
    color: "#9c27b0",
    icon: "/images/cert-icons/corrosion-test.svg",
    title: "Corrosion Test",
    responsible: [modules.LAB_INTERNAL],
    testType: TEST_TYPES.TEST,
  },

  [TYPES.RADIOGRAPHIC]: {
    certificateView: <CertViewRadiographic />,
    testForm: <TestFormRadioGraphic />,
    addDataForm: <AddDataFormRadioGraphic />,
    type: TYPES.RADIOGRAPHIC,
    color: "#9c27b0",
    icon: "/images/cert-icons/radio-graphic.svg",
    title: "Radiographic Report",
    responsible: [modules.LAB_INTERNAL, modules.PRODUCER, modules.END_OWNER],
    testType: TEST_TYPES.TEST,
  },

  [TYPES.LIQUID]: {
    certificateView: <CertViewLiquid />,
    testForm: <TestFormLiquid />,
    addDataForm: <TestFormLiquid />,
    type: TYPES.LIQUID,
    color: "#9c27b0",
    icon: "/images/cert-icons/liquid_penetrant.png",
    title: "Liquid Penetrant Examination",
    responsible: [modules.LAB_INTERNAL, modules.PRODUCER, modules.END_OWNER],
    testType: TEST_TYPES.TEST,
  },

  [TYPES.BEND]: {
    certificateView: <CertViewBend />,
    testForm: <TestFormBend />,
    type: TYPES.BEND,
    color: "#9c27b0",
    icon: "/images/cert-icons/bending.svg",
    title: "Bend Test",
    responsible: [modules.LAB_INTERNAL],
    testType: TEST_TYPES.TEST,
  },

  [TYPES.NOTES]: {
    certificateView: <CertViewNotes />,
    addDataForm: <AddDataFormNotes />,
    type: TYPES.NOTES,
    color: "#9c27b0",
    icon: "/images/cert-icons/notes_test.png",
    title: "Notes",
    responsible: [modules.PRODUCER, modules.END_OWNER],
    testType: TEST_TYPES.DECLARATION,
  },

  [TYPES.TREATMENT]: {
    certificateView: <CertViewTreatment />,
    testForm: <TestFormTreatment />,
    addDataForm: <AddDataFormTreatment />,
    type: TYPES.TREATMENT,
    color: "#9c27b0",
    icon: "/images/cert-icons/treatment_test.gif",
    title: "Treatment",
    responsible: [modules.LAB_INTERNAL, modules.PRODUCER],
    testType: TEST_TYPES.DECLARATION,
  },

  [TYPES.OTHER]: {
    certificateView: <CertViewOther />,
    addDataForm: <TestFormOther />,
    type: TYPES.OTHER,
    color: "#9c27b0",
    icon: "/images/cert-icons/other_test.png",
    title: "Other tests and Guarantees",
    responsible: [modules.PRODUCER, modules.END_OWNER],
    testType: TEST_TYPES.DECLARATION,
  },

  [TYPES.ULTRASONIC]: {
    certificateView: <CertViewUltrasonic />,
    testForm: <TestFormUltrasonic />,
    addDataForm: <TestFormUltrasonic />,
    type: TYPES.ULTRASONIC,
    color: "#9c27b0",
    icon: "/images/cert-icons/ultrasonic_test.gif",
    title: "Ultrasonic Test",
    responsible: [modules.LAB_INTERNAL, modules.PRODUCER, modules.END_OWNER],
    testType: TEST_TYPES.TEST,
  },

  [TYPES.MAGNETIC]: {
    certificateView: <CertViewMagnetic />,
    testForm: <TestFormMagnetic />,
    addDataForm: <TestFormMagnetic />,
    type: TYPES.MAGNETIC,
    color: "#9c27b0",
    icon: "/images/cert-icons/magnetic_particle.png",
    title: "Magnetic Particle Examination",
    responsible: [modules.LAB_INTERNAL, modules.PRODUCER, modules.END_OWNER],
    testType: TEST_TYPES.TEST,
  },

  [TYPES.AUSTENITE]: {
    certificateView: <CertViewAustenite />,
    testForm: <TestFormAustenite />,
    type: TYPES.AUSTENITE,
    color: "#9c27b0",
    icon: "/images/cert-icons/ferrite.png",
    title: "Austenite spacing",
    responsible: [modules.LAB_INTERNAL],
    testType: TEST_TYPES.TEST,
  },

  [TYPES.FERRITE]: {
    certificateView: <CertViewFerrite />,
    testForm: <TestFormFerrite />,
    addDataForm: <TestFormFerrite />,
    type: TYPES.FERRITE,
    color: "#9c27b0",
    icon: "/images/cert-icons/ferrite.png",
    title: "Ferrite Content Measurement",
    responsible: [modules.PRODUCER, modules.LAB_INTERNAL],
    testType: TEST_TYPES.TEST,
  },
  [TYPES.MICROGRAPHIC]: {
    certificateView: <CertViewMicrographic />,
    testForm: <TestFormMicrographic />,
    addDataForm: <TestFormMicrographic />,
    type: TYPES.MICROGRAPHIC,
    color: "#9c27b0",
    icon: "/images/cert-icons/micrographic.png",
    title: "Micrographic Test",
    responsible: [modules.PRODUCER, modules.LAB_INTERNAL],
    testType: TEST_TYPES.TEST,
  },
  [TYPES.MACROGRAPHIC]: {
    certificateView: <CertViewMacrographic />,
    testForm: <TestFormMacrographic />,
    type: TYPES.MACROGRAPHIC,
    color: "#9c27b0",
    icon: "/images/cert-icons/micrographic.png",
    title: "Macrographic Test",
    responsible: [modules.LAB_INTERNAL],
    testType: TEST_TYPES.TEST,
  },
  [TYPES.MACROGRAPHIC]: {
    certificateView: <CertViewMacrographic />,
    testForm: <TestFormMacrographic />,
    type: TYPES.MACROGRAPHIC,
    color: "#9c27b0",
    icon: "/images/cert-icons/micrographic.png",
    title: "Macrographic Test",
    responsible: [modules.LAB_INTERNAL],
    testType: TEST_TYPES.TEST,
  },

  [TYPES.HEATTREATMENT]: {
    certificateView: <CertViewHeatTreatment />,
    addDataForm: <AddDataFormHeatTreatment />,
    type: TYPES.HEATTREATMENT,
    color: "#9c27b0",
    icon: "/images/cert-icons/heat_treatment.png",
    title: "Heat Treatment",
    responsible: [modules.PRODUCER, modules.END_OWNER],
    testType: TEST_TYPES.DECLARATION,
  },

  [TYPES.SPLIT]: {
    certificateView: <CertViewSplit />,
    type: TYPES.SPLIT,
    color: "#0277BD",
    icon: "/images/cert-icons/split.png",
    title: "Remarking & Splitting",
    responsible: [modules.PRODUCER, modules.END_OWNER],
  },

  [TYPES.SUPPLEMENT]: {
    certificateView: <CertViewSupplement />,
    addDataForm: <AddDataFormSupplement />,
    type: TYPES.SUPPLEMENT,
    color: "#0277BD",
    icon: "/images/cert-icons/supplement_test.svg",
    title: "Supplier tests",
    responsible: [modules.PRODUCER, modules.END_OWNER],
    testType: TEST_TYPES.TEST,
  },

  [TYPES.SUPPLIER]: {
    certificateView: <CertViewSupplierCertificates />,
    type: TYPES.SUPPLIER,
    color: "#0277BD",
    icon: "/images/cert-icons/supplement_test.svg",
    title: "Supplier certificates",
    responsible: [modules.PRODUCER, modules.END_OWNER],
  },

  [TYPES.STATEMENT]: {
    certificateView: <CertViewStatement />,
    addDataForm: <AddDataFormStatement />,
    type: TYPES.STATEMENT,
    color: "#0277BD",
    icon: "/images/cert-icons/statement.svg",
    title: "Certificate statements",
    responsible: [modules.PRODUCER, modules.END_OWNER],
    testType: TEST_TYPES.DECLARATION,
  },

  [TYPES.PROOFLOAD]: {
    certificateView: <CertViewProofload />,
    testForm: <TestFormProofload />,
    addDataForm: <AddDataFormProofload />,
    type: TYPES.PROOFLOAD,
    color: "#9c27b0",
    icon: "/images/cert-icons/statement.svg",
    title: "Proofload Test",
    responsible: [modules.LAB_INTERNAL, modules.PRODUCER, modules.END_OWNER],
    testType: TEST_TYPES.TEST,
  },

  [TYPES.VISUAL]: {
    certificateView: <CertViewVisual />,
    testForm: <TestFormVisual />,
    addDataForm: <TestFormVisual />,
    type: TYPES.VISUAL,
    color: "#9c27b0",
    icon: "/images/cert-icons/statement.svg",
    title: "Visual Test",
    responsible: [modules.LAB_INTERNAL, modules.PRODUCER, modules.END_OWNER],
    testType: TEST_TYPES.TEST,
  },

  [TYPES.SULPHIDE_STRESS_CORROSIO_CRACKING]: {
    certificateView: <CertViewSulphideStressCorrosionCracking />,
    testForm: <TestFormSulphideStressCorrosionCracking />,
    type: TYPES.SULPHIDE_STRESS_CORROSIO_CRACKING,
    color: "#9c27b0",
    icon: "/images/cert-icons/statement.svg",
    title: "Sulphide Stress Corrosion Cracking Test (SSCC)",
    responsible: [modules.LAB_INTERNAL],
    testType: TEST_TYPES.TEST,
  },

  [TYPES.HYDROGEN_INDUCED_CRACKING]: {
    certificateView: <CertViewHydrogenInducedCracking />,
    testForm: <TestFormHydrogenInducedCracking />,
    type: TYPES.HYDROGEN_INDUCED_CRACKING,
    color: "#9c27b0",
    icon: "/images/cert-icons/statement.svg",
    title: "Hydrogen Induced Cracking Test (HIC)",
    responsible: [modules.LAB_INTERNAL],
    testType: TEST_TYPES.TEST,
  },

  [TYPES.CRACK_TIP_OPENING_DISPLACEMENT]: {
    certificateView: <CertViewCrackTipOpeningDisplacement />,
    testForm: <TestFormCrackTipOpeningDisplacement />,
    type: TYPES.CRACK_TIP_OPENING_DISPLACEMENT,
    color: "#9c27b0",
    icon: "/images/cert-icons/statement.svg",
    title: "Crack Tip Opening Displacement Test (CTOD)",
    responsible: [modules.LAB_INTERNAL],
    testType: TEST_TYPES.TEST,
  },

  [TYPES.ELECTROMAGNETIC_INDUCTION]: {
    certificateView: <CertViewElectromagneticInduction />,
    testForm: <TestFormElectromagneticInduction />,
    addDataForm: <TestFormElectromagneticInduction />,
    type: TYPES.ELECTROMAGNETIC_INDUCTION,
    color: "#9c27b0",
    icon: "/images/cert-icons/statement.svg",
    title: "Electromagnetic Induction Test (EMI)",
    responsible: [modules.LAB_INTERNAL, modules.PRODUCER, modules.END_OWNER],
    testType: TEST_TYPES.TEST,
  },

  [TYPES.INSPECTION_RELEASE_NOTE]: {
    certificateView: <CertViewInspectionReleaseNote />,
    addDataForm: <AddDataFormInspectionReleaseNote />,
    type: TYPES.INSPECTION_RELEASE_NOTE,
    color: "#9c27b0",
    icon: "/images/cert-icons/notes_test.png",
    title: "Inspection Release Note (IRN)",
    responsible: [modules.PRODUCER, modules.END_OWNER],
    testType: TEST_TYPES.DECLARATION,
  },

  [TYPES.DIMENSIONAL]: {
    certificateView: <CertViewDimensionalTest />,
    addDataForm: <TestFormDimensionalTest />,
    testForm: <TestFormDimensionalTest />,
    type: TYPES.DIMENSIONAL,
    color: "#9c27b0",
    icon: "/images/cert-icons/other_test.png",
    title: "Dimensional Test",
    responsible: [modules.PRODUCER, modules.END_OWNER, modules.LAB_INTERNAL],
    plots: [PLOT_TYPES.BOX_PLOT, PLOT_TYPES.VIOLIN, PLOT_TYPES.HISTOGRAM, PLOT_TYPES.SCATTER],
    testType: TEST_TYPES.TEST,
  },

  [TYPES.FLATTENING]: {
    certificateView: <CertViewFlatteningTest />,
    testForm: <TestFormFlatteningTest />,
    type: TYPES.FLATTENING,
    color: "#9c27b0",
    icon: "/images/cert-icons/other_test.png",
    title: "Flattening Test",
    responsible: [modules.LAB_INTERNAL],
    testType: TEST_TYPES.TEST,
  },

  [TYPES.DROP_WEIGHT_TEAR]: {
    certificateView: <CertViewDropWeightTear />,
    testForm: <TestFormDropWeightTear />,
    type: TYPES.DROP_WEIGHT_TEAR,
    color: "#9c27b0",
    icon: "/images/cert-icons/other_test.png",
    title: "Drop Weight Tear Test (DWTT)",
    responsible: [modules.LAB_INTERNAL],
    testType: TEST_TYPES.TEST,
  },

  [TYPES.CUSTOM]: {
    certificateView: <CertViewCustomTest />,
    type: TYPES.CUSTOM,
    color: "#0277BD",
    title: "Custom test",
    responsible: [modules.PRODUCER, modules.END_OWNER],
    plotsByDisplayName: {
      [CUSTOM_TEST_TYPES.FINAL_INSPECTION]: [PLOT_TYPES.BOX_PLOT, PLOT_TYPES.VIOLIN, PLOT_TYPES.SCATTER],
      [CUSTOM_TEST_TYPES.COUNTERBORING]: [PLOT_TYPES.RADAR],
    },
  },

  [TYPES.CUSTOM_REPORT]: {
    certificateView: <CertViewCustomReport />,
    type: TYPES.CUSTOM_REPORT,
    color: themeSettings.palette.warning.main,
    title: "Custom report",
    responsible: [modules.PRODUCER, modules.END_OWNER],
  },

  [TYPES.POROSITY]: {
    certificateView: <CertViewPorosityTest />,
    addDataForm: <TestFormPorosity />,
    testForm: <TestFormPorosity />,
    type: TYPES.POROSITY,
    color: "#9c27b0",
    icon: "/images/cert-icons/other_test.png",
    title: "Porosity Test",
    responsible: [modules.LAB_INTERNAL],
    testType: TEST_TYPES.TEST,
  },

  [TYPES.TRANSVERSE_RUPTURE_STRENGTH]: {
    certificateView: <CertViewTransverseRuptureStrength />,
    addDataForm: <TransverseRuptureStrength />,
    type: TYPES.TRANSVERSE_RUPTURE_STRENGTH,
    color: "#9c27b0",
    title: "Transverse Rupture Strength (TRS)",
    responsible: [modules.LAB_INTERNAL, modules.PRODUCER],
    testType: TEST_TYPES.TEST,
  },

  [TYPES.EROSION_TEST]: {
    certificateView: <CertViewErosionTest />,
    addDataForm: <TestFormErosionTest />,
    testForm: <TestFormErosionTest />,
    type: TYPES.EROSION_TEST,
    color: "#d02d52",
    title: "Erosion Test",
    titlePlural: "Erosion Tests",
    responsible: [modules.LAB_INTERNAL, modules.PRODUCER],
    testType: TEST_TYPES.TEST,
  },

  [TYPES.PALMQVIST_TEST]: {
    certificateView: <CertViewPalmqvistTest />,
    addDataForm: <TestFormPalmqvist />,
    testForm: <TestFormPalmqvist />,
    type: TYPES.PALMQVIST_TEST,
    color: "#d02d52",
    title: "Palmqvist Test",
    titlePlural: "Palmqvist Tests",
    responsible: [modules.LAB_INTERNAL, modules.PRODUCER],
    testType: TEST_TYPES.TEST,
  },

  [TYPES.DENSITY_TEST]: {
    certificateView: <CertViewDensityTest />,
    addDataForm: <TestFormDensity />,
    testForm: <TestFormDensity />,
    type: TYPES.DENSITY_TEST,
    color: "#d02d52",
    title: "Density Test",
    titlePlural: "Density Tests",
    responsible: [modules.LAB_INTERNAL, modules.PRODUCER],
    testType: TEST_TYPES.TEST,
  },

  [TYPES.POSITIVE_MATERIAL_IDENTIFICATION]: {
    certificateView: <CertViewPositiveMaterialIdentification />,
    addDataForm: <TestFormPositiveMaterialIdentification />,
    testForm: <TestFormPositiveMaterialIdentification />,
    type: TYPES.POSITIVE_MATERIAL_IDENTIFICATION,
    color: "#0277BD",
    title: "Positive Material Identification (PMI)",
    responsible: [modules.LAB_INTERNAL, modules.PRODUCER],
    testType: TEST_TYPES.TEST,
  }
};
