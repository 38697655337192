import React, {useCallback, useEffect, useState} from "react";
import {Box, Button, Grid, circularProgressClasses} from "@mui/material";
import {Input} from "@core/components/Form";
import {debounce} from "@core/helpers";
import {getFilteredDataFromApiAsync, getValuesFromApiAsync} from "./helpers";
import Loader from "@core/components/Loader";
import {COLUMN_NAMES_NEW, TABLE_FIELDS} from "./constants";
import CircularProgress from "@mui/material/CircularProgress";
import IconistsIcon from "@core/components/IconistsIcon";
import {ICONISTS_ICONS} from "@core/constants/iconistsIcons";
import "react-datasheet-grid/dist/style.css";
import DataSheetTable from "@core/components/DataSheetTable";

const TraceabilityPage = () => {
  const [initialLoad, setInitialLoad] = useState(true);
  const [filterInProgress, setFilterInProgress] = useState(false);
  const [data, setData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onInputChange = useCallback(async (event) => {
    setFilterInProgress(true);
    const newData = await getFilteredDataFromApiAsync(event.target.value);
    setData(newData);
    setFilterInProgress(false);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const initialData = await getValuesFromApiAsync();
      setData(initialData);
      setInitialLoad(false);
    };
    fetchData();
  }, []);

  const onSubmit = async () => {
    setIsSubmitting(true);
    const newData = await getValuesFromApiAsync(); // TODO change to logic which takes data from table and sends it to the server
    setData(newData);
    setIsSubmitting(false);
  };

  return (
    <>
      <Box sx={{maxWidth: "1024px"}}>
        <Box sx={{
          [`.${circularProgressClasses.root}`]: {
            margin: (theme) => `0 ${theme.spacing(1)} 0 0`
          }
        }}>
          <Input
            disabled={filterInProgress}
            endAdornment={filterInProgress && <Loader size={15} />}
            label="Search for material(s)"
            onChange={debounce(onInputChange, 700)}
          />
        </Box>

        <Box marginTop={2}>
          <Grid container marginBottom={2} gap={3}>
            <Button variant="contained" size="small" disabled={isSubmitting} onClick={onSubmit}>
              {isSubmitting && <CircularProgress color="inherit" size={12} sx={{marginRight: 1}}/>}
              Save changes
            </Button>
            <Grid item>
              <Button
                variant="secondaryNewDesign"
                size="small"
                disabled={initialLoad}
              >
                <Box marginRight={"5px"}>
                  <IconistsIcon icon={ICONISTS_ICONS.fileText} width="18px"/>
                </Box>
                Import Data
              </Button>
            </Grid>
          </Grid>
          {
            initialLoad ?
              <Loader size={40}/> :
              <>
                <DataSheetTable
                  data={data}
                  setData={setData}
                  disabled={filterInProgress || isSubmitting}
                  columns={TABLE_FIELDS}
                  headerNameByColId={COLUMN_NAMES_NEW}
                />
              </>
          }
        </Box>
      </Box>
    </>
  );
};

export default TraceabilityPage;