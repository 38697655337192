import React from "react";
import {Grid} from "@mui/material";
import PendingActions from "./components/PendingActions";
import TestsOverview from "./components/TestsOverview";

const TestsAndAnalysesPage = ({activeSubTab}) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <PendingActions />
      </Grid>
      <Grid item xs={12}>
        <TestsOverview
          selectedGroup={activeSubTab}
        />
      </Grid>
    </Grid>
  );
};

export default TestsAndAnalysesPage;