import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {useHistory} from "react-router-dom";
import {withStyles} from "tss-react/mui";
import Avatar from "@core/components/ColoredModuleAvatar";
import userService from "@core/api/user-service";
import styles from "./styles";
import useStores from "../../../../../useStores";

const User = observer(({classes}) => {
  const {UserStore} = useStores();
  const history = useHistory();

  const user = UserStore.user.data;

  useEffect(() => {
    if(user.avatar && user.avatar.file) return;

    userService.getUserInfo().then((user) => {
      if(!user._id) return;

      UserStore.setUser(user);
    });
  }, []);

  const [module] = user.company.modules;

  return (
    <div className={classes.user} onClick={() => history.push("/account")}>
      <Avatar
        user={user}
        className={classes.avatar}
        module={module.name}
      />
      <div>
        <div>{user.firstname} {user.lastname}</div>
        <div className={classes.company}>{user.company.name}</div>
      </div>
    </div>
  );
});

export default withStyles(User, styles);
