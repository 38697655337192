import TestResults from "@core/components/TestViews/PositiveMaterialIdentification/components/TestResults";
import {
  Box,
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import React from "react";
import PositiveMaterialIdentificationForm from "@core/components/TestForms/PositiveMaterialIdentification";

const PositiveMaterialIdentification = ({addDataOpen, dataDialogClose, formRef, updateTest, test}) => {

  return (
    <>
      <Typography variant="h6" marginBottom={1}>Test Information</Typography>
      <Table className="styled-table">
        <TableHead>
          <TableRow>
            {test.properties.client && <TableCell>Client</TableCell>}
            {test.properties.laboratory && <TableCell>Laboratory</TableCell>}
            <TableCell>Test Standard</TableCell>
            <TableCell>Material specification</TableCell>
            <TableCell>Grade / UNS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {test.properties.client && <TableCell>{test.properties.client}</TableCell>}
            {test.properties.laboratory && <TableCell>{test.properties.laboratory}</TableCell>}
            <TableCell>{test.properties.testStandard}</TableCell>
            <TableCell>{test.norm || test.properties.norm}</TableCell>
            <TableCell>{test.grade || test.properties.grade}</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Box sx={{marginTop: 3}}>
        <Typography variant="h6" marginBottom={1}>Test results</Typography>
        <TestResults
          test={test}
        />
      </Box>

      <Dialog
        open={addDataOpen}
        onClose={dataDialogClose}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent>
          <PositiveMaterialIdentificationForm
            formRef={formRef}
            saveTest={updateTest}
            test={test}
            isProducerTest
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PositiveMaterialIdentification;