import testsConfig from "@core/configs/test";
import {QCPS} from "@core/constants/qcps";
import {ORDERS} from "@core/constants/sort";
import {STATUSES, TEST_ORDER, TYPES} from "@core/constants/test";
import {ACCEPTABLE_TEST_RESULTS, PARTIALLY_UNACCEPTABLE_TEST_RESULTS} from "@core/constants/testResults";
import {sort as sortBy} from "@core/helpers";
import {compose, descend, flatten, groupBy, isEmpty, map, path, prop, sort, values, isNil} from "ramda";
import themeSettings from "../../theme-settings";

const TEST_RESULTS = {
  EMPTY: "N/A",
  AWAITING_DATA: "Awaiting Data",
  COMPLETE: "Complete",
  FILLED: "Filled",
  SATISFACTORY: "Acceptable",
  UNSATISFACTORY: "Not Acceptable",
  PRESENT: "Present",
  PARTIALLY_UNACCEPTABLE: "Partially Unacceptable"
};

const CONFIG_BY_TYPE_OF_RESULT = {
  ACCEPTABLE: {
    acceptable: true,
    theme: themeSettings.palette.success,
  },
  WARNING: {
    theme: themeSettings.palette.warning,
  },
  NOT_ACCEPTABLE: {
    theme: themeSettings.palette.error,
  }
};

/**
 * @name getTestName
 * @param {string} test - test object
 * @returns {string} || {null} - test title from the config or null if test is not found
 * @summary gets test name depending on the test type
 */
const getTestName = (test) => {
  const testConfig = testsConfig[test.type] || {};

  return test.displayName || testConfig.title || null;
};

/**
 * @name isTestCompleted
 * @param {object} test - whole test
 * @returns {boolean}
 * @summary check test's completion depending on test type and status.
 *          Used for batch transfer
 */
const isTestCompleted = (test) => {
  if (test.type === TYPES.SPLIT) {
    return test.witnesses.length < 1 ? true : test.status === STATUSES.INSPECTED;
  }

  return test.status === STATUSES.APPROVED;
};

/**
 * @name getTestResult
 * @param {object} test - whole test
 * @returns {object} {result} - the object with test result text and color
 * @summary - takes whole test and depending on the tests type / status / result returns the result
 *            Used for certificate summary
 */
const getTestResult = (test) => {
  if (test.status === STATUSES.EMPTY) {
    return {
      ...CONFIG_BY_TYPE_OF_RESULT.WARNING,
      text: TEST_RESULTS.EMPTY
    };
  }

  if(test.properties?.result === PARTIALLY_UNACCEPTABLE_TEST_RESULTS.PARTIALLY_UNACCEPTABLE) {
    return {
      ...CONFIG_BY_TYPE_OF_RESULT.WARNING,
      text: TEST_RESULTS.PARTIALLY_UNACCEPTABLE
    };
  }

  if (test.status === STATUSES.FILLED && isEmpty(test.properties)) {
    return {
      ...CONFIG_BY_TYPE_OF_RESULT.WARNING,
      text: TEST_RESULTS.AWAITING_DATA
    };
  }

  if ([TYPES.SUPPLEMENT, TYPES.STATEMENT, TYPES.SUPPLIER, TYPES.TREATMENT, TYPES.CUSTOM_REPORT].includes(test.type)) {
    if ([STATUSES.EMPTY, STATUSES.ASSIGNED].includes(test.status)) {
      return {
        ...CONFIG_BY_TYPE_OF_RESULT.WARNING,
        text: TEST_RESULTS.EMPTY
      };
    } else {
      return {
        ...CONFIG_BY_TYPE_OF_RESULT.ACCEPTABLE,
        text: TEST_RESULTS.COMPLETE
      };
    }
  } else if (test.type === TYPES.NOTES) {
    if ([STATUSES.EMPTY, STATUSES.ASSIGNED].includes(test.status)) {
      return {
        ...CONFIG_BY_TYPE_OF_RESULT.WARNING,
        text: TEST_RESULTS.EMPTY
      };
    } else {
      return {
        ...CONFIG_BY_TYPE_OF_RESULT.ACCEPTABLE,
        text: TEST_RESULTS.FILLED
      };
    }
  } else if ([TYPES.RADIOGRAPHIC, TYPES.AUSTENITE, TYPES.HEATTREATMENT].includes(test.type)) {
    if ([STATUSES.EMPTY, STATUSES.ASSIGNED].includes(test.status)) {
      return {
        ...CONFIG_BY_TYPE_OF_RESULT.WARNING,
        text: TEST_RESULTS.EMPTY
      };
    } else {
      return {
        ...CONFIG_BY_TYPE_OF_RESULT.ACCEPTABLE,
        text: TEST_RESULTS.SATISFACTORY
      };
    }
  } else {
    if ([STATUSES.EMPTY, STATUSES.ASSIGNED,].includes(test.status)) {
      return {
        ...CONFIG_BY_TYPE_OF_RESULT.WARNING,
        text: TEST_RESULTS.EMPTY
      };
    } else {
      if ((test.properties && isAcceptable(test.properties.result)) ||
        !test.properties.result) {
        return {
          ...CONFIG_BY_TYPE_OF_RESULT.ACCEPTABLE,
          text: TEST_RESULTS.SATISFACTORY
        };
      } else {
        if (test.type === TYPES.CHEMICAL) {
          if (["Weld", "Haz"].includes(test.properties.zone) && test.properties.elements.length !== 0) {
            return {
              ...CONFIG_BY_TYPE_OF_RESULT.ACCEPTABLE,
              text: TEST_RESULTS.PRESENT
            };
          }
        }

        return {
          ...CONFIG_BY_TYPE_OF_RESULT.NOT_ACCEPTABLE,
          text: TEST_RESULTS.UNSATISFACTORY
        };
      }
    }
  }
};

const isAcceptable = (result) => values(ACCEPTABLE_TEST_RESULTS).includes(result);

/**
 * @name getTestTextColor
 * @param {object} test - whole test
 * @returns {string} color - the color of the test
 * @summary returns test's color depending on test type and result.
 *          Used for batch transfer
 */
const getTestTextColor = (test) => {
  if ([TYPES.NOTES, TYPES.OTHER, TYPES.RADIOGRAPHIC, TYPES.AUSTENITE, TYPES.HEATTREATMENT].includes(test.type)) {
    return "green";
  } else {
    if (isAcceptable(test.properties?.result)) {
      return "green";
    } else { return "red"; }
  }
};

const sortTestsByType = (tests) => {
  const sortedTests = sort(descend(prop("date_created")))(tests);
  const testsByCompanyId = groupBy(path(["company", "_id"]))(sortedTests);

  const sortByType = (companyTests) => sort((testA, testB) => {
    return TEST_ORDER.indexOf(testA.type) - TEST_ORDER.indexOf(testB.type);
  }, companyTests);

  return flatten(compose(values, map((companyTests) => sortByType(companyTests)))(testsByCompanyId));
};

const getTestVersions = (test) => {
  const retests = test.retest ? test.retest.map(({testId: test}) => test) : [];

  return sortBy(ORDERS.DESC, "date_created", [...retests, test]);
};

const getIsWithinRequirements = (value, min, max = null) => {
  const minimum = Number(min) || 0;
  const maximum = Number(max);
  const number = Number(value);

  return (number >= minimum && number <= maximum) || (number >= minimum && isNil(max));
};

const getActivityNumber = (test) => {
  const qcp = test.properties.acceptance;

  const {producerTests = [], labTests = []} = QCPS[qcp] || {};

  const testConfig = producerTests.find((t) => t.type === test.type) ||
    labTests.find((t) => t.type === test.type) || {};

  return testConfig.activityNumber;
};
const getSurface = (thickness = 0, weight = 0, length = 0) => (2 * (thickness * weight + weight * length + thickness * length) / 100).toFixed(2);

export {getSurface, getActivityNumber, getIsWithinRequirements, getTestName, getTestResult, getTestTextColor, getTestVersions, isAcceptable, isTestCompleted, sortTestsByType};

