import AcceptanceCriteriaLabel from "@core/components/AcceptanceCriteriaLabel";
import {TableCell, TableRow} from "@mui/material";
import React from "react";

const AcceptanceRow = ({min, max, test}) => {
  return (
    <TableRow>
      <TableCell>
        <AcceptanceCriteriaLabel
          min={min}
          max={max}
        />
      </TableCell>
      {test.properties.testResults.map((result) => (
        <TableCell key={`element-min-${result.element}`}>
          {min ? test.properties.acceptanceCriteria[result.element]?.min || "-" : null}
          {max ? test.properties.acceptanceCriteria[result.element]?.max || "-" : null}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default AcceptanceRow;