import {CHEMICAL_COMPOSITION_SUB_TYPES, TYPES} from "@core/constants/test";
import {PO_ITEMS} from "@core/constants/poItems";
import {TEST_RESULTS} from "@core/constants/testResults";
import {getPoItemNumber} from "@core/helpers";

export const QCPS = {
  "QCP 6916 Rev. 3 (FDPT)": {
    companies: ["Tenaris", "Turcotte Group"],
    witnesses: ["QTC International", "Sporer Group"],
    labs: ["TenarisLab", "Tremblay Inc."],
    base: "QCP 6916 Rev. 3",
    producerTests: [
      {
        type: TYPES.HEATTREATMENT,
        properties: {
          method : "In-line",
          type : "Quench and Temper",
          tempUnits : "celsius",
          unitOfHoldingTime : "time",
          coolingMedium : "Water",
          secondTempUnits : "celsius",
          secondCoolingMedium : "Still air",
          strainHardening : false,
          temp: "860-970",
          secondTemp: "570-720",
        },
        witnesses: true
      },],
    labTests: [{
      type: TYPES.CHEMICAL,
      quantity: 2
    }, {
      type: TYPES.TENSILE,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.IMPACT,
      quantity: 2,
      witnesses: true
    }, {
      type: TYPES.HARDNESS,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.HYDROGEN_INDUCED_CRACKING,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.HOT_TENSILE,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.CRACK_TIP_OPENING_DISPLACEMENT,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.SULPHIDE_STRESS_CORROSIO_CRACKING,
      quantity: 1,
      witnesses: true
    }]
  },
  "QCP 6916 Rev. 3": {
    companies: ["Tenaris", "Turcotte Group"],
    witnesses: ["QTC International", "Sporer Group"],
    labs: ["TenarisLab", "Tremblay Inc."],
    base: "QCP 6916 Rev. 3",
    poItems: [PO_ITEMS.PO_ITEM_1, PO_ITEMS.PO_ITEM_2],
    producerTests: [
      {
        type: TYPES.HEATTREATMENT,
        properties: {
          method : "In-line",
          type : "Quench and Temper",
          tempUnits : "celsius",
          unitOfHoldingTime : "time",
          coolingMedium : "Water",
          secondTempUnits : "celsius",
          secondCoolingMedium : "Still air",
          strainHardening : false,
          temp: "860-970",
          secondTemp: "570-720",
        },
        witnesses: true
      },
      {
        type: TYPES.ELECTROMAGNETIC_INDUCTION,
        properties : {
          testStandard: "API 5L Table E.7 (46 edition)",
          surfaceCondition: "As rolled",
          examinedSurface : [
            "100% of surface",
            "External surface"
          ],
          acceptance : "QCP 6916 Rev. 3",
          elements : [
            {
              notchesLocation : "OD Longitudinal / Transversal (Notch 5%)",
              width : "1",
              length : "50",
              depth : "5",
              depthUnit : "%"
            }
          ],
        },
        witnesses: true
      },
      {
        type: TYPES.ULTRASONIC,
        properties : {
          testStandard : "ASTM A578 (2017)",
          acceptance : "QCP 6916 Rev. 3",
          surfacePreparation : "As rolled",
          examination : {
            speed : "100-150",
            overlap : "15"
          },
          notes: "Acceptance criteria:\n Defects:\n1. Cracks are not allowed.\n2. Imperfection deeper than 5% nominal WT shall be considered defects and removed.\nLaminations:\n1. Pipe body maximum area 500 mm^2.\n2. No indication on pipe ends (calibration with groove 17x6 mm)"
        },
        witnesses: true
      },
      {
        type: TYPES.OTHER,
        properties: {
          result: TEST_RESULTS.ACCEPTABLE,
          elements: [
            {
              test: "Visual test",
              description: "Full pipe body visually inspected externally and as much of the internal surface as is practical under at least 300 lux illumination",
              notes: "Compliant to QCP 6916 Rev. 3 requirements",
              result: "Satisfactory"
            }
          ]
        },
        witnesses: true
      },
      {
        type: TYPES.HYDROSTATIC,
        getProperties: (poItem) => {
          const poItemNumber = getPoItemNumber(poItem);

          const pressureByPoItem = {
            [PO_ITEMS.PO_ITEM_1]: 19570,
            [PO_ITEMS.PO_ITEM_2]: 20000,
          };

          return {
            pressure: pressureByPoItem[Number(poItemNumber)] || "",
            minTime: 10,
            testingFluid: "Water",
            minHoldingTimeUnits: "sec",
            pressureUnit: "psi",
          };
        },
        witnesses: true
      },
      {
        type: TYPES.MAGNETIC,
        properties : {
          pieceIdentification: "Single piece",
          testStandard : "ISO 10893-5",
          condition : "Black light intensity at the examined surface > 1000 µW/cm2, and visible light in the darkened area < 20 lux",
          materialObj: {
            type: "Wet particles",
            visibility: "Fluorescent",
            solution: "Water",
            concentration: "0.2-0.4 mL per 100 mL of liquid vehicle"
          },
          technique: {
            examinedSurface: ["Square cut surface"],
            demagnetization: "Performed",
            residualMagnetism: "Less than 15 Gauss"
          },
          additionalRemarks: "Square cut surface and 250 mm from each end. Traceability was maintained by heat and lot, and pipe numbers"
        },
        witnesses: true
      },
      {
        type: TYPES.DIMENSIONAL,
        properties: {},
        witnesses: true
      }],
    labTests: [{
      type: TYPES.CHEMICAL,
      quantity: 2
    }, {
      type: TYPES.TENSILE,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.IMPACT,
      quantity: 2,
      witnesses: true
    }, {
      type: TYPES.HARDNESS,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.HYDROGEN_INDUCED_CRACKING,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.HOT_TENSILE,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.CRACK_TIP_OPENING_DISPLACEMENT,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.SULPHIDE_STRESS_CORROSIO_CRACKING,
      quantity: 1,
      witnesses: true
    }]
  },
  "QCP 7928 Rev. 2": {
    companies: ["Tenaris", "Turcotte Group"],
    witnesses: ["Sporer Group"], // TODO: Ask Chevron which inspector company they're going to use.
    labs: ["TenarisLab", "Tremblay Inc."],
    producerTests: [
      {
        type: TYPES.HEATTREATMENT,
        properties: {
          method : "In-line",
          type : "Quench and Temper",
          tempUnits : "celsius",
          unitOfHoldingTime : "time",
          coolingMedium : "Water",
          secondTempUnits : "celsius",
          secondCoolingMedium : "Still air",
          strainHardening : false,
          temp: "860-970",
          secondTemp: "570-720",
        },
        witnesses: true
      },
      {
        type: TYPES.ELECTROMAGNETIC_INDUCTION,
        properties : {
          testStandard: "API 5L Table E.7 (46 edition)",
          surfaceCondition: "As rolled",
          examinedSurface : [
            "100% od surface",
            "External surface"
          ],
          acceptance : "QCP 7928 Rev. 2",
          elements : [
            {
              notchesLocation : "OD Longitudinal / Transversal (Notch 5%)",
              width : "1",
              length : "50",
              depth : "5",
              depthUnit : "%"
            }
          ],
        },
        witnesses: true
      },
      {
        type: TYPES.ULTRASONIC,
        properties : {
          testStandard : "ASTM A578 (2017)",
          surfacePreparation : "As rolled",
          examination : {
            speed : "100-150",
            overlap : "15"
          },
          notes: "Acceptance criteria:\n Defects:\n1. Cracks are not allowed.\n2. Imperfection deeper than 5% nominal WT shall be considered defects and removed.\nLaminations:\n1. Pipe body maximum area 500 mm^2.\n2. No indication on pipe ends (calibration with groove 17x6 mm)"
        },
        witnesses: true
      },
      {
        type: TYPES.HYDROSTATIC,
        properties: {
          minTime: 10,
          testingFluid: "Water",
          minHoldingTimeUnits: "sec",
          pressureUnit: "psi",
          pressure: 3450,
        },
        witnesses: true
      },
      {
        type: TYPES.DIMENSIONAL,
        properties: {},
        witnesses: true
      }],
    labTests: [{
      type: TYPES.CHEMICAL,
      quantity: 2
    }, {
      type: TYPES.TENSILE,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.IMPACT,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.HARDNESS,
      quantity: 1,
      witnesses: true
    }]
  },
  "HS-D-ITP-22-02-02 Rev. 5": {
    companies: ["Husteel", "Turcotte Group"],
    witnesses: ["proinspectionltd", "Sporer Group"],
    labs: ["HusteelLab", "Tremblay Inc."],
    producerTests: [{
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Flattening test",
            description: "No openings in the specimen",
            notes: "Distance H = 1/2 OD * Without weld stop: CSA Z245.1 Sec. 7.3 * 4 Samples/coil (Leading 1'st pipe: 2, Trailing last pipe: 2) * Position : weld 0°, 90° * With weld stop: 4 Samples/weld stop locations. Immediately before : 2, after : 2, Position : weld 0°, 90° * MPQT: 4 Samples/each coil(3 heats)",
            result: "Satisfactory"
          }
        ]
      }
    }, {
      type: TYPES.HYDROSTATIC,
      properties: {
        minTime: 10,
        testingFluid: "Water",
        minHoldingTimeUnits: "sec",
        pressureUnit: "MPa",
        pressure: 30.4,
      }
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Pipe end manual UT on 100% of pipes",
            description: "Uninspected pipe end manual UT for lamination and longitudinal defects",
            notes: "Reference standard: See MPS M-15 * Rejection limits: 100% of echo height of reference standard (Threshold level: 80% of full screen height) * Calibration frequency: 2 times per shift (Every 4 hours)",
            result: "Satisfactory"
          },
          {
            test: "Pipe longitudinal UT on 100% of pipes",
            description: "For longitudinal defects",
            notes: "* Reference standard: See MPS M-16 * Rejection limits: 100% of echo height of reference standard (Threshold level: 80% of full screen height) * Calibration frequency: 2 times per shift(Every 4 hours) or after production stops * Uninspected end length : 150 mm * Uninspected length inspected by manual UST",
            result: "Satisfactory"
          },
          {
            test: "Pipe full body UT  on 100% of pipes",
            description: "Lamination check",
            notes: "* Reference standard: See MPS M-17 * Rejection limits: 100% of echo height of reference standard (Threshold level: 80% of full screen height) * Calibration frequency: 2 times per shift(Every 4 hours) or after production stops",
            result: "Satisfactory"
          },
          {
            test: "Off-line UT on 100% of repaired pipes",
            description: "Check of weld seam",
            notes: "* Reference standard: See MPS M-18 * Rejection limits: 100% of echo height of reference standard (Threshold level: 80% of full screen height) * Acceptance criteria: Max width of 20 mm and an area of 500 mm^2 * Calibration frequency: 2 times per shift(Every 4 hours) or after production stopos * Uninspected end length : 15 mm * Uninspected length inspected by manual UST",
            result: "Satisfactory"
          }
        ]
      }
    },
    {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Visual test",
            description: "Performed on 100% of pipes according to CSA Z245.1 and Chevron Spec 4.7",
            notes: "Dent with Depth < 6mm, Length < 0.5D * No dents that are 6 mm or less in depth and contain stress concentrators * Arc Burns: Removed by grinding, provided that the remaining wall thickness is within specified limits. * The section of pipe containing the defect is cut out as a cyclinder. * Only the marked area of the pipe is varnish coating",
            result: "Satisfactory"
          }
        ]
      },
      witnesses: true
    },
    {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Bend test",
            description: "2 Samples/Coil (Leading 1st pipe: 1, Trailing last pipe: 1) * Position : weld 0° * MPQT: 2 Samples/Coil (3heats)",
            notes: "* Root guided-bend test specimens bent cold approximately 180° in a jig. * The test specimens did not fracture completely and no opening larger than 3 mm occurred. * Guided-bend test jig dimensions: width of male member: 120 mm, radius of male member: 60 mm, width of groove in female member: 139.04 mm, radius of female member: 69.52 mm",
            result: "Satisfactory"
          }
        ]
      }
    },
    {
      type: TYPES.DIMENSIONAL,
      properties: {},
      witnesses: true
    }],
    labTests: [{
      type: TYPES.CHEMICAL,
      subType: CHEMICAL_COMPOSITION_SUB_TYPES.PRODUCT,
      quantity: 1
    }, {
      type: TYPES.TENSILE,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.IMPACT,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.HARDNESS,
      quantity: 2,
      witnesses: true
    }, {
      type: TYPES.MICROGRAPHIC,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.MACROGRAPHIC,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.HYDROGEN_INDUCED_CRACKING,
      quantity: 1,
      witnesses: true
    }]
  },
  "HS-D-ITP-23-01-06 Rev. 3": {
    companies: ["Husteel", "Turcotte Group"],
    witnesses: ["proinspectionltd", "Sporer Group"],
    labs: ["HusteelLab", "Tremblay Inc."],
    poItems: [PO_ITEMS.PO_ITEM_1, PO_ITEMS.PO_ITEM_2, PO_ITEMS.PO_ITEM_3],
    producerTests: [{
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Flattening test",
            description: "No openings in the specimen",
            notes: "Distance H = 1/2 OD * Without weld stop: CSA Z245.1 Sec. 7.3 * 4 Samples/coil (Leading 1'st pipe: 2, Trailing last pipe: 2) * Position : weld 0°, 90° * With weld stop: 4 Samples/weld stop locations. Immediately before : 2, after : 2, Position : weld 0°, 90° * MPQT: 4 Samples/each coil(3 heats)",
            result: "Satisfactory"
          }
        ]
      }
    }, {
      type: TYPES.HYDROSTATIC,
      getProperties: (poItem) => {
        const pressureByPoItem = {
          [PO_ITEMS.PO_ITEM_1]: 30.4,
          [PO_ITEMS.PO_ITEM_2]: 9.0,
          [PO_ITEMS.PO_ITEM_3]: 25.4,
        };

        return {
          pressure: pressureByPoItem[poItem] || "",
          minTime: 10,
          testingFluid: "Water",
          minHoldingTimeUnits: "sec",
          pressureUnit: "MPa",
        };
      },
      witnesses: true
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Pipe end manual UT on 100% of pipes",
            description: "Uninspected pipe end manual UT for lamination and longitudinal defects",
            notes: "Reference standard: See MPS M-15 and M-16 * Rejection limits: 100% of echo height of reference standard (Threshold level: 80% of full screen height) * Calibration frequency: 2 times per shift (Every 4 hours)",
            result: "Satisfactory"
          },
          {
            test: "Pipe longitudinal UT on 100% of pipes",
            description: "For longitudinal defects",
            notes: "* Reference standard: See MPS M-16 * Rejection limits: 100% of echo height of reference standard (Threshold level: 75% of full screen height) * Using only one of both channels in one set. (Total using 7 channels of 14 channels) * Calibration frequency: 2 times per shift(Every 4 hours) or after production stops * Uninspected end length : 150 mm * Uninspected length inspected by manual UST",
            result: "Satisfactory"
          },
          {
            test: "Pipe full body UT  on 100% of pipes",
            description: "Lamination check",
            notes: "* Reference standard: See MPS M-17 * Sensitivity: 30-90% of FSH * Threshold level: 25% of FSH * Acceptance criteria: Max width of 20 mm and an area of 500 mm^2 * Calibration frequency: 2 times per shift(Every 4 hours) or after production stops * Uninspected end length: 15mm / Uninspected length shall be inspected by manual UST",
            result: "Satisfactory"
          },
          {
            test: "Off-line UT on 100% of repaired pipes",
            description: "Check of weld seam",
            notes: "* Reference standard: See MPS M-18 * Sensitivity: 80% (Range: 70% - 90%) of FSH for N5 * Threshold level: 60% of FSH * Except 3.2 mm RDH For dead zone * 1.6mm RDH: Initial height's ±2 dB Range * Calibration frequency: 2 times per shift(Every 4 hours) or after production stops",
            result: "Satisfactory"
          }
        ]
      }
    },
    {
      type: TYPES.OTHER,
      getProperties: (poItem) => {
        const descriptionByPoItem = {
          [PO_ITEMS.PO_ITEM_1]: "Performed on 100% of pipes according to CSA Z245.1 sec. 11.5 and Chevron Spec 4.7",
          [PO_ITEMS.PO_ITEM_2]: "Performed on 100% of pipes according to CSA Z245.1 and Chevron Spec 4.7",
          [PO_ITEMS.PO_ITEM_3]: "Performed on 100% of pipes according to CSA Z245.1 and Chevron Spec 4.7",
        };

        return {
          result: TEST_RESULTS.ACCEPTABLE,
          elements: [
            {
              test: "Visual test",
              description: descriptionByPoItem[poItem] || "",
              notes: "Dent with Depth < 6mm, Length < 0.5D * No dents that are 6 mm or less in depth and contain stress concentrators * Arc Burns: Removed by grinding, provided that the remaining wall thickness is within specified limits. * The section of pipe containing the defect is cut out as a cyclinder. * Only the marked area of the pipe is varnish coating",
              result: "Satisfactory"
            }
          ]
        };
      },
      witnesses: true
    },
    {
      type: TYPES.OTHER,
      getProperties: (poItem) => {
        const notesByPoItem = {
          [PO_ITEMS.PO_ITEM_1]: "* Root guided-bend test specimens bent cold approximately 180° in a jig. * The test specimens did not fracture completely and no opening larger than 3 mm occurred. * Guided-bend test jig dimensions: width of male member: 150 mm, radius of male member: 75 mm, width of groove in female member: 173.80 mm, radius of female member: 86.90 mm",
          [PO_ITEMS.PO_ITEM_2]: "* Root guided-bend test specimens bent cold approximately 180° in a jig. * The test specimens did not fracture completely and no opening larger than 3 mm occurred. * Guided-bend test jig dimensions: width of male member: 105 mm, radius of male member: 52.50 mm, width of groove in female member: 127.20 mm, radius of female member: 63.60 mm",
          [PO_ITEMS.PO_ITEM_3]: "* Root guided-bend test specimens bent cold approximately 180° in a jig. * The test specimens did not fracture completely and no opening larger than 3 mm occurred. * Guided-bend test jig dimensions: width of male member: 165 mm, radius of male member: 82.50 mm, width of groove in female member: 193.60 mm, radius of female member: 96.80 mm",
        };

        return {
          result: TEST_RESULTS.ACCEPTABLE,
          elements: [
            {
              test: "Bend test",
              description: "2 Samples/Coil (Leading 1st pipe: 1, Trailing last pipe: 1) * Position : weld 0° * MPQT: 2 Samples/Coil (3heats)",
              notes: notesByPoItem[poItem] || "",
              result: "Satisfactory"
            }
          ]
        };
      },
    },
    {
      type: TYPES.DIMENSIONAL,
      properties: {},
      witnesses: true
    }],
    labTests: [{
      type: TYPES.CHEMICAL,
      subType: CHEMICAL_COMPOSITION_SUB_TYPES.PRODUCT,
      quantity: 2
    }, {
      type: TYPES.TENSILE,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.IMPACT,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.HARDNESS,
      quantity: 2,
      witnesses: true
    }, {
      type: TYPES.MICROGRAPHIC,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.MACROGRAPHIC,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.HYDROGEN_INDUCED_CRACKING,
      quantity: 1,
      witnesses: true
    }]
  },
  "FP-ITP Rev. 0": {
    companies: ["FoxPipe Producer", "Ferry - Murphy", "Sauer LLC", "Triton Energy", "Southwest Gas"],
    witnesses: ["Sporer Inspector", "Witnessing Bros"],
    labs: ["Mechan Lab"],
    producerTests: [{
      type: TYPES.HEATTREATMENT,
      properties: {
        method : "In-line",
        type : "Post weld heat treatment",
        temp : "610-900",
        tempUnits : "celsius",
        coolingMedium : "Water",
        strainHardening : false,
      },
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Flattening test",
            description: "No openings in the specimen",
            notes: "Distance H = 1/2 OD * Without weld stop: CSA Z245.1 Sec. 7.3 * 4 Samples/coil (Leading 1'st pipe: 2, Trailing last pipe: 2) * Position : weld 0°, 90° * With weld stop: 4 Samples/weld stop locations. Immediately before : 2, after : 2, Position : weld 0°, 90° * MPQT: 4 Samples/each coil(3 heats)",
            result: "Satisfactory"
          }
        ]
      },
      witnesses: true
    }, {
      type: TYPES.HYDROSTATIC,
      properties: {
        minTime: 10,
        testingFluid: "Water",
        minHoldingTimeUnits: "sec",
        pressureUnit: "MPa",
        pressure: 30.4,
        pieceIdentification: "Heat and lot",
      },
      witnesses: true
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Pipe end manual UT on 100% of pipes",
            description: "Uninspected pipe end manual UT for lamination and longitudinal defects",
            notes: "Reference standard: See MPS M-15 * Rejection limits: 100% of echo height of reference standard (Threshold level: 80% of full screen height) * Calibration frequency: 2 times per shift (Every 4 hours)",
            result: "Satisfactory"
          },
          {
            test: "Pipe longitudinal UT on 100% of pipes",
            description: "For longitudinal defects",
            notes: "* Reference standard: See MPS M-16 * Rejection limits: 100% of echo height of reference standard (Threshold level: 80% of full screen height) * Calibration frequency: 2 times per shift(Every 4 hours) or after production stops * Uninspected end length : 150 mm * Uninspected length inspected by manual UST",
            result: "Satisfactory"
          },
          {
            test: "Pipe full body UT  on 100% of pipes",
            description: "Lamination check",
            notes: "* Reference standard: See MPS M-17 * Rejection limits: 100% of echo height of reference standard (Threshold level: 80% of full screen height) * Calibration frequency: 2 times per shift(Every 4 hours) or after production stops",
            result: "Satisfactory"
          },
          {
            test: "Off-line UT on 100% of repaired pipes",
            description: "Check of weld seam",
            notes: "* Reference standard: See MPS M-18 * Rejection limits: 100% of echo height of reference standard (Threshold level: 80% of full screen height) * Acceptance criteria: Max width of 20 mm and an area of 500 mm^2 * Calibration frequency: 2 times per shift(Every 4 hours) or after production stopos * Uninspected end length : 15 mm * Uninspected length inspected by manual UST",
            result: "Satisfactory"
          }
        ]
      }
    },
    {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Visual test",
            description: "Performed on 100% of pipes according to CSA Z245.1 and Chevron Spec 4.7",
            notes: "Dent with Depth < 6mm, Length < 0.5D * No dents that are 6 mm or less in depth and contain stress concentrators * Arc Burns: Removed by grinding, provided that the remaining wall thickness is within specified limits. * The section of pipe containing the defect is cut out as a cyclinder. * Only the marked area of the pipe is varnish coating",
            result: "Satisfactory"
          }
        ]
      },
    },
    {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Bend test",
            description: "2 Samples/Coil (Leading 1st pipe: 1, Trailing last pipe: 1) * Position : weld 0° * MPQT: 2 Samples/Coil (3heats)",
            notes: "* Root guided-bend test specimens bent cold approximately 180° in a jig. * The test specimens did not fracture completely and no opening larger than 3 mm occurred. * Guided-bend test jig dimensions: width of male member: 165 mm, radius of male member: 82.50 mm, width of groove in female member: 193.60 mm, radius of female member: 96.80 mm",
            result: "Satisfactory"
          }
        ]
      }
    }],
    labTests: [{
      type: TYPES.CHEMICAL,
      quantity: 2
    }, {
      type: TYPES.TENSILE,
      quantity: 6,
      witnesses: true
    }, {
      type: TYPES.IMPACT,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.HARDNESS,
      quantity: 2,
      witnesses: true
    }, {
      type: TYPES.MICROGRAPHIC,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.MACROGRAPHIC,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.HYDROGEN_INDUCED_CRACKING,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.DIMENSIONAL,
      quantity: 1,
      witnesses: true
    },]
  },
  "FX-FBE-Coating-ITP Rev. 0": {
    companies: ["Turcotte Group", "FoxPipe Producer", "Triton Energy", "Southwest Gas"],
    producerTests: [
      {
        type: TYPES.CUSTOM,
        displayName: "Surface Preparation",
        properties: {
          activities : [
            {
              activity : "3.1.0",
              description : "Dew Point",
              acceptanceCriteria : {
                label : "To be recorded"
              }
            },
            {
              activity : "3.1.1",
              description : "Relative humidity",
              acceptanceCriteria : {
                label : "To be recorded"
              }
            },
            {
              activity : "4.4.0",
              description : "Soluble salt after blasting",
              acceptanceCriteria : {
                label : "≤ 2μg/cm2",
                value : {
                  max : 2,
                  unit : "μg/cm2"
                }
              }
            },
            {
              activity: "5.0.1",
              description: "Surface cleanliness",
              acceptanceCriteria: {
                label : "NACE #2"
              }
            },
            {
              activity : "5.0.2",
              description : "Surface profile",
              acceptanceCriteria: {
                label : "To be recorded"
              }
            },
            {
              activity : "6.0.0",
              description : "Acid type",
              acceptanceCriteria: {
                label : "CREST 10B"
              }
            },
            {
              activity : "6.0.0",
              description : "Acid %",
              acceptanceCriteria: {
                label : "To be recorded"
              }
            },
            {
              activity : "6.0.1",
              description : "Preheat temperature",
              acceptanceCriteria: {
                label : "To be recorded"
              }
            },
            {
              activity : "6.0.2",
              description : "Dwell time",
              acceptanceCriteria: {
                label : "To be recorded"
              }
            },
            {
              activity : "6.0.3",
              description : "Acid wash water rinse conductivity",
              acceptanceCriteria: {
                label : "To be recorded"
              }
            },
            {
              activity : "6.0.4",
              description : "Rinse pH",
              acceptanceCriteria: {
                label : "To be recorded"
              }
            }
          ]},
      },
      {
        type: TYPES.CUSTOM,
        displayName: "Heating",
        properties: {
          activities : [
            {
              activity : "7.1.1",
              description : "Temperature",
              acceptanceCriteria : {
                name : "surfaceTemperature",
                label : "≤ 500°F",
                value : {
                  max : 500,
                  unit : "°F"
                }
              }
            },
            {
              activity : "7.1.2",
              description : "IR Temperature",
              acceptanceCriteria : {
                name : "surfaceTemperature",
                label : "≤ 500°F",
                value : {
                  max : 500,
                  unit : "°F"
                }
              }
            }
          ]},
      },
      {
        type: TYPES.CUSTOM,
        displayName: "Coating Application",
        properties: {
          activities : [
            {
              activity : "9.0.1",
              description : "FBE Type",
              acceptanceCriteria: {
                label : "VALSPAR HOT 180 BASE"
              }
            },
            {
              activity : "9.0.2",
              description : "FBE Batch",
              acceptanceCriteria: {
                label : "To be recorded"
              }
            },
            {
              activity : "9.0.3",
              description : "MRO Type",
              acceptanceCriteria: {
                label : "VALSPAR HOT 180 MRO"
              }
            },
            {
              activity : "9.0.4",
              description : "MRO Batch",
              acceptanceCriteria: {
                label : "To be recorded"
              }
            },
            {
              activity : "10.0.0",
              description : "Time to quench",
              acceptanceCriteria : {
                name : "timeToQuench",
                label: "≥ 3:30mins",
                value : {
                  unit : "s",
                  min: 210
                }
              }
            },
            {
              activity: "10.0.1",
              description: "Time to touch",
              acceptanceCriteria: {
                label : "To be recorded"
              }
            }
          ]},
      },
      {
        type: TYPES.CUSTOM,
        displayName: "Final Inspection",
        properties: {
          activities : [
            {
              activity : "12.1.1",
              description : "FBE mils",
              acceptanceCriteria :
                {
                  label: "7mils -1mil +2mils",
                  value : {
                    min: 6,
                    max: 9,
                    unit: "mils"
                  }
                }
            },
            {
              activity : "12.1.2",
              description : "MRO mils",
              acceptanceCriteria :
                {
                  label: "7mils -1mil +2mils",
                  value : {
                    min: 6,
                    max: 9,
                    unit: "mils"
                  }
                }
            },
            {
              activity : "12.2.0",
              description : "Adhesion Rating",
              acceptanceCriteria : {
                label: "≤ 5°C",
                value : {
                  max : 5,
                  unit : "°C"
                }
              }
            },
            {
              activity : "12.3.0",
              description : "Cutbacks",
              acceptanceCriteria: {
                label : "7.9\", +1,-0/14.6\", +1, -0"
              }
            },
            {
              activity : "13.0.0",
              description : "Spacers and size",
              acceptanceCriteria : {
                label: "Four 1” rope ring separators per pipe on 40 linear feet (12m)",
              }
            }
          ]},
      },{
        type: TYPES.CUSTOM,
        displayName: "Production Tests",
        properties: {
          activities : [
            {
              activity : "18.1.0",
              description : "DSC Cure",
              acceptanceCriteria : {
                name : "dscCure",
                label : "≤ 5°C",
                value : {
                  max : 5,
                  unit : "°C"
                }
              }
            },
            {
              activity : "18.2.1",
              description : "Porosity - Interface",
              acceptanceCriteria : {
                label : "Between 1 and 2",
                value : {
                  min: 1,
                  max : 2,
                  unit : ""
                }
              }
            },
            {
              activity : "18.2.2",
              description : "Porosity - Cross-section",
              acceptanceCriteria : {
                label : "Between 1 and 2",
                value : {
                  min: 1,
                  max : 2,
                  unit : ""
                }
              }
            },
            {
              activity : "18.3.0",
              description : "Contamination",
              acceptanceCriteria : {
                label : "≤ 30%",
                value : {
                  max: 30,
                  unit: "%"
                }
              }
            },
            {
              activity : "18.4.0",
              description : "Cathodic Disbondment (CDT)",
              acceptanceCriteria : {
                label : "≤ 6.5mm",
                value : {
                  unit : "mm",
                  max : 6.5
                }
              }
            },
            {
              activity: "18.5.0",
              description: "Moisture Permeation",
              acceptanceCriteria : {
                label : "Between 1 and 2",
                value : {
                  min: 1,
                  max : 2,
                  unit : ""
                }
              }
            },
            {
              activity : "18.7.0",
              description : "Flexibility (Bend test)",
              acceptanceCriteria : {
                label : "1.5°/pd at 0°C",
                value : {
                  min: 1.5,
                  max: 1.5,
                  unit: "°/pd"
                }
              }
            }
          ]},
      },
    ],
    labTests:[]
  },
  "FX-Spool-ITP Rev. 0": {
    companies: ["Turcotte Group", "FoxPipe Producer", "Triton Energy", "Southwest Gas"],
    producerTests: [
      {
        type: TYPES.CUSTOM,
        displayName: "Counterboring",
        properties: {
          activities : [
            {
              activity : "5.1",
              description : "Verify Machined ID Length",
              acceptanceCriteria : {
                label : "6\" (+1.0\"/ -0.0\")",
                value : {
                  min : 6,
                  max : 7,
                  unit : "\""
                }
              }
            },
            {
              activity : "5.2",
              description : "Verify Machined ID (Tier 1)",
              acceptanceCriteria : {
                label : "7.180 +/-0.010\"",
                value : {
                  min : 7.170,
                  max : 7.190,
                  unit : "\""
                }
              }
            },
            {
              activity : "5.2",
              description : "Verify Machined ID (Tier 2)",
              acceptanceCriteria : {
                label : "7.285 +/-0.010\"",
                value : {
                  min : 7.275,
                  max : 7.285,
                  unit : "\""
                }
              }
            },
            {
              activity: "5.3.1",
              description: "Verify Machined WT (1\") (Tier 1)",
              acceptanceCriteria: {
                name: "targetWt",
                label: "≥ 1.760”",
                value: {
                  min: 1.76,
                  unit: "\""
                }
              }
            },
            {
              activity : "5.3.1",
              description : "Verify Machined WT (1\") (Tier 2)",
              acceptanceCriteria :
                {
                  name : "targetWt",
                  label : "≥ 1.690”",
                  value : {
                    min : 1.69,
                    unit : "\""
                  }
                }
            },
            {
              activity : "5.3.2",
              description : "Verify Machined WT (3.5\") (Tier 1)",
              acceptanceCriteria :
                {
                  name : "targetWt",
                  label : "≥ 1.760”",
                  value : {
                    min : 1.76,
                    unit : "\""
                  }
                }
            },
            {
              activity : "5.3.2",
              description : "Verify Machined WT (3.5\") (Tier 2)",
              acceptanceCriteria :
                {
                  name : "targetWt",
                  label : "≥ 1.690”",
                  value : {
                    min : 1.69,
                    unit : "\""
                  }
                }
            },
            {
              activity : "5.4",
              description : "Taper Transition",
              acceptanceCriteria :
                {
                  name : "taperTransition",
                  label : "20’’ radius",
                  value : {
                    min: 20,
                    max: 20,
                    equal : 20,
                    unit : "\""
                  }
                }
            },
            {
              activity : "5.5.1",
              description : "Machined Surface Finish (Circ.)",
              acceptanceCriteria :
                {
                  name: "rmsConf",
                  label : "< 125 RMS",
                  value : {
                    max : 125,
                    unit : "RMS"
                  }
                }
            },
            {
              activity : "5.5.2",
              description : "Machined Surface Finish (Long.)",
              acceptanceCriteria :
                {
                  name : "rmsLong",
                  label : "< 125 RMS",
                  value : {
                    max : 125,
                    unit : "RMS"
                  }
                }
            }
          ]},
      },
      {
        type: TYPES.CUSTOM,
        displayName: "Field Joint Coating",
        properties: {
          activities : [
            {
              activity : "8.2.1",
              description : "Compressed air quality check",
              acceptanceCriteria :
                {
                  name : "airCheck",
                  label : "No evidence of water, oil, or grease during or after 60 second compressed air discharge"
                }
            },
            {
              activity : "8.2.5",
              description : "Steel substrate surface temperature",
              acceptanceCriteria : {
                name : "surfaceTemperature",
                label : "≥ 5°C",
                value : {
                  min : 5,
                  unit : "°C"
                }
              }
            },
            {
              activity : "8.2.6",
              description : "Abrasive contamination testing",
              acceptanceCriteria : {
                name : "abrasiveContamination",
                label : "< 100µS/cm",
                value : {
                  max : 99,
                  unit : "µS/cm"
                }
              }
            },
            {
              activity : "8.3.4",
              description : "Dust Test",
              acceptanceCriteria : {
                name : "dustTest",
                label : "≤ 2",
                value : {
                  max : 2,
                  unit: ""
                }
              }
            },
            {
              activity : "8.3.5",
              description : "Salt contamination",
              acceptanceCriteria : {
                name : "saltContamination",
                label : "≤ 2 μg/cm2",
                value : {
                  unit : "μg/cm2",
                  max : 2
                }
              }
            },
            {
              activity: "8.3.6",
              description: "Surface profile",
              acceptanceCriteria: {
                name: "surfaceProfile",
                label: "50µm - 100µm",
                value: {
                  unit: "µm",
                  min: 50,
                  max: 100
                }
              }
            },
            {
              activity : "8.4.3",
              description : "Removal of surplus powder",
              acceptanceCriteria : {
                name : "surplusPowder",
                label : "Surplus powder blown from overlap with compressed air"
              }
            },
            {
              activity : "8.5.4",
              description : "Holiday Detection",
              acceptanceCriteria : {
                name : "holidayDetection",
                label : "No holidays at 1.5kV. All pinholes marked but to be repaired after abrading/blasting FBE in section 8.6",
                value : {
                  max : 0,
                  unit : ""
                }
              }
            },
            {
              activity : "8.5.3",
              description : "DFT FBE FJC",
              acceptanceCriteria : {
                name : "DFT",
                label : "305µm - 533µm",
                value : {
                  unit : "µm",
                  min : 305,
                  max : 533
                }
              }
            }
          ]},
      },
    ],
    labTests:[]
  },
  "MTIP WA0058-4400025974-H06-44004 Rev. 6 (MPQT)": {
    companies: ["Sumitomo", "Turcotte Group"],
    witnesses: ["Sporer Group"],  // TODO: Ask subsea7 which inspector company they are going to use
    labs: ["SumitomoLab", "Tremblay Inc."],
    base: "MTIP WA0058-4400025974-H06-44004 Rev. 6",
    producerTests: [{
      type: TYPES.HEATTREATMENT,
      properties: {
        method: "In-line",
        type: "Quench and Temper",
        temp: "870-1100",
        tempUnits: "celsius",
        unitOfHoldingTime: "time",
        holdingTime: 5,
        holdingTimeUnits: "min",
        coolingMedium: "Water",
        secondTemp: "580-670",
        secondTempUnits: "celsius",
        secondCoolingMedium: "Still air",
        secondUnitOfHoldingTime: "time",
        secondHoldingTime: 30,
        secondHoldingTimeUnits: "min",
      },
      witnesses: true
    }, {
      type: TYPES.HYDROSTATIC,
      properties: {
        minTime: 10,
        testingFluid: "Water",
        minHoldingTimeUnits: "sec",
        pressureUnit: "MPa",
        pressure: 60.6,
      }
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Ultrasonic examination - Longitudinal",
            description: "No imperfection that produces a signal equal to or greater than the one received from the reference standard",
            notes: "ISO 10893-10 U2/C - Longitudinal N5 notch (outside and inside surface) - Depth: 5 % x NWT, minimum 0.3 mm - Notch length 50 mm, width 1 mm",
            result: "Satisfactory"
          },
          {
            test: "Ultrasonic examination - Transverse",
            description: "No imperfection that produces a signal equal to or greater than the one received from the reference standard",
            notes: "ISO 10893-10 U2/C - Transverse N5 notch (outside and inside surface) - Depth: 5 % x NWT, minimum 0.3 mm - Notch length 50 mm, width 1 mm",
            result: "Satisfactory"
          },
          {
            test: "Ultrasonic examination - Lamination",
            description: "No imperfection that produces a signal equal to or greater than the one received from the reference standard",
            notes: "ISO 10893-8 - 6.0 mm -0/+0.6mm diameter flat bottomed hole at middle wall position",
            result: "Satisfactory"
          }
        ]
      }
    },
    {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Residual magnetism",
            description: "Less than 2.0 mT (20 Gauss) for each reading, less than 1.5 mT (15 Gauss) for average of four readings",
            notes: "Four readings taken approximately 90 deg. apart around the circumference of each end of the pipe. Measurement made at each end of the pipe by gauss meter",
            result: "Satisfactory"
          }
        ]
      }
    },
    {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Magnetic Particle Inspection of pipe end faces",
            description: "No laminar imperfections",
            notes: "Reference Standard: Burmah Castrol strip specimen or JIS Z 2320 Type A1 30/100 - Applicable Standards/Specifications: DNVGL-ST-F101 Appendix D, ISO 10893-5",
            result: "Satisfactory"
          }
        ]
      }
    },
    {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Visual test",
            description: "Full pipe body visually inspected externally and as much of the internal surface as is practical in accordance with DNVGL-ST-F101 D8.5 and MTIP WA0058-4400025974-H06-44004 additional requirements",
            notes: "no cold-formed dents with sharp-bottom gouges and depth exceeding 3.2mm, and no other dents with depth exceeding 6.4mm * For dents, the length in any direction does not exceed 1/2 OD. Dents > 1 mm are not acceptable at the pipe ends, i.e. within a length of 100mm at each of the pipe extremities. * Surface imperfection with a depth greater than 5% of nominal WT, but maximum 1.0 mm and which do not encroach below the specified min. WT shall be considered as defects and repaired by grinding. * Imperfections which encroach upon the specified minimum wall thickness shall be classified as defect",
            result: "Satisfactory"
          }
        ]
      },
      witnesses: true
    },
    {
      type: TYPES.DIMENSIONAL,
      properties: {},
      witnesses: true
    }],
    labTests: [{
      type: TYPES.CHEMICAL,
      quantity: 3
    }, {
      type: TYPES.TENSILE,
      quantity: 3,
      witnesses: true
    }, {
      type: TYPES.IMPACT,
      quantity: 5,
      witnesses: true
    }, {
      type: TYPES.CRACK_TIP_OPENING_DISPLACEMENT,
      quantity: 2,
      witnesses: true
    },
    {
      type: TYPES.HARDNESS,
      quantity: 2,
      witnesses: true
    }, {
      type: TYPES.MICROGRAPHIC,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.DROP_WEIGHT_TEAR,
      quantity: 2,
      witnesses: true
    }]
  },
  "MTIP WA0058-4400025974-H06-44004 Rev. 6": {
    companies: ["Sumitomo", "Turcotte Group"],
    witnesses: ["Sporer Group"],  // TODO: Ask subsea7 which inspector company they are going to use
    labs: ["SumitomoLab", "Tremblay Inc."],
    producerTests: [{
      type: TYPES.HEATTREATMENT,
      properties: {
        method: "In-line",
        type: "Quench and Temper",
        temp: "870-1100",
        tempUnits: "celsius",
        coolingMedium: "Water",
        unitOfHoldingTime: "time",
        holdingTime: 5,
        holdingTimeUnits: "min",
        secondTemp: "580-670",
        secondTempUnits: "celsius",
        secondCoolingMedium: "Still air",
        secondUnitOfHoldingTime: "time",
        secondHoldingTime: 30,
        secondHoldingTimeUnits: "min",
      },
      witnesses: true
    },
    {
      type: TYPES.HYDROSTATIC,
      properties: {
        minTime: 10,
        testingFluid: "Water",
        minHoldingTimeUnits: "sec",
        pressureUnit: "MPa",
        pressure: 60.6,
      }
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Ultrasonic examination - Longitudinal",
            description: "No imperfection that produces a signal equal to or greater than the one received from the reference standard",
            notes: "ISO 10893-10 U2/C - Longitudinal N5 notch (outside and inside surface) - Depth: 5 % x NWT, minimum 0.3 mm - Notch length 50 mm, width 1 mm",
            result: "Satisfactory"
          },
          {
            test: "Ultrasonic examination - Transverse",
            description: "No imperfection that produces a signal equal to or greater than the one received from the reference standard",
            notes: "ISO 10893-10 U2/C - Transverse N5 notch (outside and inside surface) - Depth: 5 % x NWT, minimum 0.3 mm - Notch length 50 mm, width 1 mm",
            result: "Satisfactory"
          },
          {
            test: "Ultrasonic examination - Lamination",
            description: "No imperfection that produces a signal equal to or greater than the one received from the reference standard",
            notes: "ISO 10893-8 - 6.0 mm -0/+0.6mm diameter flat bottomed hole at middle wall position",
            result: "Satisfactory"
          }
        ]
      }
    },
    {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Residual magnetism",
            description: "Less than 2.0 mT (20 Gauss) for each reading, less than 1.5 mT (15 Gauss) for average of four readings",
            notes: "Four readings taken approximately 90 deg. apart around the circumference of each end of the pipe. Measurement made at each end of the pipe by gauss meter",
            result: "Satisfactory"
          }
        ]
      }
    },
    {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Magnetic Particle Inspection of pipe end faces",
            description: "No laminar imperfections",
            notes: "Reference Standard: Burmah Castrol strip specimen or JIS Z 2320 Type A1 30/100 - Applicable Standards/Specifications: DNVGL-ST-F101 Appendix D, ISO 10893-5",
            result: "Satisfactory"
          }
        ]
      }
    },
    {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Visual test",
            description: "Full pipe body visually inspected externally and as much of the internal surface as is practical in accordance with DNVGL-ST-F101 D8.5 and MTIP WA0058-4400025974-H06-44004 additional requirements",
            notes: "no cold-formed dents with sharp-bottom gouges and depth exceeding 3.2mm, and no other dents with depth exceeding 6.4mm * For dents, the length in any direction does not exceed 1/2 OD. Dents > 1 mm are not acceptable at the pipe ends, i.e. within a length of 100mm at each of the pipe extremities. * Surface imperfection with a depth greater than 5% of nominal WT, but maximum 1.0 mm and which do not encroach below the specified min. WT shall be considered as defects and repaired by grinding. * Imperfections which encroach upon the specified minimum wall thickness shall be classified as defect",
            result: "Satisfactory"
          }
        ]
      },
      witnesses: true
    },
    {
      type: TYPES.DIMENSIONAL,
      properties: {},
      witnesses: true
    }],
    labTests: [{
      type: TYPES.CHEMICAL,
      quantity: 3
    }, {
      type: TYPES.HOT_TENSILE,
      quantity: 1,
      witnesses: true
    },
    {
      type: TYPES.TENSILE,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.IMPACT,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.HARDNESS,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.MICROGRAPHIC,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.DROP_WEIGHT_TEAR,
      quantity: 1,
      witnesses: true
    }]
  },
  "QCP 8262 Rev. 6": {
    companies: ["Tenaris", "Turcotte Group"],
    witnesses: ["Sporer Group"], // TODO: Ask Tenaris which inspector company they're going to use.
    labs: ["TenarisLab", "Tenaris-Siderca", "Tremblay Inc."],
    poItems: [PO_ITEMS.PO_ITEM_1, PO_ITEMS.PO_ITEM_2, PO_ITEMS.PO_ITEM_3],
    producerTests: [{
      type: TYPES.HEATTREATMENT,
      properties: {
        method: "In-line",
        type: "Quench and Temper",
        tempUnits: "celsius",
        unitOfHoldingTime: "time",
        coolingMedium: "Water",
        secondTempUnits: "celsius",
        secondCoolingMedium: "Still air",
        temp: "870-960",
        secondTemp: "580-720",
      },
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Electromagnetic induction test (EMI)",
            description: "All pipes have been tested by Electromagnetic Induction Test (longitudinal, outside N5 notch - ISO 10893-3 Level F2",
            result: "Satisfactory"
          }
        ]
      }
    }, {
      type: TYPES.ULTRASONIC,
      properties : {
        pieceIdentification: "Single piece",
        testStandard : "ISO 10893",
        surfacePreparation : "As rolled",
        notes: "All pipes have been tested by ultrasonic inspection for transversal/longitudinal, inside/outside defects (Notch 5,0) according to QCP requirements with satisfactory results. The wall thickness controlled by UT of each pipe is within the QCP requested tolerance. All pipes have been tested for lamination according to QCP with satisfactory results. UT coverage 100%"
      },
    }, {
      type: TYPES.MAGNETIC,
      properties : {
        pieceIdentification: "Single piece",
        testStandard : "ISO 10893-5",
        condition : "Black light intensity at the examined surface > 1000 µW/cm2, and visible light in the darkened area < 20 lux",
        materialObj: {
          type: "Wet particles",
          visibility: "Fluorescent",
          solution: "Water",
          concentration: "0.2-0.4 mL per 100 mL of liquid vehicle"
        },
        technique: {
          examinedSurface: ["Square cut surface"],
          demagnetization: "Performed",
          residualMagnetism: "Less than 15 Gauss"
        },
        additionalRemarks: "Square cut surface and 250 mm from each end. Traceability was maintained by heat and lot, and pipe numbers"
      },
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Visual inspection",
            description: "All pipes have been visually inspected for surface defects over the full length of each pipe, the inside surface of the pipe has been visually inspected at the pipe ends as far as inside the pipe length was practical",
            result: "Satisfactory"
          }
        ]
      }
    }, {
      type: TYPES.HYDROSTATIC,
      getProperties: (poItem) => {
        const pressureByPoItem = {
          [PO_ITEMS.PO_ITEM_1]: 72.2,
          [PO_ITEMS.PO_ITEM_2]: 63.3,
          [PO_ITEMS.PO_ITEM_3]: 54.6,
        };

        return {
          minTime: 10,
          minHoldingTimeUnits: "sec",
          pressureUnit: "MPa",
          pressure: pressureByPoItem[poItem] || "",
        };
      },
    }, {
      type: TYPES.DIMENSIONAL,
      properties: {},
    }],
    labTests: [{
      type: TYPES.CHEMICAL,
      quantity: 2
    }, {
      type: TYPES.TENSILE,
      quantity: 1,
      witnesses: true,
    }, {
      type: TYPES.IMPACT,
      quantity: 1,
      witnesses: true,
    }, {
      type: TYPES.HARDNESS,
      quantity: 1,
      witnesses: true,
    }, {
      type: TYPES.HYDROGEN_INDUCED_CRACKING,
      quantity: 1,
      witnesses: true
    }],
  },
  "QCP 8262 Rev. 6 MPQT/FDPT": {
    companies: ["Tenaris", "Turcotte Group"],
    witnesses: ["Sporer Group"], // TODO: Ask Tenaris which inspector company they're going to use.
    labs: ["TenarisLab", "Tenaris-Siderca", "Tremblay Inc."],
    base: "QCP 8262 Rev. 6",
    producerTests: [{
      type: TYPES.HEATTREATMENT,
      properties: {
        method: "In-line",
        type: "Quench and Temper",
        tempUnits: "celsius",
        unitOfHoldingTime: "time",
        coolingMedium: "Water",
        secondTempUnits: "celsius",
        secondCoolingMedium: "Still air",
        temp: "870-960",
        secondTemp: "580-720",
      },
    }],
    labTests: [{
      type: TYPES.CHEMICAL,
      quantity: 2
    }, {
      type: TYPES.TENSILE,
      quantity: 2,
      witnesses: true
    }, {
      type: TYPES.IMPACT,
      quantity: 3,
      witnesses: true
    },{
      type: TYPES.HARDNESS,
      quantity: 2,
      witnesses: true
    }, {
      type: TYPES.MICROGRAPHIC,
      quantity: 2,
      witnesses: true
    }, {
      type: TYPES.CRACK_TIP_OPENING_DISPLACEMENT,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.HOT_TENSILE,
      quantity: 2,
      witnesses: true,
    }, {
      type: TYPES.HYDROGEN_INDUCED_CRACKING,
      quantity: 2,
      witnesses: true
    }],
  },
  "QCP 8252 Rev. 6": {
    companies: ["Tenaris", "Turcotte Group"],
    witnesses: ["Sporer Group"], // TODO: Ask Tenaris which inspector company they're going to use.
    labs: ["TenarisLab", "Tenaris-Siderca", "Tremblay Inc."],
    poItems: [PO_ITEMS.PO_ITEM_1, PO_ITEMS.PO_ITEM_2, PO_ITEMS.PO_ITEM_3, PO_ITEMS.PO_ITEM_4],
    producerTests: [{
      type: TYPES.HEATTREATMENT,
      properties: {
        method: "In-line",
        type: "Quench and Temper",
        tempUnits: "celsius",
        unitOfHoldingTime: "time",
        coolingMedium: "Water",
        secondTempUnits: "celsius",
        secondCoolingMedium: "Still air",
        temp: "870-960",
        secondTemp: "570-720",
      },
    }, {
      type: TYPES.HYDROSTATIC,
      getProperties: (poItem) => {
        const pressureByPoItem = {
          [PO_ITEMS.PO_ITEM_1]: 55.6,
          [PO_ITEMS.PO_ITEM_2]: 55.6,
          [PO_ITEMS.PO_ITEM_3]: 60.9,
          [PO_ITEMS.PO_ITEM_4]: 60.9,
        };

        return {
          pressure: pressureByPoItem[poItem] || "",
          minTime: 10,
          minHoldingTimeUnits: "sec",
          pressureUnit: "MPa",
        };
      },
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Electromagnetic induction test (EMI)",
            description: "All pipes have been tested by Electromagnetic Induction Test (longitudinal, outside N5 notch - ISO 10893-3 Level F2",
            result: "Satisfactory"
          }
        ]
      }
    }, {
      type: TYPES.ULTRASONIC,
      properties : {
        pieceIdentification: "Single piece",
        testStandard : "ISO 10893",
        surfacePreparation : "As rolled",
        notes: "All pipes have been tested by ultrasonic inspection for transversal/longitudinal, inside/outside defects (Notch 5,0) according to QCP requirements with satisfactory results. The wall thickness controlled by UT of each pipe is within the QCP requested tolerance. All pipes have been tested for lamination according to QCP with satisfactory results. UT coverage 100%"
      },
    }, {
      type: TYPES.MAGNETIC,
      properties : {
        pieceIdentification: "Single piece",
        testStandard : "ISO 10893-5",
        condition : "Black light intensity at the examined surface > 1000 µW/cm2, and visible light in the darkened area < 20 lux",
        materialObj: {
          type: "Wet particles",
          visibility: "Fluorescent",
          solution: "Water",
          concentration: "0.2-0.4 mL per 100 mL of liquid vehicle"
        },
        technique: {
          examinedSurface: ["Square cut surface"],
          demagnetization: "Performed",
          residualMagnetism: "The average of four readings shall be 15 Gauss maximum, the maximum individual value shall be 20 Gauss maximum"
        },
        additionalRemarks: "Square cut surface and 250 mm from each end. Traceability was maintained by heat and lot, and pipe numbers"
      },
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Visual inspection",
            description: "All pipes have been visually inspected for surface defects over the full length of each pipe, the inside surface of the pipe has been visually inspected at the pipe ends as far as inside the pipe length was practical",
            result: "Satisfactory"
          }
        ]
      }
    }, {
      type: TYPES.DIMENSIONAL,
      properties: {},
    }],
    labTests: [{
      type: TYPES.CHEMICAL,
      quantity: 2
    }, {
      type: TYPES.TENSILE,
      quantity: 1,
      witnesses: true
    },{
      type: TYPES.IMPACT,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.HARDNESS,
      quantity: 1,
      witnesses: true
    }],
  },
  "QCP 8252 Rev. 6 MPQT/FDPT": {
    companies: ["Tenaris", "Turcotte Group"],
    witnesses: ["Sporer Group"], // TODO: Ask Tenaris which inspector company they're going to use.
    labs: ["TenarisLab", "Tenaris-Siderca", "Tremblay Inc."],
    base: "QCP 8252 Rev. 6",
    producerTests: [{
      type: TYPES.HEATTREATMENT,
      properties: {
        method: "In-line",
        type: "Quench and Temper",
        tempUnits: "celsius",
        unitOfHoldingTime: "time",
        coolingMedium: "Water",
        secondTempUnits: "celsius",
        secondCoolingMedium: "Still air",
        temp: "870-960",
        secondTemp: "570-720",
      },
    }, {
      type: TYPES.DIMENSIONAL,
      properties: {},
    }],
    labTests: [{
      type: TYPES.CHEMICAL,
      quantity: 2
    }, {
      type: TYPES.TENSILE,
      quantity: 1,
      witnesses: true
    },{
      type: TYPES.IMPACT,
      quantity: 2,
      witnesses: true
    }, {
      type: TYPES.HARDNESS,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.MICROGRAPHIC,
      quantity: 1,
      witnesses: true
    }],
  },
  "QCP 8251 Rev. 6": {
    companies: ["Tenaris", "Turcotte Group"],
    witnesses: ["Sporer Group"], // TODO: Ask Tenaris which inspector company they're going to use.
    labs: ["TenarisLab", "Tenaris-Siderca", "Tremblay Inc."],
    producerTests: [{
      type: TYPES.HEATTREATMENT,
      activityNumber: "6",
      properties: {
        method: "In-line",
        type: "Quench and Temper",
        tempUnits: "celsius",
        unitOfHoldingTime: "time",
        coolingMedium: "Water",
        secondTempUnits: "celsius",
        secondCoolingMedium: "Still air",
        temp: "870-960",
        secondTemp: "580-720",
      },
    }, {
      type: TYPES.HYDROSTATIC,
      activityNumber: "12",
      properties: {
        minTime: 10,
        minHoldingTimeUnits: "sec",
        pressureUnit: "bar",
        pressure: 554,
      },
    }, {
      type: TYPES.OTHER,
      activityNumber: "13",
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Electromagnetic induction test (EMI)",
            description: "All pipes have been tested by Electromagnetic Induction Test (longitudinal, outside N5 notch - ISO 10893-3 Level F2",
            result: "Satisfactory"
          }
        ]
      }
    }, {
      type: TYPES.ULTRASONIC,
      activityNumber: "16",
      properties : {
        pieceIdentification: "Single piece",
        testStandard: "ISO 10893",
        surfacePreparation: "As rolled",
        notes: "All pipes have been tested by ultrasonic inspection for transversal/longitudinal, inside/outside defects (Notch 5,0) according to QCP requirements with satisfactory results. The wall thickness controlled by UT of each pipe is within the QCP requested tolerance. All pipes have been tested for lamination according to QCP with satisfactory results. UT coverage 100%"
      },
      witnesses: true
    }, {
      type: TYPES.MAGNETIC,
      activityNumber: "17",
      properties : {
        pieceIdentification: "Single piece",
        testStandard : "ISO 10893-5",
        condition : "Black light intensity at the examined surface > 1000 µW/cm2, and visible light in the darkened area < 20 lux",
        materialObj: {
          type: "Wet particles",
          visibility: "Fluorescent",
          solution: "Water",
          concentration: "0.2-0.4 mL per 100 mL of liquid vehicle"
        },
        technique: {
          examinedSurface: ["Square cut surface"],
          demagnetization: "Performed",
          residualMagnetism: "Less than 15 Gauss"
        },
        additionalRemarks: "Square cut surface and 250 mm from each end. Traceability was maintained by heat and lot, and pipe numbers"
      },
    }, {
      type: TYPES.OTHER,
      activityNumber: "19",
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Visual inspection",
            description: "All pipes have been visually inspected for surface defects over the full length of each pipe, the inside surface of the pipe has been visually inspected at the pipe ends as far as inside the pipe length was practical",
            result: "Satisfactory"
          }
        ]
      }
    }, {
      type: TYPES.DIMENSIONAL,
      activityNumber: "18",
      properties: {},
    }],
    labTests: [{
      type: TYPES.CHEMICAL,
      activityNumber: "3.2",
      quantity: 1
    }, {
      type: TYPES.CHEMICAL,
      activityNumber: "11.1",
      quantity: 1
    }, {
      type: TYPES.TENSILE,
      activityNumber: "11.2",
      quantity: 1,
      witnesses: true
    },{
      type: TYPES.IMPACT,
      activityNumber: "11.3",
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.HARDNESS,
      activityNumber: "11.4",
      quantity: 1,
      witnesses: true
    }],
  },
  "QCP 8251 Rev. 6 MPQT/FDPT": {
    companies: ["Tenaris", "Turcotte Group"],
    witnesses: ["Sporer Group"], // TODO: Ask Tenaris which inspector company they're going to use.
    labs: ["TenarisLab", "Tenaris-Siderca", "Tremblay Inc."],
    base: "QCP 8251 Rev. 6",
    producerTests: [{
      type: TYPES.HEATTREATMENT,
      properties: {
        method: "In-line",
        type: "Quench and Temper",
        tempUnits: "celsius",
        unitOfHoldingTime: "time",
        coolingMedium: "Water",
        secondTempUnits: "celsius",
        secondCoolingMedium: "Still air",
        temp: "870-960",
        secondTemp: "580-720",
      },
    }],
    labTests: [{
      type: TYPES.CHEMICAL,
      quantity: 2
    }, {
      type: TYPES.TENSILE,
      quantity: 1,
      witnesses: true
    },{
      type: TYPES.IMPACT,
      quantity: 2,
      witnesses: true
    }, {
      type: TYPES.HARDNESS,
      quantity: 1,
      witnesses: true
    },{
      type: TYPES.MICROGRAPHIC,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.CRACK_TIP_OPENING_DISPLACEMENT,
      quantity: 1,
      witnesses: true
    }],
  },
  "QCP 8263 Rev. 6": {
    companies: ["Tenaris", "Turcotte Group"],
    witnesses: ["Sporer Group"], // TODO: Ask Tenaris which inspector company they're going to use.
    labs: ["TenarisLab", "Tenaris-Siderca", "Tremblay Inc."],
    poItems: [PO_ITEMS.PO_ITEM_1, PO_ITEMS.PO_ITEM_2],
    producerTests: [{
      type: TYPES.HYDROSTATIC,
      getProperties: (poItem) => {
        const pressureByPoItem = {
          [PO_ITEMS.PO_ITEM_1]: 41.6,
          [PO_ITEMS.PO_ITEM_2]: 45.2,
        };

        return {
          pressure: pressureByPoItem[poItem] || "",
          minTime: 10,
          minHoldingTimeUnits: "sec",
          pressureUnit: "MPa",
        };
      },
    }, {
      type: TYPES.HEATTREATMENT,
      properties: {
        method: "In-line",
        type: "Quench and Temper",
        tempUnits: "celsius",
        unitOfHoldingTime: "time",
        coolingMedium: "Water",
        secondTempUnits: "celsius",
        secondCoolingMedium: "Still air",
        temp: "870-960",
        secondTemp: "570-720",
      },
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Electromagnetic induction test (EMI)",
            description: "All pipes have been tested by Electromagnetic Induction Test (longitudinal, outside N5 notch - ISO 10893-3 Level F2",
            result: "Satisfactory"
          }
        ]
      }
    }, {
      type: TYPES.ULTRASONIC,
      properties : {
        pieceIdentification: "Single piece",
        testStandard : "ISO 10893",
        surfacePreparation : "As rolled",
        notes: "All pipes have been tested by ultrasonic inspection for transversal/longitudinal, inside/outside defects (Notch 5,0) according to QCP requirements with satisfactory results. The wall thickness controlled by UT of each pipe is within the QCP requested tolerance. All pipes have been tested for lamination according to QCP with satisfactory results. UT coverage 100%"
      },
    },{
      type: TYPES.MAGNETIC,
      properties : {
        pieceIdentification: "Single piece",
        testStandard : "ISO 10893-5",
        condition : "Black light intensity at the examined surface > 1000 µW/cm2, and visible light in the darkened area < 20 lux",
        materialObj: {
          type: "Wet particles",
          visibility: "Fluorescent",
          solution: "Water",
          concentration: "0.2-0.4 mL per 100 mL of liquid vehicle"
        },
        technique: {
          examinedSurface: ["Square cut surface"],
          demagnetization: "Performed",
          residualMagnetism: "The average of four readings shall be 15 Gauss maximum, the maximum individual value shall be 20 Gauss maximum"
        },
        additionalRemarks: "Square cut surface and 250 mm from each end. Traceability was maintained by heat and lot, and pipe numbers"
      },
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Visual inspection",
            description: "All pipes have been visually inspected for surface defects over the full length of each pipe, the inside surface of the pipe has been visually inspected at the pipe ends as far as inside the pipe length was practical",
            result: "Satisfactory"
          }
        ]
      }
    }, {
      type: TYPES.DIMENSIONAL,
      properties: {},
    }],
    labTests: [{
      type: TYPES.CHEMICAL,
      quantity: 2
    }, {
      type: TYPES.TENSILE,
      quantity: 1,
      witnesses: true
    },{
      type: TYPES.IMPACT,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.HARDNESS,
      quantity: 1,
      witnesses: true
    }],
  },
  "QCP 8263 Rev. 6 MPQT/FDPT": {
    companies: ["Tenaris", "Turcotte Group"],
    witnesses: ["Sporer Group"], // TODO: Ask Tenaris which inspector company they're going to use.
    labs: ["TenarisLab", "Tenaris-Siderca", "Tremblay Inc."],
    base: "QCP 8263 Rev. 6",
    producerTests: [{
      type: TYPES.HEATTREATMENT,
      properties: {
        method: "In-line",
        type: "Quench and Temper",
        tempUnits: "celsius",
        unitOfHoldingTime: "time",
        coolingMedium: "Water",
        secondTempUnits: "celsius",
        secondCoolingMedium: "Still air",
        temp: "870-960",
        secondTemp: "570-720",
      },
    }],
    labTests: [{
      type: TYPES.CHEMICAL,
      quantity: 2
    }, {
      type: TYPES.TENSILE,
      quantity: 2,
      witnesses: true
    },{
      type: TYPES.IMPACT,
      quantity: 3,
      witnesses: true
    }, {
      type: TYPES.HARDNESS,
      quantity: 2,
      witnesses: true
    }, {
      type: TYPES.MICROGRAPHIC,
      quantity: 2,
      witnesses: true
    }, {
      type: TYPES.HOT_TENSILE,
      quantity: 2,
      witnesses: true
    }],
  },
  "BLFB1-SSGN-UFR-ITP-BAY-00000-00011-00": {
    companies: ["Chevron", "The Bayou Companies", "Turcotte Group"],
    producerTests: [
      {
        type: TYPES.CUSTOM,
        displayName: "Surface Preparation",
        properties: {
          activities : [
            {
              activity : "3.1.0",
              description : "Dew Point",
              acceptanceCriteria : {
                label : "To be recorded"
              }
            },
            {
              activity : "3.1.1",
              description : "Relative humidity",
              acceptanceCriteria : {
                label : "To be recorded"
              }
            },
            {
              activity : "4.4.0",
              description : "Soluble salt after blasting",
              acceptanceCriteria : {
                label : "≤ 2μg/cm2",
                value : {
                  max : 2,
                  unit : "μg/cm2"
                }
              }
            },
            {
              activity: "5.0.1",
              description: "Surface cleanliness",
              acceptanceCriteria: {
                label : "NACE #2"
              }
            },
            {
              activity : "5.0.2",
              description : "Surface profile",
              acceptanceCriteria: {
                label : "To be recorded"
              }
            },
            {
              activity : "6.0.0",
              description : "Acid type",
              acceptanceCriteria: {
                label : "CREST 10B"
              }
            },
            {
              activity : "6.0.0",
              description : "Acid %",
              acceptanceCriteria: {
                label : "To be recorded"
              }
            },
            {
              activity : "6.0.1",
              description : "Preheat temperature",
              acceptanceCriteria: {
                label : "To be recorded"
              }
            },
            {
              activity : "6.0.2",
              description : "Dwell time",
              acceptanceCriteria: {
                label : "To be recorded"
              }
            },
            {
              activity : "6.0.3",
              description : "Acid wash water rinse conductivity",
              acceptanceCriteria: {
                label : "To be recorded"
              }
            },
            {
              activity : "6.0.4",
              description : "Rinse pH",
              acceptanceCriteria: {
                label : "To be recorded"
              }
            }
          ]},
      },
      {
        type: TYPES.CUSTOM,
        displayName: "Heating",
        properties: {
          activities : [
            {
              activity : "7.1.1",
              description : "Temperature",
              acceptanceCriteria : {
                name : "surfaceTemperature",
                label : "≤ 500°F",
                value : {
                  max : 500,
                  unit : "°F"
                }
              }
            },
            {
              activity : "7.1.2",
              description : "IR Temperature",
              acceptanceCriteria : {
                name : "surfaceTemperature",
                label : "≤ 500°F",
                value : {
                  max : 500,
                  unit : "°F"
                }
              }
            }
          ]},
      },
      {
        type: TYPES.CUSTOM,
        displayName: "Coating Application",
        properties: {
          activities : [
            {
              activity : "9.0.1",
              description : "FBE Type",
              acceptanceCriteria: {
                label : "VALSPAR HOT 180 BASE"
              }
            },
            {
              activity : "9.0.2",
              description : "FBE Batch",
              acceptanceCriteria: {
                label : "To be recorded"
              }
            },
            {
              activity : "9.0.3",
              description : "MRO Type",
              acceptanceCriteria: {
                label : "VALSPAR HOT 180 MRO"
              }
            },
            {
              activity : "9.0.4",
              description : "MRO Batch",
              acceptanceCriteria: {
                label : "To be recorded"
              }
            },
            {
              activity : "10.0.0",
              description : "Time to quench",
              acceptanceCriteria : {
                name : "timeToQuench",
                label: "≥ 3:30mins",
                value : {
                  unit : "s",
                  min: 210
                }
              }
            },
            {
              activity: "10.0.1",
              description: "Time to touch",
              acceptanceCriteria: {
                label : "To be recorded"
              }
            }
          ]},
      },
      {
        type: TYPES.CUSTOM,
        displayName: "Final Inspection",
        properties: {
          activities : [
            {
              activity : "12.1.1",
              description : "FBE mils",
              acceptanceCriteria :
                {
                  label: "7mils -1mil +2mils",
                  value : {
                    min: 6,
                    max: 9,
                    unit: "mils"
                  }
                }
            },
            {
              activity : "12.1.2",
              description : "MRO mils",
              acceptanceCriteria :
                {
                  label: "7mils -1mil +2mils",
                  value : {
                    min: 6,
                    max: 9,
                    unit: "mils"
                  }
                }
            },
            {
              activity : "12.2.0",
              description : "Adhesion Rating",
              acceptanceCriteria : {
                label: "≤ 5°C",
                value : {
                  max : 5,
                  unit : "°C"
                }
              }
            },
            {
              activity : "12.3.0",
              description : "Cutbacks",
              acceptanceCriteria: {
                label : "7.9\", +1,-0/14.6\", +1, -0"
              }
            },
            {
              activity : "13.0.0",
              description : "Spacers and size",
              acceptanceCriteria : {
                label: "Four 1” rope ring separators per pipe on 40 linear feet (12m)",
              }
            }
          ]},
      },{
        type: TYPES.CUSTOM,
        displayName: "Production Tests",
        properties: {
          activities : [
            {
              activity : "18.1.0",
              description : "DSC Cure",
              acceptanceCriteria : {
                name : "dscCure",
                label : "≤ 5°C",
                value : {
                  max : 5,
                  unit : "°C"
                }
              }
            },
            {
              activity : "18.2.1",
              description : "Porosity - Interface",
              acceptanceCriteria : {
                label : "Between 1 and 2",
                value : {
                  min: 1,
                  max : 2,
                  unit : ""
                }
              }
            },
            {
              activity : "18.2.2",
              description : "Porosity - Cross-section",
              acceptanceCriteria : {
                label : "Between 1 and 2",
                value : {
                  min: 1,
                  max : 2,
                  unit : ""
                }
              }
            },
            {
              activity : "18.3.0",
              description : "Contamination",
              acceptanceCriteria : {
                label : "≤ 30%",
                value : {
                  max: 30,
                  unit: "%"
                }
              }
            },
            {
              activity : "18.4.0",
              description : "Cathodic Disbondment (CDT)",
              acceptanceCriteria : {
                label : "≤ 6.5mm",
                value : {
                  unit : "mm",
                  max : 6.5
                }
              }
            },
            {
              activity: "18.5.0",
              description: "Moisture Permeation",
              acceptanceCriteria : {
                label : "Between 1 and 2",
                value : {
                  min: 1,
                  max : 2,
                  unit : ""
                }
              }
            },
            {
              activity : "18.7.0",
              description : "Flexibility (Bend test)",
              acceptanceCriteria : {
                label : "1.5°/pd at 0°C",
                value : {
                  min: 1.5,
                  max: 1.5,
                  unit: "°/pd"
                }
              }
            }
          ]},
      },
    ],
  },
  "BLFB1-SSGN-QAC-ITP-SS7-00000-00001-00": {
    companies: ["Chevron", "Subsea7", "Turcotte Group"],
    producerTests: [
      {
        type: TYPES.CUSTOM,
        displayName: "Counterboring",
        properties: {
          activities : [
            {
              activity : "5.1",
              description : "Verify Machined ID Length",
              acceptanceCriteria : {
                label : "6\" (+1.0\"/ -0.0\")",
                value : {
                  min : 6,
                  max : 7,
                  unit : "\""
                }
              }
            },
            {
              activity : "5.2",
              description : "Verify Machined ID (Tier 1)",
              acceptanceCriteria : {
                label : "7.180 +/-0.010\"",
                value : {
                  min : 7.170,
                  max : 7.190,
                  unit : "\""
                }
              }
            },
            {
              activity : "5.2",
              description : "Verify Machined ID (Tier 2)",
              acceptanceCriteria : {
                label : "7.285 +/-0.010\"",
                value : {
                  min : 7.275,
                  max : 7.285,
                  unit : "\""
                }
              }
            },
            {
              activity: "5.3.1",
              description: "Verify Machined WT (1\") (Tier 1)",
              acceptanceCriteria: {
                name: "targetWt",
                label: "≥ 1.760”",
                value: {
                  min: 1.76,
                  unit: "\""
                }
              }
            },
            {
              activity : "5.3.1",
              description : "Verify Machined WT (1\") (Tier 2)",
              acceptanceCriteria :
                {
                  name : "targetWt",
                  label : "≥ 1.690”",
                  value : {
                    min : 1.69,
                    unit : "\""
                  }
                }
            },
            {
              activity : "5.3.2",
              description : "Verify Machined WT (3.5\") (Tier 1)",
              acceptanceCriteria :
                {
                  name : "targetWt",
                  label : "≥ 1.760”",
                  value : {
                    min : 1.76,
                    unit : "\""
                  }
                }
            },
            {
              activity : "5.3.2",
              description : "Verify Machined WT (3.5\") (Tier 2)",
              acceptanceCriteria :
                {
                  name : "targetWt",
                  label : "≥ 1.690”",
                  value : {
                    min : 1.69,
                    unit : "\""
                  }
                }
            },
            {
              activity : "5.4",
              description : "Taper Transition",
              acceptanceCriteria :
                {
                  name : "taperTransition",
                  label : "20’’ radius",
                  value : {
                    min: 20,
                    max: 20,
                    equal : 20,
                    unit : "\""
                  }
                }
            },
            {
              activity : "5.5.1",
              description : "Machined Surface Finish (Circ.)",
              acceptanceCriteria :
                {
                  name: "rmsConf",
                  label : "< 125 RMS",
                  value : {
                    max : 125,
                    unit : "RMS"
                  }
                }
            },
            {
              activity : "5.5.2",
              description : "Machined Surface Finish (Long.)",
              acceptanceCriteria :
                {
                  name : "rmsLong",
                  label : "< 125 RMS",
                  value : {
                    max : 125,
                    unit : "RMS"
                  }
                }
            }
          ]},
      },
      {
        type: TYPES.CUSTOM,
        displayName: "Field Joint Coating",
        properties: {
          activities : [
            {
              activity : "8.2.1",
              description : "Compressed air quality check",
              acceptanceCriteria :
                {
                  name : "airCheck",
                  label : "No evidence of water, oil, or grease during or after 60 second compressed air discharge"
                }
            },
            {
              activity : "8.2.5",
              description : "Steel substrate surface temperature",
              acceptanceCriteria : {
                name : "surfaceTemperature",
                label : "≥ 5°C",
                value : {
                  min : 5,
                  unit : "°C"
                }
              }
            },
            {
              activity : "8.2.6",
              description : "Abrasive contamination testing",
              acceptanceCriteria : {
                name : "abrasiveContamination",
                label : "< 100µS/cm",
                value : {
                  max : 99,
                  unit : "µS/cm"
                }
              }
            },
            {
              activity : "8.3.4",
              description : "Dust Test",
              acceptanceCriteria : {
                name : "dustTest",
                label : "≤ 2",
                value : {
                  max : 2,
                  unit: ""
                }
              }
            },
            {
              activity : "8.3.5",
              description : "Salt contamination",
              acceptanceCriteria : {
                name : "saltContamination",
                label : "≤ 2 μg/cm2",
                value : {
                  unit : "μg/cm2",
                  max : 2
                }
              }
            },
            {
              activity: "8.3.6",
              description: "Surface profile",
              acceptanceCriteria: {
                name: "surfaceProfile",
                label: "50µm - 100µm",
                value: {
                  unit: "µm",
                  min: 50,
                  max: 100
                }
              }
            },
            {
              activity : "8.4.3",
              description : "Removal of surplus powder",
              acceptanceCriteria : {
                name : "surplusPowder",
                label : "Surplus powder blown from overlap with compressed air"
              }
            },
            {
              activity : "8.5.4",
              description : "Holiday Detection",
              acceptanceCriteria : {
                name : "holidayDetection",
                label : "No holidays at 1.5kV. All pinholes marked but to be repaired after abrading/blasting FBE in section 8.6",
                value : {
                  max : 0,
                  unit : ""
                }
              }
            },
            {
              activity : "8.5.3",
              description : "DFT FBE FJC",
              acceptanceCriteria : {
                name : "DFT",
                label : "305µm - 533µm",
                value : {
                  unit : "µm",
                  min : 305,
                  max : 533
                }
              }
            }
          ]},
      },
      {
        type: TYPES.CUSTOM,
        displayName: "NDE Inspection",
        properties: {
          activities : [
            {
              activity : "7.1.1",
              description : "Cap Surface Defect",
              acceptanceCriteria :
                {
                  name : "capSurfaceDefect",
                  label : "4.0 x 13.0 / 3.0 x 19.0 / 2.0 x 31.0"
                }
            },
            {
              activity : "7.1.2",
              description : "Embedded Defect at bottom half WT (ligament=5mm)",
              acceptanceCriteria :
                {
                  name : "embeddedDefectBottomHalf5",
                  label : "4.0 x 13.0 / 3.0 x 19.0 / 2.0 x 31.0"
                }
            },
            {
              activity : "7.1.3",
              description : "Embedded Defect at bottom half WT (ligament=4mm)",
              acceptanceCriteria :
                {
                  name : "embeddedDefectBottomHalf4",
                  label : "4.0 x 12.0 / 3.0 x 14.0 / 2.0 x 26.0"
                }
            },
            {
              activity : "7.1.4",
              description : "Root Surface Defect",
              acceptanceCriteria :
                {
                  name : "rootSurfaceDefect",
                  label : "2.5 x 13.0 / 2.0 x 19.0 / 1.5 x 31.0"
                }
            }
          ]},
      },
    ],
  },
  "ITP-BPM-22.20 Rev.6": {
    companies: ["Berg Pipe", "Turcotte Group"],
    witnesses: ["Sporer Group"],
    labs: ["Tremblay Inc."],
    poItems: [PO_ITEMS.PO_ITEM_1, PO_ITEMS.PO_ITEM_2],
    producerTests: [
      {
        type: TYPES.ULTRASONIC,
        properties : {
          pieceIdentification: "Single piece",
          testStandard : "ASTM A578 Level B",
          surfacePreparation : "As rolled",
          notes: "Straight beam calibrated to detect a 6.4 mm FBH machined to a 50% wall reduction"
        },
      },
      {
        type: TYPES.HYDROSTATIC,
        getProperties: (poItem) => {
          const pressureByPoItem = {
            [PO_ITEMS.PO_ITEM_1]: 1930,
            [PO_ITEMS.PO_ITEM_2]: 2320,
          };

          return {
            pressure: pressureByPoItem[poItem] || "",
            minTime: 10,
            testingFluid: "Water",
            minHoldingTimeUnits: "sec",
            pressureUnit: "psi",
          };
        },
        witnesses: true
      },
      {
        type: TYPES.ULTRASONIC,
        properties : {
          pieceIdentification: "Single piece",
          surfacePreparation : "As welded",
          notes: "Shear wave transducers calibrated on 1.6 mm diameter through drilled hole and N5 weld-edge notches on both OD and ID surface",
          witnesses: true
        },
      },
      {
        type: TYPES.ULTRASONIC,
        properties : {
          pieceIdentification: "Single piece",
          surfacePreparation : "As welded",
          notes: "Straight beam transducer calibrated to detect a 6.4 mm FBH",
          witnesses: true
        },
      },
      {
        type: TYPES.ULTRASONIC,
        properties : {
          pieceIdentification: "Single piece",
          surfacePreparation : "As welded",
          notes: "Shear wave transducers calibrated on 1.6 mm diameter through drilled hole. Compression wave transducer calibrated to detect 6.4 m FBH",
          witnesses: true
        },
      },
      {
        type: TYPES.OTHER,
        properties: {
          result: TEST_RESULTS.ACCEPTABLE,
          elements: [
            {
              test: "Radiographic examination",
              description: "Use 2% wire IQI. Within 25 mm of the pipe ends, acceptance criteria is 10% of API 5L allowance. From 25mm to 200mm of the pipe ends, acceptance criteria is 50% of API 5L allowance.",
              result: "Satisfactory"
            }
          ]
        }
      },
      {
        type: TYPES.OTHER,
        properties: {
          result: TEST_RESULTS.ACCEPTABLE,
          elements: [
            {
              test: "Residual magnetism",
              description: "Less than 20 Gauss for each reading, less than 20 Gauss for average of four readings",
              notes: "Four readings taken approximately 90 deg. apart around the circumference of each end of the pipe. Measurement made at each end of the pipe by gauss meter",
              result: "Satisfactory"
            }
          ]
        }
      },
      {
        type: TYPES.OTHER,
        properties: {
          result: TEST_RESULTS.ACCEPTABLE,
          elements: [
            {
              test: "Visual test",
              description: "Performed to detect: arc burns, dents affecting the weld, misalignment of the ID/OD weld beads (off seam), hard spots, undercut, cracks, leaks, sweats, pipe end laminations, surface imperfections and other defects",
              notes: "Compliant to ITP-BPM-22.20 requirements",
              result: "Satisfactory"
            }
          ]
        },
        witnesses: true
      },
      {
        type: TYPES.DIMENSIONAL,
        properties: {},
        witnesses: true
      }
    ],
    labTests: [{
      type: TYPES.CHEMICAL,
      quantity: 3,
      witnesses: true
    },{
      type: TYPES.DROP_WEIGHT_TEAR,
      quantity: 1,
      witnesses: true
    },
    {
      type: TYPES.TENSILE,
      quantity: 1,
      witnesses: true
    },{
      type: TYPES.IMPACT,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.HARDNESS,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.MACROGRAPHIC,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.BEND,
      quantity: 1,
      witnesses: true
    }],
  },
  "ITP-BPM-22.20 Rev.6 MPQT": {
    companies: ["Berg Pipe", "Turcotte Group"],
    witnesses: ["Sporer Group"],
    poItems: [PO_ITEMS.PO_ITEM_1, PO_ITEMS.PO_ITEM_2],
    base: "ITP-BPM-22.20 Rev.6",
    labs: ["Tremblay Inc."],
    producerTests: [
      {
        type: TYPES.ULTRASONIC,
        properties : {
          pieceIdentification: "Single piece",
          testStandard : "ASTM A578 Level B",
          surfacePreparation : "As rolled",
          notes: "Straight beam calibrated to detect a 6.4 mm FBH machined to a 50% wall reduction"
        },
      },
      {
        type: TYPES.HYDROSTATIC,
        getProperties: (poItem) => {
          const pressureByPoItem = {
            [PO_ITEMS.PO_ITEM_1]: 1930,
            [PO_ITEMS.PO_ITEM_2]: 2320,
          };

          return {
            pressure: pressureByPoItem[poItem] || "",
            minTime: 10,
            testingFluid: "Water",
            minHoldingTimeUnits: "sec",
            pressureUnit: "psi",
          };
        },
        witnesses: true
      },
      {
        type: TYPES.ULTRASONIC,
        properties : {
          pieceIdentification: "Single piece",
          surfacePreparation : "As welded",
          notes: "Shear wave transducers calibrated on 1.6 mm diameter through drilled hole and N5 weld-edge notches on both OD and ID surface",
          witnesses: true
        },
      },
      {
        type: TYPES.ULTRASONIC,
        properties : {
          pieceIdentification: "Single piece",
          surfacePreparation : "As welded",
          notes: "Straight beam transducer calibrated to detect a 6.4 mm FBH",
          witnesses: true
        },
      },
      {
        type: TYPES.ULTRASONIC,
        properties : {
          pieceIdentification: "Single piece",
          surfacePreparation : "As welded",
          notes: "Shear wave transducers calibrated on 1.6 mm diameter through drilled hole. Compression wave transducer calibrated to detect 6.4 m FBH",
          witnesses: true
        },
      },
      {
        type: TYPES.OTHER,
        properties: {
          result: TEST_RESULTS.ACCEPTABLE,
          elements: [
            {
              test: "Radiographic examination",
              description: "Use 2% wire IQI. Within 25 mm of the pipe ends, acceptance criteria is 10% of API 5L allowance. From 25mm to 200mm of the pipe ends, acceptance criteria is 50% of API 5L allowance.",
              result: "Satisfactory"
            }
          ]
        }
      },
      {
        type: TYPES.OTHER,
        properties: {
          result: TEST_RESULTS.ACCEPTABLE,
          elements: [
            {
              test: "Residual magnetism",
              description: "Less than 20 Gauss for each reading, less than 20 Gauss for average of four readings",
              notes: "Four readings taken approximately 90 deg. apart around the circumference of each end of the pipe. Measurement made at each end of the pipe by gauss meter",
              result: "Satisfactory"
            }
          ]
        }
      },
      {
        type: TYPES.OTHER,
        properties: {
          result: TEST_RESULTS.ACCEPTABLE,
          elements: [
            {
              test: "Visual test",
              description: "Performed to detect: arc burns, dents affecting the weld, misalignment of the ID/OD weld beads (off seam), hard spots, undercut, cracks, leaks, sweats, pipe end laminations, surface imperfections and other defects",
              notes: "Compliant to ITP-BPM-22.20 requirements",
              result: "Satisfactory"
            }
          ]
        },
        witnesses: true
      },
      {
        type: TYPES.DIMENSIONAL,
        properties: {},
        witnesses: true
      }
    ],
    labTests: [{
      type: TYPES.CHEMICAL,
      quantity: 3,
      witnesses: true
    },{
      type: TYPES.DROP_WEIGHT_TEAR,
      quantity: 1,
      witnesses: true
    },
    {
      type: TYPES.TENSILE,
      quantity: 2,
      witnesses: true
    },{
      type: TYPES.IMPACT,
      quantity: 4,
      witnesses: true
    }, {
      type: TYPES.HARDNESS,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.MACROGRAPHIC,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.BEND,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.CRACK_TIP_OPENING_DISPLACEMENT,
      quantity: 1,
      witnesses: true
    }],
  },
  "ITP 011/23/124277 Rev. 05": {
    companies: ["Turcotte Group"],
    witnesses: ["Sporer Group"],
    labs: ["Tremblay Inc."],
    producerTests: [],
    labTests: [{
      type: TYPES.TENSILE,
      quantity: 1,
      witnesses: true,
    }, {
      type: TYPES.IMPACT,
      quantity: 1,
      witnesses: true
    },{
      type: TYPES.HARDNESS,
      quantity: 1,
    }, {
      type: TYPES.MACROGRAPHIC,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.MICROGRAPHIC,
      quantity: 2,
      witnesses: true
    }, {
      type: TYPES.CHEMICAL,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.BEND,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.DIMENSIONAL,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.CORROSION,
      quantity: 5,
      witnesses: true,
      properties: {
        testStandard: "ASTM A262 (2015)",
        method: "E"
      },
    }, {
      type: TYPES.CORROSION,
      quantity: 1,
      witnesses: true,
      properties: {
        testStandard: "ASTM G28-02 (2015)",
        method: "A",
        maxCorrosionRate: {
          value: 1,
          unit: "mm/y"
        }
      },
    }, {
      type: TYPES.CORROSION,
      quantity: 1,
      witnesses: true,
      properties: {
        testStandard: "ASTM G48-11 (2020)e1",
        method: "A",
        maxWeightLoss: {
          value: 4,
          unit: "g/m^2"
        }
      },
    }],
  },
  "ITP 011/23/124277 Rev. 05 MPQT": {
    companies: ["Turcotte Group"],
    witnesses: ["Sporer Group"],
    labs: ["Tremblay Inc."],
    base: "ITP 011/23/124277 Rev. 05",
    producerTests: [],
    labTests: [{
      type: TYPES.TENSILE,
      quantity: 1,
      witnesses: true,
    },{
      type: TYPES.HOT_TENSILE,
      quantity: 1,
      witnesses: true,
    }, {
      type: TYPES.IMPACT,
      quantity: 2,
      witnesses: true
    }, {
      type: TYPES.CRACK_TIP_OPENING_DISPLACEMENT,
      quantity: 1,
      witnesses: true
    },{
      type: TYPES.HARDNESS,
      quantity: 1,
    }, {
      type: TYPES.MACROGRAPHIC,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.MICROGRAPHIC,
      quantity: 2,
      witnesses: true
    }, {
      type: TYPES.CHEMICAL,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.BEND,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.DIMENSIONAL,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.CORROSION,
      quantity: 5,
      witnesses: true,
      properties: {
        testStandard: "ASTM A262 (2015)",
        method: "E"
      },
    }, {
      type: TYPES.CORROSION,
      quantity: 1,
      witnesses: true,
      properties: {
        testStandard: "ASTM G28-02 (2015)",
        method: "A",
        maxCorrosionRate: {
          value: 1,
          unit: "mm/y"
        }
      },
    }, {
      type: TYPES.CORROSION,
      quantity: 1,
      witnesses: true,
      properties: {
        testStandard: "ASTM G48-11 (2020)e1",
        method: "A",
        maxWeightLoss: {
          value: 4,
          unit: "g/m^2"
        }
      },
    }],
  },
  "QCP 5513 Rev. 2 (FDPT)": {
    companies: ["Tenaris", "Turcotte Group"],
    witnesses: ["Sporer Group"],
    labs: ["TenarisLab", "Tremblay Inc."],
    poItems: [PO_ITEMS.PO_ITEM_1, PO_ITEMS.PO_ITEM_2],
    base: "QCP 5513 Rev. 2",
    producerTests: [{
      type: TYPES.DIMENSIONAL,
      properties: {},
      witnesses: true
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Visual inspection",
            description: "Surface imperfections deeper than 5% nominal wal thickness or imperfections that encroach on the minimum permissible WT shall be classified as defects",
            result: "Satisfactory"
          }
        ]
      }
    }, {
      type: TYPES.HEATTREATMENT,
      properties: {
        method : "In-line",
        type : "Quench and Temper",
        tempUnits : "celsius",
        unitOfHoldingTime : "time",
        coolingMedium : "Water",
        secondTempUnits : "celsius",
        secondCoolingMedium : "Still air",
        strainHardening : false,
        temp: "870 - 960",
        secondTemp: "580 - 720",
      }
    },  {
      type: TYPES.HYDROSTATIC,
      getProperties: (poItem) => {
        const pressureByPoItem = {
          [PO_ITEMS.PO_ITEM_1]: 15931,
          [PO_ITEMS.PO_ITEM_2]: 19458,
        };

        return {
          pressure: pressureByPoItem[poItem] || "",
          minTime: 10,
          minHoldingTimeUnits: "sec",
          pressureUnit: "psi",
        };
      },
      witnesses: true
    }, {
      type: TYPES.ELECTROMAGNETIC_INDUCTION,
      properties : {},
      witnesses: true
    }, {
      type: TYPES.ULTRASONIC,
      properties : {},
      witnesses: true
    }, {
      type: TYPES.MAGNETIC,
      properties : {},
      witnesses: true
    }],
    labTests: [{
      type: TYPES.TENSILE,
      quantity: 2,
      witnesses: true
    }, {
      type: TYPES.IMPACT,
      quantity: 3,
      witnesses: true
    }, {
      type: TYPES.HARDNESS,
      quantity: 2,
      witnesses: true
    }, {
      type: TYPES.HOT_TENSILE,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.CRACK_TIP_OPENING_DISPLACEMENT,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.HYDROGEN_INDUCED_CRACKING,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.SULPHIDE_STRESS_CORROSIO_CRACKING,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.CHEMICAL,
      quantity: 1,
    }, {
      type: TYPES.CHEMICAL,
      quantity: 1,
      witnesses: true
    }],
  },
  "QCP 5513 Rev. 2": {
    companies: ["Tenaris", "Turcotte Group"],
    witnesses: ["Sporer Group"],
    labs: ["TenarisLab", "Tremblay Inc."],
    poItems: [PO_ITEMS.PO_ITEM_1, PO_ITEMS.PO_ITEM_2],
    producerTests: [{
      type: TYPES.DIMENSIONAL,
      properties: {},
      witnesses: true
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Visual inspection",
            description: "Surface imperfections deeper than 5% nominal wal thickness or imperfections that encroach on the minimum permissible WT shall be classified as defects",
            result: "Satisfactory"
          }
        ]
      }
    }, {
      type: TYPES.HEATTREATMENT,
      properties: {
        method : "In-line",
        type : "Quench and Temper",
        tempUnits : "celsius",
        unitOfHoldingTime : "time",
        coolingMedium : "Water",
        secondTempUnits : "celsius",
        secondCoolingMedium : "Still air",
        strainHardening : false,
        temp: "870 - 960",
        secondTemp: "580 - 720",
      }
    }, {
      type: TYPES.HYDROSTATIC,
      getProperties: (poItem) => {
        const pressureByPoItem = {
          [PO_ITEMS.PO_ITEM_1]: 15931,
          [PO_ITEMS.PO_ITEM_2]: 19458,
        };

        return {
          pressure: pressureByPoItem[poItem] || "",
          minTime: 10,
          minHoldingTimeUnits: "sec",
          pressureUnit: "psi",
        };
      },
      witnesses: true
    }, {
      type: TYPES.ELECTROMAGNETIC_INDUCTION,
      properties : {},
      witnesses: true
    }, {
      type: TYPES.ULTRASONIC,
      properties : {},
      witnesses: true
    }, {
      type: TYPES.MAGNETIC,
      properties : {},
      witnesses: true
    }],
    labTests: [{
      type: TYPES.CHEMICAL,
      quantity: 1,
    }, {
      type: TYPES.CHEMICAL,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.TENSILE,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.IMPACT,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.HARDNESS,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.HYDROGEN_INDUCED_CRACKING,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.SULPHIDE_STRESS_CORROSIO_CRACKING,
      quantity: 1,
      witnesses: true
    }]
  },
  "QCP 5982 Rev. 4 (FDPT)": {
    companies: ["Tenaris", "Turcotte Group"],
    witnesses: ["Sporer Group"],
    labs: ["TenarisLab", "Tremblay Inc."],
    base: "QCP 5982 Rev. 4",
    poItems: [PO_ITEMS.PO_ITEM_1, PO_ITEMS.PO_ITEM_2, PO_ITEMS.PO_ITEM_3, PO_ITEMS.PO_ITEM_4],
    producerTests: [{
      type: TYPES.DIMENSIONAL,
      properties: {},
      witnesses: true
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Visual inspection",
            description: "Surface imperfections deeper than 5% nominal wal thickness or imperfections that encroach on the minimum permissible WT shall be classified as defects",
            result: "Satisfactory"
          }
        ]
      }
    }, {
      type: TYPES.HEATTREATMENT,
      properties: {
        method : "In-line",
        type : "Quench and Temper",
        tempUnits : "celsius",
        unitOfHoldingTime : "time",
        coolingMedium : "Water",
        secondTempUnits : "celsius",
        secondCoolingMedium : "Still air",
        strainHardening: false,
        temp: "870 - 960",
        secondTemp: "580 - 720",
      }
    }, {
      type: TYPES.HYDROSTATIC,
      getProperties: (poItem) => {
        const pressureByPoItem = {
          [PO_ITEMS.PO_ITEM_1]: 6201,
          [PO_ITEMS.PO_ITEM_2]: 8816,
          [PO_ITEMS.PO_ITEM_3]: 9573,
          [PO_ITEMS.PO_ITEM_4]: 20000,
        };

        return {
          pressure: pressureByPoItem[poItem] || "",
          minTime: 10,
          minHoldingTimeUnits: "sec",
          pressureUnit: "psi",
        };
      },
      witnesses: true
    }, {
      type: TYPES.ELECTROMAGNETIC_INDUCTION,
      properties : {},
      witnesses: true
    }, {
      type: TYPES.ULTRASONIC,
      properties : {},
      witnesses: true
    }, {
      type: TYPES.MAGNETIC,
      properties : {},
      witnesses: true
    }],
    labTests: [{
      type: TYPES.CHEMICAL,
      quantity: 1,
    }, {
      type: TYPES.CHEMICAL,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.TENSILE,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.IMPACT,
      quantity: 2,
      witnesses: true
    },  {
      type: TYPES.HARDNESS,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.CRACK_TIP_OPENING_DISPLACEMENT,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.DROP_WEIGHT_TEAR,
      quantity: 1,
      witnesses: true,
      poItems: [PO_ITEMS.PO_ITEM_1, PO_ITEMS.PO_ITEM_2]
    }, {
      type: TYPES.HYDROGEN_INDUCED_CRACKING,
      quantity: 1,
      witnesses: true,
      poItems: [PO_ITEMS.PO_ITEM_4]
    }, {
      type: TYPES.SULPHIDE_STRESS_CORROSIO_CRACKING,
      quantity: 1,
      witnesses: true,
      poItems: [PO_ITEMS.PO_ITEM_4]
    }]
  },
  "QCP 5982 Rev. 4": {
    companies: ["Tenaris", "Turcotte Group"],
    witnesses: ["Sporer Group"],
    labs: ["TenarisLab", "Tremblay Inc."],
    poItems: [PO_ITEMS.PO_ITEM_1, PO_ITEMS.PO_ITEM_2, PO_ITEMS.PO_ITEM_3, PO_ITEMS.PO_ITEM_4],
    producerTests: [{
      type: TYPES.DIMENSIONAL,
      properties: {},
      witnesses: true
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Visual inspection",
            description: "Surface imperfections deeper than 5% nominal wal thickness or imperfections that encroach on the minimum permissible WT shall be classified as defects",
            result: "Satisfactory"
          }
        ]
      }
    }, {
      type: TYPES.HEATTREATMENT,
      properties: {
        method : "In-line",
        type : "Quench and Temper",
        tempUnits : "celsius",
        unitOfHoldingTime : "time",
        coolingMedium : "Water",
        secondTempUnits : "celsius",
        secondCoolingMedium : "Still air",
        strainHardening: false,
        temp: "870 - 960",
        secondTemp: "580 - 720",
      }
    }, {
      type: TYPES.HYDROSTATIC,
      getProperties: (poItem) => {
        const pressureByPoItem = {
          [PO_ITEMS.PO_ITEM_1]: 6201,
          [PO_ITEMS.PO_ITEM_2]: 8816,
          [PO_ITEMS.PO_ITEM_3]: 9573,
          [PO_ITEMS.PO_ITEM_4]: 20000,
        };

        return {
          pressure: pressureByPoItem[poItem] || "",
          minTime: 10,
          minHoldingTimeUnits: "sec",
          pressureUnit: "psi",
        };
      },
      witnesses: true
    }, {
      type: TYPES.ELECTROMAGNETIC_INDUCTION,
      properties : {},
      witnesses: true
    }, {
      type: TYPES.ULTRASONIC,
      properties : {},
      witnesses: true
    }, {
      type: TYPES.MAGNETIC,
      properties : {},
      witnesses: true
    }],
    labTests: [{
      type: TYPES.DROP_WEIGHT_TEAR,
      quantity: 2,
    }, {
      type: TYPES.CHEMICAL,
      quantity: 1,
    }, {
      type: TYPES.CHEMICAL,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.TENSILE,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.IMPACT,
      quantity: 1,
      witnesses: true
    },  {
      type: TYPES.HARDNESS,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.DROP_WEIGHT_TEAR,
      quantity: 1,
      witnesses: true,
      poItems: [PO_ITEMS.PO_ITEM_1, PO_ITEMS.PO_ITEM_2]
    }, {
      type: TYPES.HYDROGEN_INDUCED_CRACKING,
      quantity: 1,
      witnesses: true,
      poItems: [PO_ITEMS.PO_ITEM_4]
    }, {
      type: TYPES.SULPHIDE_STRESS_CORROSIO_CRACKING,
      quantity: 1,
      witnesses: true,
      poItems: [PO_ITEMS.PO_ITEM_4]
    }]
  },
  "CPW-ITP-24-23-SAWL Rev. 3": {
    companies: ["Turcotte Group"],
    witnesses: ["Sporer Group"],
    poItems: [PO_ITEMS.PO_ITEM_10, PO_ITEMS.PO_ITEM_20],
    labs: ["Tremblay Inc."],
    producerTests: [{
      type: TYPES.HYDROSTATIC,
      getProperties: (poItem) => {
        const pressureByPoItem = {
          [PO_ITEMS.PO_ITEM_10]: 118,
          [PO_ITEMS.PO_ITEM_20]: 120,
        };

        return {
          pressure: pressureByPoItem[poItem] || "",
          minTime: 10,
          minHoldingTimeUnits: "sec",
          pressureUnit: "bar",
        };
      },
      witnesses: true
    },{
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Ultrasonic examination",
            notes: "Each pipe, full weld seam length controlled by AUT",
            result: "Satisfactory"
          },
          {
            test: "Ultrasonic examination",
            notes: "Weld seam of each pipe end, for at least 25mm in excess of the unexplored zone by the AUT examined by Manual UT",
            result: "Satisfactory"
          },
          {
            test: "Ultrasonic examination",
            notes: "Weld seam at untested pipe ends inspected by manual UT with the same sensitivity and acceptance criteria (max speed 150mm/sec) as AUT",
            result: "Satisfactory"
          },
          {
            test: "Ultrasonic examination",
            notes: "Ultrasonic inspection for laminar imperfections at pipe ends. 50mm wide zone at each pipe end examined by UT. Pipes are free of such laminar defects, i.e. any indications which result in signals equal to or exceeding the threshold",
            result: "Satisfactory"
          },
          {
            test: "Ultrasonic examination",
            notes: "Ultrasonic inspection for laminar imperfections at plate, according to API Table K.1. Min. 20% coverage for body, 100% coverage for longitudinal edge zones inspected 100 mm, Transverse edge zones inspected 100 mm after forming (as per a) above)",
            result: "Satisfactory"
          }
        ]
      },
      witnesses: true
    },{
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Radiographic examination",
            notes: "Radiographic inspection of weld at pipe ends, AUT indications & weld repairs on the weld seam at each pipe end (200mm min) and on any suspect area. Testing according to EN ISO 10893-7 or EN ISO 10893-6 with the application of computed radiography. SWSI X-ray radiation used",
            result: "Satisfactory"
          }
        ]
      },
      witnesses: true
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Magnetic particle",
            notes: "Magnetic particle inspection at each pipe end face/bevel using wet non-fluorescent white contrast magnetic particles",
            result: "Satisfactory"
          },
          {
            test: "Residual magnetism",
            notes: "4 readings shall be taken 90° apart, on each pipe end. Max individual 20 gauss, max average 15 gauss",
            result: "Satisfactory"
          }
        ]
      }
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Visual examination",
            notes: "Free of surface defects and geometric deviations in the contour of the pipe",
            result: "Satisfactory"
          },
          {
            test: "Visual examination",
            notes: "For the pipe material only, local dressing allowed to a WT not less than 0.3 mm of the nominal minimum WT",
            result: "Satisfactory"
          },
          {
            test: "Visual examination",
            notes: "Any ground area does not exceed 150x150mm in size. Each pipe does not contain more than 5 areas of this size",
            result: "Satisfactory"
          },
          {
            test: "Visual examination",
            notes: "Any 2 areas below the nominal minimum WT are separated by at least 300mm",
            result: "Satisfactory"
          },
          {
            test: "Visual examination",
            notes: "No repairs in the longitudinal seam weld within 200 mm of each pipe end",
            result: "Satisfactory"
          },
        ]
      },
    },{
      type: TYPES.DIMENSIONAL,
      properties: {},
      witnesses: true
    }],
    labTests: [{
      type: TYPES.CHEMICAL,
      quantity: 2,
    }, {
      type: TYPES.TENSILE,
      quantity: 1,
    }, {
      type: TYPES.IMPACT,
      quantity: 1,
    }, {
      type: TYPES.MICROGRAPHIC,
      quantity: 1,
    }, {
      type: TYPES.MACROGRAPHIC,
      quantity: 1,
    }, {
      type: TYPES.HARDNESS,
      quantity: 1,
    }, {
      type: TYPES.BEND,
      quantity: 1,
      witnesses: true,
    }],
  },
  "CPW-ITP-24-23-SAWL Rev. 3 MPQT": {
    companies: ["Turcotte Group"],
    witnesses: ["Sporer Group"],
    labs: ["Tremblay Inc."],
    poItems: [PO_ITEMS.PO_ITEM_10, PO_ITEMS.PO_ITEM_20],
    base: "CPW-ITP-24-23-SAWL Rev. 3",
    producerTests: [{
      type: TYPES.HYDROSTATIC,
      getProperties: (poItem) => {
        const pressureByPoItem = {
          [PO_ITEMS.PO_ITEM_10]: 118,
          [PO_ITEMS.PO_ITEM_20]: 120,
        };

        return {
          pressure: pressureByPoItem[poItem] || "",
          minTime: 10,
          minHoldingTimeUnits: "sec",
          pressureUnit: "bar",
        };
      },
      witnesses: true
    },{
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Ultrasonic examination",
            notes: "Each pipe, full weld seam length controlled by AUT",
            result: "Satisfactory"
          },
          {
            test: "Ultrasonic examination",
            notes: "Weld seam of each pipe end, for at least 25mm in excess of the unexplored zone by the AUT examined by Manual UT",
            result: "Satisfactory"
          },
          {
            test: "Ultrasonic examination",
            notes: "Weld seam at untested pipe ends inspected by manual UT with the same sensitivity and acceptance criteria (max speed 150mm/sec) as AUT",
            result: "Satisfactory"
          },
          {
            test: "Ultrasonic examination",
            notes: "Ultrasonic inspection for laminar imperfections at pipe ends. 50mm wide zone at each pipe end examined by UT. Pipes are free of such laminar defects, i.e. any indications which result in signals equal to or exceeding the threshold",
            result: "Satisfactory"
          },
          {
            test: "Ultrasonic examination",
            notes: "Ultrasonic inspection for laminar imperfections at plate, according to API Table K.1. Min. 20% coverage for body, 100% coverage for longitudinal edge zones inspected 100 mm, Transverse edge zones inspected 100 mm after forming (as per a) above)",
            result: "Satisfactory"
          }
        ]
      },
      witnesses: true
    },{
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Radiographic examination",
            notes: "Radiographic inspection of weld at pipe ends, AUT indications & weld repairs on the weld seam at each pipe end (200mm min) and on any suspect area. Testing according to EN ISO 10893-7 or EN ISO 10893-6 with the application of computed radiography. SWSI X-ray radiation used",
            result: "Satisfactory"
          }
        ]
      },
      witnesses: true
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Magnetic particle",
            notes: "Magnetic particle inspection at each pipe end face/bevel using wet non-fluorescent white contrast magnetic particles",
            result: "Satisfactory"
          },
          {
            test: "Residual magnetism",
            notes: "4 readings shall be taken 90° apart, on each pipe end. Max individual 20 gauss, max average 15 gauss",
            result: "Satisfactory"
          }
        ]
      }
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Visual examination",
            notes: "Free of surface defects and geometric deviations in the contour of the pipe",
            result: "Satisfactory"
          },
          {
            test: "Visual examination",
            notes: "For the pipe material only, local dressing allowed to a WT not less than 0.3 mm of the nominal minimum WT",
            result: "Satisfactory"
          },
          {
            test: "Visual examination",
            notes: "Any ground area does not exceed 150x150mm in size. Each pipe does not contain more than 5 areas of this size",
            result: "Satisfactory"
          },
          {
            test: "Visual examination",
            notes: "Any 2 areas below the nominal minimum WT are separated by at least 300mm",
            result: "Satisfactory"
          },
          {
            test: "Visual examination",
            notes: "No repairs in the longitudinal seam weld within 200 mm of each pipe end",
            result: "Satisfactory"
          },
        ]
      },
    },{
      type: TYPES.DIMENSIONAL,
      properties: {},
      witnesses: true
    }],
    labTests: [{
      type: TYPES.CHEMICAL,
      quantity: 2,
      witnesses: true,
    }, {
      type: TYPES.TENSILE,
      quantity: 2,
      witnesses: true,
    }, {
      type: TYPES.IMPACT,
      quantity: 2,
      witnesses: true,
    }, {
      type: TYPES.MICROGRAPHIC,
      quantity: 1,
      witnesses: true,
    }, {
      type: TYPES.MACROGRAPHIC,
      quantity: 1,
      witnesses: true,
    }, {
      type: TYPES.HARDNESS,
      quantity: 2,
      witnesses: true,
    }, {
      type: TYPES.BEND,
      quantity: 2,
      witnesses: true,
    }],
  },
  "QCP 5987 Rev. 2": {
    companies: ["Tenaris", "Turcotte Group"],
    witnesses: ["Sporer Group"],
    labs: ["TenarisLab", "Tremblay Inc."],
    producerTests: [{
      type: TYPES.HEATTREATMENT,
      properties: {
        method: "In-line",
        type: "Quench and Temper",
        temp: "870 - 960",
        tempUnits: "celsius",
        coolingMedium: "Water",
        secondTemp: "580 - 720",
        secondTempUnits: "celsius",
        secondCoolingMedium: "Still air",
      },
      witnesses: true
    }, {
      type: TYPES.HYDROSTATIC,
      getProperties: (poItem) => {
        const poItemNumber = getPoItemNumber(poItem);

        const pressureByPoItem = {
          [PO_ITEMS.PO_ITEM_1]: 19458,
          [PO_ITEMS.PO_ITEM_2]: 20000,
        };

        return {
          pressure: pressureByPoItem[Number(poItemNumber)] || "",
          minTime: 10,
          minHoldingTimeUnits: "sec",
          pressureUnit: "psi",
        };
      },
      witnesses: true
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Electromagnetic induction test (EMI)",
            description: "EMI equipment calibrated using N5 notches for longitudinal / transverse external defects. The References standard for calibration contains Notches on OD Longitudinal & Transverse",
            notes: "Notch Depth: 5% WT nom ( minimum 0.3 mm). Length <= 50 mm. Width <= 1.0 mm",
            result: "Satisfactory"
          }
        ]
      },
      witnesses: true,
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Ultrasonic examination",
            description: "Full length inspection for Longitudinal / Transverse (L/T) and External / Internal defects (OD/ID). Inspection performed also for control of laminations. Notches on OD & ID; Longitudinal & Transverse.",
            notes: "Notch Depth: 5% WT nom. Notch Length: <=  25.4 mm. Notch Width: <= 1.0 mm. Radially Drilled Hole for detection: 4.8mm for item 1 & 6.4 mm for item 2. For laminations, internal longitudinal groove 17 x 6 mm (depth 20-50% WT)",
            result: "Satisfactory"
          },
        ]
      },
      witnesses: true,
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Magnetic particle",
            description: "Both ends of each pipe examined by wet fluorescent particle method, with black light viewing for a distance of at least 250 mm from each end. Coverage include both axial and circumferential magnetization of the ID, OD, and end surfaces in the inspected length",
            result: "Satisfactory"
          },
        ]
      },
      witnesses: true,
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Visual examination",
            description: "Visual inspection conducted under at least 300 lux illumination. Pipe surface free from grease, loose mill scales, lacquer, antifreeze (from UT compliant) and other contaminants such as chlorides, that could interfere with coating applications",
            result: "Satisfactory"
          },
        ]
      }
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Residual magnetism",
            description: "Magnetic flux density less than 15 Gauss average with single value less than 20 Gauss",
            result: "Satisfactory"
          },
        ]
      }
    }, {
      type: TYPES.DIMENSIONAL,
      properties: {},
      witnesses: true,
    }],
    labTests: [{
      type: TYPES.CHEMICAL,
      subType: CHEMICAL_COMPOSITION_SUB_TYPES.HEAT,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.CHEMICAL,
      subType: CHEMICAL_COMPOSITION_SUB_TYPES.PRODUCT,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.TENSILE,
      quantity: 1,
      witnesses: true
    },  {
      type: TYPES.IMPACT,
      quantity: 2,
      witnesses: true
    }, {
      type: TYPES.HARDNESS,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.HYDROGEN_INDUCED_CRACKING,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.SULPHIDE_STRESS_CORROSIO_CRACKING,
      quantity: 1,
      witnesses: true,
      poItems: [PO_ITEMS.PO_ITEM_2],
    }],
  },
  "QCP 5987 Rev. 2 (FDPT)": {
    companies: ["Tenaris", "Turcotte Group"],
    witnesses: ["Sporer Group"],
    labs: ["TenarisLab", "Tremblay Inc."],
    base: "QCP 5987 Rev. 2",
    producerTests: [{
      type: TYPES.HEATTREATMENT,
      properties: {
        method: "In-line",
        type: "Quench and Temper",
        temp: "870 - 960",
        tempUnits: "celsius",
        coolingMedium: "Water",
        secondTemp: "580 - 720",
        secondTempUnits: "celsius",
        secondCoolingMedium: "Still air",
      },
      witnesses: true
    }, {
      type: TYPES.HYDROSTATIC,
      getProperties: (poItem) => {
        const poItemNumber = getPoItemNumber(poItem);

        const pressureByPoItem = {
          [PO_ITEMS.PO_ITEM_1]: 19458,
          [PO_ITEMS.PO_ITEM_2]: 20000,
        };

        return {
          pressure: pressureByPoItem[Number(poItemNumber)] || "",
          minTime: 10,
          minHoldingTimeUnits: "sec",
          pressureUnit: "psi",
        };
      },
      witnesses: true
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Electromagnetic induction test (EMI)",
            description: "EMI equipment calibrated using N5 notches for longitudinal / transverse external defects. The References standard for calibration contains Notches on OD Longitudinal & Transverse",
            notes: "Notch Depth: 5% WT nom ( minimum 0.3 mm). Length <= 50 mm. Width <= 1.0 mm",
            result: "Satisfactory"
          }
        ]
      },
      witnesses: true
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Ultrasonic examination",
            description: "Full length inspection for Longitudinal / Transverse (L/T) and External / Internal defects (OD/ID). Inspection performed also for control of laminations. Notches on OD & ID; Longitudinal & Transverse.",
            notes: "Notch Depth: 5% WT nom. Notch Length: <=  25.4 mm. Notch Width: <= 1.0 mm. Radially Drilled Hole for detection: 4.8mm for item 1 & 6.4 mm for item 2. For laminations, internal longitudinal groove 17 x 6 mm (depth 20-50% WT)",
            result: "Satisfactory"
          },
        ]
      },
      witnesses: true
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Magnetic particle",
            description: "Both ends of each pipe examined by wet fluorescent particle method, with black light viewing for a distance of at least 250 mm from each end. Coverage include both axial and circumferential magnetization of the ID, OD, and end surfaces in the inspected length",
            result: "Satisfactory"
          },
        ]
      },
      witnesses: true
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Visual examination",
            description: "Visual inspection conducted under at least 300 lux illumination. Pipe surface free from grease, loose mill scales, lacquer, antifreeze (from UT compliant) and other contaminants such as chlorides, that could interfere with coating applications",
            result: "Satisfactory"
          },
        ]
      }
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Residual magnetism",
            description: "Magnetic flux density less than 15 Gauss average with single value less than 20 Gauss",
            result: "Satisfactory"
          },
        ]
      }
    }, {
      type: TYPES.DIMENSIONAL,
      properties: {},
      witnesses: true,
    }],
    labTests: [{
      type: TYPES.CHEMICAL,
      subType: CHEMICAL_COMPOSITION_SUB_TYPES.HEAT,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.CHEMICAL,
      subType: CHEMICAL_COMPOSITION_SUB_TYPES.PRODUCT,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.TENSILE,
      quantity: 1,
      witnesses: true
    },  {
      type: TYPES.IMPACT,
      quantity: 2,
      witnesses: true
    }, {
      type: TYPES.HARDNESS,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.HYDROGEN_INDUCED_CRACKING,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.IMPACT,
      quantity: 1,
      witnesses: true,
      poItems: [PO_ITEMS.PO_ITEM_2]
    }, {
      type: TYPES.CRACK_TIP_OPENING_DISPLACEMENT,
      quantity: 1,
      witnesses: true,
    }, {
      type: TYPES.CRACK_TIP_OPENING_DISPLACEMENT,
      quantity: 1,
      witnesses: true,
      poItems: [PO_ITEMS.PO_ITEM_2]
    }, {
      type: TYPES.HYDROGEN_INDUCED_CRACKING,
      quantity: 1,
      witnesses: true,
    }, {
      type: TYPES.SULPHIDE_STRESS_CORROSIO_CRACKING,
      quantity: 1,
      witnesses: true,
      poItems: [PO_ITEMS.PO_ITEM_2],
    }],
  },
  "QCP 5983 Rev. 5": {
    companies: ["Tenaris", "Turcotte Group"],
    witnesses: ["Sporer Group"],
    labs: ["TenarisLab", "Tremblay Inc."],
    producerTests: [{
      type: TYPES.HEATTREATMENT,
      properties: {
        method: "In-line",
        type: "Quench and Temper",
        temp: "870 - 960",
        tempUnits: "celsius",
        coolingMedium: "Water",
        secondTemp: "580 - 720",
        secondTempUnits: "celsius",
        secondCoolingMedium: "Still air",
      },
      witnesses: true
    }, {
      type: TYPES.HYDROSTATIC,
      getProperties: (poItem) => {
        const poItemNumber = getPoItemNumber(poItem);

        const pressureByPoItem = {
          [PO_ITEMS.PO_ITEM_1]: 5802,
          [PO_ITEMS.PO_ITEM_2]: 5802,
          [PO_ITEMS.PO_ITEM_3]: 6527,
          [PO_ITEMS.PO_ITEM_4]: 6527,
        };

        return {
          pressure: pressureByPoItem[Number(poItemNumber)] || "",
          minTime: 10,
          minHoldingTimeUnits: "sec",
          pressureUnit: "psi",
        };
      },
      witnesses: true
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Electromagnetic induction test (EMI)",
            description: "EMI equipment calibrated using N5 notches for longitudinal / transverse external defects. The References standard for calibration contains Notches on OD Longitudinal & Transverse",
            notes: "Notch Depth: 5% WT nom ( minimum 0.3 mm). Length <= 50 mm. Width <= 1.0 mm",
            result: "Satisfactory"
          }
        ]
      },
      witnesses: true,
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Ultrasonic examination",
            description: "Full length inspection for Longitudinal / Transverse (L/T) and External / Internal defects (OD/ID). Inspection performed also for control of laminations. Notches on OD & ID; Longitudinal & Transverse",
            notes: "Notch Depth: 5% WT nom. Notch Length: <=  25.4 mm. Notch Width: <= 1.0 mm. Radially Drilled Hole for detection: 4.8mm for item 1 & 6.4 mm for item 2. For laminations, internal longitudinal groove 17 x 6 mm (depth 20-50% WT)",
            result: "Satisfactory"
          },
        ]
      },
      witnesses: true,
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Magnetic particle",
            description: "Pipe ends dry MPI inspected as per ISO 10893-5",
            result: "Satisfactory"
          },
        ]
      },
      witnesses: true,
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Visual examination",
            description: "Visual inspection conducted under at least 300 lux illumination. Pipe surface free from grease, loose mill scales, lacquer, antifreeze (from UT compliant) and other contaminants such as chlorides, that could interfere with coating applications",
            result: "Satisfactory"
          },
        ]
      },
      witnesses: true
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Residual magnetism",
            description: "Magnetic flux density less than 15 Gauss average with single value less than 20 Gauss",
            result: "Satisfactory"
          },
        ]
      }
    }, {
      type: TYPES.DIMENSIONAL,
      properties: {},
      witnesses: true,
    }],
    labTests: [{
      type: TYPES.CHEMICAL,
      subType: CHEMICAL_COMPOSITION_SUB_TYPES.HEAT,
      quantity: 1,
    }, {
      type: TYPES.CHEMICAL,
      subType: CHEMICAL_COMPOSITION_SUB_TYPES.PRODUCT,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.TENSILE,
      quantity: 1,
      witnesses: true
    },  {
      type: TYPES.IMPACT,
      quantity: 2,
      witnesses: true
    }, {
      type: TYPES.HARDNESS,
      quantity: 1,
      witnesses: true
    }],
  },
  "QCP 5983 Rev. 5 (FDPT)": {
    companies: ["Tenaris", "Turcotte Group"],
    witnesses: ["Sporer Group"],
    labs: ["TenarisLab", "Tremblay Inc."],
    base: "QCP 5983 Rev. 5",
    producerTests: [{
      type: TYPES.HEATTREATMENT,
      properties: {
        method: "In-line",
        type: "Quench and Temper",
        temp: "870 - 960",
        tempUnits: "celsius",
        coolingMedium: "Water",
        secondTemp: "580 - 720",
        secondTempUnits: "celsius",
        secondCoolingMedium: "Still air",
      },
      witnesses: true
    }, {
      type: TYPES.HYDROSTATIC,
      getProperties: (poItem) => {
        const poItemNumber = getPoItemNumber(poItem);

        const pressureByPoItem = {
          [PO_ITEMS.PO_ITEM_1]: 5802,
          [PO_ITEMS.PO_ITEM_2]: 5802,
          [PO_ITEMS.PO_ITEM_3]: 6527,
          [PO_ITEMS.PO_ITEM_4]: 6527,
        };

        return {
          pressure: pressureByPoItem[Number(poItemNumber)] || "",
          minTime: 10,
          minHoldingTimeUnits: "sec",
          pressureUnit: "psi",
        };
      },
      witnesses: true
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Electromagnetic induction test (EMI)",
            description: "EMI equipment calibrated using N5 notches for longitudinal / transverse external defects. The References standard for calibration contains Notches on OD Longitudinal & Transverse",
            notes: "Notch Depth: 5% WT nom ( minimum 0.3 mm). Length <= 50 mm. Width <= 1.0 mm",
            result: "Satisfactory"
          }
        ]
      },
      witnesses: true,
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Ultrasonic examination",
            description: "Full length inspection for Longitudinal / Transverse (L/T) and External / Internal defects (OD/ID). Inspection performed also for control of laminations. Notches on OD & ID; Longitudinal & Transverse",
            notes: "Notch Depth: 5% WT nom. Notch Length: <=  25.4 mm. Notch Width: <= 1.0 mm. Radially Drilled Hole for detection: 4.8mm for item 1 & 6.4 mm for item 2. For laminations, internal longitudinal groove 17 x 6 mm (depth 20-50% WT)",
            result: "Satisfactory"
          },
        ]
      },
      witnesses: true,
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Magnetic particle",
            description: "Pipe ends dry MPI inspected as per ISO 10893-5",
            result: "Satisfactory"
          },
        ]
      },
      witnesses: true,
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Visual examination",
            description: "Visual inspection conducted under at least 300 lux illumination. Pipe surface free from grease, loose mill scales, lacquer, antifreeze (from UT compliant) and other contaminants such as chlorides, that could interfere with coating applications",
            result: "Satisfactory"
          },
        ]
      },
      witnesses: true
    }, {
      type: TYPES.OTHER,
      properties: {
        result: TEST_RESULTS.ACCEPTABLE,
        elements: [
          {
            test: "Residual magnetism",
            description: "Magnetic flux density less than 15 Gauss average with single value less than 20 Gauss",
            result: "Satisfactory"
          },
        ]
      }
    }, {
      type: TYPES.DIMENSIONAL,
      properties: {},
      witnesses: true,
    }],
    labTests: [{
      type: TYPES.CHEMICAL,
      subType: CHEMICAL_COMPOSITION_SUB_TYPES.HEAT,
      quantity: 1,
    }, {
      type: TYPES.CHEMICAL,
      subType: CHEMICAL_COMPOSITION_SUB_TYPES.PRODUCT,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.TENSILE,
      quantity: 1,
      witnesses: true
    },  {
      type: TYPES.IMPACT,
      quantity: 2,
      witnesses: true
    }, {
      type: TYPES.HARDNESS,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.IMPACT,
      quantity: 1,
      witnesses: true
    }, {
      type: TYPES.CRACK_TIP_OPENING_DISPLACEMENT,
      quantity: 1,
      witnesses: true
    }],
  },
  "KAR85-AM-K": {
    companies: ["Tenaris", "Turcotte Group", "Kennametal Inc."],
    witnesses: [],
    labs: [],
    producerTests: [{
      type: TYPES.CHEMICAL,
      properties: {
        acceptance: "KAR85-AM-K",
        acceptanceItem: "1",
        analysis: "Product",
        testStandard: "ASTM E415-17",
        elements: [
          {
            id: 1,
            min: 0,
            bm: "Co+Ni+Cr+Mo"
          },
          {
            id: 2,
            min: 0,
            bm: "WC (balance)"
          }
        ]
      },
    }, {
      type: TYPES.TRANSVERSE_RUPTURE_STRENGTH,
      properties: {
        acceptance: "KAR85-AM-K",
        acceptanceItem: "1",
        analysis: "Product",
        testStandard: "ASTM E415-17",
        acceptanceCriteria: 300
      }
    }, {
      type: TYPES.EROSION_TEST,
      properties: {
        acceptance: "KAR85-AM-K",
        acceptanceItem: "1",
        testStandard: "ASTM G76 (2018)",
        testConditions: "Modified",
        thickness: 10,
        weight: 0.05,
        length: 10,
        surface: 2.02,
        preparation: "Polish",
        cleaning: "Solvent",
        initialSurfaceRoughness: 1,
        impingementAngle: 45,
        testDuration: 30
      }
    }, {
      type: TYPES.PALMQVIST_TEST,
      properties: {
        acceptance: "KAR85-AM-K",
        acceptanceItem: "1",
        testStandard: "ISO 28079:2009",
        temperature: 20,
        indentationLoad: 150,
        palmqvist: "W<sub>K</sub>",
        palmqvistUnit: "ksiin^(1/2)",
        stressIntensityFactorUnit: "ksiin^(1/2)"
      }
    }, {
      type: TYPES.DENSITY_TEST,
      properties: {
        acceptance: "KAR85-AM-K",
        acceptanceItem: "1",
        testStandard: "ASTM B311-22",
        weighingLiquid: "Water",
        waterTemperature: 20
      }
    }, {
      type: TYPES.POROSITY,
      properties: {
        acceptance: "KAR85-AM-K",
        acceptanceItem: "1",
        testStandard: "ISO 4505-1978",
        elements: [
          {
            etchingStandard: "ASTM E407-07 (2015e1)",
            specimenId: "6",
            magnification: "1500x",
            electrolytic: false,
            poresRange: "10 to 25",
            porosityLevel: "A00"
          },
          {
            etchingStandard: "ASTM E407-07 (2015e1)",
            specimenId: "6",
            magnification: "200x",
            electrolytic: false,
            poresRange: "10 to 25",
            porosityLevel: "A00"
          }
        ]
      }
    }, {
      type: TYPES.HARDNESS,
      properties: {
        acceptance: "KAR85-AM-K",
        acceptanceItem: "1",
        hardnessLocation: "Circular sample",
        hardnessTest: "HRA",
        elements: [
          {
            id: 0,
            position: 1,
            metal: "Q1",
            value: "",
            checked: false,
            active: false
          }
        ],
        elementsHistory: [
          {
            metal: "Q1",
            position: "1",
            differentMax: "",
            differentMin: ""
          }
        ],
        amount: 1
      }
    }, {
      type: TYPES.MICROGRAPHIC,
      properties: {
        norm: "ISO 4505-1978",
        acceptance: "KAR85-AM-K",
        acceptanceItem: "1",
        elements: [
          {
            etchingStandard: "ASTM E407-07 (2015e1)",
            magnification: "1500x",
            electrolytic: false
          },
          {
            etchingStandard: "ASTM E407-07 (2015e1)",
            magnification: "200x",
            electrolytic: false
          }
        ]
      }
    }],
    labTests: [],
  },
};