import React from "react";
import {useHistory} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {MenuItem} from "@mui/material";
import {withStyles} from "tss-react/mui";
import ActionCell from "@core/components/ActionCell";
import styles from "./styles";
import useStores from "../../../../useStores";

const Actions = observer(({classes, onDelete, campaign}) => {
  const {UserStore} = useStores();

  const history = useHistory();

  const companyId = UserStore.user.data.company._id;

  return (
    <ActionCell>
      <MenuItem onClick={() => {
        history.push(`/campaigns/${campaign.type.toLowerCase()}/${campaign._id}`);
      }}>
        View
      </MenuItem>
      {campaign.creator._id === companyId && (
        <MenuItem
          className={classes.deleteButton}
          onClick={onDelete}
        >
          Delete
        </MenuItem>
      )}
    </ActionCell>
  );
});

export default withStyles(Actions, styles);