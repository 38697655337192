import {DEFAULT_SCALE_BY_VIEW, DEFECT_POSITIONS, PAGE_PADDING, SPACING_BETWEEN_STALKS, STAGE_CONFIG, STATE} from "./constants";
import {sort} from "@core/helpers";
import {last, values} from "ramda";
import {ORDERS} from "@core/constants/sort";
import {MARGIN, STALK_NUMBER_WIDTH} from "./components/Stalk/constants";
import {getPipeDefects, getWeldDefects} from "../../../../helpers";

export const getStageWidth = (menuWidth, experimentalDesignEnabled, containerWidth) => {
  if(document.fullscreenElement) return window.innerWidth;

  if(experimentalDesignEnabled) return containerWidth;

  return window.innerWidth - menuWidth - 40;
};

const getCorrectedScale = (scale) => {
  if(scale < STAGE_CONFIG.MIN_SCALE) return STAGE_CONFIG.MIN_SCALE;

  if(scale > STAGE_CONFIG.MAX_SCALE) return STAGE_CONFIG.MAX_SCALE;

  return scale;
};

export const getScale = (oldScale, deltaY, scaleBy) => {
  const newScale = deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

  return getCorrectedScale(newScale);
};
export const fitToScreen = (layer, stage, height = true, width = true) => {
  const layerSize = layer.getClientRect({relativeTo: stage});
  const stageSize = stage.getSize();

  const scaleX = width ? ((stageSize.width - SPACING_BETWEEN_STALKS) / layerSize.width) : 1;
  const scaleY = height ? ((stageSize.height - SPACING_BETWEEN_STALKS) / layerSize.height) : 1;

  return getCorrectedScale(Math.min(scaleY, scaleX));
};

export const getSortedStalkEntities = (stalk) => {
  const sortedStalk = sort(ORDERS.ASC, "jointNumberA", stalk);

  const pipesByPipeNumber = sortedStalk.reduce((pipes, weld) => {
    pipes[weld.jointNumberA] = {
      weldNumber: weld.weldNumber,
      pipeNumber: weld.jointNumberA,
      position: weld.position,
      _id: weld.pipeA,
    };

    pipes[weld.jointNumberB] = {
      weldNumber: weld.weldNumber,
      pipeNumber: weld.jointNumberB,
      position: weld.position + 1,
      _id: weld.pipeB,
    };

    return pipes;
  }, {});

  const pipes = values(pipesByPipeNumber);

  return {
    pipes,
    welds: sortedStalk,
  };
};

export const getTextScale = (textScaleBy, scale, maxScale, minScale) => {
  const textScale = textScaleBy / scale;

  return Math.max(Math.min(textScale, maxScale), minScale);
};

export const getPipeDefectPositions = (defectReports, welds) => {
  const [prevWeld, nextWeld] = welds;

  const defects = getPipeDefects(defectReports, welds);

  const defectPositions = defects.reduce((positions, data) => {
    const resultValues = values(data);

    if(resultValues.includes("Trail") && data["Weld Number"] === prevWeld) positions.push(DEFECT_POSITIONS.START);

    if(resultValues.includes("Lead") && data["Weld Number"] === nextWeld) positions.push(DEFECT_POSITIONS.END);

    return positions;
  }, []);

  return defectPositions;
};

export const getWeldDefectPositions = (defectReports, welds) => {
  const defects = getWeldDefects(defectReports, welds);

  const defectPositions = defects.reduce((positions, data) => {
    const resultValues = values(data);

    if(resultValues.includes("Trail")) positions.push(DEFECT_POSITIONS.END);

    if(resultValues.includes("Lead")) positions.push(DEFECT_POSITIONS.START);

    if(resultValues.includes("Center of FJ")) positions.push(DEFECT_POSITIONS.MIDDLE);

    return positions;
  }, []);

  return defectPositions;
};

export const getNumberOfStalkItemsToFitIOneRow = (view, settings, stageWidth, pipeConfig, weldConfig) => {
  let stalkItemBase = pipeConfig.WIDTH + MARGIN;

  if(settings.welds) stalkItemBase += weldConfig.WIDTH + MARGIN;
  
  return  Math.ceil((stageWidth - STALK_NUMBER_WIDTH - PAGE_PADDING) / DEFAULT_SCALE_BY_VIEW[view] / stalkItemBase);
};

export const getNumberOfStalkItemsInRow = (stalks, settings, numberOfStalkItemsToFitIOneRow) => {
  const longestStalkLength = Math.max(...stalks.map((stalk) => last(stalk).position + 1));
  
  if(!settings.splitStalk) return longestStalkLength;

  return numberOfStalkItemsToFitIOneRow;
};

const getAsFabricatedPipeLength = (weld, pipeLengthByWeldId) => {
  return pipeLengthByWeldId[weld._id];
};

const getAsBuiltPipeLength = (weld, pipeLengthByWeldId, stalksNumbers, tieInWeldsByStalkNumber) => {
  const pipeLength = getAsFabricatedPipeLength(weld, pipeLengthByWeldId);
  
  const stalkNumber = weld.stalkNumber;
  const prevStalkNumber = stalksNumbers[stalksNumbers.indexOf(stalkNumber) - 1];

  const prevTieInWeld = tieInWeldsByStalkNumber[prevStalkNumber];
  const prevTieInWeldLength = prevTieInWeld ? getAsFabricatedPipeLength(prevTieInWeld, pipeLengthByWeldId) : 0;

  return (pipeLength + prevTieInWeldLength).toFixed(3);
};

export const getPipeLength = (state, weld, pipeLengthByWeldId, stalksNumbers, tieInWeldsByStalkNumber) => {
  const stalkNumber = weld.stalkNumber;
  const tieInWeld = tieInWeldsByStalkNumber[stalkNumber] || {};
  const isTieInWeld = tieInWeld.weldNumber === weld.weldNumber;

  if(state === STATE.AS_BUILT && !isTieInWeld) {
    return getAsBuiltPipeLength(weld, pipeLengthByWeldId, stalksNumbers, tieInWeldsByStalkNumber);
  }

  return getAsFabricatedPipeLength(weld, pipeLengthByWeldId);
};