import {getColumns} from "@core/columns/transfer";
import Table from "@core/components/Table";
import {INTERFACE_TYPE} from "@core/constants/transfer";
import React from "react";

const SelectedOrders = ({selectedTransfers, selectedOrdersIds, setSelectedOrdersIds, search}) => {
  const columns = getColumns(INTERFACE_TYPE.RECEIVER, false);
  
  const filteredTransfers = selectedTransfers.filter((transfer) => {
    const orderNumber = transfer.orderNumber.toLowerCase();
    const senderName = transfer.sender?.name?.toLowerCase() || "";
    const searchLower = search.toLowerCase();

    return !search || orderNumber.includes(searchLower) || senderName.includes(searchLower);
  });

  return (
    <Table
      allowRowSelection
      setSelectedItems={setSelectedOrdersIds}
      selectedItems={selectedOrdersIds}
      items={filteredTransfers}
      columns={columns}
      isLoaded
      total={filteredTransfers.length}
      noDataText="No orders."
    />
  );
};

export default SelectedOrders;