import TableNavigation from "@core/components/TableNavigation";
import InputAdornment from "@mui/material/InputAdornment";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import {values} from "ramda";
import React, {useCallback, useEffect, useState} from "react";
import {observer} from "mobx-react";
import {Box, Button, Grid, CircularProgress} from "@mui/material";
import {Input} from "@core/components/Form";
import DrawerHeader from "@core/components/DrawerHeader";
import ResizableDrawer from "@core/components/ResizableDrawer";
import AvailableOrders from "./components/AvailableOrders";
import SelectedOrders from "./components/SelectedOrders";
import useStores from "../../../../useStores";
import {useParams} from "react-router-dom";

const TABS = {
  AVAILABLE_ORDERS: "Available orders",
  SELECTED_ORDERS: "Selected orders",
};

const COMPONENT_BY_TAB = {
  [TABS.SELECTED_ORDERS]: SelectedOrders,
  [TABS.AVAILABLE_ORDERS]: AvailableOrders
};

const AddOrder = ({selectedTransfers}) => {
  const {ProjectStore, TransferStore, NotificationStore} = useStores();

  const [addOrderOpen, setAddOrderOpen] = useState(false);
  const [selectedOrdersIds, setSelectedOrdersIds] = useState([]);
  const [tab, setTab] = useState(TABS.AVAILABLE_ORDERS);
  const [search, setSearch] = useState("");

  const {id: projectId} = useParams();
  
  const defaultSelectedOrdersIds =  selectedTransfers.map((transfer) => transfer._id);

  useEffect(() => {
    setSelectedOrdersIds(defaultSelectedOrdersIds);
  }, [addOrderOpen]);

  const {status} = TransferStore.transfers;

  const searchDebounced = AwesomeDebouncePromise((search) => {
    TransferStore.transfers.load({search, offset: 0});
  }, 750);

  const handleSearch = useCallback(async (event) => {
    const search = event.target.value;

    setSearch(search);

    await searchDebounced(search);
  }, [tab]);

  const onClose = useCallback(() => {
    setAddOrderOpen(false);
  }, []);

  const onSave = async () => {
    const idsToLink = selectedOrdersIds.filter((id) => !defaultSelectedOrdersIds.includes(id));

    if(idsToLink.length) {
      await ProjectStore.linkOrders(projectId, idsToLink);
    }

    const idsToUnlink = defaultSelectedOrdersIds.filter((id) => !selectedOrdersIds.includes(id));

    if(idsToUnlink.length) {
      await ProjectStore.unlinkOrders(projectId, idsToUnlink);
    }

    onClose();
    NotificationStore.showSuccess("Successfully added to project!");
  };

  const Component = COMPONENT_BY_TAB[tab];

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setAddOrderOpen(true)}
      >
        Add order
      </Button>
      <ResizableDrawer
        open={addOrderOpen}
        close={onClose}
        SlideProps={{
          onExited: () => setTab(TABS.AVAILABLE_ORDERS),
        }}
      >
        <DrawerHeader
          close={onClose}
          text="Add Order(s)"
        />
        <Box
          sx={{padding: 2}}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Input
                name="search"
                endAdornment={
                  <InputAdornment position="start">
                    {status.loading && <CircularProgress size={20} />}
                  </InputAdornment>
                }
                placeholder="Search order(s)"
                onChange={handleSearch}
              />
            </Grid>
            <Grid item xs={12}>
              <TableNavigation
                views={values(TABS)}
                currentView={tab}
                onClick={setTab}
              />
              <Component
                selectedOrdersIds={selectedOrdersIds}
                setSelectedOrdersIds={setSelectedOrdersIds}
                selectedTransfers={selectedTransfers}
                search={search}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  onClick={onClose}
                  color="secondary"
                  variant="contained"
                >
                Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={onSave}
                >
                Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </ResizableDrawer>
    </>
  );
};

export default observer(AddOrder);