import React from "react";
import {Chip} from "@mui/material";
import classNames from "classnames";
import {withStyles} from "tss-react/mui";
import {isAcceptable} from "@core/helpers";
import {PARTIALLY_UNACCEPTABLE_TEST_RESULTS} from "@core/constants/testResults";
import styles from "./styles";

const TestResultTag = ({classes, result}) => {
  const acceptable = isAcceptable(result);

  if(!result) return null;

  return (
    <Chip
      size="small"
      label={result}
      className={classNames(classes.tag, {
        [classes.green]: acceptable,
        [classes.red]: !acceptable,
        [classes.warning]: result === PARTIALLY_UNACCEPTABLE_TEST_RESULTS.PARTIALLY_UNACCEPTABLE
      })}
    />
  );
};

export default withStyles(TestResultTag, styles);