import React, {useEffect, useState} from "react";
import {Box, ListItem, useTheme} from "@mui/material";
import {withStyles} from "tss-react/mui";
import DatePicker from "react-multi-date-picker";
import {DATE_FORMAT} from "@core/constants/dateFormats";
import {FILTERS} from "@core/constants/filters";
import styles from "./styles";
import FilterDropdownItemText from "@core/components/FilterDropdownItemText";
import IconistsIcon from "@core/components/IconistsIcon";
import {ICONISTS_ICONS} from "@core/constants/iconistsIcons";
import {generateID} from "@core/helpers";

const DateCreatedFilter = ({classes, filters, setFilters}) => {
  const [dateA, dateB] = filters[FILTERS.DATE_CREATED] ? filters[FILTERS.DATE_CREATED] : [];
  const [portalTarget, setPortalTarget] = useState();
  const isFilterActive = !!filters[FILTERS.DATE_CREATED]?.length;
  const theme = useTheme();

  useEffect(() => {
    const portalDiv = document.createElement("div");

    /**
     * This ID is optional and has been added
     * to better recognize it in the DOM tree.
     */
    portalDiv.id = `rmdp-portal-div-${generateID()}`;

    portalDiv.style.position = "relative";
    portalDiv.style.zIndex = theme.zIndex.modal + 5;

    document.body.appendChild(portalDiv);

    setPortalTarget(portalDiv);

    return () => document.body.removeChild(portalDiv);
  }, []);

  return (
    <ListItem>
      <FilterDropdownItemText
        text={FILTERS.DATE_CREATED}
        sxPrimary={
          isFilterActive ? {color: (theme) => theme.palette.primary.main} : {}
        }
      />
      <DatePicker
        range
        portal
        portalTarget={portalTarget}
        render={
          (value, openCalendar) => <Box onClick={() => openCalendar()} sx={{cursor: "pointer"}}>
            <IconistsIcon icon={ICONISTS_ICONS.calendar} width="18px" color={isFilterActive ? "primary.main" : undefined}/>
          </Box>
        }
        arrow={false}
        className={classes.datePicker}
        inputClass={classes.pickerInput}
        onChange={([dateA, dateB]) => {
          const formatedDateA = dateA ? dateA.format() : null;
          const formatedDateB = dateB ? dateB.format() : null;
          setFilters({...filters, [FILTERS.DATE_CREATED]: [formatedDateA, formatedDateB]});
        }}
        value={[dateA, dateB]}
        format={DATE_FORMAT}
      />
    </ListItem>
  );
};

export default withStyles(DateCreatedFilter, styles);