import Field from "@core/components/CollapsibleTable/components/Field";
import RemoveButtonCell from "@core/components/DynamicTestResultsTable/RemoveButtonCell";
import TestResultTableCell from "@core/components/TestResultTableCell";
import {getAverageErosion, getSteadyStateErosionRate} from "../../services";
import React from "react";
import {FieldArray, useFormikContext} from "formik";
import {Table, TableBody, TableCell, TableHead, TableRow, Box} from "@mui/material";
import AddRowButton from "@core/components/DynamicTestResultsTable/AddRowButton";
import {TEST_RESULT} from "../../constants";

const AsPerStandardTestResults = ({editable}) => {

  const {values, setFieldValue} = useFormikContext();
  
  const changeSteadyStateErosionRate = (item, index) => {
    const steadyStateErosionRate = getSteadyStateErosionRate(values.testResults);
    setFieldValue("steadyStateErosionRate", steadyStateErosionRate);
    setFieldValue(`testResults.${index}.averageErosion`, getAverageErosion(steadyStateErosionRate, item.density, values.particleFlow));
  };

  return (
    <FieldArray name="testResults">
      {({push, remove}) => {

        return (
          <>
            <Table className="styled-table">
              <TableHead>
                <TableRow>
                  <TableCell>Specimen ID</TableCell>
                  {values.hardnessMeasurementUnit && <TableCell>Hardness</TableCell>}
                  <TableCell>Elapsed Time</TableCell>
                  <TableCell>Mass Loss</TableCell>
                  <TableCell>Density</TableCell>
                  <TableCell>Steady State Erosion Rate</TableCell>
                  <TableCell>
                    Average Erosion
                    {values.acceptanceCriteria && (
                      <span>&nbsp;&#8804;&nbsp;{values.acceptanceCriteria}&nbsp;mm^3/kg</span>
                    )}
                  </TableCell>
                  {editable && <TableCell>Action</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {values.testResults.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Field
                        required
                        name={`testResults.${index}.specimen`}
                        errorMessage={null}
                        editable={editable}
                      />
                    </TableCell>
                    {values.hardnessMeasurementUnit && (
                      <TableCell>
                        <Field
                          type="number"
                          name={`testResults.${index}.hardness`}
                          required
                          errorMessage={null}
                          endAdornment={values.hardnessMeasurementUnit}
                          inputProps={{min: 0}}
                          editable={editable}
                        />
                      </TableCell>
                    )}
                    <TableCell>
                      <Field
                        type="number"
                        required
                        name={`testResults.${index}.elapsedTime`}
                        errorMessage={null}
                        endAdornment="s"
                        inputProps={{min: 0}}
                        editable={editable}
                        onChange={() => changeSteadyStateErosionRate(item, index)}
                      />
                    </TableCell>
                    <TableCell>
                      <Field
                        type="number"
                        required
                        name={`testResults.${index}.massLoss`}
                        errorMessage={null}
                        endAdornment="mg"
                        inputProps={{min: 0}}
                        editable={editable}
                        onChange={() => changeSteadyStateErosionRate(item, index)}
                      />
                    </TableCell>
                    <TableCell>
                      <Field
                        type="number"
                        required
                        name={`testResults.${index}.density`}
                        errorMessage={null}
                        endAdornment="g/cm^3"
                        inputProps={{min: 0}}
                        editable={editable}
                        onChange={(value) => setFieldValue(`testResults.${index}.averageErosion`, getAverageErosion(values.steadyStateErosionRate, value, values.particleFlow))}
                      />
                    </TableCell>
                    {!index && (
                      <TableCell rowSpan={values.testResults.length}>
                        <Field
                          disabled
                          type="number"
                          required
                          name="steadyStateErosionRate"
                          errorMessage={null}
                          endAdornment="mg/s"
                        />
                      </TableCell>
                    )}
                    <TableCell>
                      <Box sx={{display: "flex"}}>
                        <TestResultTableCell
                          value={item.averageErosion}
                          max={values.acceptanceCriteria}
                        >
                          <Field
                            disabled
                            type="number"
                            required
                            name={`testResults.${index}.averageErosion`}
                            errorMessage={null}
                          />
                        </TestResultTableCell>
                        &nbsp;
                        mm^3/g
                      </Box>
                    </TableCell>
                    {editable && (
                      <RemoveButtonCell
                        disabled={values.testResults?.length === 1}
                        onClick={() => remove(index)}
                      />
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            {editable && <AddRowButton push={push} rowCellsValue={TEST_RESULT}/>}
          </>
        );
      }}
    </FieldArray>
  );
};

export default AsPerStandardTestResults;