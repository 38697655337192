import React from "react";
import {
  MdAccountBox,
  MdDashboard,
  MdDescription,
  MdExitToApp,
  MdList,
  MdNoteAdd,
  MdRemoveRedEye,
  MdSettings,
  MdPlaylistAddCheck,
  MdSwapHoriz,
  MdLocalShipping,
  MdBuild,
  MdArchive,
  MdFolderOpen,
  MdAccountBalance,
  MdPeople,
} from "react-icons/md/";
import {LuListChecks} from "react-icons/lu";
import {TbReportAnalytics} from "react-icons/tb";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import {FaClipboard, FaProjectDiagram} from "react-icons/fa/";
import {BiPackage} from "react-icons/bi";
import {SiConvertio, SiCampaignmonitor} from "react-icons/si";
import {AiOutlineFileProtect} from "react-icons/ai";

import {FEATURES} from "@core/constants/features";
import modules from "../../constants/modules";
import roles from "../../constants/roles";

export default [
  {
    linkTo: "/",
    title: "Dashboard",
    icon: <MdDashboard />,
    children: [],
    min_user_level: 5,
    belongsToModules: ["All"],
    belongsToRole: ["All"]
  },
  {
    linkTo: "/campaigns/",
    title: "Campaigns",
    icon: <SiCampaignmonitor />,
    belongsToModules: [modules.PRODUCER, modules.END_OWNER, modules.WITNESS],
    belongsToRole: ["All"],
    requiredFeatures: [FEATURES.CAMPAIGN],
    children: [
      {
        linkTo: "/campaigns/",
        title: "Overview",
        exact: true,
        icon: <MdList />,
        belongsToModules: [modules.PRODUCER, modules.END_OWNER, modules.WITNESS],
        belongsToRole: ["All"]
      },
    ]
  },
  {
    linkTo: "/projects/",
    title: "Projects",
    icon: <FaProjectDiagram />,
    min_user_level: 8,
    belongsToModules: [modules.END_OWNER, modules.PRODUCER, modules.BASIC],
    belongsToRole: ["All"],
    children: [],
  },
  {
    linkTo: "/orders/",
    title: "Orders to Supplier",
    icon: <MdLocalShipping />,
    min_user_level: 8,
    belongsToModules: [modules.END_OWNER, modules.PRODUCER, modules.BASIC],
    belongsToRole: ["All"],
    children: [
      {
        linkTo: "/orders/",
        title: "Overview",
        exact: true,
        icon: <MdList />,
        belongsToModules: [modules.END_OWNER, modules.PRODUCER, modules.BASIC],
        belongsToRole: ["All"]
      },
    ]
  },
  {
    linkTo: "/certificates/",
    title: "Certificates",
    icon: <MdDescription />,
    min_user_level: 7,
    belongsToModules: [modules.PRODUCER, modules.BASIC, modules.END_OWNER],
    belongsToRole: ["All"],
    children: [
      {
        linkTo: "/certificates/",
        title: "Overview",
        exact: true,
        icon: <MdList />,
        belongsToModules: [modules.PRODUCER, modules.BASIC, modules.END_OWNER],
        belongsToRole: ["All"]
      },
      {
        linkTo: "/certificates/archive/",
        title: "Archive",
        exact: true,
        icon: <MdArchive />,
        belongsToModules: [modules.PRODUCER, modules.BASIC, modules.END_OWNER],
        belongsToRole: ["All"]
      },
      {
        linkTo: "/certificates/create/",
        title: "Create New",
        exact: true,
        icon: <MdNoteAdd />,
        belongsToModules: [modules.PRODUCER, modules.END_OWNER],
        belongsToRole: ["All"]
      },
      {
        linkTo: "/certificates/testParsing",
        title: "Parse file",
        exact: true,
        icon: <SiConvertio />,
        belongsToModules: [modules.PRODUCER, modules.BASIC, modules.END_OWNER],
        belongsToRole: ["All"],
        requiredFeatures: [FEATURES.PARSE_CSV, FEATURES.PARSE_XLS],
      },
    ]
  },
  {
    linkTo: "/tests",
    title: "Tests",
    icon: <FaClipboard />,
    min_user_level: 8,
    belongsToModules: [modules.LAB_INTERNAL, modules.PRODUCER, modules.END_OWNER],
    belongsToRole: ["All"],
    children: [
      {
        linkTo: "/tests",
        title: "Overview",
        exact: true,
        icon: <MdList />,
        belongsToModules: [modules.LAB_INTERNAL, modules.PRODUCER, modules.END_OWNER],
        belongsToRole: ["All"]
      },
      {
        linkTo: "/tests/multiSigning",
        title: "Multiple Test Signing",
        exact: true,
        icon: <MdPlaylistAddCheck />,
        belongsToModules: [modules.LAB_INTERNAL, modules.PRODUCER, modules.END_OWNER],
        belongsToRole: ["All"]
      },
      {
        linkTo: "/tests/testParsing",
        title: "Parse file",
        exact: true,
        icon: <SiConvertio />,
        belongsToModules: [modules.LAB_INTERNAL],
        belongsToRole: ["All"],
        requiredFeatures: [FEATURES.PARSE_CSV, FEATURES.PARSE_XLS],
      },
    ]
  },
  {
    linkTo: "/specifications/",
    title: "Specifications",
    icon: <MdPlaylistAddCheck />,
    belongsToModules: [modules.PRODUCER, modules.BASIC, modules.END_OWNER],
    belongsToRole: [roles.ADMIN],
    min_user_level: 7,
    children: [
      {
        linkTo: "/specifications/",
        title: "Overview",
        icon: <MdPlaylistAddCheck />,
        belongsToModules: [modules.PRODUCER, modules.BASIC, modules.END_OWNER],
        belongsToRole: [roles.ADMIN],
      },
      {
        linkTo: "/qcps",
        title: "QCPs/ITPs",
        icon: <LuListChecks />,
        belongsToModules: [modules.PRODUCER, modules.BASIC, modules.END_OWNER],
        belongsToRole: [roles.ADMIN],
      }
    ]
  },
  {
    linkTo: "/transfers/",
    title: "Customer Orders",
    icon: <MdSwapHoriz />,
    min_user_level: 8,
    belongsToModules: [modules.PRODUCER, modules.BASIC],
    belongsToRole: ["All"],
    children: []
  },
  {
    linkTo: "/witness/",
    title: "Reports",
    icon: <MdRemoveRedEye />,
    min_user_level: 8,
    belongsToModules: [modules.WITNESS],
    belongsToRole: ["All"],
    children: [
      {
        linkTo: "/witness/",
        title: "Overview",
        exact: true,
        icon: <MdList />,
        belongsToModules: [modules.WITNESS],
        belongsToRole: ["All"]
      },
      {
        linkTo: "/witness/multiSigning",
        title: "Multiple Test Signing",
        exact: true,
        icon: <MdPlaylistAddCheck />,
        belongsToModules: [modules.WITNESS],
        belongsToRole: ["All"]
      }
    ]
  },
  {
    linkTo: "/account/",
    title: "Account",
    icon: <MdAccountBox />,
    min_user_level: 5,
    belongsToModules: ["All"],
    belongsToRole: ["All"],
    children: []
  },
  {
    linkTo: "/instruments/",
    title: "Instruments",
    icon: <MdBuild />,
    min_user_level: 8,
    belongsToModules: [modules.LAB_INTERNAL],
    belongsToRole: ["All"],
    children: []
  },
  {
    linkTo: "/admin/",
    title: "Admin",
    icon: <MdSettings />,
    min_user_level: 9,
    belongsToModules: [modules.PRODUCER, modules.BASIC, modules.LAB_INTERNAL, modules.WITNESS, modules.END_OWNER],
    belongsToRole: ["All"],
    children: [
      {
        linkTo: "/admin/",
        title: "Overview",
        exact: true,
        icon: <MdList />,
        belongsToModules: [modules.PRODUCER, modules.BASIC, modules.LAB_INTERNAL, modules.WITNESS, modules.END_OWNER],
        belongsToRole: ["All"]
      },
      {
        linkTo: "/admin/users/",
        title: "Users",
        exact: true,
        icon: <MdPeople />,
        belongsToModules: [modules.PRODUCER, modules.BASIC, modules.LAB_INTERNAL, modules.WITNESS, modules.END_OWNER],
        belongsToRole: ["All"]
      },
      {
        linkTo: "/admin/external-users",
        title: "External users",
        exact: true,
        icon: <SupervisedUserCircleIcon />,
        belongsToModules: [modules.PRODUCER, modules.BASIC, modules.LAB_INTERNAL, modules.WITNESS, modules.END_OWNER],
        belongsToRole: [roles.ADMIN]
      }]
  },
  {
    linkTo: "/preferences",
    title: "Preferences",
    icon: <SettingsApplicationsIcon />,
    belongsToModules: [modules.PRODUCER, modules.BASIC, modules.END_OWNER],
    belongsToRole: [roles.ADMIN],
    min_user_level: 7,
    children: [
      {
        linkTo: "/preferences",
        title: "Product Types",
        exact: true,
        icon: <BiPackage />,
        belongsToModules: [modules.PRODUCER, modules.BASIC, modules.END_OWNER],
        belongsToRole: ["All"]
      },
      {
        linkTo: "/preferences/statements",
        title: "Statements",
        exact: true,
        icon: <AssignmentIcon />,
        belongsToModules: [modules.PRODUCER, modules.BASIC, modules.END_OWNER],
        belongsToRole: ["All"]
      },
      {
        linkTo: "/preferences/conditions",
        title: "Conditions",
        exact: true,
        icon: <AiOutlineFileProtect />,
        belongsToModules: [modules.PRODUCER, modules.BASIC, modules.END_OWNER],
        belongsToRole: ["All"]
      }
    ]
  },
  {
    linkTo: "/metrics/",
    title: "Metrics",
    icon: <TbReportAnalytics />,
    min_user_level: 9,
    belongsToModules: [modules.SUPER_ADMIN],
    belongsToRole: ["All"],
    children: [],
  },
  {
    linkTo: "/admin/",
    title: "Admin",
    icon: <MdSettings />,
    min_user_level: 9,
    belongsToModules: [modules.SUPER_ADMIN],
    belongsToRole: ["All"],
    children: [
      {
        linkTo: "/admin/logs/",
        title: "Logs",
        exact: true,
        icon: <MdFolderOpen />,
        belongsToModules: [modules.SUPER_ADMIN],
        belongsToRole: ["All"]
      }
    ]
  },
  {
    linkTo: "/companies/",
    title: "Companies",
    icon: <MdAccountBalance />,
    min_user_level: 9,
    belongsToModules: [modules.SUPER_ADMIN],
    belongsToRole: ["All"],
    children: []
  },
  {
    linkTo: "/users/",
    title: "Users",
    icon: <MdList />,
    min_user_level: 9,
    belongsToModules: [modules.SUPER_ADMIN],
    belongsToRole: ["All"],
    children: []
  },
  {
    linkTo: "/logout/",
    title: "Logout",
    icon: <MdExitToApp />,
    min_user_level: 5,
    belongsToModules: ["All"],
    belongsToRole: ["All"],
    children: []
  }
];
