import {INTERNAL_LAB} from "@core/components/AddTestsOrDeclarationForm/constants";
import React, {useEffect, useState} from "react";
import {any, omit, propEq, isEmpty, intersection, uniqBy, prop} from "ramda";
import {observer} from "mobx-react-lite";
import {useFormikContext} from "formik";
import {
  FormControlLabel,
  Checkbox,
  Grid,
  MenuItem,
  Typography,
  TextField as DateField,
  InputLabel,
  Box,
  Divider,
  Tooltip
} from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import {withStyles} from "tss-react/mui";
import {MultipleSelect} from "@core/components/Form";
import SelectField from "@core/components/FormikSelect";
import TextField from "@core/components/FormikTextField";
import testConfig from "@core/configs/test";
import modules from "@core/constants/modules";
import {STATUSES} from "@core/constants/test";
import {TAGS} from "@core/constants/tags";
import ActionTypeSelect from "../ActionTypeSelect";
import AddDataModal from "../AddDataModal";
import useStores from "../../../../../useStores";
import styles from "./styles";

const ACTIONS = {
  addData: "addData",
  inviteLab: "inviteLab"
};

const Test = ({classes, testsWithRequiredWitnesses, createTestWithData, test, onClose, index, certificates, ...props}) => {
  const defaultAction = testConfig[test.type].responsible.includes(modules.LAB_INTERNAL) ? ACTIONS.inviteLab : null;
  const [action, setAction] = useState(defaultAction);
  const [types, setTypes] = useState(props.types);
  const {validateForm, setFieldValue, values} = useFormikContext();

  const amount = types[test.type];

  const {CompanyStore, QcpStore} = useStores();

  const laboratories = CompanyStore.meta.filter((company) => {
    return any(propEq(modules.LAB_INTERNAL, "name"), company.modules);
  });

  const witnessCompanies = CompanyStore.meta.filter((company) => {
    return any(propEq(modules.WITNESS, "name"), company.modules);
  });

  const qcpsWithSelectedTest = QcpStore.qcps.data.filter((qcp) => qcp.tests.some((qcpTest) => qcpTest.type === test.type));
  const qcpNames = uniqBy(prop("name"), qcpsWithSelectedTest);
  const qcpItems = qcpsWithSelectedTest.filter((qcp) => qcp.name === test.properties.acceptance);

  useEffect(() => {
    const acceptanceItem = test.properties.acceptance ? qcpItems[0]._id : "";
    setFieldValue(`tests.${index}.properties.acceptanceItem`, acceptanceItem);
  }, [test.properties.acceptance]);

  const onAddData = async (data, {witnesses}) => {
    const testToCreate = {
      ...omit(["assignee"], test),
      properties: omit(["items", "inspectionDate", "inspectorJobNumber"], data),
      status: STATUSES.FILLED,
      witnesses: witnesses.map((witness) => ({company: witness._id, status: 0})),
      inspectionDate: data.inspectionDate,
      inspectorJobNumber: data.inspectorJobNumber,
    };

    await createTestWithData(testToCreate);

    setAction(defaultAction);

    const newTypes = omit([test.type], types);

    if (amount - 1) newTypes[test.type] = amount - 1;
    else {
      const newTests = values.tests.filter((_, idx) => idx !== index);
      setFieldValue("tests", newTests);

      if (!newTests.length) onClose();
    }

    setTypes(newTypes);
    validateForm();
  };

  const isProducerTest = Boolean(intersection([modules.PRODUCER, modules.END_OWNER], testConfig[test.type].responsible).length);
  const isLabTest = testConfig[test.type].responsible.includes(modules.LAB_INTERNAL);

  if(!amount) return null;

  return <>
    <Grid container spacing={3} alignItems="center">
      <Grid item>
        <Typography component="h5" variant="h5">
          {testConfig[test.type].title}
          {amount > 1 && <span>: {amount}</span>}
        </Typography>
      </Grid>
      <Grid item>
        <ActionTypeSelect
          type={test.type}
          action={action}
          setAction={setAction}
        />
      </Grid>
    </Grid>
    {isProducerTest && (
      <AddDataModal
        open={action === ACTIONS.addData}
        onClose={() => setAction(defaultAction)}
        addData={onAddData}
        certificates={certificates}
        test={test}
      />
    )}
    {isLabTest && (
      <>
        <Grid container spacing={3} alignItems="flex-end">
          <Grid item xs={3}>
            <SelectField
              name={`tests.${index}.assignee`}
              label='Laboratory'
              required={!isProducerTest || !test.properties.acceptance}
            >
              {laboratories.map((lab) => (
                <MenuItem key={lab._id} value={lab}>{lab.name}</MenuItem>
              ))}
              {!!qcpNames.length && (
                <MenuItem value={INTERNAL_LAB}>{INTERNAL_LAB}</MenuItem>
              )}
            </SelectField>
          </Grid>
          {test.assignee === INTERNAL_LAB && (
            <Grid item xs={3}>
              <Grid container spacing={3}>
                <Grid item xs={test.properties.acceptance ? 8 : 12}>
                  <SelectField
                    required
                    name={`tests.${index}.properties.acceptance`}
                    label='QCP/ITP'
                  >
                    {qcpNames.map((qcp) => (
                      <MenuItem key={qcp.name} value={qcp.name}>
                        {qcp.name}
                      </MenuItem>
                    ))}
                  </SelectField>
                </Grid>
                {test.properties.acceptance && (
                  <Grid item xs={4}>
                    <SelectField
                      required
                      name={`tests.${index}.properties.acceptanceItem`}
                      label='QCP Item'
                    >
                      {qcpItems.map((qcp) => (
                        <MenuItem key={qcp._id} value={qcp._id}>
                          {qcp.qcpItem}
                        </MenuItem>
                      ))}
                    </SelectField>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
          <Grid item xs={3}>
            <div className={classes.witnesses}>
              <MultipleSelect
                required={testsWithRequiredWitnesses.includes(test.type)}
                label="Witnesses"
                value={test.witnesses.map((c) => c.name)}
                elements={witnessCompanies.map((c) => c.name)}
                onChange={(values) => {
                  const witnesses = witnessCompanies.filter((c) => values.includes(c.name));
                  setFieldValue(`tests.${index}.witnesses`, witnesses);
                }}
              />
            </div>
          </Grid>
          <Grid item>
            <FormControlLabel
              onChange={(e) => setFieldValue(`tests.${index}.confidential`, e.target.checked)}
              checked={test.confidential}
              control={<Checkbox />}
              label={(
                <Box className={classes.confidential}>
                      Confidential&nbsp;
                  <Tooltip title="Confidential tests are only visible to you until they've been fully approved">
                    <InfoIcon color="info" fontSize="small" />
                  </Tooltip>
                </Box>
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <MultipleSelect
              label="Tags"
              value={test.properties.tags}
              elements={TAGS}
              onChange={(values) => setFieldValue(`tests.${index}.properties.tags`, values)}
            />
          </Grid>
          <Grid item xs={!isEmpty(test.witnesses) ? 3 : 6}>
            <TextField
              rows={2}
              multiline
              label="Notes"
              name={`tests.${index}.notes`}
            />
          </Grid>
          {!isEmpty(test.witnesses) && (
            <Grid item xs={3} className={classes.dateContainer}>
              <InputLabel shrink htmlFor="inspectionDate" className={classes.dateLabel}>
                Inspection Date
              </InputLabel>
              <DateField
                variant="standard"
                name="inspectionDate"
                className={classes.dateField}
                type="date"
                InputLabelProps={{
                  shrink: true
                }}
                value={test.inspectionDate}
                onChange={(event) => setFieldValue(`tests.${index}.inspectionDate`, event.target.value)} />
            </Grid>
          )}
          {!isEmpty(test.witnesses) && (
            <Grid item xs={3}>
              <TextField
                label="Inspector Job Number"
                value={test.inspectorJobNumber}
                name={`tests.${index}.inspectorJobNumber`}
              />
            </Grid>
          )}
        </Grid>
      </>
    )}
    {index !== values.tests.length - 1 && (
      <Divider className={classes.divider} />
    )}
  </>;
};

export default withStyles(observer(Test), styles);
