import {CAMPAIGN_TYPES} from "@core/constants/campaign";
import * as yup from "yup";

export const END_PRODUCT = {
  quantity: "",
};

export const SPECIFICATION = {
  norm: "",
  grades: [],
};

export const initialValuesDefault = {
  name: "",
  type: CAMPAIGN_TYPES.SINGLE_MATERIAL,
  certificationType: "",

  // remove
  connectToPO: false,
  // remove
  existingMaterial: false,

  // required if attach to PO
  customer: "", // omit from result
  purchaseOrder: {}, // format to id

  manufacturingLocation: "",
  properties: {
    product: {
      qcp: "",
      qcpName: "", // omit from result
      productType: {
        _id: "", // omit from result
        name: "",
        internalName: "", // to be renamed to internalId
        properties: [],
      },
      materialSpecifications: [SPECIFICATION],
      nominalSizes: []
    },
    statistics: {
      estimatedHeats: "",
      estimatedHeatLots: ""
    },
  },

  singleProducts: [],
};

export const validationSchema = yup.object().shape({
  name: yup.string().required("This field is required"),
  type: yup.string().required("This field is required"),
  certificationType: yup.string().required("This field is required"),

  connectToPO: yup.bool(),
  existingMaterial: yup.bool(),
  
  customer: yup.string(),
  purchaseOrder: yup.object().shape({
    _id: yup.string().test("purchaseOrder", "This field is required", function (value) {
      const parent = this.from[1];

      const connectToPO = parent.value.connectToPO;

      return !connectToPO || value;
    }),
  }),
  manufacturingLocation: yup.string().required("This field is required"),
  properties: yup.object().shape({
    product: yup.object().shape({
      qcpName: yup.string(),
      qcp: yup.string().when("qcpName", {
        is: true,
        then: (schema) => schema.required("This field is required"),
      }),
      productType: yup.object().shape({
        _id: yup.string().required("This field is required"),
        name: yup.string().required("This field is required"),
        internalName: yup.string().required("This field is required"),
        properties: yup.array().of(yup.object().shape({
          name: yup.string().required("This field is required"),
          label: yup.string().required("This field is required"),
          value: yup.string().required("This field is required"),
          measurements: yup.string(),
        })),
      }),
      materialSpecifications: yup.array().of(yup.object().shape({
        norm: yup.string().required("This field is required"),
        grades: yup.array().of(yup.string()).required("This field is required"),
      })),
      nominalSizes: yup.array().of(yup.object().shape({
        quantity: yup.number().min(1).required("This field is required"),
      })),
    }),
    statistics: yup.object().shape({
      estimatedHeats: yup.number().min(1).test("estimatedHeats", "This field is required", function (value) {
        const parent = this.from[1];

        const existingMaterial = parent.value.existingMaterial;

        return !existingMaterial || value;
      }),
      estimatedHeatLots: yup.number().min(1).test("estimatedHeatLots", "This field is required", function (value) {
        const parent = this.from[1];

        const existingMaterial = parent.value.existingMaterial;

        return !existingMaterial || value;
      }),
    }),
  }),
  singleProducts: yup.array().of(yup.string()),
});

export const initialValuesEdit = {
  "name": "3",
  "type": "Single Material",
  "certificationType": "3.2",
  "connectToPO": true,
  "existingMaterial": false,
  "customer": "646331321e8e4700277d3629",
  "purchaseOrder": {
    "_id": "659e4ae0a076f7ed191b8dbb",
    "date_created": "2024-01-10T07:44:32.839Z",
    "certificates": [
      {
        "_id": "659e4adca076f7ed191b8b14",
        "heat": "HEAT_1",
        "certificateType": "3.1",
        "createdByPortal": false,
        "markingFiles": [],
        "properties": {
          "lotId": "LOT_1",
          "productType": "64633f435584df8862ea5833",
          "grade": "BR",
          "norm": "API 5L (2018) PSL2"
        },
        "previousProperties": [],
        "items": [
          {
            "quantity": "1",
            "outerDiameter": "100",
            "wallThickness": "1000",
            "length": "10"
          }
        ],
        "previousItems": [],
        "date_created": "2024-01-10T07:44:28.196Z",
        "conditions": [],
        "tests": [
          {
            "_id": "659e4adea076f7ed191b8d0c",
            "type": "notes_test",
            "company": "646331321e8e4700277d3615",
            "status": "FILLED",
            "witnesses": [],
            "confidential": false
          }
        ],
        "company": "646331321e8e4700277d3615",
        "transactions": [],
        "location": "646331321e8e4700277d3616",
        "transfers": [],
        "creator": "646331321e8e4700277d3614",
        "owner": "646331321e8e4700277d3615",
        "previous_owners": [],
        "__v": 0,
        "orderNumber": "Test order 1.",
        "projectId": "659e4ae2a076f7ed191b8f20"
      }
    ],
    "receiver": {
      "_id": "646331321e8e4700277d3629",
      "name": "Jaskolski LLC"
    },
    "sender": {
      "_id": "646331321e8e4700277d3615",
      "name": "Satterfield, Hessel and Bechtelar44"
    },
    "orderNumber": "Test order 1.",
    "isDraft": true,
    "campaigns": []
  },
  "manufacturingLocation": "646331321e8e4700277d3619",
  "properties": {
    "product": {
      "qcp": "65bb41988821a14156c6f782",
      "qcpName": "Kennametal QCP",
      "productType": {
        "name": "3D printed part",
        "internalName": "3D printed part",
        "properties": [
          {
            "name": "OD",
            "label": "Nominal Outside Diameter",
            "value": "273.1",
            "measurements": "mm"
          },
          {
            "name": "WT",
            "label": "Nominal Wall Thickness",
            "value": "18.3",
            "measurements": "mm"
          },
          {
            "name": "Length",
            "label": "Nominal Length",
            "value": "11.9 - 12.5",
            "measurements": "m"
          }
        ],
        "nominalQuantity": 1,
        "_id": "3D printed part"
      },
      "materialSpecifications": [
        {
          "norm": "DNV-ST-F101 (2021)",
          "grades": [
            "SMLS 450 PD"
          ]
        }
      ],
      "nominalSizes": [
        {
          "quantity": 1,
          "OD": "273.1",
          "WT": "18.3",
          "Length": "11.9 - 12.5"
        }
      ]
    },
    "statistics": {
      "estimatedHeats": "33",
      "estimatedHeatLots": "333"
    }
  },
  "singleProducts": []
};