import React from "react";
import {Formik} from "formik";
import {useHistory} from "react-router-dom";
import useStores from "../../../useStores";
import Form from "./components/Form";
import {validationSchema} from "@core/validationSchemas/user";
import {observer} from "mobx-react";
import userService from "@core/api/user-service.js";
import modules from "@core/constants/modules";

const initialValues = {
  username: "",
  firstname: "",
  lastname: "",
  password: "",
  passwordConfirmation: "",
  role: "",
  email: "",
  avatar: null,
  avatarPreview: ""
};

const UserForm = ({user, ...formProps}) => {
  const {NotificationStore, UserStore} = useStores();
  const history = useHistory();

  const handleSubmit = async (values) => {
    try {
      const user = await userService.addNew(values);
      NotificationStore.showSuccess("Successfully added!");

      const [module] = UserStore.user.data.company.modules;
      const isSuperAdminView = module.name === modules.SUPER_ADMIN;

      const redirectLink = isSuperAdminView ? `/users/${user._id}` : `/admin/users/${user._id}`;

      history.push(redirectLink);
    } catch (error) {
      NotificationStore.showError("User is not available");
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={user || initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      children={(props) => (
        <Form {...props} {...formProps} />
      )}
    />
  );
};

export default observer(UserForm);
