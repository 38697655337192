import React, {useCallback} from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  Tooltip,
  TableSortLabel,
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import Skeleton from "@core/components/TableSkeleton";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import {map, prop, path, isNil} from "ramda";
import styles from "./styles";
import classNames from "classnames";
import {useHistory, useLocation} from "react-router-dom";
import qs from "qs";
import {DATE_FORMAT} from "@core/constants/dateFormats";

const TableSkeleton = ({
  classes,
  selectedItems,
  setSelectedItems,
  items,
  columns,
  allowRowSelection,
  children,
  isLoaded,
  sort = {orderBy: "date_created", order: "desc"},
  setSort,
  saveSortToQueryParams,
  getRowClassName,
  onRowClick,
  noDataText,
  ...props
}) => {
  const itemsIds = map(prop("_id"), items);

  const isSomethingSelected = isLoaded && selectedItems.length && items.length;
  const areAllItemsInSelectedItems = isSomethingSelected && itemsIds.every((id) => selectedItems.includes(id));
  const isAtLeastOneItemInSelectedItems = isSomethingSelected && itemsIds.some((id) => selectedItems.includes(id)) && !areAllItemsInSelectedItems;
  const location = useLocation();
  const history = useHistory();

  const onCheckboxClick = (item) => {
    if (!allowRowSelection) return;

    const isItemSelected = selectedItems.includes(item._id);

    if (isItemSelected) {
      setSelectedItems(selectedItems.filter((id) => id !== item._id));
    } else {
      setSelectedItems(selectedItems.concat(item._id));
    }
  };

  const onSelectAllItems = () => {
    if (areAllItemsInSelectedItems && selectedItems.length) setSelectedItems(selectedItems.filter((id) => !itemsIds.includes(id)));
    else setSelectedItems(itemsIds.concat(selectedItems));
  };

  const selectedColumns = props.selectedColumns || map(prop("dataIndex"), columns);
  const columnsToShow = selectedColumns.reduce((acc, dataIndex) => {
    const column = columns.find((column) => column.dataIndex === dataIndex);

    if (column) acc.push(column);

    return acc;
  }, []);

  const createSortHandler = (dataIndex) => {
    if (!setSort) return;

    let order = "desc";

    if (sort.orderBy === dataIndex && sort.order === "desc") order = "asc";

    setSort({order, orderBy: dataIndex});

    if (saveSortToQueryParams) {
      const queryParams = qs.parse(location.search?.length ? location.search.slice(1) : "");
      history.replace({
        pathname: location.pathname,
        search: qs.stringify({...queryParams, sort: {order, orderBy: dataIndex}})
      });
    }
  };

  const renderProp = useCallback((item, dataIndex) => {
    const renderValue = path(dataIndex.split("."), item);

    if (typeof renderValue === "object") {
      console.warn("Requested item prop resolved to object, expected string or number", item, dataIndex);

      return "";
    }

    return !isNil(renderValue) ? renderValue : "";
  }, []);

  return (
    <div className={classes.container}>
      <Table size="small" className="styled-table">
        <TableHead>
          <TableRow>
            {allowRowSelection && (
              <TableCell padding="none">
                <Checkbox
                  onClick={onSelectAllItems}
                  color="primary"
                  checked={areAllItemsInSelectedItems || false}
                  indeterminate={isAtLeastOneItemInSelectedItems || false}
                />
              </TableCell>
            )}

            {columnsToShow.map(({title, align, dataIndex, isSorted}, index) => (
              <TableCell
                className={classes.tableCell}
                key={title + index}
                align={align}
                style={isSorted ? {cursor: "pointer"} : null}
                onClick={isSorted ? () => createSortHandler(dataIndex) : null}
              >
                {title}

                {title === "Requested on" && (
                  <Tooltip title={`Date format: ${DATE_FORMAT}`} placement="bottom">
                    <InfoIcon color="primary" style={{verticalAlign: "middle", fontSize: "18px", margin: "0px 3px",}} />
                  </Tooltip>
                )}

                {isSorted && (
                  <TableSortLabel
                    active={sort.orderBy === dataIndex}
                    direction={sort.order}
                    onClick={() => createSortHandler(dataIndex)}
                  />
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoaded ? (
            <>
              {items.length ? (
                items.map((item, index) => {
                  const isItemSelected = selectedItems.includes(item._id);

                  const rowClassName = getRowClassName(item);

                  return (
                    <TableRow
                      className={classNames(rowClassName, {[classes.selectableRow]: allowRowSelection || onRowClick})}
                      key={item._id}
                      selected={isItemSelected}
                      onClick={() => {
                        onCheckboxClick(item);

                        if(onRowClick) onRowClick(item);
                      }}
                    >
                      {allowRowSelection && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={selectedItems.includes(item._id)}
                          />
                        </TableCell>
                      )}
                      {columnsToShow.map(({render, align, dataIndex, width}) => (
                        <TableCell
                          width={width}
                          className={classes.tableCell}
                          align={align}
                          key={`${item._id}${dataIndex}`}
                        >
                          {render ? render(item, index) : renderProp(item, dataIndex)}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={allowRowSelection ? columnsToShow.length + 1 : columnsToShow.length}
                  >
                    {noDataText}
                  </TableCell>
                </TableRow>
              )}
            </>
          ) : (
            <Skeleton
              allowRowSelection={allowRowSelection}
              columns={columns.length}
            />
          )}
        </TableBody>
        {children}
      </Table>
    </div>
  );
};

TableSkeleton.defaultProps = {
  selectedItems: [],
  getRowClassName: () => { },
  noDataText: "No data."
};

export default withStyles(TableSkeleton, styles);
