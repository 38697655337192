import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {textColumn, DynamicDataSheetGrid, keyColumn} from "react-datasheet-grid";
import {isNumber} from "./helpers";
import {Box} from "@mui/material";
import {withStyles} from "tss-react/mui";
import styles from "./styles";
import Loader from "@core/components/Loader";

const DataSheetTable = ({data, setData, disabled, columns, headerNameByColId, classes}) => {

  const wrapperRef = useRef(null);
  const [dataGridHeight, setDataGridHeight] = useState(null);

  const decimalSeparator = useMemo(() => {
    // Detect user's locale
    const userLocale = navigator.language;

    // Format a test number using the detected locale
    const formattedNumber = new Intl.NumberFormat(userLocale).format(1.1);
    console.log(new Intl.NumberFormat(userLocale));

    // Check the formatted number to determine the decimal separator
    const decimalSeparator = formattedNumber.indexOf("1") === 0 ? formattedNumber.charAt(1) : null;

    return decimalSeparator; // Returns '.' or ',' depending on the locale
  }, []);

  const columnsDataSheet = useMemo(() => {
    return Object.values(columns).map((columnId) => ({
      ...keyColumn(columnId, textColumn),
      title: headerNameByColId[columnId],
      grow: 0.5,
      height: 25,
      cellClassName: ({columnId, rowData}) => {
        return isNumber(rowData[columnId], decimalSeparator) ? classes.alignRight : "";
      },
      disabled: disabled,
    }));
  }, [columns, headerNameByColId, decimalSeparator, disabled]);

  const disableContextMenu = useMemo(() => disabled ? true : undefined, [disabled]);

  // TODO add debounce!!
  const onChange = useCallback((data) => {
    setData(data);
  }, []);

  useEffect(() => {
    // Assuming you have a div with an id 'myDiv'
    const div = wrapperRef.current;

    // Get the div's bottom position relative to the document
    const divBottom = div.getBoundingClientRect().bottom + window.scrollY;

    // Get the total document height
    const documentHeight = Math.max(
      document.body.scrollHeight, document.documentElement.scrollHeight,
      document.body.offsetHeight, document.documentElement.offsetHeight,
      document.body.clientHeight, document.documentElement.clientHeight
    );

    // Calculate the height from the div's bottom to the bottom of the document
    const remainingHeight = documentHeight - divBottom;

    setDataGridHeight(remainingHeight);
  }, []);

  return (
    <Box
      ref={wrapperRef}
      sx={{
        "& .dsg-add-row": {
          pointerEvents: disabled ? "none" : undefined,
          "& .dsg-add-row-btn, & .dsg-add-row-input": {
            background: disabled ? "rgb(88 88 88 / 7%) !important" : undefined
          }
        }
      }}
    >
      {
        !dataGridHeight ?
          <Loader size={40}/> :
          <>
            <DynamicDataSheetGrid
              value={data}
              onChange={onChange}
              columns={columnsDataSheet}
              disableContextMenu={disableContextMenu}
              rowHeight={30}
              height={dataGridHeight}
            />
          </>
      }
    </Box>
  );
};

export default withStyles(DataSheetTable, styles);