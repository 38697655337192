import {times} from "ramda";

export const TEST_STANDARDS = ["ASTM E1086-14", "ASTM E415-17", "ASTM E415-21", "ASTM E3047-16", "Element SOP 20-02", "EN 10315 (2006)", "EN ISO 15350 (2000)", "CR 10320 (2004)"];

export const COLUMN_MIN_WIDTH = 120;

export const TEST_RESULT = {element: "", values: [undefined], formula: ""};

export const DEFAULT_ELEMENTS_AMOUNT = 1;

export const DEFAULT_TEST_RESULTS = times(() => TEST_RESULT, DEFAULT_ELEMENTS_AMOUNT);