import React from "react";
import {Box, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {FieldArray, useFormikContext} from "formik";
import {Input} from "@core/components/Form";
import {debounce} from "@core/helpers";
import RemoveButtonCell from "@core/components/DynamicTestResultsTable/RemoveButtonCell";
import AddRowButton from "@core/components/DynamicTestResultsTable/AddRowButton";
import {
  EMPTY_CELL,
  STRESS_AT_BREAK_COLUMN_NAMES,
  STRESS_AT_BREAK_FIELDS,
  STRESS_AT_BREAK_ROW_DEFAULT
} from "@core/constants/transverseRuptureStrength";
import CalculatedValuesRows
  from "@core/components/TestForms/TransverseRuptureStrength/TestResults/CalculatedValuesRows";

function TestResults() {

  const {values, handleChange} = useFormikContext();

  return (
    <FieldArray name="stressAtBreak">
      {({push, remove}) => {

        return (
          <>
            <Box sx={{maxWidth: "700px"}}>
              <Table className="styled-table">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    {Object.values(STRESS_AT_BREAK_COLUMN_NAMES).map((columnName) => (
                      <TableCell key={columnName}>{columnName}</TableCell>
                    ))}
                    <TableCell>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {values.stressAtBreak.map(({uniqueId, ...item}, index) => (
                    <TableRow key={uniqueId}>
                      {Object.values(STRESS_AT_BREAK_FIELDS).map((fieldName) => (
                        <React.Fragment key={fieldName}>
                          {
                            fieldName === EMPTY_CELL ?
                              <TableCell className="vertical-header-col"></TableCell> :
                              <TableCell>
                                <Input
                                  defaultValue={item[fieldName]}
                                  type={fieldName === STRESS_AT_BREAK_FIELDS.specimenId ? undefined : "number"}
                                  name={`stressAtBreak.${index}.${fieldName}`}
                                  onChange={debounce(handleChange)}/>
                              </TableCell>
                          }
                        </React.Fragment>
                      ))}
                      <RemoveButtonCell
                        disabled={values.stressAtBreak?.length === 1}
                        onClick={() => remove(index)}
                      />
                    </TableRow>
                  ))}
                  <CalculatedValuesRows stressAtBreak={values.stressAtBreak}/>
                </TableBody>
              </Table>

              <AddRowButton push={push} rowCellsValue={STRESS_AT_BREAK_ROW_DEFAULT}/>
            </Box>
          </>
        );
      }}
    </FieldArray>
  );
}

export default TestResults;