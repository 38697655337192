import {Parser} from "hot-formula-parser";

const parser = new Parser;

export const calculateElementValue = (elements, formula) => {
  elements.forEach(({element, value}) => parser.setVariable(element, value));
  const result = parser.parse(formula).result;

  if (!result) return 0;

  return parseFloat(result.toFixed(2));
};