import {
  descend,
  flatten,
  groupBy,
  isEmpty,
  map,
  partition,
  pick,
  prop,
  sort,
  uniq,
  values
} from "ramda";
import {CUSTOM_REPORT_TYPES, CUSTOM_TEST_TYPES, TYPES} from "@core/constants/test";
import {CAMPAIGN_SPECIMEN_LABELS} from "@core/constants/campaign";
import {TEST_RESULTS} from "@core/constants/testResults";
import moment from "moment";
import {FILTERS} from "@core/constants/filters";
import {DATE_FORMAT} from "@core/constants/dateFormats";

export const getGlobalViewTests = (tests) => {
  const [customTests, restTests] = partition((test) => test.type === TYPES.CUSTOM, tests);

  const grouppedTests = groupBy(prop("displayName"), customTests);

  const newCustomTests = values(grouppedTests).map((bunch) => {
    const [test] = bunch;
    const activities = flatten(bunch.map((test) => test.properties.activities));

    return {
      ...pick(["company", "displayName", "type"], test),
      transactions: [],
      instruments: [],
      witnesses: [],
      properties: {
        activities,
      },
      status: test.status,
    };
  });

  return newCustomTests.concat(restTests);
};

export const getAsBuiltViewTests = (tests, weldedPipes) => {
  const [pipeCustomTests, restTests] = partition((test) => test.specimenLabel === CAMPAIGN_SPECIMEN_LABELS.PIPE, tests);

  const asBuildPipeCustomTests = pipeCustomTests
    .map((test) => {
      const activities = test.properties.activities.filter((activity) => weldedPipes.includes(activity.specimen));
      const activitiesResult = activities.every((activity) => activity.results.result === TEST_RESULTS.ACCEPTABLE) ? TEST_RESULTS.ACCEPTABLE : TEST_RESULTS.NOT_ACCEPTABLE;

      return {
        ...test,
        properties: {
          activities,
          result: activitiesResult
        }
      };
    })
    .filter((test) => test.properties.activities.length);

  return asBuildPipeCustomTests.concat(restTests);
};

const filterCustomTest = (test, dateA, dateB, startDate, endDate, specimens, specimensOfBatchNumbers, welds) => {
  const activities = test.properties.activities.filter((activity) => {
    const compareDate = moment(activity.date);
    const isBetween = dateA && dateB && compareDate.isBetween(startDate, endDate);
    const isAfterStart = dateA && compareDate.isAfter(startDate);
    const isInRange = !dateA || isBetween || (!dateB && isAfterStart);

    const isInSpecimensArr = !specimens.length || specimens.includes(activity.specimen);
    const isInSpecimensBatchNumberArr = !specimensOfBatchNumbers.length || specimensOfBatchNumbers.includes(activity.specimen);
    const isInWeldsArr = !welds.length || welds.includes(activity.specimen);

    return isInRange && isInSpecimensArr && isInSpecimensBatchNumberArr && isInWeldsArr;
  });

  const activitiesResult = activities.every((activity) => activity.results.result === TEST_RESULTS.ACCEPTABLE) ? TEST_RESULTS.ACCEPTABLE : TEST_RESULTS.NOT_ACCEPTABLE;

  return {...test, properties: {activities, result: activitiesResult}};
};

const filterCustomTests = (tests, dateA, dateB, startDate, endDate, specimens, specimensOfBatchNumbers, welds) => {
  return tests
    .map((test) => filterCustomTest(test, dateA, dateB, startDate, endDate, specimens, specimensOfBatchNumbers, welds))
    .filter((test) => test.properties.activities.length);
};

const filterCustomReports = (customReports, welds, specimens, neighboringWelds = []) => {
  const [defectReports, rest] = partition((test) => test.displayName === CUSTOM_REPORT_TYPES.COATING_DEFECT_LOG, customReports);
  
  const filteredDefectReports = defectReports
    .map((test) => {
      const weldsDefects = welds.length ? getWeldDefects([test], welds) : [];
      const pipeDefects = neighboringWelds.length ? getPipeDefects([test], neighboringWelds) : [];

      const defects = weldsDefects.concat(pipeDefects);

      return {...test, properties: {...test.properties, data: defects}};
    })
    .filter((test) => test.properties.data.length);

  return rest.concat(filteredDefectReports);
};

export const filterTests = (filters, tests, rawMaterials, secondaryFilter = {}) => {
  if(isEmpty(filters)) return tests;

  const [dateA, dateB] = filters[FILTERS.DATE_CREATED] || [];
  const specimens = filters[FILTERS.SPECIMEN_ID] || [];
  const batchNumbers = filters[FILTERS.BATCH_NUMBER] || [];
  const welds = filters[FILTERS.WELD_ID] || [];

  const startDate = moment(dateA, DATE_FORMAT);
  const endDate = moment(dateB, DATE_FORMAT);

  const specimensOfBatchNumbers = flatten(batchNumbers.map((bn) => {
    const material = rawMaterials.find((material) => material.batchNumber === bn) || {};

    return material.pipes;
  }));

  const [custom, rest] = partition((test) =>  [TYPES.CUSTOM, TYPES.CUSTOM_REPORT].includes(test.type), tests);
  const [customReports, customTests] = partition((test) => test.type === TYPES.CUSTOM_REPORT, custom);

  const filteredCustomTests = filterCustomTests(customTests, dateA, dateB, startDate, endDate, specimens, specimensOfBatchNumbers, welds);

  const filteredCustomReports = filterCustomReports(customReports, welds, specimens, secondaryFilter.welds);

  return filteredCustomReports.concat(filteredCustomTests).concat(rest);
};

export const getPipeProductionTests = (pipe, productionTests) => {
  if(!pipe.heat) return [];

  const [dimensionalTests, restTests] = partition((test) => test.type === TYPES.DIMENSIONAL, productionTests);

  const dimensionalTestsWithPipe = dimensionalTests.filter((test) => test.properties.elements?.some((element) => element.productId === pipe.pipeNumber));

  const lotId = dimensionalTestsWithPipe[0]?.lotId;

  if(!lotId) return restTests.filter((test) => test.item_heat === pipe.heat);

  const productionTestsFilteredByHeatAndLotId = restTests.filter((test) => test.item_heat === pipe.heat && test.lotId === lotId);

  return dimensionalTestsWithPipe.concat(productionTestsFilteredByHeatAndLotId);
};

export const getDefectReports = (tests) => {
  const defectReports =  tests.filter((test) =>
    test.type === TYPES.CUSTOM_REPORT &&
    test.displayName === CUSTOM_REPORT_TYPES.COATING_DEFECT_LOG);

  return defectReports;
};

export const getWeldDefects = (defectReports, welds) => {
  const data = flatten(defectReports.map((test) => {
    const data = test.properties.data.filter((data) =>
      welds.includes(data["Weld Number"]) &&
      values(data).includes("F-Joint")
    );

    return data;
  }));

  return data;
};

export const getPipeDefects = (defectReports, welds) => {
  const [prevWeld, nextWeld] = welds;
  const data = flatten(defectReports.map((test) => {
    const data = test.properties.data.filter((data) => {
      const results = values(data);

      const isPipeCrack = results.includes("P-Coating") && results.includes("Type-A");
      const isStartCrack = data["Weld Number"] === prevWeld && results.includes("Trail");
      const isEndCrack = data["Weld Number"] === nextWeld && results.includes("Lead");

      return isPipeCrack && (isStartCrack || isEndCrack);
    });

    return data;
  }));

  return data;
};

export const getBaseProductsTitle = (products) => {
  const productTypes = map(prop("productType"), products);
  const uniqueProductTypes = uniq(productTypes);
  const [productTypeName] = uniqueProductTypes;

  if(uniqueProductTypes.length > 1 || !productTypeName) return null;

  return `${productTypeName}(s)`;
};

export const getAsBuiltActivitiesByStalkNumber = (tests, stalkNumber) => {
  const asBuildTests = tests.filter((test) =>
    test.type === TYPES.CUSTOM &&
    test.displayName === CUSTOM_TEST_TYPES.AS_BUILD_RECORD &&
    test.properties.activities.find((activity) => activity.description === "Stalk Number").results.values.some(({value}) => value === stalkNumber)
  );

  const asLaidTests = tests.filter((test) =>
    test.type === TYPES.CUSTOM &&
    test.displayName === CUSTOM_TEST_TYPES.AS_LAID_RECORD
  );

  const [asBuildTest] = sort(descend(prop("date_created")), asBuildTests);
  const [asLaidTest] = sort(descend(prop("date_created")), asLaidTests);

  if(asLaidTest) {
    const stalk = asLaidTest.properties.activities.find((activity) => activity.description === "Stalk Number" && activity.results.values.some(({value}) => value === stalkNumber)) || {};
    const tieInWeld = stalk.specimen;
    
    const stalkTieInActivities = asLaidTest.properties.activities.filter((activity) => activity.specimen === tieInWeld);

    if(asBuildTest && stalkTieInActivities.length) {
      asBuildTest.properties.activities.push(...stalkTieInActivities);
    }
  }

  return asBuildTest;
};

export const getPipeTotalActual = (activities, weldNumber) => {
  const totalActual = activities.find((activity) => activity.description === "Total Actual" && activity.specimen === weldNumber);

  return totalActual ? Number(totalActual.results.values[0].value) : 0;
};