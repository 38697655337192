import React, {useState} from "react";
import axios from "axios";
import classNames from "classnames";
import RouterLink from "@core/components/RouterLink";
import {TextField, Button, Typography} from "@mui/material";
import {withStyles} from "tss-react/mui";
import userService from "@core/api/user-service";
import styles from "./styles";

const LoginForm = ({classes, setUser}) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();

    const {data} = await userService.login(username, password);

    axios.defaults.headers.common["X-Token-Jwt"] = data.accessToken;

    setUser(data.user);
  };

  return (
    <>
      <Typography component="h1" variant="h5">Login</Typography>
      <Typography variant="overline">Welcome to SteelTrace</Typography>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="username"
        label="Username"
        name="username"
        onChange={(e) => setUsername(e.target.value)}
        value={username}
        autoFocus
        InputLabelProps={{
          shrink: true
        }}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        onChange={(e) => setPassword(e.target.value)}
        onKeyDown={(e) => {
          if (e.key !== "Enter") return;

          onSubmit(e);
        }}
        value={password}
        InputLabelProps={{
          shrink: true
        }}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        size="large"
        className={classes.submit}
        onClick={onSubmit}
      >
        Sign In
      </Button>
      <Button
        className={`${classes.textButton} ${classes.recoverPassword}`}
        size="small"
        color="primary"
        component={RouterLink}
        to="/passwordRecovery"
      >
        Forgot your password?
      </Button>

      <Typography variant="body1" className={classes.signUp}>
        Don't have an account?
        <br/>
        Sign up&nbsp;
        <a
          href='https://docs.google.com/forms/d/e/1FAIpQLSf030BnX5ISXaXZeKZWQRbjnWvrFapvn2RFD_kZ0SC8tt3dJQ/viewform'
          target={"_blank"}
          className={classes.link}
        >
          {"here!"}
        </a>
        <a
          href='https://steeltrace.co/privacy-policy/'
          target="_blank"
          className={classNames(classes.link, classes.privacyPolicy)}
        >
          Privacy Statement
        </a>
      </Typography>
    </>
  );
};

export default withStyles(LoginForm, styles);
