import {NotificationStore} from "@core/components/Modal/stores";
import {TableStore} from "@core/stores";
import axios from "axios";
import {action, makeObservable, observable} from "mobx";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

class ProjectStore {

  constructor() {
    makeObservable(this, {
      projects: observable,
      project: observable,
      removeProject: action,
      createProject: action,
      getProjectById: action,
      linkOrders: action,
      unlinkOrders: action,
      linkOrder: action,
      unlinkOrder: action,
    });
  }
  
  projects = new TableStore({
    loader: (queryParams) => {

      const newQueryParams = {...this.projects.fetch, ...queryParams};
      const {offset, limit, search} = newQueryParams;
      this.projects.setFetch(newQueryParams);

      return axios.get(ROUTES.PROJECT[ACTIONS.ALL], {
        params: {
          offset,
          limit,
          search: {value: search}
        },
      });
    }
  });

  project = {
    data: {},
    isLoaded: false,
  };

  async removeProject(id) {
    try {
      await axios.delete(ROUTES.PROJECT[ACTIONS.REMOVE](id));

      this.projects.data = this.projects.data.filter((p) => p._id !== id);

      return true;
    } catch (err) {
      NotificationStore.showError("Something went wrong, could not delete project");

      return false;
    }
  }

  async createProject(data) {
    const response = await axios.post(ROUTES.PROJECT[ACTIONS.CREATE], data);

    this.projects.data = [response.data].concat(this.projects.data);
    this.projects.total = this.projects.total + 1;

    return response.data;
  }

  async getProjectById(id) {
    this.project.isLoaded = false;

    const response = await axios.get(ROUTES.PROJECT[ACTIONS.SINGLE](id));

    this.project.data = response.data;
    this.project.isLoaded = true;
  }

  async linkOrder(projectId, transferId) {
    const response = await axios.patch(ROUTES.PROJECT[ACTIONS.LINK](projectId), {}, {
      params: {transferId}});

    this.project.data = response.data;
  }

  async unlinkOrder(projectId, transferId) {
    await axios.patch(ROUTES.PROJECT[ACTIONS.UNLINK](projectId), {}, {
      params: {transferId}
    });

    this.project.data = {
      ...this.project.data,
      transfers: this.project.data.transfers.filter((t) => t._id !== transferId),
    };
  }

  async linkOrders(projectId, transferIds) {
    const response = await axios.patch(ROUTES.PROJECT[ACTIONS.BULK_LINK](projectId), {}, {
      params: {transferIds}
    });

    this.project.data = response.data;
  }

  async unlinkOrders(projectId, transferIds) {
    const response = await axios.patch(ROUTES.PROJECT[ACTIONS.BULK_UNLINK](projectId), {}, {
      params: {transferIds}
    });

    this.project.data = response.data;
  }
}

export default new ProjectStore();
