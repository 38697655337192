
export default (theme) => ({
  label: {
    width: "100%",
    position: "relative",
    whiteSpace: "nowrap"
  },

  control: {
    display: "block",
    width: "100%"
  },

  root: {
    width: "100%",
    marginTop: 10,
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
  },
  rootNoLabel: {
    width: "100%",
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
  },

  input: {
    fontSize: 15,
    padding: "13px 26px 13px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      outline: "none",
      outlineWidth: 0,
      borderRadius: 4,
    },
    "&:disabled": {
      cursor: "not-allowed",
    }
  },
  customPadding: {
    "& input": {
      padding: "13px 5px 13px 12px",
    }
  },

  error: {
    borderColor: "#f44336",
    borderRadius: 4
  },

  helperText: {
    position: "absolute",
    overflow: "hidden",
    marginTop: 3,
    lineHeight: "15px",
    zIndex: 100,
  },

  helperTextError: {
    color: "#f44336",
  },

  helperTextWarning: {
    color: "#ef9734",
  },

  helperTextInfo: {
    color: "#0000008a"
  },

  endAdornment: {
    marginRight: 8,
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: 14,
    textWrap: "nowrap"
  },
  startAdornment: {
    marginLeft: 8,
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: 14
  },
  asteriskLabel: {
    position: "relative",
    "&:after" : {
      position: "absolute",
      content: "' *'",
      color: "#ff0000"
    }
  },
  asteriskField: {
    position: "relative",
    "&:after" : {
      position: "absolute",
      left: 1,
      top: -2,
      content: "' *'",
      color: "#e91e63",
      transform: "scale(.8)",
    }
  }
});