import FieldRow from "@core/components/CollapsibleTable/components/FieldRow";
import ExpandMore from "@core/components/ExpandMore";
import IconistsIcon from "@core/components/IconistsIcon";
import {ICONISTS_ICONS} from "@core/constants/iconistsIcons";
import {path} from "ramda";
import {Box, Chip, Collapse, Table, TableBody, TableCell, TableRow, useTheme} from "@mui/material";
import {useFormikContext} from "formik";
import React, {useState} from "react";

const OptionalFields = ({fields, editable}) => {
  const {values} = useFormikContext();

  const filledFields = fields.filter((field) => path(field.name.split("."), values));

  const [expanded, setExpanded] = useState(!!filledFields.length);

  const theme = useTheme();

  if(!editable) {
    return filledFields.map((field) => (
      <FieldRow
        key={field.name}
        field={field}
        editable={editable}
      />
    ));
  }
   
  return (
    <>
      <TableRow
        onClick={() => setExpanded(!expanded)}
      >
        <TableCell
          colSpan={2}
          sx={{borderBottom: "none", cursor: "pointer"}}
        >
          <Box sx={{display: "flex", alignItems: "center"}}>
            <ExpandMore
              expand={expanded}
            >
              <IconistsIcon
                width="18px"
                icon={ICONISTS_ICONS.chevronBottom}
                color={theme.palette.text.primary}
              />
            </ExpandMore>
            &nbsp;
            Show optional fields
            &nbsp;
            <Chip
              size="small"
              label={`${filledFields.length}/${fields.length}`}
            />
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={2} style={{padding: 0}}>
          <Collapse
            sx={{borderTop: `1px solid ${theme.palette.divider}`}}
            in={expanded}
          >
            <Table>
              <TableBody>
                {fields.map((field) => (
                  <FieldRow
                    key={field.name}
                    field={field}
                    editable={editable}
                  />
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  ); 
};

export default OptionalFields;