import React, {useMemo} from "react";
import {getIsTieInWeld, getWeldIsRepaired} from "../../../../../../helpers";
import {TOOLTIP_CONFIG, TOOLTIP_PROGRESS_BAR_HEIGHT} from "../../constants";
import LengthProgressBar from "../LengthProgressBar";
import TooltipContainer from "../TooltipContainer";
import {STAGE_CONFIG} from "../../../../constants";
import Header from "./components/Header";
import WeldTypes from "./components/WeldTypes";

const WeldTooltip = ({node, scale, stage}) => {
  const {weld, length, totalLength} = node.data;

  const isRepairedWeld = getWeldIsRepaired(weld);
  const isTieInWeld = getIsTieInWeld(weld);
  
  const scaleBy = useMemo(() => {
    const scaleBy = (STAGE_CONFIG.MAX_SCALE - scale) || 0.1;

    return Math.max(TOOLTIP_CONFIG.SCALE_MIN, scaleBy);
  }, [scale]);

  const weldTypes = useMemo(() => {
    const types = [];

    if(isTieInWeld) types.push("Tie-in");

    if(isRepairedWeld) types.push("Repair");

    return types.join("&");
  }, [weld]);

  return (
    <TooltipContainer
      node={node}
      scale={scaleBy}
      stage={stage}
      rows={[{
        component: <Header weld={weld} />,
        height: TOOLTIP_CONFIG.FONT_SIZE,
      }, {
        component:  <WeldTypes weldTypes={weldTypes} />,
        height: TOOLTIP_CONFIG.GENERAL_TEXT_FONT_SIZE,
      }, {
        component: (
          <LengthProgressBar
            length={length}
            totalLength={totalLength}
          />
        ),
        height: TOOLTIP_PROGRESS_BAR_HEIGHT
      }]}
    />
  );
};

export default WeldTooltip;