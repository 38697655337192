import React from "react";
import {useParams} from "react-router-dom";
import BlockHeader from "../../../../../BlockHeader";
import TestSummaryTable from "@core/components/TestSummaryTable";
import TestReports from "./components/TestReports";
import {CUSTOM_TEST_TYPES, TYPES} from "@core/constants/test";
import useStores from "../../../../../../../../useStores";

const FieldJointCoating = ({tests, setFilteredTestIds, onTestClick}) => {
  const {id: campaignId} = useParams();
  const {CampaignStore} = useStores();

  const campaign = CampaignStore.campaign;

  const isLoaded = campaignId === campaign._id;

  const fieldJointCoatingTests = tests.filter((test) => test.type === TYPES.CUSTOM && test.displayName === CUSTOM_TEST_TYPES.FIELD_JOINT_COATING);

  return (
    <>
      <BlockHeader>Field Joint Coating Test Summary</BlockHeader>
      <TestSummaryTable
        setFilteredTestIds={setFilteredTestIds}
        tests={fieldJointCoatingTests}
        isLoaded={isLoaded}
        onTestClick={onTestClick}
      />
      <TestReports
        tests={fieldJointCoatingTests}
      />
    </>
  );
};

export default FieldJointCoating;