import React, {Component} from "react";
import classNames from "classnames";
import {inject, observer} from "mobx-react";
import {Grid, Button, Tooltip} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {Link} from "react-router-dom";
import TestResultTag from "@core/components/TestResultTag";
import TimelineBlock from "@core/components/TimelineBlock";
import EntityNotFound from "@core/components/EntityNotFound";
import TestMarker from "@core/components/TestMarker";
import InstrumentsList from "@core/components/InstrumentsList";
import Loader from "@core/components/Loader";
import InspectorNotes from "@core/components/InspectorNotes";
import WitnessAttest from "@core/components/WitnessAttest";
import testsConfig from "@core/configs/test";
import styles from "./styles";
import Status from "@core/components/Status";
import TimelineToggle from "@core/components/TimelineToggle";
import {copyToClipboard} from "@core/helpers/copyToClipboard";
import {compose} from "ramda";

class TestWitnessDetails extends Component {
  constructor() {
    super();
    this.testsContainer = React.createRef();
  }

  componentDidMount() {
    const {TestStore, CertificateStore, match: {params}} = this.props;
    TestStore.getTestById(params.id);
    CertificateStore.getCertificateByTestId(params.id);
  }

  render() {
    const {classes, CertificateStore, TestStore, TimelineStore} = this.props;

    if (!TestStore.test.isLoaded) {
      return <Loader />;
    }

    if (TestStore.test.isEmpty) {
      return <EntityNotFound entity='Test' />;
    }

    const test = TestStore.test.data;
    const testConfig = testsConfig[test.type];
    const certificate = CertificateStore.certificate.data;

    return (
      <>
        <div className={classes.mainContainer}>
          <Grid container item spacing={2}>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Grid xs={6}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        component={Link}
                        to="/witness"
                      >
                          Back
                      </Button>
                    </Grid>
                    {certificate._id && (
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          component={Link}
                          to={`/witness/${test._id}/certificate`}
                          target="_blank"
                        >
                            View certificate
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.container} ref={this.testsContainer}>
                <div
                  className={classNames({
                    [classes.content]: true,
                    [classes.contentSmall]: TimelineStore.open
                  })}
                  style={{borderLeft: `10px solid ${testConfig.color}`,minWidth:200}}
                >
                  <TestMarker
                    disabled
                    test={test}
                  />
                  <div className={classes.testView}>
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        {test._id && (
                          <h3>
                            <Tooltip title="Click to copy">
                              <span
                                className={classes.testId}
                                onClick={() => copyToClipboard(test._id)}
                              >
                                Test ID: {test._id.substring(0,3) + "..." +test._id.slice(-3) }
                              </span>
                            </Tooltip>
                          </h3>
                        )}
                      </Grid>
                      <Grid item>
                        <Status
                          test={test}
                        />
                      </Grid>
                    </Grid>
                    <div className={classes.instruments}>
                      <InstrumentsList instruments={test.instruments}/>
                    </div>
                    {test.productType && (
                      <Grid item xs={12} className={classes.values}>
                        <h4>Product type:</h4>
                        <p>{test.productType.name}</p>
                      </Grid>
                    )}
                    {React.cloneElement(testConfig.certificateView, {test})}
                    <InspectorNotes
                      test={test}
                      inspectorNotesStyles={classes.inspectorNotes}
                    />
                    <Grid item xs={12} container justifyContent="space-between" className={classes.result}>
                      <Grid item xs>
                        <TestResultTag
                          result={test.properties.result}
                        />
                      </Grid>
                      <Grid item xs container alignItems="flex-end" justifyContent="flex-end">
                        <WitnessAttest
                          allowToSign
                          certificate={certificate}
                          test={test}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <TimelineBlock isLast isFirst test={test}/>
              </div>
            </Grid>
            <Grid item>
              <TimelineToggle refTest={this.testsContainer} />
            </Grid>
          </Grid>
        </div>

      </>
    );
  }
}

export default compose(
  inject("TestStore", "CertificateStore", "TimelineStore"),
)(withStyles(observer(TestWitnessDetails), styles));