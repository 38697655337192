import React, {useState} from "react";
import {FormControlLabel, Grid, Radio, RadioGroup, Switch, Typography} from "@mui/material";
import {flatten, groupBy, keys, prop, sort, values} from "ramda";
import QuadrantTable from "./QuadrantTable";

const ZONES_ORDER = ["OD", "MW", "ID"];

const sortByZone = (elementsHistory) => sort((a, b) => {
  const metalCharacteristicsA = a.metal.split("-");
  const metalCharacteristicsB = b.metal.split("-");

  const quadrantNameA = metalCharacteristicsA[0].trim();
  const quadrantNameB = metalCharacteristicsB[0].trim();

  const zoneNameA = metalCharacteristicsA[1] ? metalCharacteristicsA[1].trim() : quadrantNameA;
  const zoneNameB = metalCharacteristicsB[1] ? metalCharacteristicsB[1].trim() : quadrantNameB;

  return ZONES_ORDER.indexOf(zoneNameA) - ZONES_ORDER.indexOf(zoneNameB);
}, elementsHistory);

const QuadrantView = ({test, editable, updateElement}) => {
  const [sortByQuadrant, setSortByQuadrant] = useState(JSON.parse(localStorage.getItem("sortByQuadrant")) === true);
  const [value, setValue] = useState("all");

  const sortedHistory = sortByZone(test.properties.elementsHistory);

  const quadrants = sortedHistory.reduce((quadrants, history) => {
    const metalCharacteristics = history.metal.split("-");
    const quadrantName = metalCharacteristics[0].trim();
    const zoneName = metalCharacteristics[1] ? metalCharacteristics[1].trim() : quadrantName;
    const positions = history.position.split(", ");
    const zoneElements = test.properties.elements.filter((element) => positions.includes(element.position.toString()));

    quadrants.push({quadrantName, zoneName, elements: zoneElements});

    return quadrants;
  }, []);

  const zonesGroupedByQuadrant = groupBy(prop("quadrantName"), quadrants);
  const quadrantsNames = keys(zonesGroupedByQuadrant);
  const quadrantsSortedByQuadrant = values(zonesGroupedByQuadrant);
  const quadrantsSortedByZone = values(groupBy(prop("zoneName"), flatten(quadrantsSortedByQuadrant)));

  const selectedSort = sortByQuadrant ? quadrantsSortedByQuadrant : quadrantsSortedByZone;

  const quadrantsToShow = value === "all" ? selectedSort : [zonesGroupedByQuadrant[value]];

  return (
    <>
      {quadrantsToShow.length > 1 && (
        <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
          <Grid item xs={6}>
            <RadioGroup
              aria-label="position"
              name="position"
              color="primary"
              value={value}
              onChange={(_, value) => setValue(value)}
              row
            >
              <FormControlLabel value="all" control={<Radio color="primary" />} label="All" />
              {quadrantsNames.map((name) => (
                <FormControlLabel value={name} control={<Radio color="primary" />} label={name} />
              ))}
            </RadioGroup>
          </Grid>
          {value === "all" && (
            <Grid item xs={6} container alignItems="center" justifyContent="flex-end">
              <Grid item><Typography>{test.productType.name.includes("ERW") ? "Sort by Depth" : "Sort by Zone"}</Typography></Grid>
              <Grid item>
                <Switch
                  color="primary"
                  checked={sortByQuadrant}
                  onChange={() => {
                    setSortByQuadrant(!sortByQuadrant);
                    localStorage.setItem("sortByQuadrant", !sortByQuadrant);
                  }}
                />
              </Grid>
              <Grid item><Typography>{test.productType.name.includes("ERW") ? "Sort by Zone" : "Sort by Quadrant"}</Typography></Grid>
            </Grid>
          )}
        </Grid>
      )}
      <Grid container>
        <Grid item xs={12}>
          <QuadrantTable
            min={parseFloat(test.properties.min)}
            max={parseFloat(test.properties.max)}
            avgMin={parseFloat(test.properties.avgMin)}
            avgMax={parseFloat(test.properties.avgMax)}
            quadrants={quadrantsToShow}
            sortByQuadrant={sortByQuadrant}
            value={value}
            productType={test.productType.name}
            editable={editable}
            updateElement={updateElement}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default QuadrantView;