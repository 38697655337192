
export function isNumber(str, decimalSeparator) {
  // Escape the decimalSeparator if necessary since some characters have special meanings in regex
  const escapedDecimalSeparator = [".", "\\", "^", "$", "*", "+", "?", "(", ")", "[", "]", "{", "}", "|"].includes(decimalSeparator) ?
    `\\${decimalSeparator}` :
    decimalSeparator;

  // Dynamically construct the regex using the escapedDecimalSeparator
  // This regex matches a string that starts and ends with one or more digits,
  // and allows for an optional decimal separator (specified by the decimalSeparator argument)
  // followed by one or more digits.
  // It does not match if the string starts with the decimal separator or if there are multiple decimal separators.
  const regex = new RegExp(`^\\d+${escapedDecimalSeparator}?\\d*$`);

  return regex.test(str);
}
