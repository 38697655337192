import React, {memo} from "react";
import {Group} from "react-konva";
import {STALK_NUMBER_WIDTH} from "../../constants";
import {PAGE_PADDING, SPACING_BETWEEN_STALKS} from "../../../../constants";
import StalkItem from "../StalkItem";

const StalkRow = ({totalLength, pipesInRow, settings, stalk, pipes, welds, animateShape, scale, setOpenPipe, setOpenWeld, state, pipeConfig, weldConfig, chunk, index, defectPositionsByPipeId, defectPositionsByWeldId, tieInWeldsByStalkNumber, stalksNumbers}) => {
  return chunk.map((weld) => {
    const stalkItemIndex = weld.position;
    const chunkIndex = Math.floor(stalkItemIndex / pipesInRow);
    const stalkItemIndexInChunk = stalkItemIndex % pipesInRow;

    return (
      <Group
        key={weld._id}
        x={STALK_NUMBER_WIDTH + PAGE_PADDING}
        y={(pipeConfig.HEIGHT + SPACING_BETWEEN_STALKS) * chunkIndex}
      >
        <StalkItem
          weld={weld}
          index={stalkItemIndex}
          indexInRow={stalkItemIndexInChunk}
          settings={settings}
          stalk={stalk}
          chunk={chunk}
          pipes={pipes}
          welds={welds}
          chunkIndex={index}
          animateShape={animateShape}
          scale={scale}
          setOpenPipe={setOpenPipe}
          setOpenWeld={setOpenWeld}
          state={state}
          tieInWeldsByStalkNumber={tieInWeldsByStalkNumber}
          pipeConfig={pipeConfig}
          weldConfig={weldConfig}
          totalLength={totalLength}
          defectPositionsByPipeId={defectPositionsByPipeId}
          defectPositionsByWeldId={defectPositionsByWeldId}
          stalksNumbers={stalksNumbers}
        />
      </Group>
    );
  });
};

export default memo(StalkRow);