import React, {createRef} from "react";
import classNames from "classnames";
import qs from "qs";
import {inject, observer} from "mobx-react";
import {withStyles} from "tss-react/mui";
import {compose, isEmpty, omit} from "ramda";
import EmptyTest from "./components/EmptyTest";
import AssignedTest from "./components/AssignedTest";
import TestView from "./components/TestView";
import testsConfig from "@core/configs/test";
import userService from "@core/api/user-service";
import {STATUSES, TEST_ID_PARAM} from "@core/constants/test";
import TimelineBlock from "@core/components/TimelineBlock";
import styles from "./styles";
import {getTestVersions} from "@core/helpers";

const TEST_WITH_DATA_STATUSES = [STATUSES.INSPECTED, STATUSES.INSPECTING, STATUSES.APPROVED, STATUSES.SUBMITTED, STATUSES.FILLED];

class NewTestView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addDataOpen: false,
      witnessCompanies: [],
      isAddingWitness: false,
      witness: [],
      testVersionIndex: 0,
    };
    this.containerRef = createRef();
  }

  componentDidMount() {
    const {test} = this.props;
    const params = qs.parse(window.location.search.slice(1));
    
    if (params[TEST_ID_PARAM] && params[TEST_ID_PARAM] === test._id) {
      this.containerRef.current.scrollIntoView({behavior: "smooth", block: "start"});
    }

    this.switchToSelectedTestVersion();
  }
  
  switchToSelectedTestVersion = () => {
    const test = this.props.test;
    const params = qs.parse(window.location.search.slice(1));

    const testVersions = getTestVersions(test);
    const testVersionIndex = testVersions.findIndex((test) => test._id === params[TEST_ID_PARAM]);

    if(testVersionIndex === -1) return;

    this.setState({testVersionIndex});
  };

  updateTest = async (properties, testChanges) => {
    const {onTestUpdate} = this.props;
    await userService.getUserInfo();

    const {witnesses, ...changes} = testChanges || {};

    const test = {
      ...changes,
      inspectionWaivedDocument: properties.inspectionWaivedDocument || "",
      inspectionDate: properties.inspectionDate,
      inspectorJobNumber: properties.inspectorJobNumber,
      properties: {...omit(["inspectionDate", "inspectorJobNumber", "inspectionWaivedDocument"], properties)}
    };

    if (witnesses) {
      const witnessesCompaniesId = witnesses.map((witness) => ({company: witness._id, status: 0}));
      test.witnesses = witnessesCompaniesId;
    }

    onTestUpdate(test, this.props.test._id);

    this.setState({addDataOpen: false});
  };

  setTestVersionIndex = (testVersionIndex) => {
    this.setState({testVersionIndex});
  };

  render() {
    const {classes, productId, test, isLast, isFirst, certificate = {}, assignInspector, deleteTest, approveTest, disabled, hideSign, timeline, shareLink} = this.props;
    const {TimelineStore} = this.props;
    const {addDataOpen, testVersionIndex} = this.state;
    
    const testVersions = getTestVersions(test);
    const currentTestVersion = testVersions[testVersionIndex];

    const timelineOpen = TimelineStore.open || !timeline;

    if (!test) {
      return "";
    }

    return (
      <div className={classes.container} ref={this.containerRef}>
        <div
          className={classNames(classes.content, timelineOpen ? classes.contentSmall : classes.contentBig)}
          style={{borderLeft: `10px solid ${testsConfig[test.type]?.color}`}}
        >
          {test.status === STATUSES.ASSIGNED && (
            <AssignedTest
              test={test}
              deleteTest={deleteTest}
              updateTest={this.updateTest}
              disabled={disabled}
              shareLink={shareLink}
            />
          )}
          {test.status === STATUSES.EMPTY && (
            <EmptyTest
              test={test}
              deleteTest={deleteTest}
              dataDialogOpen={() => this.setState({addDataOpen: true})}
              disabled={disabled}
              dataDialogClose={() => this.setState({addDataOpen: false})}
              updateTest={this.updateTest}
              addDataOpen={addDataOpen}
              certificate={certificate}
              shareLink={shareLink}
            />
          )}
          {TEST_WITH_DATA_STATUSES.includes(test.status) && (
            <TestView
              test={currentTestVersion}
              dataDialogOpen={() => this.setState({addDataOpen: true})}
              dataDialogClose={() => this.setState({addDataOpen: false})}
              addDataOpen={addDataOpen}
              updateTest={this.updateTest}
              approveTest={() => approveTest(test)}
              deleteTest={deleteTest}
              assignInspector={assignInspector}
              disabled={disabled || certificate.createdByPortal && isEmpty(certificate.transactions)}
              certificate={certificate}
              hideSign={hideSign}
              productId={productId}
              testVersions={testVersions}
              testVersionIndex={testVersionIndex}
              setTestVersionId={this.setTestVersionIndex}
              shareLink={shareLink}
            />
          )}
        </div>
        {timeline && (
          <TimelineBlock
            isLast={isLast}
            isFirst={isFirst}
            test={currentTestVersion}
          />
        )}
      </div>
    );
  }
}

NewTestView.defaultProps = {
  onTestUpdate: () => {},
  approveTest: () => {},
  assignInspector: () => {},
  deleteTest: () => {},
  hideSign: false,
  timeline: true,
};

export default compose(
  inject("CertificateStore", "SigningStore", "TestStore", "UserStore", "NotificationStore", "ConditionStore", "CampaignStore", "TimelineStore"),
)(withStyles(observer(NewTestView), styles));
