import React, {useRef, memo} from "react";
import {keys, values} from "ramda";
import {Rect, Layer, Group, RegularPolygon} from "react-konva";
import {isElementOnScreen} from "../../services";
import {TOOLTIP_CONFIG} from "../../constants";

const TOOLTIP_POSITIONS = {
  TOP: "top",
  BOTTOM: "bottom",
  LEFT: "left",    
  RIGHT: "right"
};

const TOOLTIP_POSITIONS_PRIORITY = [TOOLTIP_POSITIONS.TOP, TOOLTIP_POSITIONS.BOTTOM, TOOLTIP_POSITIONS.RIGHT, TOOLTIP_POSITIONS.LEFT];

const TooltipContainer = ({node = {}, scale, stage, rows}) => {
  const layer = useRef(null);
  const tooltips = useRef({});

  const pointerHeight = TOOLTIP_CONFIG.POINTER_RADIUS / 2;
  const paddingInBetweenPointerAndTooltip = 2 * TOOLTIP_CONFIG.PADDING;

  const filledRows = rows.filter(({component}) => values(component.props).every((value) => !!value));

  const contentHeight = filledRows.reduce((acc, {height}) => acc + height, 0) + filledRows.length * TOOLTIP_CONFIG.MARGIN + 2 * TOOLTIP_CONFIG.PADDING;

  const tooltipCoordinatesByPosition = {
    [TOOLTIP_POSITIONS.TOP]: {
      y: node.y / scale - contentHeight - paddingInBetweenPointerAndTooltip,
      x: node.x / scale - TOOLTIP_CONFIG.WIDTH / 2,
    },
    [TOOLTIP_POSITIONS.BOTTOM]: {
      y : node.y / scale + paddingInBetweenPointerAndTooltip,
      x: node.x / scale - TOOLTIP_CONFIG.WIDTH / 2,
    },
    [TOOLTIP_POSITIONS.LEFT]: {
      y: node.y / scale - contentHeight / 2,
      x: node.x / scale - TOOLTIP_CONFIG.WIDTH - paddingInBetweenPointerAndTooltip,
    },
    [TOOLTIP_POSITIONS.RIGHT]: {
      y: node.y / scale - contentHeight / 2,
      x: node.x / scale + paddingInBetweenPointerAndTooltip,
    },
  };
  
  const pointerCoordinatesByPosition = {
    [TOOLTIP_POSITIONS.TOP]: {
      y: contentHeight + pointerHeight,
      rotation: 180,
      x: TOOLTIP_CONFIG.WIDTH / 2
    },
    [TOOLTIP_POSITIONS.BOTTOM]: {
      y: -pointerHeight,
      rotation: 0,
      x: TOOLTIP_CONFIG.WIDTH / 2
    },
    [TOOLTIP_POSITIONS.LEFT]: {
      y: contentHeight / 2,
      rotation: 90,
      x: TOOLTIP_CONFIG.WIDTH + pointerHeight
    },
    [TOOLTIP_POSITIONS.RIGHT]: {
      y: contentHeight / 2,
      rotation: 270,
      x: 0 - pointerHeight
    },
  };

  const visibleTooltip = TOOLTIP_POSITIONS_PRIORITY.find((position) => {
    if(!tooltips.current[position]) return false;

    return isElementOnScreen(tooltips.current[position], stage.current);
  });

  return (
    <Layer
      listening={false}
      ref={layer}
      scale={{x: scale, y: scale}}
    >
      {keys(TOOLTIP_POSITIONS).map((position) => {
        const pointerCoordinates = pointerCoordinatesByPosition[TOOLTIP_POSITIONS[position]];
        const tooltipCoordinates = tooltipCoordinatesByPosition[TOOLTIP_POSITIONS[position]];

        return (
          <Group
            visible={visibleTooltip === TOOLTIP_POSITIONS[position]}
            key={position}
            x={tooltipCoordinates.x}
            y={tooltipCoordinates.y}
          >
            <RegularPolygon
              x={pointerCoordinates.x}
              y={pointerCoordinates.y}
              rotation={pointerCoordinates.rotation}
              sides={3}
              radius={TOOLTIP_CONFIG.POINTER_RADIUS}
              fill={TOOLTIP_CONFIG.BACKGROUND_COLOR}
              strokeWidth={TOOLTIP_CONFIG.STROKE_WIDTH}
              stroke={TOOLTIP_CONFIG.STROKE_COLOR}
            />
            <Rect
              ref={(ref) => tooltips.current[TOOLTIP_POSITIONS[position]] = ref}
              fill={TOOLTIP_CONFIG.BACKGROUND_COLOR}
              width={TOOLTIP_CONFIG.WIDTH}
              height={contentHeight}
              strokeWidth={TOOLTIP_CONFIG.STROKE_WIDTH}
              stroke={TOOLTIP_CONFIG.STROKE_COLOR}
              cornerRadius={TOOLTIP_CONFIG.RADIUS}
            />
            <Group
              x={TOOLTIP_CONFIG.PADDING}
              y={TOOLTIP_CONFIG.PADDING}
            >
              {filledRows.map(({component}, index) => {
                const height = filledRows.slice(0, index).reduce((acc, {height}) => acc + height, 0);
                const marginsHeight = TOOLTIP_CONFIG.MARGIN * index;

                return (
                  <Group
                    key={index}
                    y={height + marginsHeight}
                  >
                    {component}
                  </Group>
                );
              })}
            </Group>
          </Group>
        );
      })}
    </Layer>
  );
};

export default memo(TooltipContainer);