import React from "react";
import {withStyles} from "tss-react/mui";
import {observer} from "mobx-react-lite";
import {TestPlot} from "@core/components/Chart";
import ResizableDrawer from "@core/components/ResizableDrawer";
import DrawerHeader from "@core/components/DrawerHeader";
import TestMarker from "@core/components/TestMarker";
import testConfig from "@core/configs/test";
import styles from "./styles";

const AnalyticsSlider = ({classes, open, close, tests}) => {
  const [test] = tests;

  const type = test?.type || "";
  const displayName = test?.displayName || "";

  const color = type ? testConfig[type].color : "";

  return (
    <ResizableDrawer
      open={open}
      close={close}
      paperClasses={{root: classes.container}}
    >
      <DrawerHeader close={close} />
      <div
        className={classes.graphContainer}
        style={{borderColor: color}}
      >
        <TestMarker
          test={{type, displayName}}
          disabled
        />
        <div className={classes.content}>
          <TestPlot
            testConfig={type ? testConfig[type] : {plots: []}}
            tests={tests}
          />
        </div>
      </div>
    </ResizableDrawer>
  );
};

export default withStyles(observer(AnalyticsSlider), styles);