import React from "react";
import {Text} from "react-konva";
import {PIPE_WITH_TYPE_CONFIG, STAGE_CONFIG} from "../../../../../../constants";
import {TOOLTIP_CONFIG, CONTENT_WIDTH} from "../../../../constants";

const PipeType = ({type, baseMaterialsTypes = []}) => {
  if(!type) return null;

  return (
    <Text
      width={CONTENT_WIDTH}
      align="left"
      text={type}
      fill={PIPE_WITH_TYPE_CONFIG.BACKGROUND_COLORS[baseMaterialsTypes.findIndex((t) => t === type)]}
      fontSize={TOOLTIP_CONFIG.FONT_SIZE}
      fontFamily={STAGE_CONFIG.FONT_FAMILY}
      fontStyle="bold"
    />
  );
};

export default PipeType;