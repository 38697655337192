import themeSettings from "../../../../../../../../theme-settings";

export const TIE_IN_WELD_CONFIG = {
  BACKGROUND_COLOR: themeSettings.palette.primary.main,
};

export const LINE_WELD_CONFIG = {
  POINTER_HEIGHT: 10,
  POINTER_WIDTH: 10,
  STROKE_WIDTH: 3,
  FONT_SIZE: 13,
  BACKGROUND_WIDTH: 100,
  RECTANGLE_PADDING: 6,
};

export const PIPE_WITH_COATING_CONFIG = {
  BACKGROUND_COLORS: ["#41C08C", "#008A59"]
};

export const DEFECT_CONFIG = {
  BACKGROUND_COLOR: "#e8833a",
  WIDTH: 10,
  HEIGHT: 55,
  RADIUS: 5,
};

export const MARGIN = 7;

export const SINGLE_RED_BAND_PIPE_CONFIG = {
  BACKGROUND_COLOR: "#d3465c"
};

export const DOUBLE_RED_BAND_PIPE_CONFIG = {
  BACKGROUND_COLOR: "#d3465c"
};

export const BLACK_BANDED_ANODE_PIPE_CONFIG = {
  BACKGROUND_COLOR: "#000000"
};

export const TRANSITION_PIPE_CONFIG = {
  BACKGROUND_COLOR: "#016fbb"
};

export const QUARANTINE_PIPE_CONFIG = {
  BACKGROUND_COLOR: "#f93442"
};

export const BASE_MATERIAL_PIPE_CONFIG = {
  BACKGROUND_COLOR: "#1cae9f",
};

export const REPAIRED_WELD_CONFIG = {
  BACKGROUND_COLOR: "#ffd12c",
};

export const SEQUENCE_NUMBER_EVERY = 10;

export const STALK_NUMBER_WIDTH = 250;