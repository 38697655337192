import React from "react";
import {Text} from "react-konva";
import {STAGE_CONFIG} from "../../../../../../constants";
import {TOOLTIP_CONFIG, CONTENT_WIDTH} from "../../../../constants";

const CoatingType = ({coatingTypes = []}) => {
  if(!coatingTypes.length) return null;

  return (
    <Text
      width={CONTENT_WIDTH}
      align="left"
      text={coatingTypes.join("/")}
      fill={TOOLTIP_CONFIG.TEXT_COLOR}
      fontSize={TOOLTIP_CONFIG.FONT_SIZE}
      fontFamily={STAGE_CONFIG.FONT_FAMILY}
      fontStyle="bold"
    />
  );
};

export default CoatingType;