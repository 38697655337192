import CollapsibleTable from "../../../../CollapsibleTable";
import {useFormikContext} from "formik";
import React from "react";
import {getSurface} from "@core/helpers";

const Dimensions = ({editable}) => {
  const {setFieldValue, values} = useFormikContext();

  return (
    <CollapsibleTable
      editable={editable}
      title="Dimensions"
      fields={[{
        name: "thickness",
        label: "Thickness",
        type: "number",
        endAdornment: "mm",
        inputProps: {min: 0},
        onChange: (value) => setFieldValue("surface", getSurface(value, values.weight, values.length)),
        required: true,
      }, {
        name: "weight",
        label: "Weight",
        type: "number",
        endAdornment: "kg",
        inputProps: {min: 0},
        onChange: (value) => setFieldValue("surface", getSurface(values.thickness, value, values.length)),
        required: true,
      }, {
        name: "length",
        label: "Length",
        type: "number",
        endAdornment: "mm",
        inputProps: {min: 0},
        onChange: (value) => setFieldValue("surface", getSurface(values.thickness, values.weight, value)),
        required: true,
      }, {
        name: "surface",
        label: "Surface",
        type: "number",
        endAdornment: "cm^2",
        inputProps: {min: 0},
        disabled: true,
        required: true,
      }, {
        name: "heatTreatment",
        label: "Heat Treatment",
      }, {
        name: "processingTreatment",
        label: "Processing Treatment",
      }]}
    />
  );
};

export default Dimensions;