import React, {useEffect, useRef, useState} from "react";
import {Box} from "@mui/material";

const ListAndDateContainer = ({children, popoverOpen}) => {
  const listAndDateRef = useRef();
  const [spaceToBottom, setSpaceToBottom] = useState(null);
  const popoverOpenRef = useRef(popoverOpen);

  useEffect(() => {
    popoverOpenRef.current = popoverOpen;
  }, [popoverOpen]);

  useEffect(() => {
    let initialSetWasDone = false;
    function setSpaceToBottomDimension() {
      const element = listAndDateRef.current;

      if (initialSetWasDone && (!element || popoverOpenRef.current)) {

        return;
      }

      // Get the element's bounding rectangle
      const rect = element.getBoundingClientRect();

      // Calculate space to the bottom
      const remainingSpaceInPx = window.innerHeight - rect.bottom + window.scrollY;
      setSpaceToBottom(remainingSpaceInPx);
    }

    setSpaceToBottomDimension();
    initialSetWasDone = true;
    document.addEventListener("scroll", setSpaceToBottomDimension);
    document.addEventListener("resize", setSpaceToBottomDimension);

    return () => {
      document.removeEventListener("scroll", setSpaceToBottomDimension);
      document.removeEventListener("resize", setSpaceToBottomDimension);
    };
  }, []);

  // 100px stands for dimension of reset filter button & a some offset for better UX
  const maxHeight = spaceToBottom && spaceToBottom - 100 > 0 ?
    `${spaceToBottom - 100}px` :
    undefined;

  return (
    <Box
      overflow="auto"
      ref={listAndDateRef}
      maxHeight={maxHeight}
    >
      {children}
    </Box>
  );
};

export default ListAndDateContainer;