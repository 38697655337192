export default () => ({
  popover: {
    width: 350,
    overflow: "visible"
  },
  input: {
    padding: 10,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0
  }
});