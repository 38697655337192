import QcpSideBar from "@core/components/QcpSideBar";
import TableNavigation from "@core/components/TableNavigation";
import qs from "qs";
import {tail, values} from "ramda";
import React, {useEffect, useMemo, useState} from "react";
import Table from "@core/components/Table";
import TableFooter from "@core/components/TableFooter";
import {STORE_DATA} from "@core/constants/storeDataKeys";
import {setRowsPerPage} from "@core/helpers";
import useFetchTableDataByQueryParam from "@core/hooks/useFetchTableDataByQueryParam";
import {observer} from "mobx-react-lite";
import {Grid, Box, Typography} from "@mui/material";
import {useLocation, useRouteMatch} from "react-router-dom";
import useStores from "../useStores";
import {getColumns} from "./columns";
import {TABS} from "./constants";

const Qcps = () => {
  const [qcpDrawerVisible, setQcpDrawerVisible] = useState(false);
  const [qcpToView, setQcpToView] = useState(null);

  const match = useRouteMatch();
  const location = useLocation();

  const {QcpStore} = useStores();

  const {status, data: qcps, total, fetch} = QcpStore.qcps;

  const currentTab = useMemo(() => qs.parse(tail(location.search)).view || TABS.CURRENT, [location.search]);

  const columns = getColumns(setQcpToView, setQcpDrawerVisible);

  useEffect(() => {
    return () => QcpStore.qcps.reset();
  }, []);

  useFetchTableDataByQueryParam({
    getStore: () => QcpStore,
    dataKey: STORE_DATA.qcps,
    matchPath: match.path,
  });

  const handleRowsPerPageChange = (limit) => {
    setRowsPerPage(match.path, limit);
    QcpStore.qcps.load({limit, offset: 0});
  };

  // const searchAPIDebounced = AwesomeDebouncePromise((search) => {
  //   QcpStore.qcps.load({search, offset: 0});
  // }, 750);

  // const handleSearch = useCallback(async (event) => {
  //   await searchAPIDebounced(event.target.value);
  // }, []);

  return (
    <Box sx={{padding: 4}}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h4" fontSize="1.8rem">
            QCPs/ITPs
          </Typography>
        </Grid>
        {/* <Grid item xs={12}> */}
        {/*   <Input */}
        {/*     name="search" */}
        {/*     endAdornment={ */}
        {/*       <InputAdornment position="start"> */}
        {/*         {status.loading && <CircularProgress size={20} />} */}
        {/*       </InputAdornment> */}
        {/*     } */}
        {/*     placeholder={"Start typing to search"} */}
        {/*     onChange={handleSearch} */}
        {/*   /> */}
        {/* </Grid> */}
        <Grid item xs={12}>
          <TableNavigation
            views={values(TABS)}
            currentView={currentTab}
            link="/qcps"
            query="view"
          />
          <Table
            items={qcps}
            columns={columns}
            isLoaded={status.loaded}
            sort={fetch.sort}
            setSort={(sort) => QcpStore.qcps.load({sort})}
            saveSortToQueryParams
            total={total}
            noDataText="No available qcps."
          >
            <TableFooter
              saveTablePageToQueryParams
              isLoaded={status.loaded}
              items={qcps}
              total={total}
              limit={fetch.limit}
              offset={fetch.offset}
              onOffsetChange={(offset) => QcpStore.qcps.load({offset})}
              onLimitChange={handleRowsPerPageChange}
            />
          </Table>
        </Grid>
      </Grid>
      <QcpSideBar
        open={qcpDrawerVisible}
        close={() => setQcpDrawerVisible(false)}
        qcp={qcpToView}
      />
    </Box>
  );
};

export default observer(Qcps);