import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import React from "react";

const TransverseRuptureStrength = ({qcpConfig}) => {
  const properties = qcpConfig.properties;

  return (
    <>
      <h3>Local Requirements</h3>
      <Table className="styled-table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Transverse Rupture Strength [ksi]</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Min</TableCell>
            <TableCell>{properties.acceptanceCriteria}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default TransverseRuptureStrength;