import React from "react";
import {INTERFACE_TYPE} from "@core/constants/transfer";
import moment from "moment/moment";
import {DATE_FORMAT} from "@core/constants/dateFormats";
import {flatten} from "ramda";
import TestsProgressBar from "@core/components/TestsProgressBar";

const getColumns = (interfaceType, isSorted = true) => {
  const columns = [
    {
      isSorted,
      dataIndex: "orderNumber",
      title: "PO Number",
      render: (transfer) => transfer.orderNumber || "-"
    },
    {
      isSorted,
      dataIndex: "date_created",
      title: "Date of issue",
      render: (transfer) => moment(transfer.date_created).format(DATE_FORMAT) || "-"
    },
    {
      dataIndex: "progressBar",
      title: "Status",
      width: 150,
      render: (transfer) => {
        const certificateTests = flatten(transfer.certificates.map((certificate) => certificate.tests));
        const campaignTests = transfer.campaigns ? flatten(transfer.campaigns.map((campaign) => campaign.tests)) : [];

        const tests = certificateTests.concat(campaignTests) || [];

        return (
          <TestsProgressBar
            isLoaded
            tests={tests}
          />
        );
      },
    },
  ];

  if (interfaceType === INTERFACE_TYPE.SENDER) columns.unshift({
    dataIndex: "receiver",
    title: "Customer",
    render: (transfer) => transfer.receiver?.name || "-"
  });

  if (interfaceType === INTERFACE_TYPE.RECEIVER) columns.unshift({
    isSorted,
    dataIndex: "sender",
    title: "Supplier",
    render: (transfer) => transfer.sender?.name || "-"
  });

  return columns;
};

export {getColumns};