import React, {useCallback, useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {Button, Grid, CircularProgress, Box} from "@mui/material";
import {Input} from "@core/components/Form";
import DrawerHeader from "@core/components/DrawerHeader";
import ResizableDrawer from "@core/components/ResizableDrawer";
import TableNavigation from "@core/components/TableNavigation";
import useFetchTableDataByQueryParam from "@core/hooks/useFetchTableDataByQueryParam";
import {STORE_DATA} from "@core/constants/storeDataKeys";
import {values} from "ramda";
import useStores from "../../../useStores";
import TableFooter from "@core/components/TableFooter";
import Table from "@core/components/Table";
import {columns} from "./columns";
import {TABS} from "./constants";

const SelectBaseMaterialsForm = ({children, setBaseMaterials, baseMaterialFormOpen, setBaseMaterialFormOpen, ...props}) => {
  const [selectedIds, setSelectedIds] = useState(props.selectedIds || []);
  const [tab, setTab] = useState(TABS.SELECTED_BASE_MATERIALS);

  const {SingleProductStore} = useStores();
  
  const {status, data: products, total, fetch} = SingleProductStore.singleProducts;
  
  useEffect(() => {
    return () => SingleProductStore.singleProducts.reset();
  }, []);

  useFetchTableDataByQueryParam({
    getStore: () => SingleProductStore,
    dataKey: STORE_DATA.singleProducts,
  });

  const handleSearch = useCallback(async (event) => {
    await SingleProductStore.singleProducts.load({search: event.target.value, offset: 0});
  }, []);
  
  const onSave = useCallback(() => {
    setBaseMaterials(selectedIds);
    setBaseMaterialFormOpen(false);
  }, [selectedIds.length]);

  return (
    <>
      <ResizableDrawer
        open={baseMaterialFormOpen}
        close={() => setBaseMaterialFormOpen(false)}
        keepMounted={false}
      >
        <DrawerHeader
          close={() => setBaseMaterialFormOpen(false)}
          text="Modify base material(s)"
        />
        <Box sx={{padding: 2}}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Input
                type="text"
                endAdornment={status.loading && <CircularProgress size={20} />}
                placeholder="Search product(s)"
                onChange={handleSearch}
              />
            </Grid>
            <Grid item xs={12}>
              <TableNavigation
                views={values(TABS)}
                currentView={tab}
                onClick={setTab}
              />
              <Table
                setSelectedItems={setSelectedIds}
                selectedItems={selectedIds}
                allowRowSelection
                items={products}
                columns={columns}
                isLoaded={status.loaded}
                sort={fetch.sort}
                setSort={(sort) => SingleProductStore.singleProducts.load({sort})}
                saveSortToQueryParams
                total={total}
                noDataText="No available products."
              >
                <TableFooter
                  saveTablePageToQueryParams
                  isLoaded={status.loaded}
                  items={products}
                  total={total}
                  limit={fetch.limit}
                  offset={fetch.offset}
                  onOffsetChange={(offset) => SingleProductStore.singleProducts.load({offset})}
                  onLimitChange={(limit) => SingleProductStore.singleProducts.load({limit, offset: 0})}
                />
              </Table>
            </Grid>
            <Grid item container justifyContent="space-between">
              <Grid item>
                <Button
                  onClick={() => setBaseMaterialFormOpen(false)}
                  color="secondary"
                  variant="contained"
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={onSave}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </ResizableDrawer>
      {children}
    </>
  );
};

export default observer(SelectBaseMaterialsForm);