import {TAGS} from "@core/constants/tags";
import React, {useState} from "react";
import {Button, Dialog, DialogContent, DialogTitle, Grid} from "@mui/material";
import {MultipleSelect} from "@core/components/Form";

const AddTagsPopup = ({open, close, onSubmit, selectedTags = []}) => {
  const [tags, setTags] = useState(selectedTags);

  const saveTags = async () => {
    await onSubmit(tags);
    close();
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        {tags.length ? "Change tags" : "Add tags"}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MultipleSelect
              label="Tags"
              value={tags}
              elements={TAGS}
              onChange={setTags}
            />
          </Grid>
          <Grid item xs>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={saveTags}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AddTagsPopup;