import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";
import modules from "@core/constants/modules";
import {omitEmptyFields} from "@core/helpers";
import axios from "axios";
import {Formik} from "formik";
import {observer} from "mobx-react-lite";
import React, {useEffect, useMemo, useState} from "react";
import useStores from "../../useStores";
import DefiningStartingMaterialForm from "./components/DefiningStartingMaterialForm";
import UptimeLikeFormLayout from "@core/components/UptimeLikeFormLayout";
import {initialValuesDefault, initialValuesEdit, validationSchema} from "./constants";
import CampaignDetailsFields from "./components/CampaignDetailsFields";
import DefineEndProduct from "./components/DefineEndProduct";
import SubmitForm from "./components/SubmitForm";
import {useParams, useHistory} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import {Grid} from "@mui/material";
import {STATUSES} from "@core/constants/singleMaterialCampaign";

// simulates async API call and retrieves values
const fakeApiRequestAsync = () => new Promise((resolve) => {
  setTimeout(() => {
    resolve(JSON.parse(JSON.stringify(initialValuesEdit)));
  }, 3000);
});

const SingleMaterialCampaignForm = () => {
  const [initialValues, setInitialValues] = useState(initialValuesDefault);

  const {id} = useParams();
  const history = useHistory();
  const editState = useMemo(() => !!id, [id]);
  const [isLoading, setIsLoading] = useState(!!editState);

  const {TransferStore, NotificationStore} = useStores();

  useEffect(() => {
    TransferStore.getTransfersReceivers([modules.PRODUCER, modules.END_OWNER]);

    if (editState) {
      const setValuesAsync = async () => {
        const data = await fakeApiRequestAsync({id});
        setInitialValues(data);
        setIsLoading(false);
      };
      setValuesAsync();
    }
  }, []);

  const createWithProducts  = async (campaign, singleProducts) => {
    const {data} = await axios.post(ROUTES.SINGLE_MATERIAL_CAMPAIGN[ACTIONS.CREATE], {
      singleCampaign: campaign,
      singleProducts
    });

    return data;
  };

  const create = async (campaign) => {
    const {data} = await axios.post(ROUTES.SINGLE_CAMPAIGN[ACTIONS.CREATE], campaign);

    return data;
  };

  const getFormattedData = (data) => {
    const {name, type, certificationType, purchaseOrder, manufacturingLocation, properties} = data;

    return omitEmptyFields({
      name,
      type,
      status: STATUSES.ACTIVE,
      certificationType,
      purchaseOrder: purchaseOrder._id,
      manufacturingLocation,
      properties: {
        product: {
          qcp: properties.product.qcp,
          productType: {
            name: properties.product.productType.name,
            internalId: properties.product.productType.internalName,
            properties: properties.product.productType.properties,
          },
          materialSpecifications: properties.product.materialSpecifications,
          nominalSizes: properties.product.nominalSizes,
        },
        statistics: properties.statistics,
      },
    });
  };

  const onSubmit = async (values) => {
    const data = getFormattedData(values);

    let campaign = {};

    if(values.existingMaterial) {
      campaign = await createWithProducts(data, values.singleProducts);
    } else {
      campaign = await create(data);
    }

    history.push(`/single-material-campaigns/${campaign._id}`);

    NotificationStore.showSuccess("Single product campaign created successfully!");
  };

  return (
    <>
      {
        isLoading ?
          <Grid container justifyContent="center" marginTop={7}>
            <CircularProgress size={48}/>
          </Grid> :
          <Formik
            validateOnMount
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={onSubmit}
          >
            {() => {
              return (
                <UptimeLikeFormLayout
                  title={"Create campaign"}
                  formRows={
                    [
                      {
                        sectionDescription: {
                          title: "Campaign details",
                          text: "A campaign follows the manufacturing of one QCP/ITP item. Connecting a campaign to a PO will enable the customer to track progress."
                        },
                        sectionForm: <CampaignDetailsFields/>
                      },
                      {
                        sectionDescription: {
                          title: "Define starting material situation",
                          text: "Choose if you are creating new material or if you have existing base materials (e.g. plate) that you will convert into a new product (e.g. pipe). To provide a progress estimate, you can estimate to amount to heats you intent to produce."
                        },
                        sectionForm: <DefiningStartingMaterialForm/>
                      },
                      {
                        sectionDescription: {
                          title: "Define end product",
                          text: "Define to which material specification(s) and grade(s) the end product will manufactured including nominal sizes. Choosing 3.2 certification will require all underlying tests to be signed by a third party inspector."
                        },
                        sectionForm: <DefineEndProduct />
                      },
                    ]
                  }
                  formFooter={<SubmitForm />}
                >
                </UptimeLikeFormLayout>
              );
            }}
          </Formik>
      }
    </>
  );
};

export default observer(SingleMaterialCampaignForm);