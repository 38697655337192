import React, {useState, useRef} from "react";
import moment from "moment";
import {observer} from "mobx-react-lite";
import {
  Paper,
  MenuList,
  MenuItem,
  ClickAwayListener,
  Popper,
  Grow,
  IconButton,
  Grid,
  Box,
  Chip,
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import ListIcon from "@mui/icons-material/List";
import TestReportTitle from "@core/components/TestReportTitle";
import Confirmation from "@core/components/Modal/Confirmation/Confirmation";
import Tags from "@core/components/Tags";
import ConfidentialLabel from "@core/components/ConfidentialLabel";
import {Uploader} from "@core/components/Uploaders";
import modules from "@core/constants/modules";
import {STATUSES, TEST_ID_PARAM, TYPES} from "@core/constants/test";
import {openFileInNewTab} from "@core/helpers/openFileInNewTab";
import SetInspectionDatePopup from "./components/SetInspectionDatePopup";
import AddWitnessesPopup from "./components/AddWitnessesPopup";
import AddTagsPopup from "./components/AddTagsPopup";
import {DATE_FORMAT} from "@core/constants/dateFormats";
import styles from "./styles";
import useStores from "../../../useStores";
import qs from "qs";

const Tag = ({title, value}) => {
  if(!value) return null;

  return (
    <Grid item>
      <Chip
        size="small"
        label={`${title}: ${value}`}
        sx={{
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          marginRight: (theme) => theme.spacing(1),
          color: (theme) => theme.palette.deactivate.text,
          backgroundColor: (theme) => theme.palette.deactivate.backgroundColor
        }}
      />
    </Grid>
  );
};

const TestMarker = observer(({
  deleteTest,
  updateTest,
  test = {},
  disabled,
  testVersions,
  shareLink,
  classes
}) => {
  const {UserStore, CertificateStore, NotificationStore} = useStores();

  const [settingsOpen, setSettingsOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [dateModalOpen, setDateModalOpen] = useState(false);
  const [addWitnessesOpen, setAddWitnessesOpen] = useState(false);
  const [changeConfidentialOpen, setChangeConfidentialOpen] = useState(false);
  const [addTagsOpen, setAddTagsOpen] = useState(false);
      
  const anchorEl = useRef(null);

  const onDelete = async () => {
    setDeleteConfirmationOpen(false);
    await deleteTest(test._id);
  };

  const onChangeConfidential = async () => {
    await updateTest(test.properties, {confidential: !test.confidential});
    NotificationStore.showSuccess("Successfully updated!");
    setChangeConfidentialOpen(false);
  };

  const user = UserStore.user.data;
  const [module] = user.company.modules;
  const isProducer = module.name === modules.PRODUCER;

  const certificate = CertificateStore.certificate.data;
  const lastSplit = (!disabled && isProducer && certificate.tests) ? certificate.tests.find((test) => test.type === "split_certificate") : null;

  const isCreator = user.company._id === test.company?._id;

  const shareTestUrl = () => {
    const paramsRaw = location.href.split("?")[1];
    let urlToShare;

    if (paramsRaw) {
      const params = qs.parse(paramsRaw);
      params[TEST_ID_PARAM] = test._id;
      urlToShare = location.href.split("?")[0] + "?" + qs.stringify(params);
    } else {
      const lastCharacterIndex = location.href.length - 1;
      const currentUrl = location.href[lastCharacterIndex] === "/" ? location.href.slice(0, lastCharacterIndex) : location.href;

      urlToShare = currentUrl + "?" + qs.stringify({[TEST_ID_PARAM]: test._id});
    }

    navigator.clipboard.writeText(urlToShare);
    NotificationStore.showSuccess("Link copied to clipboard!");
    setSettingsOpen(false);
  };

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.typeIndicator}>
          <h1>
            <TestReportTitle
              test={test}
              testVersions={testVersions}
            />
          </h1>
          {!!test.properties?.tags?.length && (
            <Box sx={{marginLeft: 1}}>
              <Tags
                tags={test.properties.tags}
              />
            </Box>
          )}
          {(test.confidential && test.status !== STATUSES.APPROVED) && (
            <ConfidentialLabel />
          )}
        </div>
        <div className={classes.properties}>
          <div className={classes.tagsContainer}>
            {test.item_heat && (
              <Tag
                title="Heat"
                value={test.item_heat}
              />
            )}
            {test.lotId && (
              <Tag
                title="Lot ID"
                value={test.lotId}
              />
            )}
            {test.internalWorkOrder && (
              <Tag
                title="Work Order"
                value={test.internalWorkOrder}
              />
            )}
            {test.internalItemNumber && (
              <Tag
                title="Item No."
                value={test.internalItemNumber}
              />
            )}
            {test.inspectorJobNumber && (
              <Tag
                title="Inspector Job Number"
                value={test.inspectorJobNumber}
              />
            )}
            {!test.inspectionWaivedDocument && (
              <Tag
                title="Inspection Date"
                value={test.inspectionDate ? moment(test.inspectionDate).format(DATE_FORMAT) : null}
              />
            )}
            {test.productType && (
              <Tag
                title="Product type"
                value={test.productType.name ? test.productType.name : null}
              />
            )}
          </div>
          {(!disabled || shareLink) && (
            <div>
              <IconButton
                ref={anchorEl}
                onClick={() => setSettingsOpen(!settingsOpen)}
                size="large">
                <ListIcon />
              </IconButton>
            </div>
          )}
        </div>
      </div>
      <Popper
        style={{zIndex: 1300}}
        open={settingsOpen}
        anchorEl={anchorEl.current}
        transition
        disablePortal={false}
        placement="bottom-end"
      >
        {({TransitionProps}) => (
          <Grow
            {...TransitionProps}
            id="menu-list-grow"
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setSettingsOpen(false)}>
                <MenuList>
                  {(!disabled && test.type !== TYPES.SPLIT && test.status !== STATUSES.APPROVED && (!test.company || test.company._id === user.company._id)) && (
                    <MenuItem onClick={() => setChangeConfidentialOpen(true)}>
                      {test.confidential ? "Make non confidential" : "Make confidential"}
                    </MenuItem>
                  )}
                  {(!disabled && test.status === STATUSES.FILLED) &&  (
                    <MenuItem onClick={() => setAddWitnessesOpen(true)}>
                      {test.witnesses.length ? "Change witnesses" : "Add witnesses"}
                    </MenuItem>
                  )}
                  {!!(!disabled && test.witnesses.length && !test.inspectionWaivedDocument) && (
                    <>
                      {![STATUSES.APPROVED, STATUSES.INSPECTED].includes(test.status) && (
                        <MenuItem onClick={() => setDateModalOpen(true)}>
                          {test.inspectionDate ? "Change inspection date" : "Set inspection date"}
                        </MenuItem>
                      )}
                      {[STATUSES.INSPECTING, STATUSES.FILLED, STATUSES.SUBMITTED].includes(test.status) && (
                        <MenuItem>
                          <Uploader
                            fileType="image/*, application/pdf, .csv"
                            file={null}
                            isRelatedToInspection
                            buttonText="Waive inspection"
                            alertText="You are going to waive the inspection, are you sure?"
                            handleUploadedFile={async (event) => {
                              await updateTest({...test.properties, inspectionWaivedDocument: event.file.dir + event.file.name});
                              NotificationStore.showSuccess("Successfully updated!");
                            }}
                          />
                        </MenuItem>
                      )}
                    </>
                  )}
                  {test.inspectionWaivedDocument && (
                    <MenuItem onClick={() => openFileInNewTab(test.inspectionWaivedDocument)}>
                        Download inspection file
                    </MenuItem>
                  )}
                  <MenuItem onClick={() => setAddTagsOpen(true)}>
                    {test.properties.tags?.length ? "Change tags" : "Add tags"}
                  </MenuItem>
                  {shareLink && (
                    <MenuItem onClick={shareTestUrl}>Share link</MenuItem>
                  )}
                  {(!disabled && isCreator && (!lastSplit || test.date_created > lastSplit.date_created) && test.type !== "split_certificate") && (
                    <MenuItem
                      onClick={() => setDeleteConfirmationOpen(true)}
                      className={classes.removeButton}
                    >
                        Remove
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Confirmation
        open={deleteConfirmationOpen}
        alertText="Are you sure you want to delete this test?"
        onCancel={() => setDeleteConfirmationOpen(false)}
        onConfirm={onDelete}
      />
      <Confirmation
        open={changeConfidentialOpen}
        alertText={test.confidential ?
          "Are you sure you want to make this test non confidential? Non confidential tests are visible to every project party." :
          "Are you sure you want to make this test confidential? Confidential tests are only visible to you until they've been fully approved."
        }
        onCancel={() => setChangeConfidentialOpen(false)}
        onConfirm={onChangeConfidential}
      />
      <SetInspectionDatePopup
        open={dateModalOpen}
        close={() => setDateModalOpen(false)}
        inspectionDate={test.inspectionDate}
        onSubmit={(inspectionDate) => updateTest({...test.properties, inspectionDate})}
      />
      <AddWitnessesPopup
        open={addWitnessesOpen}
        close={() => setAddWitnessesOpen(false)}
        witnesses={test.witnesses || []}
        onSubmit={(witnesses) => updateTest(test.properties, {witnesses})}
      />
      <AddTagsPopup
        open={addTagsOpen}
        close={() => setAddTagsOpen(false)}
        onSubmit={(tags) => updateTest({...test.properties, tags})}
        selectedTags={test.properties?.tags}
      />
    </div>
  );
});

export default withStyles(TestMarker, styles);
