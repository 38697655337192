import React, {useEffect} from "react";
import {observer} from "mobx-react";
import Certificate from "@core/components/DisabledCertifcate";
import {useRouteMatch} from "react-router-dom";
import useStores from "../../useStores";

const OutgoingCertificate = () => {
  const {TransferStore} = useStores();

  const match = useRouteMatch();

  const {id: transferId, certificateId} = match.params;
  const {data: certificate, isLoaded} = TransferStore.certificate;

  useEffect(() => {
    TransferStore.getTransferCertificateById(transferId, certificateId);
  }, []);

  return (
    <Certificate
      certificate={certificate}
      isLoaded={isLoaded}
    />
  );
};

export default observer(OutgoingCertificate);
