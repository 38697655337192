import React, {useState, useEffect} from "react";
import {sort} from "ramda";
import {Box, Checkbox, Collapse, List, ListItem} from "@mui/material";
import {withStyles} from "tss-react/mui";
import IconistsIcon from "@core/components/IconistsIcon";
import styles from "./styles";
import FilterDropdownItemText from "@core/components/FilterDropdownItemText";
import {ICONISTS_ICONS} from "@core/constants/iconistsIcons";

const FilterType = ({classes, name, options, filters, setFilters, filterBy}) => {
  const [open, setOpen] = useState(false);

  const selectedOptions = filters[name] || [];
  const filteredOptions = options.filter((o) => o.toLowerCase().includes(filterBy.toLowerCase()));
  const sortedOptions = sort((itemA, itemB) => itemA.localeCompare(itemB, undefined, {numeric: true}), filteredOptions);

  useEffect(() => {
    if(filterBy && filteredOptions.length) setOpen(true);
    else setOpen(false);
  }, [filterBy]);

  const onClick = (option) => {
    let newFilters = {};

    if(selectedOptions.includes(option)) {
      const value = filters[name].filter((o) => o !== option);
      newFilters = {...filters, [name]: value};
    } else {
      newFilters = {...filters, [name]: [...(filters[name] || []), option]};
    }

    setFilters(newFilters);
  };

  if(!filteredOptions.length) return null;

  return (
    <>
      <ListItem
        button
        key={name}
        onClick={() => setOpen(!open)}
        sx={filters[name]?.length ? {color: (theme) => theme.palette.primary.main} : undefined}
      >
        <FilterDropdownItemText
          text={name}
          sxPrimary={
            filters[name]?.length ?
              {color: (theme) => theme.palette.primary.main} :
              {}
          }
        />
        <Box
          sx={{
            transition: "all 0.3s",
            transform: open ? "rotate(180deg)" : "rotate(0)"
          }}
        >
          <IconistsIcon icon={ICONISTS_ICONS.chevronBottom} width="18px"/>
        </Box>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          className={classes.optionsList}
        >
          {sortedOptions.map((option) => (
            <ListItem
              button
              key={option}
              classes={{root: classes.listItemCheckbox}}
              onClick={() => onClick(option)}
            >
              <Checkbox
                checked={selectedOptions.includes(option)}
                value={option}
                size="small"
                color="primary"
              />
              <FilterDropdownItemText text={option} fontSize="0.86rem"/>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default withStyles(FilterType, styles);