import {TEST_RESULTS} from "@core/constants/testResults";

export function getAcceptanceResult(values) {
  const acceptanceCriteria = values.acceptanceCriteria;
  const stressAtBreak = values.stressAtBreak;
  const isAccepted = stressAtBreak.every(
    ({x, y, z}) => x >= acceptanceCriteria && y >= acceptanceCriteria && z >= acceptanceCriteria
  );

  return isAccepted ? TEST_RESULTS.ACCEPTABLE : TEST_RESULTS.NOT_ACCEPTABLE;
}
