export default (theme) => ({
  listItemCheckbox: {
    paddingTop: 0,
    paddingBottom: 0,
    margin: 0
  },
  selectedListItem: {
    color: theme.palette.primary.main,
  },
  optionsList: {
    maxHeight: 300,
    overflowX: "auto",
  }
});