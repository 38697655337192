import {FEATURES} from "@core/constants/features";
import modules from "@core/constants/modules";
import roles from "@core/constants/roles";
import {ICONISTS_ICONS} from "@core/constants/iconistsIcons";

const PRODUCER_MENU_GROUPS = [{
  title: null,
  items: [
    {
      exact: true,
      linkTo: "/",
      title: "Dashboard",
      icon: ICONISTS_ICONS.dotGrid,
    },
    {
      linkTo: "/projects",
      title: "Projects",
      icon: ICONISTS_ICONS.pumpjack,
    },
  ],
}, {
  title: "Suppliers",
  items: [
    {
      linkTo: "/orders",
      title: "Orders",
      icon: ICONISTS_ICONS.folderPaper,
      items: [{
        linkTo: "/orders",
        title: "Overview",
        icon: ICONISTS_ICONS.squareChecklist,
      }, {
        linkTo: "/campaigns",
        title: "Campaigns",
        icon: ICONISTS_ICONS.target,
        requiredFeatures: [FEATURES.CAMPAIGN],
      }, {
        linkTo: "/single-material-campaigns",
        title: "Single material campaigns",
        icon: ICONISTS_ICONS.target,
        requiredFeatures: [FEATURES.SINGLE_MATERIAL_CAMPAIGN],
      }]
    },
    {
      linkTo: "/certificates",
      title: "Certificates",
      icon: ICONISTS_ICONS.sketchbook,
      items: [
        {
          linkTo: "/certificates",
          title: "Overview",
          icon: ICONISTS_ICONS.squareChecklist,
        },{
          linkTo: "/certificates/archive",
          title: "Archive",
          icon: ICONISTS_ICONS.archive
        },
        {
          linkTo: "/certificates/testParsing",
          title: "Parse file",
          icon: ICONISTS_ICONS.pageTextSearch,
          requiredFeatures: [FEATURES.PARSE_CSV, FEATURES.PARSE_XLS],
        }]
    },
    {
      linkTo: "/tests",
      title: "Tests",
      icon: ICONISTS_ICONS.clipboard,
      items: [{
        linkTo: "/tests",
        title: "Overview",
        icon: ICONISTS_ICONS.squareChecklist,
      },{
        linkTo: "/tests/multiSigning",
        title: "Multiple Test Signing",
        icon: ICONISTS_ICONS.signature
      }]
    },
  ],
}, {
  title: "Customers",
  items: [
    {
      linkTo: "/transfers",
      title: "Orders",
      icon: ICONISTS_ICONS.folderPaper,
    },
  ]
}, {
  title: null,
  items: [
    {
      linkTo: "/products",
      title: "Stock",
      icon: ICONISTS_ICONS.playground,
      requiredFeatures: [FEATURES.SINGLE_MATERIAL_CAMPAIGN],
    },
    {
      linkTo: "/specifications",
      title: "Specifications",
      icon: ICONISTS_ICONS.settingsSliderThree,
      belongsToRole: [roles.ADMIN],
      items: [{
        linkTo: "/specifications",
        title: "Overview",
        icon: ICONISTS_ICONS.squareChecklist,
      }, {
        linkTo: "/qcps",
        title: "QCPs/ITPs",
        icon: ICONISTS_ICONS.squareChecklistMagnifyingGlass,
        requiredFeatures: [FEATURES.QCP],
      }]
    },
  ],
}];

const BASIC_MENU_GROUPS = [{
  title: null,
  items: [
    {
      exact: true,
      linkTo: "/",
      title: "Dashboard",
      icon: ICONISTS_ICONS.dotGrid,
    },
    {
      linkTo: "/projects",
      title: "Projects",
      icon: ICONISTS_ICONS.pumpjack,
    },
  ],
}, {
  title: "Suppliers",
  items: [
    {
      linkTo: "/orders",
      title: "Orders",
      icon: ICONISTS_ICONS.folderPaper,
    },
    {
      linkTo: "/certificates",
      title: "Certificates",
      icon: ICONISTS_ICONS.sketchbook,
      items: [
        {
          linkTo: "/certificates",
          title: "Overview",
          icon: ICONISTS_ICONS.squareChecklist,
        },{
          linkTo: "/certificates/archive",
          title: "Archive",
          icon: ICONISTS_ICONS.archive
        },
        {
          linkTo: "/certificates/testParsing",
          title: "Parse file",
          icon: ICONISTS_ICONS.pageTextSearch,
          requiredFeatures: [FEATURES.PARSE_CSV, FEATURES.PARSE_XLS],
        }]
    },
  ],
}, {
  title: "Customers",
  items: [
    {
      linkTo: "/transfers",
      title: "Orders",
      icon: ICONISTS_ICONS.folderPaper,
    },
  ]
}, {
  title: null,
  items: [
    {
      linkTo: "/specifications",
      title: "Specifications",
      icon: ICONISTS_ICONS.settingsSliderThree,
      belongsToRole: [roles.ADMIN],
      items: [{
        linkTo: "/specifications",
        title: "Overview",
        icon: ICONISTS_ICONS.squareChecklist,
      }, {
        linkTo: "/qcps",
        title: "QCPs/ITPs",
        icon: ICONISTS_ICONS.squareChecklistMagnifyingGlass,
        requiredFeatures: [FEATURES.QCP],
      }]
    },
  ],
}];

const LAB_MENU_GROUPS = [{
  title: null,
  items: [
    {
      exact: true,
      linkTo: "/",
      title: "Dashboard",
      icon: ICONISTS_ICONS.dotGrid,
    },
  ],
}, {
  title: "Customers",
  items: [
    {
      linkTo: "/tests",
      title: "Tests",
      icon: ICONISTS_ICONS.clipboard,
      items: [{
        linkTo: "/tests",
        title: "Overview",
        icon: ICONISTS_ICONS.squareChecklist,
      }, {
        linkTo: "/tests/multiSigning",
        title: "Multiple Test Signing",
        icon: ICONISTS_ICONS.signature
      }, {
        linkTo: "/tests/testParsing",
        title: "Parse file",
        icon: ICONISTS_ICONS.pageTextSearch,
        requiredFeatures: [FEATURES.PARSE_CSV, FEATURES.PARSE_XLS],
      }]
    },
  ]
}, {
  title: null,
  items: [
    {
      linkTo: "/instruments",
      title: "Instruments",
      icon: ICONISTS_ICONS.toolbox,
    },
  ],
}];

const WITNESS_MENU_GROUPS = [{
  title: null,
  items: [
    {
      exact: true,
      linkTo: "/",
      title: "Dashboard",
      icon: ICONISTS_ICONS.dotGrid,
    },
  ],
}, {
  title: "Customers",
  items: [
    {
      linkTo: "/campaigns",
      title: "Campaigns",
      icon: ICONISTS_ICONS.target,
      requiredFeatures: [FEATURES.CAMPAIGN],
    },
    {
      linkTo: "/single-material-campaigns",
      title: "Single material campaigns",
      icon: ICONISTS_ICONS.target,
      requiredFeatures: [FEATURES.SINGLE_MATERIAL_CAMPAIGN],
    },
    {
      linkTo: "/witness",
      title: "Reports",
      icon: ICONISTS_ICONS.fileText,
      items: [
        {
          linkTo: "/witness",
          title: "Overview",
          icon: ICONISTS_ICONS.squareChecklist,
        },
        {
          linkTo: "/witness/multiSigning",
          title: "Multiple Test Signing",
          icon: ICONISTS_ICONS.signature
        }
      ],
    }
  ]
}];

const END_OWNER_MENU_GROUPS = [{
  title: null,
  items: [
    {
      exact: true,
      linkTo: "/",
      title: "Dashboard",
      icon: ICONISTS_ICONS.dotGrid,
    },
    {
      linkTo: "/projects",
      title: "Projects",
      icon: ICONISTS_ICONS.pumpjack,
    },
  ],
}, {
  title: "Suppliers",
  items: [
    {
      linkTo: "/orders",
      title: "Orders",
      icon: ICONISTS_ICONS.folderPaper,
      items: [{
        linkTo: "/orders",
        title: "Overview",
        icon: ICONISTS_ICONS.squareChecklist,
      }, {
        linkTo: "/campaigns",
        title: "Campaigns",
        icon: ICONISTS_ICONS.target,
        requiredFeatures: [FEATURES.CAMPAIGN],
      }, {
        linkTo: "/single-material-campaigns",
        title: "Single material campaigns",
        icon: ICONISTS_ICONS.target,
        requiredFeatures: [FEATURES.SINGLE_MATERIAL_CAMPAIGN],
      }]
    },
    {
      linkTo: "/certificates",
      title: "Certificates",
      icon: ICONISTS_ICONS.sketchbook,
      items: [
        {
          linkTo: "/certificates",
          title: "Overview",
          icon: ICONISTS_ICONS.squareChecklist,
        },{
          linkTo: "/certificates/archive",
          title: "Archive",
          icon: ICONISTS_ICONS.archive
        },
        {
          linkTo: "/certificates/testParsing",
          title: "Parse file",
          icon: ICONISTS_ICONS.pageTextSearch,
          requiredFeatures: [FEATURES.PARSE_CSV, FEATURES.PARSE_XLS],
        }]
    },
    {
      linkTo: "/tests",
      title: "Tests",
      icon: ICONISTS_ICONS.clipboard,
      items: [{
        linkTo: "/tests",
        title: "Overview",
        icon: ICONISTS_ICONS.squareChecklist,
      },{
        linkTo: "/tests/multiSigning",
        title: "Multiple Test Signing",
        icon: ICONISTS_ICONS.signature
      }]
    },
  ],
}, {
  title: null,
  items: [
    {
      linkTo: "/products",
      title: "Stock",
      icon: ICONISTS_ICONS.playground,
      requiredFeatures: [FEATURES.SINGLE_MATERIAL_CAMPAIGN],
    },
    {
      linkTo: "/specifications",
      title: "Specifications",
      icon: ICONISTS_ICONS.settingsSliderThree,
      belongsToRole: [roles.ADMIN],
      items: [{
        linkTo: "/specifications",
        title: "Overview",
        icon: ICONISTS_ICONS.squareChecklist,
      }, {
        linkTo: "/qcps",
        title: "QCPs/ITPs",
        icon: ICONISTS_ICONS.squareChecklistMagnifyingGlass,
        requiredFeatures: [FEATURES.QCP],
      }]
    },
  ],
}];

const SUPER_ADMIN_MENU_GROUPS = [{
  title: null,
  items: [
    {
      exact: true,
      linkTo: "/",
      title: "Dashboard",
      icon: ICONISTS_ICONS.dotGrid,
    },
    {
      linkTo: "/metrics",
      title: "Metrics",
      icon: ICONISTS_ICONS.stocks
    },
    {
      linkTo: "/admin",
      title: "Admin",
      icon: ICONISTS_ICONS.preferences,
      items: [{
        linkTo: "/admin",
        title: "Overview",
        icon: ICONISTS_ICONS.squareChecklist,
      }, {
        linkTo: "/admin/logs",
        title: "Logs",
        icon: ICONISTS_ICONS.thread
      }],
    },
    {
      linkTo: "/companies",
      title: "Companies",
      icon: ICONISTS_ICONS.buildings
    },
    {
      linkTo: "/users",
      title: "Users",
      icon: ICONISTS_ICONS.group
    }
  ],
}];

export const MENU_BY_MODULE = {
  [modules.PRODUCER]: PRODUCER_MENU_GROUPS,
  [modules.BASIC]: BASIC_MENU_GROUPS,
  [modules.LAB_INTERNAL]: LAB_MENU_GROUPS,
  [modules.WITNESS]: WITNESS_MENU_GROUPS,
  [modules.END_OWNER]: END_OWNER_MENU_GROUPS,
  [modules.SUPER_ADMIN]: SUPER_ADMIN_MENU_GROUPS
};

export const SETTINGS_ITEM =  {
  linkTo: "/preferences",
  title: "Settings",
  icon: ICONISTS_ICONS.settingsGear,
  belongsToModules: [modules.PRODUCER, modules.BASIC, modules.END_OWNER],
  items: [
    {
      linkTo: "/preferences",
      title: "Product Types",
      icon: ICONISTS_ICONS.shapesPlusXSquareCircle,
    },
    {
      linkTo: "/preferences/statements",
      title: "Statements",
      icon: ICONISTS_ICONS.pageText,
    },
    {
      linkTo: "/preferences/conditions",
      title: "Conditions",
      icon: ICONISTS_ICONS.around,
    }
  ]
};

export const ACCOUNT_ITEM = {
  linkTo: "/account",
  title: "Account",
  icon: ICONISTS_ICONS.people,
};

export const COMPANY_ITEM = {
  linkTo: "/admin",
  title: "Company",
  icon: ICONISTS_ICONS.building,
  items: [{
    linkTo: "/admin",
    title: "Overview",
    icon: ICONISTS_ICONS.squareChecklist,
  },
  {
    linkTo: "/admin/users",
    title: "Users",
    icon: ICONISTS_ICONS.group
  },
  {
    linkTo: "/admin/external-users",
    title: "External users",
    icon: ICONISTS_ICONS.group
  }]
};

export const MIN_SCREEN_WIDTH = 1200;
export const COLLAPSED_MENU_WIDTH = 56;
export const EXPANDED_MENU_WIDTH = 210;