import React, {useState} from "react";
import {Grid, Paper, Switch} from "@mui/material";
import {observer} from "mobx-react-lite";
import {withStyles} from "tss-react/mui";
import TestsProgressBar from "@core/components/TestsProgressBar";
import TestSummaryTable from "@core/components/TestSummaryTable";
import TestTitleNotes from "../TestTitleNotes";
import styles from "./styles";
import {useParams} from "react-router-dom";
import useStores from "../../../../useStores";
import {CAMPAIGN_TYPES} from "@core/constants/campaign";

const VIEWS = {
  COMPACT: "COMPACT",
  DETAILED: "DETAILED",
};

const CampaignProgress = observer(({classes, onTestClick, tests, label, setFilteredTestIds}) => {
  const [view, setView] = useState(VIEWS.COMPACT);
  const {CampaignStore} = useStores();

  const {id: campaignId} = useParams();
  const campaign = CampaignStore.campaign;

  const isLoaded = campaignId === campaign._id;

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <h3>{label}</h3>
        </Grid>
        <Grid item>
          Compact View
          <Switch
            color="primary"
            checked={view === VIEWS.DETAILED}
            onChange={(event) => setView(event.target.checked ? VIEWS.DETAILED : VIEWS.COMPACT)}
          />
          Detailed View
        </Grid>
      </Grid>
      <div className={classes.progressBar}>
        <TestsProgressBar
          isLoaded={isLoaded}
          tests={tests}
        />
      </div>
      <Paper className={classes.paper}>
        <TestSummaryTable
          isLoaded={isLoaded}
          setFilteredTestIds={setFilteredTestIds}
          onTestClick={onTestClick}
          tests={tests}
          withGrouping={view === VIEWS.COMPACT}
          TitleNotes={campaign.type === CAMPAIGN_TYPES.WELDING ? TestTitleNotes : null}
        />
      </Paper>
    </>
  );
});

export default withStyles(CampaignProgress, styles);