import {useLocation} from "react-router-dom";
import {useEffect, useMemo, useRef} from "react";
import qs from "qs";

function useSwitchTableView({getStore, dataKey, defaultParams = {}, runEffect = true}) {
  const location = useLocation();
  const getParsedQueryParams = () => qs.parse(location.search.slice(1));
  const initialTableView = useMemo(() => qs.parse(location.search.slice(1)).view, []);
  const lastTableView = useRef(initialTableView);

  const switchPage = () => {
    const queryParams = getParsedQueryParams();

    if (lastTableView.current !== queryParams.view) {
      getStore()[dataKey].load({...defaultParams, offset: 0});
    }
  };

  useEffect(() => {
    if (runEffect) {
      switchPage();
      lastTableView.current = getParsedQueryParams().view;
    }
  }, [location.search]);

  return {lastTableView};
}

export default useSwitchTableView;