import React from "react";
import {useParams} from "react-router-dom";
import {Skeleton} from "@mui/material";
import {observer} from "mobx-react-lite";
import {withStyles} from "tss-react/mui";
import EditableValue from "@core/components/EditableValue";
import useStores from "../../../../useStores";
import styles from "./styles";
import {INTERFACE_TYPE} from "@core/constants/transfer";

const OrderNumber = observer(({classes, interfaceType}) => {
  const {TransferStore} = useStores();

  const {id: transferId} = useParams();

  const orderNumber = TransferStore.transfer.orderNumber;

  const isLoaded = TransferStore.transfer._id === transferId;

  return (
    <div className={classes.container}>
      {isLoaded ? (
        <>
          <h3>PO:</h3>
          <EditableValue
            required
            disabled={interfaceType === INTERFACE_TYPE.RECEIVER}
            value={orderNumber}
            placeholder="Customer PO"
            onSave={(orderNumber) => TransferStore.updateById(transferId, {orderNumber})}
          />
        </>
      ) : (
        <Skeleton width={200} />
      )}
    </div>
  );
});

export default withStyles(OrderNumber, styles);