import LabTestFooter from "@core/components/LabTestFooter";
import ProducerTestFooter from "@core/components/ProducerTestFooter";
import React from "react";
import {useFormikContext} from "formik";

const Footer = ({isProducerTest, saveTest, result}) => {
  const {values} = useFormikContext();
  
  if(isProducerTest) return (
    <ProducerTestFooter
      result={result}
    />
  );
  
  return (
    <LabTestFooter
      onSubmit={() => saveTest({...values, result})}
      result={result}
    />
  );
};

export default Footer;