import themeSettings from "../../../../../../../../theme-settings";

export const TOOLTIP_CONFIG = {
  FONT_SIZE: 12.5,
  GENERAL_TEXT_FONT_SIZE: 10,
  PADDING: 10,
  MARGIN: 5,
  WIDTH: 200,
  TEXT_COLOR: themeSettings.palette.text.primary,
  LIGHT_TEXT_COLOR: "#8c8c8c",
  BACKGROUND_COLOR: "#fff",
  SCALE_MIN: 0.6,
  RADIUS: 2,
  STROKE_COLOR: themeSettings.palette.deactivate.backgroundColor,
  STROKE_WIDTH: 1,
  POINTER_RADIUS: 10,
};

export const CONTENT_WIDTH = TOOLTIP_CONFIG.WIDTH - 2 * TOOLTIP_CONFIG.PADDING;

export const TOOLTIP_PROGRESS_BAR_CONFIG = {
  HEIGHT: 5,
  RADIUS: 3,
  BACKGROUND_COLOR: "#5db7d6",
  STROKE_COLOR: themeSettings.palette.text.primary,
  STROKE_WIDTH: 1.5,
  LINE_HEIGHT: 2.5,
};

export const TOOLTIP_PROGRESS_BAR_HEIGHT = 4 * TOOLTIP_CONFIG.MARGIN + TOOLTIP_PROGRESS_BAR_CONFIG.HEIGHT + TOOLTIP_PROGRESS_BAR_CONFIG.LINE_HEIGHT + TOOLTIP_CONFIG.MARGIN + TOOLTIP_CONFIG.FONT_SIZE;