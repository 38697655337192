import TestResultTableCell from "@core/components/TestResultTableCell";
import React, {Component} from "react";
import {Table, TableBody, TableCell, TableRow, TableHead, Grid} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {generateID} from "@core/helpers";
import PositionTitle from "../PositionTitle";
import styles from "./styles";
import classNames from "classnames";

class HardnessTable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      positionNumberingActual: true
    };
  }

  render() {

    const {elementsHistory, table, value, classes} = this.props;
    const {positionNumberingActual} = this.state;

    const countColumns = 8;
    const tables = Array(Math.ceil(table.length / countColumns)).fill();

    const startPositionOfQuadrant = elementsHistory.map((elementHistory) => Number(elementHistory.position.split(",")[0]));

    return (
      <Grid container spacing={3}>
        {tables.map((nil, tableIndex) => {
          const from = tableIndex * countColumns;
          const to = tableIndex * countColumns + countColumns;

          const tableData = table.slice(from, to);

          const avg = tableData.reduce((acc, el) => acc + Number(el.value), 0) / tableData.length;
          const avgRounded = Math.round(avg * 100) / 100;
          const min = Math.min(...tableData.map((el) => Number(el.value)));
          const max = Math.max(...tableData.map((el) => Number(el.value)));

          return (
            <Grid item xs={12}>
              <Table className={classNames("styled-table", classes.table)} key={generateID()}>
                <TableHead>
                  {(value === "all" && tableData.some((element) => element.metal)) && (
                    <TableRow>
                      <TableCell width={150}>Zone</TableCell>
                      {tableData.map((el, index) => {
                        const nextElement = tableData[index + 1] || {};
                        const quadrant = tableData.filter((element) => element.metal === el.metal);

                        if (!el.metal || nextElement.metal === el.metal) return null;

                        return (
                          <TableCell
                            align="center"
                            className={classNames({[classes.leftBorder]: value === "all"})}
                            colSpan={quadrant.length}
                            key={el.metal}
                          >
                            {el.metal}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell>
                      <PositionTitle
                        tableIndex={tableIndex}
                        positionNumberingActual={positionNumberingActual}
                        onChange={() => this.setState({positionNumberingActual: !positionNumberingActual})}
                      />
                    </TableCell>
                    {tableData.map((el, index) => (
                      <TableCell key={el.position + index} className={classNames({[classes.leftBorder]: startPositionOfQuadrant.includes(el.position) && value == "all"})}>
                        {positionNumberingActual ? el.position : tableIndex * countColumns + (index + 1)}
                      </TableCell>
                    ))}
                    {value !== "all" && (
                      <>
                        <TableCell>Avg</TableCell>
                        <TableCell>Min</TableCell>
                        <TableCell>Max</TableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.some((el) => el.min) && (
                    <TableRow>
                      <TableCell width={150}>Min</TableCell>
                      {tableData.map((el, index) => <TableCell className={classNames({[classes.leftBorder]: startPositionOfQuadrant.includes(el.position) && value == "all"})} key={el.min + index}>{el.min || 0}</TableCell>)}
                      {value !== "all" && (
                        <>
                          <TableCell>-</TableCell>
                          <TableCell>-</TableCell>
                          <TableCell>-</TableCell>
                        </>
                      )}
                    </TableRow>
                  )}
                  {tableData.some((el) => el.max) && (
                    <TableRow>
                      <TableCell width={150}>Max</TableCell>
                      {tableData.map((el, index) => <TableCell className={classNames({[classes.leftBorder]: startPositionOfQuadrant.includes(el.position) && value == "all"})} key={el.max + index}>{el.max}</TableCell>)}
                      {value !== "all" && (
                        <>
                          <TableCell>-</TableCell>
                          <TableCell>-</TableCell>
                          <TableCell>-</TableCell>
                        </>
                      )}
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell width={150}>Value</TableCell>
                    {tableData.map((el) => {
                      return (
                        <TableCell
                          key={el.value}
                          className={classNames({
                            [classes.leftBorder]: startPositionOfQuadrant.includes(el.position) && value == "all"
                          })}
                        >
                          <TestResultTableCell
                            value={el.value}
                            max={el.max || null}
                            min={el.min}
                          />
                        </TableCell>
                      );
                    })}
                    {value !== "all" && (
                      <>
                        <TableCell>{avgRounded}</TableCell>
                        <TableCell>{min}</TableCell>
                        <TableCell>{max}</TableCell>
                      </>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          );
        })}
      </Grid>
    );
  }
}

export default  withStyles(HardnessTable, styles);
