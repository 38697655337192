import React, {useCallback, useMemo} from "react";
import {useLocation, matchPath, NavLink} from "react-router-dom";
import {Box} from "@mui/material";
import IconistsIcon from "@core/components/IconistsIcon";

const MenuLink = ({menuItem, exactMatch, displayTitle}) => {
  const location = useLocation();

  const getIsMatch = useCallback((linkTo) => {
    return matchPath(location.pathname, {
      path: linkTo,
      exact: exactMatch,
    });
  }, [exactMatch, location.pathname]);

  const isActive = useMemo(() => {
    const itemsMatch = menuItem.items && menuItem.items.some((item) => getIsMatch(item.linkTo));

    return itemsMatch || getIsMatch(menuItem.linkTo);
  }, [menuItem.linkTo, location.pathname]);

  return (
    <NavLink
      to={menuItem.linkTo}
      style={{
        textDecoration: "none",
      }}
    >
      <Box
        sx={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          borderRadius: "2px",
          "&:hover": {
            boxShadow: (theme) => `0 0 0 4px ${theme.palette.primary.hover}`,
            backgroundColor: (theme) => theme.palette.primary.hover
          },
          ...isActive ? {
            boxShadow: (theme) => `0 0 0 4px ${theme.palette.primary.hover}`,
            backgroundColor: (theme) => theme.palette.primary.hover
          } : {}
        }}
      >
        <IconistsIcon width="24px" icon={menuItem.icon} color="#AFAFAF"/>
        {displayTitle && (
          <Box
            sx={{
              fontSize: "0.82rem",
              fontWeight: (theme) => theme.typography.fontWeightSemibold,
              marginLeft: 1,
              color: (theme) => theme.palette.text.primary,
            }}
          >
            {menuItem.title}
          </Box>
        )}
      </Box>
    </NavLink>
  );
};

export default MenuLink;