import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";
import {TableStore} from "@core/stores";
import axios from "axios";
import {makeObservable, observable} from "mobx";

class SingleMaterialCampaignStore {
  constructor() {
    makeObservable(this, {
      campaigns: observable,
      testsByCategory: observable,
    });
  }

  campaigns = new TableStore({
    loader: (queryParams) => {
      const newQueryParams = {...this.campaigns.fetch, ...queryParams};
      const {offset, limit, sort} = newQueryParams;

      const params = {
        sort,
        offset,
        limit,
      };

      this.campaigns.setFetch(newQueryParams);

      return axios.get(ROUTES.SINGLE_MATERIAL_CAMPAIGN[ACTIONS.ALL], {params});
    }
  });

  testsByCategory = {};

  async getTestsByCategory(id, category) {
    const response = await axios.get(ROUTES.SINGLE_MATERIAL_CAMPAIGN_TESTS[ACTIONS.ALL](id), {params: {category}});

    this.testsByCategory = {
      ...this.testsByCategory,
      [category]: response.data.items,
    };
  }

  async createTest(id, test) {
    const response = await axios.post(ROUTES.SINGLE_MATERIAL_CAMPAIGN_TESTS[ACTIONS.CREATE](id), test);

    const category = response.data.category;
    const categoryTests = this.testsByCategory[category] || [];

    this.testsByCategory = {
      ...this.testsByCategory,
      [category]: [...categoryTests, response.data],
    };
  }
}

export default new SingleMaterialCampaignStore();