import AcceptanceCriteriaLabel from "@core/components/AcceptanceCriteriaLabel";
import React, {useState, useRef} from "react";
import {withStyles} from "tss-react/mui";
import {
  Button,
  MenuItem,
  Grid,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  TableHead
} from "@mui/material";
import useResize from "@core/hooks/useResize";
import useFetchTestNorms from "@core/hooks/useFetchTestNorms";
import NormAutocomplete from "@core/components/NormAutocomplete";
import SelectField from "@core/components/FormikSelect";
import {omit, isNil, isEmpty, keys, prop,indexBy, values as getValues} from "ramda";
import {Formik, getIn} from "formik";
import * as yup from "yup";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import {Input, Select} from "@core/components/Form";
import {ZONES as ZONES_CONFIG, CHEMICAL_ELEMENTS} from "@core/constants/test";
import {
  norms,
  acceptancesConfig,
  COLUMN_MIN_WIDTH,
  TEST_STANDARDS,
  ANALYSIS_TYPES,
  POSITIONS_BY_ANALYSIS,
  DISTANCE_ANALYSIS,
  ZONES,
  ANALYSIS_VALUES,
  STEELMAKING_PROCESSES
} from "./data";
import {
  getResult,
  calculateElementValue,
  isValueInLimitRange,
  validateElementMax,
  validateElementMin
} from "./services";
import styles from "./styles";
import {generateID, getConfigFromCondition} from "@core/helpers";
import FormulaBuilder from "@core/components/FormulaBuilder";
import {MdEdit} from "react-icons/md";
import classNames from "classnames";
import ClientField from "../../../../Tests/Test/components/ClientField";
import TestFooter from "../../LabTestFooter";
import MaxMinInput from "./MaxMinInput";
import SpecimenCell from "./SpecimenCell";
import HeaderCell from "./HeaderCell";
import LockSwitch from "./LockSwitch";
import MuiSelect from "@core/components/MuiSelect";

const ChemicalComposition = ({
  classes,
  test,
  saveTest,
  saveDisabled,
  certificate,
  user,
  client,
  formRef
}) => {
  const [tableLocked, setTableLocked] = useState(false);
  const [materialGrade, setMaterialGrade] = useState({});
  const [formulaBuilder, setFormulaBuilder] = useState({
    opened: false,
    formula: "",
    chemicalName: ""
  });
  const containerRef = useRef({});
  const {width: availableWidth} = useResize(containerRef);

  const initialNorm = test.properties?.norm || test.norm || certificate?.properties?.norm;
  const initialGrade =  test.properties?.grade || test.grade || certificate?.properties?.grade;

  const findGrade = (norm) => norms.find((nrm) => nrm.value === norm)?.grades;
  const predefinedGrade = findGrade(initialNorm)
    ?.find((grd) => grd.value === test.properties?.grade || grd.value === test.properties.grade || grd.value === certificate?.properties?.grade) ||
    {elements: [], value: initialGrade || ""};

  const filteredAcceptances = Object.keys(acceptancesConfig).reduce(function(r, e) {
    if (!acceptancesConfig[e].hasOwnProperty("company") || acceptancesConfig[e].company.includes(user?.company.name)) r[e] = acceptancesConfig[e];

    return r;
  }, {});

  const predefinedElements = isEmpty(test.properties) ?
    predefinedGrade.elements :
    test.properties.elements;

  const initialValues = {
    client: test.properties.client || client.name || "",
    lab: test.properties.lab || user.company.name,
    testStandard: test.properties.testStandard || "",
    norm: initialNorm || "",
    grade: predefinedGrade,
    elementActions: [],
    elements: predefinedElements || [],
    measurement: test.properties.measurement || "",
    analysis: test.properties.analysis || "",
    zone: test.properties.zone || ZONES_CONFIG.BASE_METAL,
    steelMakingProcess: test.properties.steelMakingProcess || "",
    position: test.properties.position || "",
    distance: test.properties.distance || "",
    acceptance: test.properties.acceptance || "",
    element: "",
    specimenId: test.properties.specimenId || test.properties.name || "",
    secondSpecimenId: test.properties.secondSpecimenId || "",
    min: "",
    max: "",
    notes: test.properties.notes || "",
    result: test.properties.result || "",
  };

  useFetchTestNorms("certificate", initialValues.norm, setMaterialGrade);

  const validationSchema = yup.object().shape({
    client: yup.string().required("Client is required"),
    lab: yup.string().required("Laboratory is required"),
    testStandard: yup.string(),
    norm: yup.string().required("Material specification is required"),
    zone: yup.string().required("Zone is required!"),
    position: yup.string(),
    distance: yup.string().when("position", {
      is: (position) => getValues(DISTANCE_ANALYSIS).includes(position),
      then: yup.string().required("Distance is required!")
    }),
    measurement: yup.string().test("measurement", "Thickness is required!", function (value) {
      return !this.parent.grade?.elements?.some((event) => event.thickness) || !!value;
    }),
    analysis: yup.string().test("analysis", "Analysis is required!", function (value) {
      return !this.parent.grade?.elements?.some((event) => event.analysis || event.thickness && keys(event.thickness).some((m) => event.thickness[m].analysis)) || !!value;
    }),
    steelMakingProcess: yup.string(),
    specimenId: yup.string().when("analysis", {
      is: ANALYSIS_TYPES.HEAT,
      then: yup.string(),
      otherwise: yup.string().required("Specimen ID is required")
    }),
    secondSpecimenId: yup.string(),
    min: yup.number().when(["grade", "element"], (grade, element, schema) => {
      if (!element || !grade.elements) return schema;

      const config = grade.elements.find((e) => e.bm === element) || {};

      if (!isNil(config.min)) return schema.min(config.min, `Should be \u2265 ${config.min}`);

      return schema;
    }),
    max: yup.number().when(["grade", "element", "acceptance"], (grade, element, acceptance, schema) => {
      if (!element || !grade.elements) return schema;

      const config = grade.elements.find((e) => e.bm === element) || {};

      const max =  acceptance ? Infinity : config.maxError || config.max;

      if (max === Infinity || isNil(max)) return schema;

      return schema.max(max, `Should be \u2264 ${max}`);
    }),
    element: yup.string(),
    elements: yup.array().of(yup.object().shape({
      min: yup.number().test("min", validateElementMin),
      max: yup.number().test("max", validateElementMax),
      value: yup.number().typeError("Should be a number")
    })),
    notes: yup.string(),
  });

  const onSubmit = (values, additionalAnalysis) => {
    const result = values.zone === ZONES_CONFIG.BASE_METAL ? getResult(values.elements, additionalAnalysis) : "";

    const elements = values.elements.map((el) => {
      if (additionalAnalysis) return el;

      return omit(["secondValue"], el);
    });

    saveTest({
      client: values.client,
      lab: values.lab,
      testStandard: values.testStandard,
      elements,
      grade: values.grade.value,
      norm: values.norm,
      specimenId: values.specimenId,
      secondSpecimenId: additionalAnalysis ? values.secondSpecimenId : "",
      zone: values.zone,
      steelMakingProcess: values.steelMakingProcess,
      position: values.position,
      distance: values.distance,
      analysis: values.analysis,
      measurement: values.measurement,
      acceptance: values.acceptance,
      notes: values.notes,
      result
    });
  };

  return (
    <Formik
      onSubmit={(values) => onSubmit(values, Boolean(values.secondSpecimenId))}
      innerRef={formRef}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
    >
      {(props) => {
        const {
          values: {
            lab,
            norm,
            grade,
            measurement,
            analysis,
            steelMakingProcess,
            zone,
            position,
            distance,
            element,
            specimenId,
            secondSpecimenId,
            min,
            max,
            elements,
            acceptance,
            notes
          },
          touched,
          errors,
          setFieldValue,
          setFieldTouched,
        } = props;

        const [additionalAnalysis, setAdditionalAnalysis] = useState(Boolean(secondSpecimenId));

        const calculatedFields = elements.filter((el) => el.formula);
        const calculatedValues = calculatedFields.map((el) => el.bm);

        const defaultBms = grade.elements.map((el) => el.bm);

        const customCalculatedFields = calculatedFields.filter((el) => !defaultBms.includes(el.bm));
        const customCalculatedFieldsBms = customCalculatedFields.map((el) => el.bm);

        const requiredMinWidth = (elements.length + 1) * COLUMN_MIN_WIDTH;
        const countColumns = requiredMinWidth > availableWidth ? Math.ceil(elements.length / 2) : elements.length;
        const columnWidth = availableWidth / (countColumns + 1);

        const tables = elements.length ? Array(Math.ceil(elements.length / countColumns)).fill() : [];

        const getIndexToInsertNewEl = (elements) => Math.min(...elements.reduce((acc, curr) => {
          if (calculatedValues.includes(curr.bm)) {
            const index = elements.indexOf(elements.find((el) => el.bm === curr.bm));

            return [...acc, index];
          }

          return acc;
        }, []));

        const changeElements = (elements, measurement, analysis, steelMakingProcess) => {
          const newElements = elements.map((element, index) => {
            const config = grade.elements[index] || element;

            const exception = acceptance && acceptancesConfig[acceptance] ? acceptancesConfig[acceptance].elements.find((exc) => exc.bm === element.bm) || {}: {};
            const getMin = exception.getMin || element.getMin;
            const getMax = exception.getMax || element.getMax;
            const defaultElements = acceptance && acceptancesConfig[acceptance] && acceptancesConfig[acceptance].elements || grade.elements;

            const min = getMin ? getMin({elements, defaultElements, measurement, analysis, poItem: certificate.lineItem, grade: grade.value}) : element.min;
            const max = getMax ? getMax({elements, defaultElements, measurement, analysis, poItem: certificate.lineItem, steelMakingProcess, grade: grade.value}) : element.max;

            if (config.thickness && measurement) {
              const thicknessConfig = getConfigFromCondition(config.thickness, measurement);

              if (thicknessConfig.analysis && analysis) {
                return {...element, ...thicknessConfig.analysis[ANALYSIS_VALUES[analysis]], value: element.value};
              }

              return {...element, ...thicknessConfig, value: element.value};
            }

            if (config.analysis && analysis) {
              return {...element, ...config.analysis[ANALYSIS_VALUES[analysis]], value: element.value};
            }

            return {...element, min, max};
          });

          setFieldValue("elements", newElements);
        };

        const changeGrade = (value) => {
          const grade = findGrade(value.Norm)?.find((grd) => grd.value === value.Material);

          if (grade) setFieldValue("grade", grade);
          else setFieldValue("grade", {elements: [], value: value.Material});

          changeElements(grade ? grade.elements : [], measurement, analysis);
        };

        const changeNorm = ({norm, value}) => {
          setFieldValue("norm", norm);

          if (!value) {
            setMaterialGrade({});
            setFieldValue("grade", {elements: [], value: ""});

            return;
          }

          setMaterialGrade(indexBy(prop("Material"), value));

          if (value.length === 1) {
            const [grade] = value;
            changeGrade(grade);

            return;
          }

          setFieldValue("grade", {elements: [], value: ""});
        };

        const change = (fieldName, event) => {
          const value = event.target.value;

          if (fieldName === "zone" && value === ZONES_CONFIG.WELD_METAL) {
            const elementsWithoutExceptions = elements.filter((element) => grade.elements.find((el) => el.bm === element.bm));
            setFieldValue("elements", elementsWithoutExceptions);
          }

          if (fieldName === "measurement") {
            changeElements(elements, value, analysis);
          }

          if (fieldName === "analysis") {
            changeElements(elements, measurement, value);
          }

          if (fieldName === "steelMakingProcess") {
            changeElements(elements, measurement, analysis, value);
          }

          if (fieldName === "position") {
            setFieldValue("distance", "");
          }

          if (fieldName === "acceptance") {
            if (!value) {
              setFieldValue("acceptance", "");
              setFieldValue("elements", grade.elements);

              return;
            }

            const exception = acceptancesConfig[value];

            const newElements = exception.elements.map((em) => {
              const {bm, formula, required, getMin, getMax} = em;
              let {min, max} = em;

              if (getMin) min = getMin({elements: exception.elements, defaultElements: exception.elements, poItem: certificate.lineItem, grade: grade.value}) || 0;

              if (getMax) max = getMax({elements: exception.elements, defaultElements: exception.elements, poItem: certificate.lineItem, steelMakingProcess, grade: grade.value}) || 0;

              return {bm, min, max, formula, required};
            });

            setFieldValue("elements", newElements);
          }

          setFieldValue(fieldName, value, true);
          setFieldTouched(fieldName, true, false);
        };

        const handleChangeFormula = (changes) => {
          let updatedElements = [];

          if (!formulaBuilder.chemicalName) {
            updatedElements = elements.concat([{id: elements.length + 1, ...changes}]);
          } else {
            updatedElements = elements.map((el) => {
              if (el.bm !== formulaBuilder.chemicalName) return el;

              const newElement = {...el, ...changes, bm: el.bm};

              if (!newElement.label) delete newElement.label;

              return newElement;
            });
          }

          setFieldValue("elements", updatedElements);
        };

        const getLabelForFormula = (el) => {
          if (acceptance !== "" && el.bm === "Pren") return acceptancesConfig[acceptance]?.prenLabel || el.bm;

          return el.label || el.bm;
        };

        const setCalculatedElement = (element, field) => {
          const result = calculateElementValue(elements, element.formula, field);
          const indexOfElement = elements.indexOf(elements.find((el) => el.bm === element.bm));
          elements[indexOfElement][field] !== result && setFieldValue(`elements.${indexOfElement}.${field}`, result);
        };

        const onValueChange = (name, event, idx, bm, field) => {
          const newElements = Array.from(elements);
          newElements[idx] = {...newElements[idx], [field]: event.target.value};

          const notesToDisplay = [];

          elements.forEach((el, index) => {
            const exception = acceptance && acceptancesConfig[acceptance] ? acceptancesConfig[acceptance].elements.find((exc) => exc.bm === el.bm) || {} : {};
            const defaultElements = acceptance ? acceptancesConfig[acceptance].elements : grade.elements;

            const getMin = exception.getMin || el.getMin;
            const getMax = exception.getMax || el.getMax;
            const getValue = exception.getValue || el.getValue;
            const min = getMin ? getMin({elements: newElements, defaultElements, measurement, analysis, poItem: certificate.lineItem, grade: grade.value}) || 0 : el.min;
            const max = getMax ? getMax({elements: newElements, defaultElements, measurement, analysis, poItem: certificate.lineItem, steelMakingProcess, grade: grade.value}) || 0 : el.max;
            const calculatedValue = getValue ? getValue(newElements) : "";

            const value = bm === el.bm ? event.target.value : el[field];
            const newValue  = calculatedValue || value;
            newElements[index] = {...newElements[index], min, max, [field]: newValue};

            const getNote = exception.getNote || el.getNote;

            if(getNote) notesToDisplay.push(getNote(newElements));

          });
          setFieldValue("elements", newElements);
          setFieldValue("notes", notesToDisplay.join(". "));
          change(name, event, idx);
        };

        const removeElement = (bm) => {
          const newElements = elements.filter((el) => el.bm !== bm);
          setFieldValue("elements", newElements);
        };

        const addException = (data) => {
          const value = elements.find((el) => el.bm === data.element);
          const freshElement = {
            value: value ? value.value : 0,
            min: data.min,
            max: data.max,
            bm: data.element
          };

          const updatedElements = [...elements];
          const indexToInsertNewEl = getIndexToInsertNewEl(updatedElements);
          updatedElements.splice(indexToInsertNewEl, 0, freshElement);
          setFieldValue("elements", updatedElements);
        };

        const resetException = () => {
          setFieldValue("element", "");
          setFieldValue("min", "");
          setFieldValue("max", "");
        };

        const chemical = Number(grade.elements.find((e) => e.bm === element)) || {};

        return (
          <div ref={containerRef}>
            <Grid container className={classes.gridRoot} spacing={5} alignItems="flex-end">
              <Grid item xs={2}>
                <ClientField
                  isFromProducer={!!client.name}
                />
              </Grid>

              <Grid item xs={2}>
                <Input
                  disabled
                  label='Laboratory'
                  name='lab'
                  required
                  value={lab}
                  error={Boolean(errors.lab) && touched.lab}
                  errorMessage={errors.lab}
                  onChange={(event) => change("lab", event)}
                />
              </Grid>

              <Grid item xs={2}>
                <MuiSelect
                  label="Test standard"
                  name="testStandard"
                  defaultOptions={TEST_STANDARDS}
                />
              </Grid>
            </Grid>

            <Grid container className={classes.gridRoot} spacing={5}>
              {certificate._id ? (
                <>
                  <Grid item xs={2}>
                    <Input
                      value={norm}
                      label="Material Specification"
                      name="norm"
                      required
                      disabled
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <Input
                      value={grade.value}
                      label="Grade / UNS"
                      name="grade"
                      required
                      disabled
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={2}>
                    <NormAutocomplete
                      label="Material Specification"
                      name="norm"
                      testType="certificate"
                      onChange={changeNorm}
                      required
                    />
                  </Grid>

                  {norm && getValues(materialGrade).some((norm) => norm.Material) && (
                    <Grid item xs={2}>
                      <SelectField
                        value={grade.value}
                        label="Grade / UNS"
                        name="grade"
                        onChange={(value) => changeGrade(materialGrade[value])}
                        required
                      >
                        {getValues(materialGrade).map((item) => (
                          <MenuItem key={item.Material} value={item.Material}>{item.Material}</MenuItem>
                        ))}
                      </SelectField>
                    </Grid>
                  )}
                </>
              )
              }

              <Grid item xs={2}>
                <Select
                  required={grade.elements.some((event) => event.analysis || event.thickness && keys(event.thickness).some((m) => event.thickness[m].analysis))}
                  value={analysis}
                  name='analysis'
                  label='Analysis Type'
                  error={Boolean(errors.analysis) && touched.analysis}
                  onChange={(event) => {
                    change("analysis", event);

                    if (event.target.value === ANALYSIS_TYPES.HEAT) setAdditionalAnalysis(false);
                  }}
                >
                  {Object.keys(ANALYSIS_TYPES).map((item) => (
                    <MenuItem key={item} value={ANALYSIS_TYPES[item]}>{ANALYSIS_TYPES[item]}</MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={2}>
                <Select
                  value={steelMakingProcess}
                  name='steelMakingProcess'
                  label='Steel making process'
                  error={Boolean(errors.steelMakingProcess) && touched.steelMakingProcess}
                  onChange={(event) => change("steelMakingProcess", event)}
                >
                  <MenuItem value={STEELMAKING_PROCESSES.BOF}>{STEELMAKING_PROCESSES.BOF}</MenuItem>
                  <MenuItem value={STEELMAKING_PROCESSES.EAF}>{STEELMAKING_PROCESSES.EAF}</MenuItem>
                  <MenuItem value="">-</MenuItem>
                </Select>
              </Grid>
            </Grid>

            <Grid container className={classes.gridRoot} spacing={5}>
              {POSITIONS_BY_ANALYSIS[analysis] && (
                <Grid item xs={2}>
                  <Select
                    value={position}
                    name='position'
                    label='Position'
                    error={Boolean(errors.position) && touched.position}
                    onChange={(event) => change("position", event)}
                  >
                    {POSITIONS_BY_ANALYSIS[analysis].map((position) => (
                      <MenuItem key={position} value={position}>
                        {getValues(DISTANCE_ANALYSIS).includes(position) ? `Specify distance from ${position}` : position}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}
              {getValues(DISTANCE_ANALYSIS).includes(position) && (
                <Grid item xs={2}>
                  <Input
                    endAdornment="MM"
                    label={`Distance from ${position}`}
                    name='distance'
                    required
                    value={distance}
                    error={Boolean(errors.distance) && touched.distance}
                    errorMessage={errors.distance}
                    onChange={(event) => change("distance", event)}
                  />
                </Grid>
              )}
              {analysis === ANALYSIS_TYPES.PRODUCT && (
                <Grid item xs={2}>
                  <Select
                    value={zone}
                    name='zone'
                    label='Test Zone'
                    required
                    error={Boolean(errors.zone) && touched.zone}
                    onChange={(event) => change("zone", event)}
                  >
                    {ZONES.map((E) => <MenuItem key={generateID()} value={E}>{E}</MenuItem>)}
                  </Select>
                </Grid>
              )}

              <Grid item xs={2}>
                <Input
                  type="number"
                  placeholder="0"
                  label={(
                    <Tooltip
                      placement="top-start"
                      title={(
                        <span>For ASTM A182: Maximum section thickness at the time of heat treatment<br />
                        For EN 10025-2: Final product thickness
                        </span>)}
                    >
                      <span className={classes.helpIcon}>Thickness&nbsp;<HelpOutlineIcon fontSize="small" /></span>
                    </Tooltip>
                  )}
                  name='measurement'
                  endAdornment={"MM"}
                  required={grade.elements.some((event) => event.thickness)}
                  value={measurement}
                  error={Boolean(errors.thickness) && touched.thickness}
                  errorMessage={errors.thickness}
                  onChange={(event) => change("measurement", event)}
                />
              </Grid>

              {grade.value && zone !== ZONES_CONFIG.WELD_METAL && <Grid item xs={2}>
                <Select
                  value={acceptance}
                  name='acceptance'
                  label='Load requirements'
                  error={Boolean(errors.acceptance) && touched.acceptance}
                  onChange={(event) => change("acceptance", event)}
                >
                  <MenuItem key="N/A" value={undefined}>N/A</MenuItem>
                  {Object.keys(filteredAcceptances).map((name) => (
                    <MenuItem key={name} value={name}>{name}</MenuItem>
                  ))}
                </Select>
              </Grid>}
            </Grid>
            <Grid container spacing={2}>
              {calculatedFields.map((el) =>
                <Grid item container spacing={5} alignItems={"flex-end"} key={el.bm}>
                  <Grid item xs={1}>
                    <Input
                      label={getLabelForFormula(el)}
                      name={`${el.bm}`}
                      value={calculateElementValue(elements, el.formula, "value")}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.formulaSection}>
                    <p className={classes.prenFormula}>{el.formula}</p>
                    {!saveDisabled &&
                    <Tooltip title={"Set custom formula"} classes={{tooltip: classes.editFormulaTooltip}}>
                      <IconButton
                        onClick={() => setFormulaBuilder({opened: true, chemicalName: el.bm, formula: el.formula})}
                        disabled={grade.value === "" || saveDisabled}
                        size="large">
                        <MdEdit color={"#2196f3"} />
                      </IconButton>
                    </Tooltip>}
                    {customCalculatedFieldsBms.includes(el.bm) && (
                      <Tooltip title={"Delete custom formula"} classes={{tooltip: classes.editFormulaTooltip}}>
                        <IconButton
                          color="secondary"
                          onClick={() => removeElement(el.bm)}
                          disabled={!grade.value || saveDisabled}
                          size="large">
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              )}
              {grade.value && (
                <Grid item>
                  <Button
                    onClick={() => setFormulaBuilder({opened: true, chemicalName: "", formula: ""})}
                    color="primary"
                    variant="contained"
                  >
                    Add custom formula
                  </Button>
                </Grid>
              )}
            </Grid>
            <React.Fragment>
              <Grid container className={classes.gridRoot} spacing={3}>
                <Grid item xs={12}>
                  <h2>Add acceptance</h2>
                </Grid>

                <Grid item xs={2}>
                  <Select
                    value={element}
                    name='element'
                    label='Element'
                    error={Boolean(errors.element) && Boolean(touched.element)}
                    onChange={(event) => change("element", event)}
                  >
                    {CHEMICAL_ELEMENTS
                      .filter((el) => !elements.find((elm) => elm.bm === el))
                      .map((E) => <MenuItem key={E} value={E}>{E}</MenuItem>)}
                  </Select>
                </Grid>

                <Grid item xs={1}>
                  <Input
                    label='Min'
                    name='min'
                    value={min}
                    type={"number"}
                    error={Boolean(errors.min) && touched.min}
                    errorMessage={errors.min}
                    onChange={(event) => change("min", event)} />
                </Grid>

                <Grid item xs={1}>
                  <Input
                    label='Max'
                    name='max'
                    value={max}
                    type={"number"}
                    error={Boolean(errors.max) && touched.max}
                    errorMessage={errors.max}
                    warning={!errors.max && element && max > chemical.max}
                    warningMessage={`This value exceeds the ${grade.value} limit`}
                    onChange={(event) => change("max", event)} />
                </Grid>

                <Grid item xs={1} className={classes.roundButtonGrid}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.btnAdd}
                    onClick={() => {
                      addException({element, min, max});
                      resetException();
                    }}
                    disabled={Boolean(errors.min) ||
                            Boolean(errors.max) ||
                            !element ||
                            !grade.value ||
                            saveDisabled
                    }
                  >
                          Add
                  </Button>
                </Grid>
              </Grid>

              <LockSwitch
                acceptance={acceptance}
                tableLocked={tableLocked}
                setTableLocked={setTableLocked}
              />
              {tables.map((nil, tableIndex) => {
                const from = tableIndex * countColumns;
                const to = tableIndex * countColumns + countColumns;
                const tableData = elements.slice(from, to);

                const isError = (name, el, value) => (Boolean(getIn(errors, name)) || !isValueInLimitRange(el, zone, value));

                const batchIndex = (tableIndex * countColumns);

                return (
                  <React.Fragment key={tableIndex}>
                    <Table
                      className={classNames("styled-table", classes.table)}
                      style={{width: `${((tableData.length + 1) / (countColumns + 1)) * 100}%`}}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell width={columnWidth}>Spec. ID</TableCell>
                          {tableData.map((el, idx) => {
                            const valueError = isError(`elements[${idx - 1}].value`, el, el.value);
                            const secondValueError = additionalAnalysis && isError(`elements[${idx - 1}].value`, el, el.secondValue);

                            return (
                              <HeaderCell
                                width={columnWidth}
                                key={idx}
                                className={classNames({
                                  [classes.error]: valueError || secondValueError
                                })}
                                title={getLabelForFormula(el)}
                                acceptance={acceptance}
                                el={el}
                                grade={grade}
                                onRemove={() => removeElement(el.bm)}
                              />
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {zone === ZONES_CONFIG.BASE_METAL && (
                          <>
                            <TableRow>
                              <TableCell>
                                <AcceptanceCriteriaLabel min/>
                              </TableCell>
                              {tableData.map((el, idx) => {
                                const valueError = isError(`elements[${idx - 1}].value`, el, el.value);
                                const secondValueError = additionalAnalysis && isError(`elements[${idx - 1}].value`, el, el.secondValue);

                                const cellIdx = batchIndex + idx;
                                const name = `elements[${cellIdx}].min`;
                                const config = grade.elements.find((e) => e.bm === el.bm) || {};
                                const exception = acceptance && acceptancesConfig[acceptance] ? acceptancesConfig[acceptance].elements.find((exc) => exc.bm === el.bm) : null;

                                return (
                                  <TableCell
                                    key={`${idx}-min-${el.bm}`}
                                    className={classNames({
                                      [classes.error]: valueError || secondValueError
                                    })}
                                  >
                                    <MaxMinInput
                                      className={classes.tableInput}
                                      name={name}
                                      value={el.min}
                                      disabled={exception || tableLocked}
                                      error={Boolean(getIn(errors, name))}
                                      errorMessage={getIn(errors, name)}
                                      warning={!getIn(errors, name) && el.min < Number(config.min)}
                                      warningMessage={`This value falls behind the ${grade.value} limit`}
                                      onChange={(event) => change(name, event)}
                                    />
                                  </TableCell>
                                );
                              })}
                            </TableRow>

                            <TableRow>
                              <TableCell>
                                <AcceptanceCriteriaLabel max />
                              </TableCell>
                              {tableData.map((el, idx) => {
                                const valueError = isError(`elements[${idx - 1}].value`, el, el.value);
                                const secondValueError = additionalAnalysis && isError(`elements[${idx - 1}].value`, el, el.secondValue);

                                const config = grade.elements.find((e) => e.bm === el.bm) || {};
                                const cellIdx = batchIndex + idx;
                                const name = `elements[${cellIdx}].max`;
                                const exception = acceptance && acceptancesConfig[acceptance] ? acceptancesConfig[acceptance].elements.find((exc) => exc.bm === el.bm) : null;

                                return (
                                  <TableCell
                                    key={`${idx}-max-${el.bm}`}
                                    className={classNames({
                                      [classes.error]: valueError || secondValueError
                                    })}
                                  >
                                    <MaxMinInput
                                      className={classes.tableInput}
                                      name={name}
                                      value={el.max}
                                      disabled={exception || tableLocked}
                                      error={Boolean(getIn(errors, name))}
                                      errorMessage={getIn(errors, name)}
                                      warning={!getIn(errors, name) && el.max > Number(config.max)}
                                      warningMessage={`This value exceeds the ${grade.value} limit`}
                                      onChange={(event) => change(name, event)}
                                    />
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          </>
                        )}
                        <TableRow>
                          <SpecimenCell
                            additionalAnalysis={additionalAnalysis}
                            setAdditionalAnalysis={setAdditionalAnalysis}
                            required
                          >
                            <Input
                              classes={{input: classes.tableInput}}
                              name='specimenId'
                              required={analysis === ANALYSIS_TYPES.PRODUCT}
                              value={specimenId}
                              error={Boolean(errors.specimenId) && touched.specimenId}
                              onChange={(event) => change("specimenId", event)}
                              placeholder="Spec. ID"
                            />
                          </SpecimenCell>
                          {tableData.map((el, idx) => {
                            const cellIdx = batchIndex + idx;
                            const name = `elements[${cellIdx}].value`;

                            const exception = acceptance && acceptancesConfig[acceptance] ? acceptancesConfig[acceptance].elements.find((exc) => exc.bm === el.bm) || {}: {};
                            const config = grade.elements.find((exc) => exc.bm === el.bm) || {};
                            const getValue = exception.getValue || config.getValue;

                            const isInLimitRange = isValueInLimitRange(el, zone, el.value);

                            if (calculatedValues.includes(el.bm)) {
                              return (
                                <TableCell key={idx} className={classNames({
                                  [classes.error]: !isInLimitRange,
                                  [classes.outOfLimitRange]: !isInLimitRange
                                })}>
                                  {calculateElementValue(elements, el.formula, "value")}
                                  {setCalculatedElement(el, "value")}
                                </TableCell>
                              );
                            }

                            if(getValue) return (
                              <TableCell   key={`${idx}-value-${el.bm}`}>
                                {el.value}
                              </TableCell>
                            );

                            return (
                              <TableCell
                                key={`${idx}-value-${el.bm}`}
                                className={classNames({
                                  [classes.error]: isError(name, el, el.value)
                                })}
                              >
                                <Input
                                  classes={{input: classes.tableInput}}
                                  placeholder="0"
                                  name={name}
                                  value={el.value}
                                  onChange={(event) => onValueChange(name, event, idx, el.bm, "value")}
                                  type="number"
                                  error={
                                    Boolean(getIn(errors, name)) &&
                                          Boolean(getIn(touched, name)) ||
                                          !isValueInLimitRange(el, zone, el.value)
                                  }
                                />
                              </TableCell>
                            );
                          })}
                        </TableRow>
                        {additionalAnalysis && (
                          <TableRow>
                            <SpecimenCell
                              additionalAnalysis={additionalAnalysis}
                              setAdditionalAnalysis={setAdditionalAnalysis}
                            >
                              <Input
                                placeholder="Spec. ID"
                                classes={{input: classes.tableInput}}
                                name='secondSpecimenId'
                                value={secondSpecimenId}
                                error={Boolean(errors.secondSpecimenId) && touched.secondSpecimenId}
                                onChange={(event) => change("secondSpecimenId", event)}
                              />
                            </SpecimenCell>
                            {tableData.map((el, idx) => {
                              const cellIdx = batchIndex + idx;
                              const name = `elements[${cellIdx}].secondValue`;

                              const exception = acceptance && acceptancesConfig[acceptance] ? acceptancesConfig[acceptance].elements.find((exc) => exc.bm === el.bm) || {}: {};
                              const config = grade.elements.find((exc) => exc.bm === el.bm) || {};
                              const getValue = exception.getValue || config.getValue;

                              const isInLimitRange = isValueInLimitRange(el, zone, el.secondValue);

                              if (calculatedValues.includes(el.bm)) {
                                return (
                                  <TableCell key={idx} className={classNames({
                                    [classes.error]: !isInLimitRange,
                                    [classes.outOfLimitRange]: !isInLimitRange
                                  })}>
                                    {calculateElementValue(elements, el.formula, "secondValue")}
                                    {setCalculatedElement(el, "secondValue")}
                                  </TableCell>
                                );
                              }

                              if(getValue) return (
                                <TableCell key={idx}>
                                  {el.secondValue}
                                </TableCell>
                              );

                              return (
                                <TableCell
                                  key={idx}
                                  className={classNames({
                                    [classes.error]: isError(name, el, el.secondValue)
                                  })}
                                >
                                  <Input
                                    classes={{input: classes.tableInput}}
                                    placeholder="0"
                                    name={name}
                                    value={el.secondValue}
                                    onChange={(event) => onValueChange(name, event, idx, el.bm, "secondValue")}
                                    type="number"
                                    error={
                                      Boolean(getIn(errors, name)) &&
                                            Boolean(getIn(touched, name)) ||
                                            !isValueInLimitRange(el, zone, el.secondValue)
                                    }
                                  />
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </React.Fragment>
                );
              })}
            </React.Fragment>

            <Grid container spacing={5}>
              <Grid item xs={6}>
                <Input
                  rows={4}
                  multiline
                  name="notes"
                  label="Additional remarks"
                  value={notes}
                  error={Boolean(errors.notes) && touched.notes}
                  errorMessage={errors.notes}
                  onChange={(event) => change("notes", event)}
                />
              </Grid>
            </Grid>

            <TestFooter
              onSubmit={(values) => onSubmit(values, additionalAnalysis)}
              result={zone === ZONES_CONFIG.BASE_METAL && getResult(elements, additionalAnalysis)}
            />

            {formulaBuilder.opened && (
              <FormulaBuilder
                elements={elements}
                chemical={elements.find((el) => el.bm === formulaBuilder.chemicalName) || {}}
                formula={formulaBuilder.formula}
                close={() => setFormulaBuilder({opened: false})}
                onSubmit={handleChangeFormula}
              />
            )}
          </div>
        );
      }}
    </Formik>
  );
};

export default withStyles(ChemicalComposition, styles);
