import React, {useState, useContext, useMemo} from "react";
import {IconButton, TableRow, TableCell, Box} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {TYPES} from "@core/constants/test";
import CompanyLogos from "@core/components/CompanyLogos";
import TestReportTitle from "@core/components/TestReportTitle";
import {ResizableDrawerContext} from "@core/components/ResizableDrawer/context";
import useStores from "../../../../../useStores";
import HydrogenInducedCracking from "../HydrogenInducedCracking";
import ImpactTest from "../ImpactTest";
import TensileTest from "../TensileTest";
import HardnessTest from "../HardnessTest";
import ChemicalComposition from "../ChemicalComposition";
import ElectromagneticInduction from "../ElectromagneticInduction";
import HydrostaticTest from "../HydrostaticTest";
import HeatTreatment from "../HeatTreatment";
import DimensionalTest from "../DimensionalTest";
import UltrasonicTest from "../UltrasonicTest";
import OtherTest from "../OtherTest";
import CustomTest from "../CustomTest";
import CrackTipOpeningDisplacement from "../CrackTipOpeningDisplacement";
import CorrosionTest from "../CorrosionTest";
import TransverseRuptureStrength from "../TransverseRuptureStrength";
import {QCP_CONFIG_BY_TEST_TYPE} from "../../constants";

const TABLE_COMPONENT_BY_TEST_TYPE = {
  [TYPES.HYDROGEN_INDUCED_CRACKING]: HydrogenInducedCracking,
  [TYPES.IMPACT]: ImpactTest,
  [TYPES.TENSILE]: TensileTest,
  [TYPES.HOT_TENSILE]: TensileTest,
  [TYPES.HARDNESS]: HardnessTest,
  [TYPES.CHEMICAL]: ChemicalComposition,
  [TYPES.HEATTREATMENT]: HeatTreatment,
  [TYPES.ELECTROMAGNETIC_INDUCTION]: ElectromagneticInduction,
  [TYPES.ULTRASONIC]: UltrasonicTest,
  [TYPES.HYDROSTATIC]: HydrostaticTest,
  [TYPES.DIMENSIONAL]: DimensionalTest,
  [TYPES.OTHER]: OtherTest,
  [TYPES.CRACK_TIP_OPENING_DISPLACEMENT]: CrackTipOpeningDisplacement,
  [TYPES.CUSTOM]: CustomTest,
  [TYPES.CORROSION]: CorrosionTest,
  [TYPES.TRANSVERSE_RUPTURE_STRENGTH]: TransverseRuptureStrength
};

const TestSummaryTableRow = ({qcp, baseQcp, quantity = 1, test, labs = [], witnesses = [], activityNumber, withActivityNumber}) => {
  const [open, setOpen] = useState(false);

  const {CompanyStore} = useStores();

  const RequirementsComponent = TABLE_COMPONENT_BY_TEST_TYPE[test.type];

  const width = useContext(ResizableDrawerContext);

  const qcpConfig = QCP_CONFIG_BY_TEST_TYPE[test.type] ?
    QCP_CONFIG_BY_TEST_TYPE[test.type](qcp) || QCP_CONFIG_BY_TEST_TYPE[test.type](baseQcp) :
    test;
  const properties = test.getProperties ? test.getProperties() : test.properties;

  const labsWithMeta = useMemo(() => {
    return labs.map((lab) => CompanyStore.meta.find((company) => company.name === lab));
  }, [CompanyStore.meta.length, labs]);

  const witnessWithMeta = useMemo(() => {
    return witnesses.map((witness) => CompanyStore.meta.find((company) => company.name === witness));
  }, [CompanyStore.meta.length, witnesses]);

  return (
    <>
      <TableRow>
        <TableCell padding="none" width="45">
          {(RequirementsComponent && qcpConfig) && (
            <IconButton
              sx={{
                padding: 0,
              }}
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        {withActivityNumber && <TableCell>{activityNumber || "-"}</TableCell>}
        <TableCell>
          <TestReportTitle
            test={{...test, properties}}
            groupTests
          />
        </TableCell>
        <TableCell>{quantity}</TableCell>
        <TableCell>
          <CompanyLogos
            companies={labsWithMeta}
          />
        </TableCell>
        <TableCell>
          <CompanyLogos
            companies={witnessWithMeta}
          />
        </TableCell>
      </TableRow>
      {open && (
        <TableRow>
          <TableCell colSpan={6}>
            <Box
              sx={{
                "& div:has(table)": {
                  overflowX: "scroll",
                },
                padding: 1,
                paddingBottom: 3,
                "& h3": {
                  marginTop: 0,
                },
                "& div p": {
                  color: (theme) => theme.palette.text.primary,
                }
              }}
            >
              <Box sx={{width: width - 80}}>
                <RequirementsComponent
                  qcp={qcp}
                  qcpConfig={qcpConfig}
                />
              </Box>
            </Box>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default TestSummaryTableRow;