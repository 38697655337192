import React from "react";
import {isEmpty} from "ramda";
import {useParams} from "react-router-dom";
import {observer} from "mobx-react";
import RawMaterialsTable from "@core/components/RawMaterialsTable";
import {FILTERS} from "@core/constants/filters";
import useStores from "../../../../useStores";
import BlockHeader from "../BlockHeader";

const RawMaterials = observer(({id, label}) => {
  const {CampaignStore, FilterStore} = useStores();

  const campaign = CampaignStore.campaign;

  const {id: campaignId} = useParams();

  const {setFilters, filters} = FilterStore;

  const isLoaded = campaignId === campaign._id;

  const updateRawMaterial = (materialId, data) => {
    if(!isEmpty(data.file)) data.file = data.file._id;
    else delete data.file;

    CampaignStore.updateRawMaterialById(campaign._id, materialId, data);
  };

  const createRawMaterial = (data) => {
    if(!isEmpty(data.file)) data.file = data.file._id;
    else delete data.file;

    CampaignStore.createRawMaterial(campaign._id, data);
  };

  const onRowClick = (index, editableIndex, material) => {
    const editable = editableIndex === index;

    if(editable || !material.batchNumber) return;

    setFilters({...filters, [FILTERS.BATCH_NUMBER]: [material.batchNumber]});
  };

  return (
    <>
      <BlockHeader
        id={id}
      >
        {label}
      </BlockHeader>
      <RawMaterialsTable
        isLoaded={isLoaded}
        savedRawMaterials={campaign.rawMaterials}
        deleteRawMaterial={(materialToDelete) => CampaignStore.deleteRawMaterialById(campaign._id, materialToDelete)}
        updateRawMaterial={updateRawMaterial}
        createRawMaterial={createRawMaterial}
        onRowClick={onRowClick}
      />
    </>
  );
});

export default RawMaterials;