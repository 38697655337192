export const PAGE_TABS = {
  GENERAL_INFO: "GENERAL_INFO",
  PIPELINE: "PIPELINE",
  BASE_MATERIALS: "BASE_MATERIALS",
  WELDS: "WELDS",
  COATING: "COATING",
  TEST_REPORTS: "TEST_REPORTS",
  ASSEMBLY_TEST_REPORTS: "ASSEMBLY_TEST_REPORTS",
  UPLOADS: "UPLOADS"
};

export const PAGE_TABS_LABELS = {
  [PAGE_TABS.GENERAL_INFO]: "General Info",
  [PAGE_TABS.BASE_MATERIALS]: "Base Materials",
  [PAGE_TABS.PIPELINE]: "Pipeline",
  [PAGE_TABS.WELDS]: "Welds",
  [PAGE_TABS.COATING]: "Coating",
  [PAGE_TABS.TEST_REPORTS]: "Weld Test Reports",
  [PAGE_TABS.ASSEMBLY_TEST_REPORTS]: "Assembly Test Reports",
  [PAGE_TABS.UPLOADS]: "Uploads"
};

export const SUB_TABS = {
  STALK_INFORMATION: "STALK_INFORMATION",
  BASE_PRODUCTS: "BASE_PRODUCTS",
  WELDING_CONSUMABLES: "WELDING_CONSUMABLES",
  CAMPAIGN_PROGRESS: "CAMPAIGN_PROGRESS",
};

export const SUB_TABS_LABELS = {
  [SUB_TABS.STALK_INFORMATION]: "Stalk Information",
  [SUB_TABS.BASE_PRODUCTS]: "Base Products",
  [SUB_TABS.WELDING_CONSUMABLES]: "Welding Consumables",
  [SUB_TABS.CAMPAIGN_PROGRESS]: "Campaign Progress",
};

export const COATING_SUB_NAVIGATION = {
  FIELD_JOINT_COATING: "FIELD_JOINT_COATING",
  COATING_DEFECTS: "COATING_DEFECTS",
};

export const SUB_NAVIGATION_BY_TAB = {
  [PAGE_TABS.COATING]: {
    [COATING_SUB_NAVIGATION.FIELD_JOINT_COATING]: "Field Joint Coating (FJC)",
    [COATING_SUB_NAVIGATION.COATING_DEFECTS]: "Coating Defects",
  }
};