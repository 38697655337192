import React, {useCallback, useEffect, useMemo, useState} from "react";
import {observer} from "mobx-react";
import {intersection} from "ramda";
import Modules from "@core/constants/modules";
import {
  Button,
  CircularProgress,
  Grid, Typography,
} from "@mui/material";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import InputAdornment from "@mui/material/InputAdornment";
import Confirmation from "@core/components/Modal/Confirmation/Confirmation";
import CreateProject from "@core/components/CreateProjectModal";
import Table from "@core/components/Table";
import TableFooter from "@core/components/TableFooter";
import {Input} from "@core/components/Form";
import {useHistory, useRouteMatch} from "react-router-dom";
import useStores from "../../useStores";
import {getColumns} from "./columns";
import useFetchTableDataByQueryParam from "@core/hooks/useFetchTableDataByQueryParam";
import {STORE_DATA} from "@core/constants/storeDataKeys";
import {setRowsPerPage} from "@core/helpers";

const ProjectsList = observer(() => {
  const {ProjectStore, UserStore} = useStores();
  const {user} = UserStore;

  const match = useRouteMatch();
  const history = useHistory();

  const userModules = useMemo(() => user.data.company.modules.map((m) => m.name), [user.data.company.modules]);

  const {status, data, total, fetch} = ProjectStore.projects;

  const [createProjectOpen, setCreateProjectOpen] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);

  const searchPhraseDebounced = AwesomeDebouncePromise((search) => {
    ProjectStore.projects.load({search, offset: 0});
  }, 750);

  useEffect(() => {
    return () => ProjectStore.projects.reset();
  }, []);

  useFetchTableDataByQueryParam({
    getStore: () => ProjectStore,
    dataKey: STORE_DATA.projects,
    matchPath: match.path,
  });

  const handleChangeRowsPerPage = useCallback((limit) => {
    setRowsPerPage(match.path, limit);
    ProjectStore.projects.load({limit, offset: 0});
  }, []);

  const handleSearch = useCallback(async (event) => {
    await searchPhraseDebounced(event.target.value);
  }, []);

  const columns = getColumns(setProjectToDelete);

  return (
    <div className="padded-container">
      <Grid container spacing={3} marginBottom={3}>
        <Grid item>
          <Typography variant="h4" fontSize="1.8rem">
            Projects
          </Typography>
        </Grid>
        {intersection([Modules.END_OWNER, Modules.PRODUCER], userModules) && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setCreateProjectOpen(true)}
            >
                Create new
            </Button>
            <CreateProject
              open={createProjectOpen}
              setOpen={setCreateProjectOpen}
              createProject={(data) => ProjectStore.createProject(data)}
            />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={4} marginBottom={2}>
        <Grid item xs={12}>
          <Input
            name='search'
            endAdornment={
              <InputAdornment position="start">
                {status.loading && <CircularProgress size={20} />}
              </InputAdornment>
            }
            placeholder={"Search Projects"}
            onChange={handleSearch}
          />
        </Grid>
        <Grid item xs={12}>
          <Table
            items={data}
            columns={columns}
            isLoaded={status.loaded}
            total={total}
            onRowClick={(project) => history.push(`/projects/${project._id}`)}
            noDataText="No available projects."
          >
            <TableFooter
              saveTablePageToQueryParams
              isLoaded={status.loaded}
              items={data}
              total={total}
              limit={fetch.limit}
              offset={fetch.offset}
              onOffsetChange={(offset) => ProjectStore.projects.load({offset})}
              onLimitChange={handleChangeRowsPerPage}
            />
          </Table>
        </Grid>
      </Grid>
      <Confirmation
        open={!!projectToDelete}
        onCancel={() => setProjectToDelete(null)}
        onConfirm={async () => {
          await ProjectStore.removeProject(projectToDelete);
          setProjectToDelete(null);
        }}
        alertText="Are you sure you want to delete this project?"
      />
    </div>
  );
});

export default ProjectsList;
