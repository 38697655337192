import {COLUMNS} from "@core/components/TestSummaryTable/constants";
import {STATUSES} from "@core/constants/test";
import {isAcceptable} from "@core/helpers";
import React, {useMemo, useState} from "react";
import {flatten, indexBy, prop} from "ramda";
import {observer} from "mobx-react-lite";
import {Box} from "@mui/material";
import TestSummaryTable from "@core/components/TestSummaryTable";
import {PAGE_TABS} from "../constants";
import {useParams} from "react-router-dom";
import useStores from "../../../../useStores";

const TABLE_COLUMNS = [
  COLUMNS.ACTIVITY_NUMBER,
  COLUMNS.TITLE,
  COLUMNS.HEAT,
  COLUMNS.QCP_ITEM,
  COLUMNS.WITNESS,
  COLUMNS.COMPANY,
  COLUMNS.LAB,
  COLUMNS.STATUS,
  COLUMNS.RESULT,
  COLUMNS.INSPECTION_RESULT
];

const NonConformingTests = ({certificates, campaigns, setTestsToView}) => {
  const [filteredTestIds, setFilteredTestIds] = useState([]);

  const {id: transferId} = useParams();

  const {TransferStore} = useStores();

  const transfer = TransferStore.transfer;

  const isLoaded = transfer._id === transferId;
  
  const certificateTests = flatten(certificates.map((certificate) => certificate.tests));
  const campaignTests = flatten(campaigns.map((campaign) => campaign.tests));
  
  const tests = useMemo(() => certificateTests.concat(campaignTests), [certificateTests.length, campaignTests.length]);

  const unacceptableTests = useMemo(() => tests.filter((test) =>
    test.properties.result &&
    !isAcceptable(test.properties.result) &&
    ![STATUSES.EMPTY, STATUSES.ASSIGNED].includes(test.status)
  ), [tests.length]);

  const testsById = useMemo(() => indexBy(prop("_id"), unacceptableTests), [unacceptableTests]);

  const filteredTests = filteredTestIds.length ? filteredTestIds.map((id) => testsById[id]) : unacceptableTests;

  if(!unacceptableTests.length) return null;

  return (
    <Box
      sx={{
        paddingRight: 4,
        paddingLeft: 4,
      }}
    >
      <Box
        sx={{
          marginTop: 3,
          marginBottom: 1,
        }}
      >
        <h3
          id={PAGE_TABS.CAMPAIGNS}
        >
          Non conforming tests
        </h3>
      </Box>
      <TestSummaryTable
        isLoaded={isLoaded}
        onTestClick={setTestsToView}
        tests={filteredTests}
        setFilteredTestIds={setFilteredTestIds}
        columns={TABLE_COLUMNS}
      />
    </Box>
  );
};

export default observer(NonConformingTests);