import React, {useMemo} from "react";
import {observer} from "mobx-react-lite";
import {uniq} from "ramda";
import {TableHead, Table, TableRow, TableBody, TableCell, Box} from "@mui/material";
import {getWeldsByStalkNumber} from "../../../../../../helpers";
import useStores from "../../../../../../../../../../useStores";
import {useParams} from "react-router-dom";
import TableSkeleton from "@core/components/TableSkeleton";
import CoatingRow from "../CoatingRow";

const StatisticByStalkNumber = ({totalDefects}) => {
  const {CampaignStore} = useStores();

  const {id: campaignId} = useParams();

  const campaign = CampaignStore.campaign;

  const isLoaded = campaignId === campaign._id;

  const {welds = []} = campaign;

  const stalks = useMemo(() => getWeldsByStalkNumber(welds).stalks, [welds.length]);

  const joints = useMemo(() =>
    uniq(welds.reduce((acc, weld) => acc.concat([weld.jointNumberA, weld.jointNumberB]), []))
  , [welds]);

  return (
    <Table className="styled-table">
      <TableHead>
        <TableRow>
          <TableCell>Stalk Number</TableCell>
          <TableCell>No. of Joints</TableCell>
          <TableCell>No. of Cracks</TableCell>
          <TableCell>% of Cracks in stalk</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {isLoaded ? (
          <>
            {stalks.map((stalk, index) => (
              <CoatingRow
                key={index}
                stalk={stalk}
                totalDefects={totalDefects}
              />
            ))}
            <TableRow>
              <TableCell>
                <Box sx={{fontWeight: (theme) => theme.typography.fontWeightMedium, display: "inline"}}>Total</Box>: {stalks.length} Stalks
              </TableCell>
              <TableCell>{joints.length}</TableCell>
              <TableCell colSpan={2}>{totalDefects.length}</TableCell>
            </TableRow>
          </>
        ) : (
          <TableSkeleton
            columns={4}
          />
        )}
      </TableBody>
    </Table>
  );
};

export default observer(StatisticByStalkNumber);