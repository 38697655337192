import AcceptanceCriteriaLabel from "@core/components/AcceptanceCriteriaLabel";
import React, {useCallback, useMemo, useState} from "react";
import {isEmpty, uniq} from "ramda";
import moment from "moment";
import {observer} from "mobx-react";
import {
  Link,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
} from "@mui/material";
import {withStyles} from "tss-react/mui";
import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import {FILTERS} from "@core/constants/filters";
import SortableCell from "../../../../SortableCell";
import ResultCell from "../ResultCell";
import {sort} from "@core/helpers";
import {DATE_TIME_FORMAT} from "@core/constants/dateFormats";
import {ORDERS} from "@core/constants/sort";
import {ORDER_BY} from "../../constants";
import styles from "./styles";
import useStores from "../../../../../../useStores";
import {CAMPAIGN_SPECIMEN_LABELS} from "@core/constants/campaign";

const StandardView = observer(({classes, test, activities, disabled, updateTest}) => {
  const [orderBy, setOrderBy] = useState(ORDER_BY.ACTIVITY);
  const [order, setOrder] = useState(ORDERS.ASC);
  
  const {FilterStore, CampaignStore} = useStores();
  const {filters} = FilterStore;

  const withActivity = useMemo(() => activities.some((element) => element.activity), [activities]);
  const withAcceptance = useMemo(() => activities.some((element) => element.acceptanceCriteria), [activities]);

  const dateRange = FilterStore.filters[FILTERS.DATE_CREATED];
  const batchNumbers = FilterStore.filters[FILTERS.BATCH_NUMBER];
  const specimens = FilterStore.filters[FILTERS.SPECIMEN_ID] || [];
  const welds = FilterStore.filters[FILTERS.WELD_ID] || [];

  const rawMaterials = CampaignStore.campaign.rawMaterials;
  
  const sorted = sort(order, orderBy, activities);

  const onFilter = useCallback((element) => {
    const specimens = FilterStore.filters[FILTERS.SPECIMEN_ID] || [];
    const welds = FilterStore.filters[FILTERS.WELD_ID] || [];

    if(test.specimenLabel === CAMPAIGN_SPECIMEN_LABELS.PIPE || !test.specimenLabel) {
      const newSpecimenFilter = uniq([...specimens, element.specimen]);
      FilterStore.setFilters({...filters, [FILTERS.SPECIMEN_ID]: newSpecimenFilter});
    } else {
      const newWeldFilter = uniq([...welds, element.specimen]);
      FilterStore.setFilters({...filters, [FILTERS.WELD_ID]: newWeldFilter});
    }
  }, []);

  const onCancelFilter = useCallback((element) => {
    const specimens = FilterStore.filters[FILTERS.SPECIMEN_ID] || [];
    const welds = FilterStore.filters[FILTERS.WELD_ID] || [];

    if(test.specimenLabel === CAMPAIGN_SPECIMEN_LABELS.PIPE || !test.specimenLabel) {
      const newSpecimenFilter = specimens.filter((specimen) => specimen !== element.specimen);
      FilterStore.setFilters({...filters, [FILTERS.SPECIMEN_ID]: newSpecimenFilter});
    } else {
      const newWeldFilter = welds.filter((specimen) => specimen !== element.specimen);
      FilterStore.setFilters({...filters, [FILTERS.WELD_ID]: newWeldFilter});
    }
  }, []);

  return (
    <>
      <Table className="styled-table">
        <TableHead>
          <TableRow>
            {withActivity && (
              <SortableCell
                id={ORDER_BY.ACTIVITY}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                order={order}
                orderBy={orderBy}
              >
              ITP Activity
              </SortableCell>
            )}
            <TableCell>Description</TableCell>
            {withAcceptance && (
              <TableCell>
                <AcceptanceCriteriaLabel />
              </TableCell>
            )}
            <TableCell>Result</TableCell>
            <SortableCell
              id={ORDER_BY.SPECIMEN}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
              order={order}
              orderBy={orderBy}
            >
              {test.specimenLabel || "Specimen ID"}
            </SortableCell>
            {dateRange && <TableCell>Date</TableCell>}
            {batchNumbers && <TableCell>Batch number</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {sorted.map((element) => {
            const rawMaterial = rawMaterials.find((material) => material.pipes.includes(element.specimen));
            const specimen = element.specimen + (element.specimenSuffix || "");

            return (
              <TableRow key={element.id}>
                {withActivity && <TableCell>{element.activity}</TableCell>}
                <TableCell>{element.description || "-"}</TableCell>
                {withAcceptance && (
                  <TableCell>
                    {element.acceptanceCriteria ? element.acceptanceCriteria.map((ac) => ac.label).join(" ,") : "-"}
                  </TableCell>
                )}
                <ResultCell
                  element={element}
                  disabled={disabled}
                  onSave={(results) => updateTest(results, element.id)}
                  activity={activities[element.id]}
                />
                <TableCell>
                  {!isEmpty(FilterStore.filters) ? (
                    <span>
                      <Link
                        className={classes.link}
                        onClick={() => onFilter(element)}
                        underline="hover"
                      >
                        <Tooltip title="Filter activities by this specimen ID">
                          <span>{specimen}</span>
                        </Tooltip>
                      </Link>
                      {Boolean(specimens.length || welds.length) && (
                        <IconButton
                          classes={{root: classes.removeIcon}}
                          size="small"
                          color="default"
                          onClick={() => onCancelFilter(element)}
                        >
                          <Tooltip title="Remove filter by this specimen ID">
                            <CancelSharpIcon />
                          </Tooltip>
                        </IconButton>
                      )}
                    </span>
                  ) :
                    specimen}
                </TableCell>
                {dateRange && <TableCell>{moment(element.date).format(DATE_TIME_FORMAT)}</TableCell>}
                {batchNumbers && <TableCell>{rawMaterial.batchNumber}</TableCell>}
              </TableRow>
            );
          })}
          {!sorted.length && (
            <TableRow>
              <TableCell colSpan={6}>No data.</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
});

export default withStyles(StandardView, styles);