import {generateID} from "@core/helpers";

export const COLUMN_NAMES = ["Heat #", "Heat Lot", "Work Order", "Work Order Item", "PO Item"];
export const COLUMN_NAMES_EDIT = ["Heat #", "Heat Lot", "Work Order", "Work Order Item", "PO Item", "Action"];

export const TABLE_FIELDS = {
  HEAT: "heat",
  HEAT_LOT: "heatLot",
  WORK_ORDER: "workOrder",
  WORK_ORDER_ITEM: "workOrderItem",
  PO_ITEM: "poItem"
};

export const COLUMN_NAMES_NEW = {
  [TABLE_FIELDS.HEAT]: "Heat #",
  [TABLE_FIELDS.HEAT_LOT]: "Heat Lot",
  [TABLE_FIELDS.WORK_ORDER]: "Work Order",
  [TABLE_FIELDS.WORK_ORDER_ITEM]: "Work Order Item",
  [TABLE_FIELDS.PO_ITEM]: "PO Item"
};

export const TRACEABILITY_TABLE_DEFAULT_VALUES = [
  {
    [TABLE_FIELDS.HEAT]: "H-91223",
    [TABLE_FIELDS.HEAT_LOT]: "L-12#",
    [TABLE_FIELDS.WORK_ORDER]: "339",
    [TABLE_FIELDS.WORK_ORDER_ITEM]: "1",
    [TABLE_FIELDS.PO_ITEM]: "1",
    rowId: generateID()
  },
  {
    [TABLE_FIELDS.HEAT]: "H-91223",
    [TABLE_FIELDS.HEAT_LOT]: "L-12#",
    [TABLE_FIELDS.WORK_ORDER]: "339",
    [TABLE_FIELDS.WORK_ORDER_ITEM]: "1",
    [TABLE_FIELDS.PO_ITEM]: "1",
    rowId: generateID()
  },
  {
    [TABLE_FIELDS.HEAT]: "H-91223",
    [TABLE_FIELDS.HEAT_LOT]: "L-12#",
    [TABLE_FIELDS.WORK_ORDER]: "339",
    [TABLE_FIELDS.WORK_ORDER_ITEM]: "1",
    [TABLE_FIELDS.PO_ITEM]: "1",
    rowId: generateID()
  },
  {
    [TABLE_FIELDS.HEAT]: "H-91223",
    [TABLE_FIELDS.HEAT_LOT]: "L-12#",
    [TABLE_FIELDS.WORK_ORDER]: "339",
    [TABLE_FIELDS.WORK_ORDER_ITEM]: "1",
    [TABLE_FIELDS.PO_ITEM]: "1",
    rowId: generateID()
  },
  {
    [TABLE_FIELDS.HEAT]: "H-91223",
    [TABLE_FIELDS.HEAT_LOT]: "L-12#",
    [TABLE_FIELDS.WORK_ORDER]: "339",
    [TABLE_FIELDS.WORK_ORDER_ITEM]: "1",
    [TABLE_FIELDS.PO_ITEM]: "1",
    rowId: generateID()
  },
  {
    [TABLE_FIELDS.HEAT]: "H-91223",
    [TABLE_FIELDS.HEAT_LOT]: "L-12#",
    [TABLE_FIELDS.WORK_ORDER]: "339",
    [TABLE_FIELDS.WORK_ORDER_ITEM]: "1",
    [TABLE_FIELDS.PO_ITEM]: "1",
    rowId: generateID()
  },
  {
    [TABLE_FIELDS.HEAT]: "H-91223",
    [TABLE_FIELDS.HEAT_LOT]: "L-12#",
    [TABLE_FIELDS.WORK_ORDER]: "339",
    [TABLE_FIELDS.WORK_ORDER_ITEM]: "1",
    [TABLE_FIELDS.PO_ITEM]: "1",
    rowId: generateID()
  },
];

export const TABLE_FIELDS_NAMES = Object.values(TABLE_FIELDS);

export const ROW_VALUES_TO_ADD = TABLE_FIELDS_NAMES.reduce((acc, value) => ({...acc, [value]: ""}), {});