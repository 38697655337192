import React from "react";
import {Button, Typography, Grid} from "@mui/material";
import {FieldArray, Formik} from "formik";
import {useHistory} from "react-router-dom";
import * as yup from "yup";
import TextField from "@core/components/FormikTextField";
import SpecificationRow from "./components/SpecificationRow";
import useStores from "../../useStores";

const EMPTY_SPECIFICATION = {
  entity: "",
  type: "",
  field: "",
  operator: "",
  value: "",
  secondValue: ""
};

const validationSchema = yup.object().shape({
  name: yup.string().required("This field is required!"),
  config: yup.array().of(yup.object().shape({
    entity: yup.string().required("This field is required!"),
  })).required(),
});

const Form = ({specification}) => {
  const {SpecificationStore} = useStores();

  const history = useHistory();

  const saveSpecification = async (values) => {
    if(specification) await SpecificationStore.update(values, specification._id);
    else {
      const data = await SpecificationStore.create(values);
      history.push(`/specifications/${data._id}`);
    }
  };

  return (
    <div className="content padded-container">
      <Formik
        onSubmit={saveSpecification}
        enableReinitialize
        initialValues={specification || {
          config: [EMPTY_SPECIFICATION],
          name: ""
        }}
        validationSchema={validationSchema}
      >
        {({values, setFieldValue, handleSubmit}) => {
          return (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography component="h5" variant="h4">
                    {specification ? "Edit digital rule set": "Create digital rule set"}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    name="name"
                    label="Name"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography component="h5" variant="h5">
                    Items
                  </Typography>
                </Grid>
              </Grid>
              <FieldArray
                name="config"
              >
                {({remove}) => values.config.map((item, index) => (
                  <SpecificationRow
                    item={item}
                    values={values}
                    index={index}
                    remove={remove}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </FieldArray>
              <Grid container spacing={5}>
                <Grid item>
                  <Button
                    onClick={() => {
                      setFieldValue("config", values.config.concat(EMPTY_SPECIFICATION));
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={5} justifyContent="flex-end">
                <Grid item>
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                  >
                    {specification ? "Save" : "Create"}
                  </Button>
                </Grid>
              </Grid>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default Form;
