import {TableBody, TableHead, Table, TableCell, TableRow} from "@mui/material";
import {useFormikContext} from "formik";
import {partition} from "ramda";
import React from "react";
import OptionalFields from "./components/OptionalFields";
import RequiredFields from "./components/RequiredFields";

const CollapsibleTable = ({title, fields, editable}) => {
  const {values} = useFormikContext();

  const [requiredFields, optionalFields] = partition((field) => field.required, fields);

  const filledFields = fields.filter((field) => values[field.name]);

  if(!editable && !filledFields.length) return null;

  return (
    <Table
      className="styled-table"
    >
      <TableHead>
        <TableRow>
          <TableCell colSpan={2}>
            {title}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <RequiredFields
          fields={requiredFields}
          editable={editable}
        />
        <OptionalFields
          fields={optionalFields}
          editable={editable}
        />
      </TableBody>
    </Table>
  );
};

export default CollapsibleTable;