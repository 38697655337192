import React, {useMemo} from "react";
import AppliedFiltersList from "@core/components/AppliedFiltersList";
import FilterButton from "@core/components/FilterButton";
import {FILTERS} from "@core/constants/filters";
import {Box, Chip, Grid} from "@mui/material";
import {uniq} from "ramda";
import {AiOutlinePlus} from "react-icons/ai";

const DISPLAY_ORDER = [FILTERS.PO_ITEM_NO, FILTERS.HEAT, FILTERS.HEAT_LOT, FILTERS.SPECIMEN_ID, FILTERS.DATE_CREATED];

const campaign = {
  products: [],
  endProducts: []
};

const FiltersList = () => {
  const specimenIds = useMemo(() => uniq(campaign.products.map((product) => product._id)), [campaign.products.length]);
  const heats = useMemo(() => uniq(campaign.products.map((product) => product.heat)), [campaign.products.length]);
  const lots = useMemo(() => uniq(campaign.products.map((product) => product.lot)), [campaign.products.length]);
  const poItems = useMemo(() => uniq(campaign.endProducts.map((product) => product.qcpItem)), [campaign.endProducts.length]);

  const optionsByType = useMemo(() => ({
    [FILTERS.SPECIMEN_ID]: specimenIds,
    [FILTERS.HEAT]: heats,
    [FILTERS.HEAT_LOT]: lots,
    [FILTERS.PO_ITEM_NO]: poItems,
  }), [specimenIds.length, heats.length, lots.length, poItems.length]);
  
  return (
    <Grid container spacing={2}>
      <Grid item>
        <FilterButton
          optionsByType={optionsByType}
          displayOrder={DISPLAY_ORDER}
        >
          <Chip
            size="small"
            onClick={() => {}}
            label={(
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                Add filter&nbsp;<AiOutlinePlus />
              </Box>
            )}
            variant="outlined"
          />
        </FilterButton>
      </Grid>
      <AppliedFiltersList
        optionsByType={optionsByType}
        displayOrder={DISPLAY_ORDER}
      />
    </Grid>
  );
};

export default FiltersList;