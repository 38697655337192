import React from "react";
import {observer} from "mobx-react-lite";
import {Box, Grid, MenuItem, OutlinedInput, Select, useTheme} from "@mui/material";
import {withStyles} from "tss-react/mui";
import TestSummaryTable from "@core/components/TestSummaryTable";
import Tests from "./components/Tests";
import TestTitleNotes from "../TestTitleNotes";
import BlockHeader from "../BlockHeader";
import useStores from "../../../../useStores";
import {useParams, useHistory, useLocation} from "react-router-dom";
import qs from "qs";
import {VIEWS, VIEWS_LABELS} from "../../constants";
import {CAMPAIGN_TYPES} from "@core/constants/campaign";
import {tail} from "ramda";
import styles from "./styles";

const TestReports = observer(({classes, id, label, setFilteredTestIds, onTestClick, tests}) => {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const {id: campaignId} = useParams();

  const {CampaignStore} = useStores();
  const campaign = CampaignStore.campaign;

  const {view = VIEWS.REPORT, tab} = qs.parse(tail(location.search)) || {};
  
  const isLoaded = campaignId === campaign._id;

  return (
    <>
      <Box sx={{marginBottom: theme.spacing(3.5)}}>
        <BlockHeader>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>Test Summary</Grid>
            <Grid item>
              {isLoaded && (
                <Grid item>
                  <Select
                    variant="standard"
                    value={view}
                    onChange={(e) => {
                      const view = e.target.value;
                      const stringified = qs.stringify({tab, view});
                      history.push(`${location.pathname}?${stringified}`);
                    }}
                    className={classes.select}
                    input={<OutlinedInput classes={{input: classes.selectOutlined}} />}>
                    <MenuItem value={VIEWS.REPORT}>{VIEWS_LABELS[VIEWS.REPORT]}</MenuItem>
                    <MenuItem value={VIEWS.GLOBAL}>{VIEWS_LABELS[VIEWS.GLOBAL]}</MenuItem>
                    {campaign.type === CAMPAIGN_TYPES.WELDING && (
                      <MenuItem value={VIEWS.AS_BUILT}>{VIEWS_LABELS[VIEWS.AS_BUILT]}</MenuItem>
                    )}
                  </Select>
                </Grid>
              )}
            </Grid>
          </Grid>
        </BlockHeader>
        <TestSummaryTable
          setFilteredTestIds={setFilteredTestIds}
          onTestClick={onTestClick}
          tests={tests}
          allTests={campaign.tests}
          TitleNotes={TestTitleNotes}
          isLoaded={isLoaded}
          collapsible
        />
      </Box>
      {(isLoaded && !!tests.length) && (
        <Tests
          id={id}
          key={tests.length}
          label={label}
          tests={tests}
        />
      )}
    </>
  );
});

export default withStyles(TestReports, styles);