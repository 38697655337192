import {Chip} from "@mui/material";
import React from "react";
import classNames from "classnames";
import {withStyles} from "tss-react/mui";
import {USER_STATUSES, USER_STATUSES_LABELS} from "@core/constants/userStatuses";
import styles from "./styles";

const UserStatus = ({classes, status}) => {
  return (
    <Chip
      size="small"
      label={USER_STATUSES_LABELS[status]}
      className={classNames({
        [classes.enabled]: status === USER_STATUSES.ENABLED,
        [classes.disabled]: status === USER_STATUSES.DISABLED,
        [classes.invited]: status === USER_STATUSES.PENDING,
      })}
    />
  );
};

export default withStyles(UserStatus, styles);