import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import WeldsList from "../WeldsList";
import useStores from "../../../../../../useStores";
import {useParams} from "react-router-dom";
import {ENTITIES} from "../../../../constants";
import TestsDrawer from "../../../TestsDrawer";

const Welds = () => {
  const {CampaignStore} = useStores();
  const [openWeld, setOpenWeld] = useState(null);
  const [openPipe, setOpenPipe] = useState(null);

  const campaign = CampaignStore.campaign;
  const {id: campaignId} = useParams();
  const isLoaded = campaignId === campaign._id;

  return (
    <>
      <WeldsList
        isLoaded={isLoaded}
        header="Welds"
        welds={campaign.welds}
        onWeldClick={setOpenWeld}
        onPipeClick={setOpenPipe}
      />
      <TestsDrawer
        index={0}
        open={!!openWeld}
        close={() => setOpenWeld(null)}
        pipes={[]}
        welds={[openWeld]}
        type={ENTITIES.WELD}
      />
      <TestsDrawer
        index={0}
        open={!!openPipe}
        close={() => setOpenPipe(null)}
        pipes={[openPipe]}
        welds={[]}
        type={ENTITIES.PIPE}
      />
    </>
  );
};

export default observer(Welds);