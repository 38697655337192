import SelectField from "@core/components/FormikSelect";
import IconistsIcon from "@core/components/IconistsIcon";
import {ICONISTS_ICONS} from "@core/constants/iconistsIcons";
import {CHEMICAL_ELEMENTS} from "@core/constants/test";
import {remove} from "ramda";
import {MenuItem, Tooltip, Box} from "@mui/material";
import {useFormikContext} from "formik";
import React from "react";

const ElementName = ({index, element, formula}) => {
  const {values, setFieldValue} = useFormikContext();

  const usedElements = values.testResults.map(({element}) => element);
  
  if(!formula) {
    return (
      <SelectField
        name={`testResults.${index}.element`}
        required
        errorMessage={null}
        onChange={(value) => {
          if(!values.acceptanceCriteria[element]) return;

          setFieldValue(`acceptanceCriteria.${value}`, values.acceptanceCriteria[element], false);
          setFieldValue(`acceptanceCriteria.${element}`, {min: undefined, max: undefined}, false);
        }}
      >
        {CHEMICAL_ELEMENTS
          .filter((value) => !usedElements.includes(value) || value === element)
          .map((value) => (
            <MenuItem key={value} value={value}>{value}</MenuItem>
          ))
        }
      </SelectField>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& span": {
          cursor: "pointer"
        }
      }}
    >
      <Tooltip title={formula}>
        <span>{element}</span>
      </Tooltip>
      <span
        onClick={() => setFieldValue("testResults", remove(index, 1, values.testResults))}
      >
        <IconistsIcon icon={ICONISTS_ICONS.circleX} />
      </span>
    </Box>
  );
};

export default ElementName;