import React, {useEffect, useCallback, useState, useMemo} from "react";
import {Typography, Box, InputAdornment, Grid, CircularProgress} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {keys, map, prop} from "ramda";
import Table from "@core/components/Table";
import TableFooter from "@core/components/TableFooter";
import {useRouteMatch} from "react-router-dom";
import {observer} from "mobx-react-lite";
import useStores from "../../../useStores";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import {Input} from "@core/components/Form";
import TableNavigation from "@core/components/TableNavigation";
import ColumnsSelect from "@core/components/ColumnsSelect";
import {TEST_STATUSES} from "./data";
import styles from "./styles";
import useFetchTableDataByQueryParam from "@core/hooks/useFetchTableDataByQueryParam";
import useSetInitialViewQueryParam from "@core/hooks/useSetInitialViewQueryParam";
import {STORE_DATA} from "@core/constants/storeDataKeys";
import {setRowsPerPage} from "@core/helpers";

const List = observer(({classes, children, testStatuses, currentTab, columns}) => {
  const {TestStore} = useStores();

  const match = useRouteMatch();

  const {status, data: tests, total, fetch} = TestStore.tests;

  const savedSelectedColumns = JSON.parse(localStorage.getItem(match.path));
  const [selectedColumns, setSelectedColumns] = useState(savedSelectedColumns || map(prop("dataIndex"), columns));

  useEffect(() => {
    return () => TestStore.tests.reset();
  }, []);

  const DEFAULT_TAB_VIEW = useMemo(() => keys(testStatuses)[0], [testStatuses]);
  useSetInitialViewQueryParam(DEFAULT_TAB_VIEW);

  useFetchTableDataByQueryParam({
    getStore: () => TestStore,
    dataKey: STORE_DATA.tests,
    matchPath: match.path,
    defaultParams: {statuses: TEST_STATUSES[currentTab]}
  });

  const handleRowsPerPageChange = (limit) => {
    setRowsPerPage(match.path, limit);
    TestStore.tests.load({limit, offset: 0});
  };

  const searchAPIDebounced = AwesomeDebouncePromise((search) => {
    TestStore.tests.load({search, offset: 0});
  }, 750);

  const handleSearch = useCallback(async (event) => {
    await searchAPIDebounced(event.target.value);
  }, []);

  return (
    <div className={classes.page}>
      <Grid container className={classes.pageHeadline} spacing={3}>
        <Grid item>
          <Typography variant="h4" fontSize="1.8rem">
            Tests
          </Typography>
        </Grid>
        <Grid item container xs={6} spacing={3}>
          {children}
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid item xs>
          <Input
            name="search"
            endAdornment={
              <InputAdornment position="start">
                {status.loading && <CircularProgress size={20} />}
              </InputAdornment>
            }
            placeholder={"Search Tests"}
            onChange={handleSearch}
          />
        </Grid>
        <Grid item>
          <ColumnsSelect
            columns={columns}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
          />
        </Grid>
      </Grid>
      <Box className={classes.pageTable}>
        <TableNavigation
          views={keys(testStatuses)}
          currentView={currentTab}
          defaultView={keys(testStatuses)[0]}
          link="/tests"
          query="view"
        />
        <Table
          selectedColumns={selectedColumns}
          items={tests}
          columns={columns}
          isLoaded={status.loaded}
          sort={fetch.sort}
          setSort={(sort) => TestStore.tests.load({sort})}
          saveSortToQueryParams
          total={total}
        >
          <TableFooter
            saveTablePageToQueryParams
            isLoaded={status.loaded}
            items={tests}
            total={total}
            limit={fetch.limit}
            offset={fetch.offset}
            onOffsetChange={(offset) => TestStore.tests.load({offset})}
            onLimitChange={handleRowsPerPageChange}
          />
        </Table>
      </Box>
    </div>
  );
});

export default withStyles(List, styles);
