import AcceptanceCriteriaLabel from "@core/components/AcceptanceCriteriaLabel";
import React from "react";
import {Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";
import {TEST_UNITS} from "@core/constants/test";
import {MEASUREMENTS_CONFIG, LOCATIONS} from "@core/components/TestForms/DimensionalTest/data";
const AcceptanceTable = ({config, poItem}) => {
  const units = config.units || TEST_UNITS.METRIC;

  const getMinMax = (localConfig = {}, poItem) => {
    const {nominalFormula, tolerancePlusFormula, toleranceMinusFormula} = localConfig;
    const {nominal, toleranceMinus = 0, tolerancePlus = 0} = localConfig;

    const nominalValue = nominalFormula ? nominalFormula.bind(localConfig)({}, poItem) : nominal;
    const tolerancePlusValue = tolerancePlusFormula ? tolerancePlusFormula.bind(localConfig)({}, poItem) : tolerancePlus;
    const toleranceMinusValue = toleranceMinusFormula ? toleranceMinusFormula.bind(localConfig)({}, poItem) : toleranceMinus;

    const min = Number(nominalValue) - Number(toleranceMinusValue);
    const max = Number(nominalValue) + Number(tolerancePlusValue);

    return {
      min: min ? min.toFixed(2) : "",
      max: max ? max.toFixed(2) : ""
    };
  };

  return (
    <Table className="styled-table">
      <TableHead>
        <TableRow>
          <TableCell
            rowSpan={2}
          />
          {Object.keys(config.data).map((measurement) => (
            <TableCell
              key={measurement}
              rowSpan={MEASUREMENTS_CONFIG[measurement].locations ? 1 : 2}
              colSpan={MEASUREMENTS_CONFIG[measurement].locations ?
                MEASUREMENTS_CONFIG[measurement].locations.length :
                0}
            >
              {MEASUREMENTS_CONFIG[measurement].title} [{MEASUREMENTS_CONFIG[measurement].units[units]}]
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          {Object.keys(config.data).map((measurement) => {
            if (!MEASUREMENTS_CONFIG[measurement].locations) {
              return null;
            }

            return MEASUREMENTS_CONFIG[measurement].locations.map((location) => (
              <TableCell key={measurement + location}>
                {LOCATIONS[location]}
              </TableCell>
            ));
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>
            <AcceptanceCriteriaLabel />
          </TableCell>
          {Object.keys(config.data).map((measurement) => {
            if (!MEASUREMENTS_CONFIG[measurement].locations) {
              const {min, max} = getMinMax(config.data[measurement], poItem);

              return <TableCell>{min} - {max}</TableCell>;
            }

            return MEASUREMENTS_CONFIG[measurement].locations.map((location) => {
              const name = location === "body" ? location : "ends";

              const {min, max} = getMinMax(config.data[measurement][name], poItem);

              return <TableCell>{min} - {max}</TableCell>;
            });
          })}
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default AcceptanceTable;