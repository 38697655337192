export default (theme) => ({
  title: {
    marginBottom: 12,
  },
  container: {
    background: "#f7f7f7",
    border: "1px solid #ced4da",
    borderRadius: 4,
    padding: 15,
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
  }
});