import React, {useState} from "react";
import {keys, omit} from "ramda";
import {Chip, Grid} from "@mui/material";
import {withStyles} from "tss-react/mui";
import ChipPopover from "./components/ChipPopover";
import styles from "./styles";
import {FILTERS} from "@core/constants/filters";
import useStores from "../../../useStores";
import {observer} from "mobx-react";

const AppliedFiltersList = observer(({classes, optionsByType, ...props}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [type, setType] = useState(null);

  const {FilterStore} = useStores();

  const {filters, setFilters} = FilterStore;

  const displayOrder = props.displayOrder || keys(optionsByType);

  return (
    <>
      {displayOrder.map((name) => {
        if (!filters[name] || !filters[name].length) return null;

        const separator = name === FILTERS.DATE_CREATED ? "/" : ", ";
        const selectedOptions = filters[name].join(separator);

        return (
          <Grid item>
            <Chip
              classes={{root: classes.chip, label: classes.chipLabel}}
              key={name}
              color="primary"
              label={`${name}: ${selectedOptions}`}
              onDelete={() => setFilters(omit([name], filters))}
              clickable
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setType(name);
              }}
            />
          </Grid>
        );
      })}
      <ChipPopover
        anchorEl={anchorEl}
        type={type}
        filters={filters}
        setFilters={setFilters}
        onClose={() => setAnchorEl(null)}
        options={optionsByType[type] || []}
      />
    </>
  );
});

export default withStyles(AppliedFiltersList, styles);