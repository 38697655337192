import React, {useState} from "react";
import {Checkbox, FormControlLabel, Grid} from "@mui/material";
import isExperimentalDesignEnabled from "@core/helpers/isExperimentalDesignEnabled";

const NewDesignCheckbox = () => {
  const [checked, setChecked] = useState(() => isExperimentalDesignEnabled());

  const onCheckBoxChange = () => {
    setChecked((prev) => {
      const next = !prev;
      next ? localStorage.setItem("experimentalDesign", "true") : localStorage.setItem("experimentalDesign", "false");

      return next;
    });
  };

  return (
    <Grid item xs={12} marginY={4}>
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={onCheckBoxChange}/>}
        label={"Try out new design (Refresh page to apply)"}/>
    </Grid>
  );
};

export default NewDesignCheckbox;