import React, {useCallback, memo, useMemo, useRef, useEffect} from "react";
import {Group} from "react-konva";
import {toLower, isNil, splitEvery, last, uniq} from "ramda";
import qs from "qs";
import {observer} from "mobx-react-lite";
import {useHistory, useLocation} from "react-router-dom";
import useStores from "../../../../../../../../useStores";
import {getDefectReports} from "../../../../../../helpers";
import Label from "../Label";
import StalkRows from "./components/StalkRows";
import LineWeld from "./components/LineWeld";
import {
  PAGE_PADDING,
  SPACING_BETWEEN_STALKS,
  STALK_NUMBER_CONFIG,
} from "../../constants";
import {PAGE_TABS} from "../../../../constants";
import {ENTITIES} from "../../../../../../constants";
import {TIE_IN_WELD_CONFIG, STALK_NUMBER_WIDTH} from "./constants";
import {getPipeDefectPositions, getPipeLength, getSortedStalkEntities, getWeldDefectPositions} from "../../helpers";

const Stalk = observer(({stalksRef, animateShape, scale, stalkIndex, settings, setOpenEntityIndex, stalks, stalk, setEntitiesToView, tieInWeldsByStalkNumber, state, label, numberOfStalkItemsInRow, pipeConfig, weldConfig, stalksNumbers}) => {
  const {CampaignStore} = useStores();

  const history = useHistory();
  const location = useLocation();

  const stalkRef = useRef();
  const pipes = useRef({});
  const welds = useRef({});

  const stalkNumber = useMemo(() => !isNil(settings.stalkNumber) ? settings.stalkNumber : stalk[0].stalkNumber, [settings.stalkNumber, stalkIndex]);

  const stalkLength = useMemo(() => last(stalk).position + 1, [stalkNumber]);

  const stalkWithUniqWelds = useMemo(() => {
    const uniqueWelds = stalk.reduce((acc, weld) => {
      acc[weld.position] = weld;

      return acc;
    }, []);

    return uniqueWelds;
  }, [stalkNumber]);

  const chunks = useMemo(() => {
    if(stalkLength <= numberOfStalkItemsInRow || !numberOfStalkItemsInRow) return [stalkWithUniqWelds];

    const chunks = splitEvery(Math.abs(numberOfStalkItemsInRow), stalkWithUniqWelds);

    return chunks;
  }, [stalkLength, numberOfStalkItemsInRow, stalkNumber]);

  const tieInWeld = useMemo(() => {
    const nextStalk = stalks[stalkIndex + 1];

    return nextStalk ? tieInWeldsByStalkNumber[stalkNumber] || {} : {};
  }, [stalkNumber, tieInWeldsByStalkNumber, stalks, stalkIndex]);

  const rowYBase = useMemo(() => pipeConfig.HEIGHT + SPACING_BETWEEN_STALKS, []);

  const height = useMemo(() => rowYBase * chunks.length, [rowYBase, chunks.length]);

  const storedY = stalksRef.current[stalkIndex - 1];

  const y = useMemo(() => storedY || 0, [storedY]);

  const nextY = useMemo(() => y + height, [y, height]);

  stalksRef.current[stalkIndex] = nextY;

  const sortedStalkEntities = useMemo(() => getSortedStalkEntities(stalk), [stalk]);

  const {pipe, weld} = useMemo(() => qs.parse(location.search, {ignoreQueryPrefix: true}), [location.search]);

  const totalLength = useMemo(() => {
    const lastWeld = last(sortedStalkEntities.welds);

    return getPipeLength(state, lastWeld, CampaignStore.pipeLengthByWeldId, stalksNumbers, tieInWeldsByStalkNumber);
  }, [stalkNumber]);

  const defectReports = useMemo(() => getDefectReports(CampaignStore.campaign.tests), [CampaignStore.campaign.tests.length]);

  const defectPositionsByPipeId = useMemo(() => {
    if(!settings.highlightCoatingDefects) return {};

    return sortedStalkEntities.pipes.reduce((defectsByPipeId, pipe, index) => {
      const prevWeldId = sortedStalkEntities.pipes[index - 1]?.weldNumber;
      const nextWeldId = pipe.weldNumber;

      defectsByPipeId[pipe._id] = uniq(getPipeDefectPositions(defectReports, [prevWeldId, nextWeldId]));

      return defectsByPipeId;
    }, {});
  }, [stalkNumber]);

  const defectPositionsByWeldId = useMemo(() => {
    if(!settings.highlightCoatingDefects) return {};

    return sortedStalkEntities.welds.reduce((defectPositionsByWeldId, weld) => {
      defectPositionsByWeldId[weld._id] = uniq(getWeldDefectPositions(defectReports, [weld.weldNumber]));

      return defectPositionsByWeldId;
    }, {});
  }, [stalkNumber]);

  const setPipeData = useCallback(() => {
    const data = sortedStalkEntities.pipes.find(({pipeNumber}) => pipeNumber === pipe);

    if(!data) return;

    setOpenEntityIndex(data.position);
    setEntitiesToView(sortedStalkEntities);
  }, [stalkNumber, pipe]);

  const setWeldData = useCallback(() => {
    const index = sortedStalkEntities.welds.findIndex(({weldNumber}) => weldNumber === weld);

    if(index === -1) return;

    setOpenEntityIndex(index);
    setEntitiesToView(sortedStalkEntities);
  }, [weld]);

  const setTieInWeldData = useCallback(() => {
    setOpenEntityIndex(0);
    setEntitiesToView({welds: [tieInWeld], pipes: []});
  }, [stalkNumber, weld]);

  useEffect(() => {
    const {stalk} = qs.parse(location.search, {ignoreQueryPrefix: true});

    if(stalk !== stalkNumber) return;

    if(pipe) setPipeData();
    else if(tieInWeld.weldNumber === weld) setTieInWeldData();
    else setWeldData();
  }, [pipe, weld]);

  const setOpenPipe = useCallback((id) => {
    history.push({
      pathname: history.pathname,
      search: qs.stringify({
        tab: toLower(PAGE_TABS.PIPELINE),
        stalk: stalkNumber,
        [ENTITIES.PIPE]: id,
      }),
    });
  }, [stalkNumber]);

  const setOpenWeld = useCallback((id) => {
    history.push({
      pathname: history.pathname,
      search: qs.stringify({
        tab: toLower(PAGE_TABS.PIPELINE),
        stalk: stalkNumber,
        [ENTITIES.WELD]: id
      }),
    });
  }, [stalkNumber]);

  return (
    <Group x={0} y={y} ref={stalkRef}>
      <Label
        width={STALK_NUMBER_WIDTH}
        label={`${label}: ${stalkNumber}`}
        y={(pipeConfig.HEIGHT - STALK_NUMBER_CONFIG.FONT_SIZE) / 2}
      />
      <StalkRows
        chunks={chunks}
        numberOfStalkItemsInRow={numberOfStalkItemsInRow}
        stalkLength={stalkLength}
        settings={settings}
        stalk={stalk}
        pipes={pipes}
        welds={welds}
        animateShape={animateShape}
        scale={scale}
        setOpenPipe={setOpenPipe}
        setOpenWeld={setOpenWeld}
        state={state}
        pipeConfig={pipeConfig}
        weldConfig={weldConfig}
        totalLength={totalLength}
        defectPositionsByPipeId={defectPositionsByPipeId}
        defectPositionsByWeldId={defectPositionsByWeldId}
        tieInWeldsByStalkNumber={tieInWeldsByStalkNumber}
        stalksNumbers={stalksNumbers}
      />
      {settings.tieInWelds && (
        <Group x={STALK_NUMBER_WIDTH + PAGE_PADDING}>
          <LineWeld
            stalkChunks={chunks}
            settings={settings}
            scale={scale}
            weldNumber={tieInWeld.weldNumber}
            weldConfig={{...weldConfig, ...TIE_IN_WELD_CONFIG}}
            pipeConfig={pipeConfig}
            setOpenWeld={setOpenWeld}
            totalLength={totalLength}
            defects={defectPositionsByWeldId[tieInWeld._id]}
          />
        </Group>
      )}
    </Group>
  );
});

export default memo(Stalk);