import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {TextField, Button, Typography} from "@mui/material";
import {withStyles} from "tss-react/mui";
import CircularProgress from "@mui/material/CircularProgress";
import userService from "@core/api/user-service";
import styles from "./styles";

const EmailLogin = observer(({classes, login, user}) => {
  const [loading, setLoading] = useState(false);
  const [totp, setTotp] = useState("");
  const [totpSent, setSent] = useState(false);

  const sendTotpToEmail = async () => {
    setLoading(true);
    await userService.generateTotp();
    setLoading(false);
    setSent(true);
  };

  useEffect(() => {
    if(!user.secret) return;

    sendTotpToEmail();
  }, []);

  const validateTotp = async () => {
    if(!totp) return;

    const {data} = await userService.validateTotp({totp});
    login(data);
  };

  const onSubmit = () => {
    if(loading) return;

    if(!totpSent) sendTotpToEmail();
    else validateTotp();
  };

  const [username, domain] = user.email.split("@");

  return (
    <>
      <Typography className={classes.description} align="left">
        {!totpSent ?
          "Extra secure! You'll need an e-mail verification code to continue logging in to your account." :
          `We've sent a code to this email address ${username.charAt(0)}**@${domain}. Add it below to finish logging in.`
        }
      </Typography>
      {totpSent && (
        <TextField
          onKeyDown={(e) => {
            if (e.key !== "Enter") return;

            onSubmit();
          }}
          autoFocus
          className={classes.input}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          autoComplete="off"
          name="verificationCode"
          label="Verification code"
          onChange={(e) => setTotp(e.target.value.split(" ").join(""))}
          value={totp}
          InputLabelProps={{
            shrink: true
          }}
        />
      )}
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        size="large"
        className={classes.submit}
        onClick={onSubmit}
      >
        {loading && <CircularProgress classes={{colorPrimary: classes.loader}} size={24} />}&nbsp;
        <span>{!totpSent ? "Send verification code" : "Login"}</span>
      </Button>
    </>
  );
});

export default withStyles(EmailLogin, styles);