import AcceptanceCriteriaLabel from "@core/components/AcceptanceCriteriaLabel";
import CollapsibleTable from "@core/components/CollapsibleTable";
import {PALMQVIST_TYPES, STRESS_INTENSITY_FACTOR_UNIT} from "../../constants";
import {TEST_UNITS} from "@core/constants/test";
import {keys, values as getValues} from "ramda";
import React from "react";
import {useFormikContext} from "formik";

const TestParameters = ({editable}) => {
  const {values, setFieldValue} = useFormikContext();

  return (
    <CollapsibleTable
      editable={editable}
      title="Test Parameters"
      fields={[{
        name: "temperature",
        label: "Test Temperature",
        type: "number",
        endAdornment: "°C",
        inputProps: {min: 0},
        required: true,
      }, {
        name: "indentationLoad",
        label: "Indentation Load",
        type: "number",
        endAdornment: "kgf",
        inputProps: {min: 0},
        required: true,
      }, {
        name: "palmqvist",
        label: "Palmqvist",
        options: keys(PALMQVIST_TYPES),
        required: true,
      }, {
        name: "palmqvistUnit",
        label: "Palmqvist Unit",
        options: getValues(PALMQVIST_TYPES[values.palmqvist]?.units),
        disabled: !values.palmqvist,
        onChange: (value) => {
          const isMetric = value === PALMQVIST_TYPES[values.palmqvist]?.units[TEST_UNITS.METRIC];
          const unit = isMetric ? STRESS_INTENSITY_FACTOR_UNIT[TEST_UNITS.METRIC] : STRESS_INTENSITY_FACTOR_UNIT[TEST_UNITS.IMPERIAL];

          setFieldValue("stressIntensityFactorUnit", unit, false);
        },
        required: true,
      }, {
        name: "surfacePreparation",
        label: "Surface Preparation",
      }, {
        name: "acceptanceCriteria.min",
        label: <AcceptanceCriteriaLabel min/>,
        type: "number",
        inputProps: {min: 0},
        endAdornment: values.stressIntensityFactorUnit,
      }, {
        name: "acceptanceCriteria.max",
        label: <AcceptanceCriteriaLabel max/>,
        type: "number",
        inputProps: {min: values.acceptanceCriteria.min || 0},
        endAdornment: values.stressIntensityFactorUnit,
      }]}
    />
  );
};

export default TestParameters;