import React, {useMemo} from "react";
import AppWrapperNewDesign from "@core/components/AppWrapper/components/AppWrapperNewDesign";
import AppWrapperOldDesign from "@core/components/AppWrapper/components/AppWrapperOldDesign";
import isExperimentalDesignEnabled from "@core/helpers/isExperimentalDesignEnabled";
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";

const AppWrapper = ({children}) => {
  const experimentalDesignEnabled = useMemo(() => isExperimentalDesignEnabled(), []);

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  // temporary solution until we remove/refactor class components that are complex/dangerous to refactor to a functional component
  const childrenWithProps = React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      location,
      match,
      history,
    });
  });

  if (experimentalDesignEnabled) {
    return <AppWrapperNewDesign children={childrenWithProps} />;
  } else {
    return <AppWrapperOldDesign children={childrenWithProps} />;
  }
};

export default AppWrapper;