import React from "react";
import {Route, Redirect, useLocation} from "react-router-dom";
import {compose} from "ramda";
import {inject, observer} from "mobx-react";
import AppWrapper from "@core/components/AppWrapper";
import {ACCESSED_ROUTE} from "@core/constants/accessedRoute";

const ProtectedRoute = (props) => {
  const {UserStore: {user}, modules, roles, children, exact, path} = props;

  const location = useLocation();

  if (!user.isLoaded) {
    const {pathname, search} = location;
    sessionStorage.setItem(ACCESSED_ROUTE, JSON.stringify({pathname, search}));

    return <Redirect to={"/login"}/>;
  }

  const userCompanyModule = user.data.company.modules[0].name;
  const userRole = user.data.role;

  const isAppropriateModule = !modules || modules.includes(userCompanyModule);
  const isAppropriateRole = !roles || roles.includes(userRole);

  const hasAccess = isAppropriateModule && isAppropriateRole;
  
  return (
    <Route exact={exact} path={path}>
      {hasAccess ? (
        <AppWrapper>{children}</AppWrapper>
      ) : (
        <Redirect to={"/"} />
      )}
    </Route>
  );
};

export default compose(inject("UserStore"), observer)(ProtectedRoute);
