import {STORE_DATA} from "@core/constants/storeDataKeys";
import {setRowsPerPage} from "@core/helpers";
import useFetchTableDataByQueryParam from "@core/hooks/useFetchTableDataByQueryParam";
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import Table from "@core/components/Table";
import TableFooter from "@core/components/TableFooter";
import {Button, Typography, Grid} from "@mui/material";
import {Link} from "react-router-dom";
import useStores from "../../useStores";
import {columns} from "./columns";

const SingleMaterialCampaigns = ({match, history}) => {
  const {SingleMaterialCampaignStore} = useStores();

  useEffect(() => {
    return () => SingleMaterialCampaignStore.campaigns.reset();
  }, []);

  useFetchTableDataByQueryParam({
    getStore: () => SingleMaterialCampaignStore,
    dataKey: STORE_DATA.campaigns,
    matchPath: match.path,
  });

  const handleRowsPerPageChange = (limit) => {
    setRowsPerPage(match.path, limit);
    SingleMaterialCampaignStore.campaigns.load({limit, offset: 0});
  };

  const {status, data: campaigns, total, fetch} = SingleMaterialCampaignStore.campaigns;

  return (
    <div className="padded-container">
      <Grid container spacing={3}>
        <Grid item>
          <Typography variant="h4" fontSize="1.8rem">
            Single Material Campaigns
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/single-material-campaigns/create"
          >
            Create new
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Table
            items={campaigns}
            columns={columns}
            isLoaded={status.loaded}
            sort={fetch.sort}
            setSort={(sort) => SingleMaterialCampaignStore.campaigns.load({sort})}
            saveSortToQueryParams
            total={total}
            onRowClick={(campaign) => history.push(`/single-material-campaigns/${campaign._id}`)}
            noDataText="No available campaigns."
          >
            <TableFooter
              saveTablePageToQueryParams
              isLoaded={status.loaded}
              items={campaigns}
              total={total}
              limit={fetch.limit}
              offset={fetch.offset}
              onOffsetChange={(offset) => SingleMaterialCampaignStore.campaigns.load({offset})}
              onLimitChange={handleRowsPerPageChange}
            />
          </Table>
        </Grid>
      </Grid>
    </div>
  );
};

export default observer(SingleMaterialCampaigns);