import AcceptanceCriteriaLabel from "@core/components/AcceptanceCriteriaLabel";
import {HARDNESS_UNITS} from "@core/constants/test";
import CollapsibleTable from "../../../../CollapsibleTable";
import React from "react";

const Specimen = ({editable}) => {
  return (
    <CollapsibleTable
      editable={editable}
      title="Specimen"
      fields={[{
        name: "preparation",
        label: "Preparation",
        required: true,
      }, {
        name: "cleaning",
        label: "Cleaning",
        required: true,
      }, {
        name: "initialSurfaceRoughness",
        label: "Initial Surface Roughness",
        type: "number",
        endAdornment: "micron rms",
        inputProps: {min: 0},
        required: true,
      }, {
        name: "hardnessMeasurementUnit",
        label: "Hardness Measurement Unit",
        options: HARDNESS_UNITS,
      }, {
        name: "acceptanceCriteria",
        label: <AcceptanceCriteriaLabel />,
        type: "number",
        endAdornment: "mm^3/kg",
        inputProps: {min: 0},
      }]}
    />
  );
};

export default Specimen;