import React from "react";
import {useHistory} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {MenuItem} from "@mui/material";
import {withStyles} from "tss-react/mui";
import ActionCell from "@core/components/ActionCell";
import styles from "./styles";
import useStores from "../../../../useStores";

const Actions = observer(({classes, onDelete, project}) => {
  const {UserStore} = useStores();

  const history = useHistory();

  return (
    <ActionCell>
      <MenuItem onClick={() => {
        history.push(`/projects/${project._id}`);
      }}>
        View
      </MenuItem>
      {project.createdBy._id === UserStore.user.data._id && (
        <MenuItem
          className={classes.deleteButton}
          onClick={onDelete}
        >
          Delete
        </MenuItem>
      )}
    </ActionCell>
  );
});

export default withStyles(Actions, styles);