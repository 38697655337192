import TestResultTableCell from "@core/components/TestResultTableCell";
import AcceptanceRow from "@core/components/TestViews/PositiveMaterialIdentification/components/AcceptanceRow";
import {Box, Table, TableBody, TableCell, TableHead, TableRow, Tooltip} from "@mui/material";
import React from "react";

const TestResults = ({test}) => {
  const acceptanceCriteria = test.properties.acceptanceCriteria || {};

  const withMin = test.properties.testResults.some((result) => acceptanceCriteria[result.element]?.min);
  const withMax = test.properties.testResults.some((result) => acceptanceCriteria[result.element]?.max);

  return (
    <Box sx={{overflowX: "scroll"}}>
      <Table className="styled-table">
        <TableHead>
          <TableRow>
            <TableCell>Element</TableCell>
            {test.properties.testResults.map((result) => (
              <TableCell key={`element-name-${result.element}`}>
                {result.formula ? (
                  <Tooltip title={result.formula}>
                    {result.element}
                  </Tooltip>
                ) : result.element}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {withMin && (
            <AcceptanceRow
              min
              test={test}
            />
          )}
          {withMax && (
            <AcceptanceRow
              max
              test={test}
            />
          )}
          {test.properties.specimenIds.map((specimenId, specimenIndex) => (
            <TableRow key={specimenIndex}>
              <TableCell>{specimenId}</TableCell>
              {test.properties.testResults.map((result) => (
                <TableCell key={`element-value-${result.element}`}>
                  <TestResultTableCell
                    value={result.values[specimenIndex] || 0}
                    max={acceptanceCriteria[result.element]?.max}
                    min={acceptanceCriteria[result.element]?.min}
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default TestResults;