import AcceptanceCriteriaLabel from "@core/components/AcceptanceCriteriaLabel";
import File from "@core/components/File";
import TestFormDimensionalTest from "@core/components/TestForms/DimensionalTest";
import TableAcceptanceRow from "@core/components/TestForms/DimensionalTest/components/TableAcceptanceRow";
import {LOCATIONS, MEASUREMENTS_CONFIG} from "@core/components/TestForms/DimensionalTest/data";
import {CUSTOM_EXCEPTIONS} from "@core/components/TestForms/DimensionalTest/exceptions";
import {isValueAcceptable} from "@core/components/TestForms/DimensionalTest/services";
import {getPoItemNumber} from "@core/helpers";
import {
  Dialog,
  DialogContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import classNames from "classnames";
import {observer} from "mobx-react";
import {is, keys, omit, uniq, flatten} from "ramda";
import React, {useMemo} from "react";
import {withStyles} from "tss-react/mui";
import useStores from "../../../../useStores";
import {sortElements} from "./services";
import styles from "./styles";

const IMPORTED_LOCATIONS = {
  body: ["bodyMin", "bodyMax"],
  leftEnd: ["leftEndMin", "leftEndMax"],
  rightEnd: ["rightEndMin", "rightEndMax"]
};

const getMaxElementsLocationsNumber = (elements, measurement) => {
  if (!MEASUREMENTS_CONFIG[measurement].locations) return 0;

  return Math.max(...elements.map((element) => {
    const measurementsLocationsNumber = MEASUREMENTS_CONFIG[measurement].locations.filter((location) => element[measurement] && element[measurement][location]).length;
    const importedLocationsNumber = MEASUREMENTS_CONFIG[measurement].locations.reduce((acc, location) => {
      if (IMPORTED_LOCATIONS[location].some((location) => element[measurement] && element[measurement][location])) acc += 1;

      return acc;
    }, 0);

    return measurementsLocationsNumber + importedLocationsNumber;
  }));
};

const formatRangeToValue = (min, max) => min && max ? `${min} - ${max}` : min || max;

const DimensionalTest = observer(({classes, productId, test, addDataOpen, dataDialogClose, updateTest, formRef}) => {
  const columns = test.properties.elements ? uniq(flatten(test.properties.elements.map((element) => keys(omit(["productId", "file", "result", "internalProductId"], element))))) : [];

  const {UserStore, CertificateStore} = useStores();

  const user = UserStore.user.data;
  const certificate = CertificateStore.certificate.data;
  
  const elements = useMemo(() => productId ? test.properties.elements.filter((element) => element.productId === productId) : test.properties.elements, [productId, test.properties]);

  const columnsToShow = columns.filter((column) => {
    const locationsNumber = getMaxElementsLocationsNumber(test.properties.elements, column);

    return MEASUREMENTS_CONFIG[column].locations ? locationsNumber : test.properties.elements.some((element) => element[column]);
  });

  const showFileCell = test.properties.elements && test.properties.elements.some((element) => element.file);

  const qcpItem = test.properties.acceptanceItem;
  const poItemNumber = getPoItemNumber(qcpItem || certificate.lineItem);

  const sortedElements = useMemo(() => sortElements(elements, columnsToShow, poItemNumber, test.properties.units, test.properties.acceptanceCriteria, test.properties.acceptance), [test.properties]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h3>Test Information:</h3>
        </Grid>
        <Grid item xs={12}>
          <Table className={classNames("styled-table", classes.table)}>
            <TableHead>
              <TableRow>
                {Boolean(test.properties.client || test.company) &&<TableCell>Client</TableCell>}
                {Boolean(test.properties.lab || test.assignee) &&<TableCell>Laboratory</TableCell>}
                <TableCell>Material specification</TableCell>
                <TableCell>Grade / UNS</TableCell>
                {test.properties.acceptance && <TableCell>QCP/ITP</TableCell>}
                {test.properties.acceptanceItem && <TableCell>QCP Item</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {Boolean(test.properties.client || test.company) &&<TableCell>{test.company?.name || test.properties.client || "-"}</TableCell>}
                {Boolean(test.properties.lab || test.assignee) &&<TableCell>{test.assignee?.name || test.properties.lab || "-"}</TableCell>}
                <TableCell>{test.properties.norm}</TableCell>
                <TableCell>{test.properties.grade}</TableCell>
                {test.properties.acceptance && <TableCell>{test.properties.acceptance}</TableCell>}
                {test.properties.acceptanceItem && <TableCell>{test.properties.acceptanceItem}</TableCell>}
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        {Boolean(test.properties.elements && test.properties.elements.length) && (
          <>
            <Grid item xs={12}>
              <h3>Test Result:</h3>
            </Grid>
            <Grid item xs={12} className={classNames(classes.tableContainer, "overflow-table")}>
              <Table className={classNames("styled-table", classes.table)}>
                <TableHead className={classes.stickyHeader}>
                  <TableRow>
                    <TableCell padding="none" align="center" rowSpan={2}
                      className={classNames(classes.stickyColumn, classes.firstStickyColumn)}>
                          Product ID
                    </TableCell>
                    {columnsToShow.map((column) => (
                      <TableCell
                        padding="none"
                        align="center"
                        colSpan={MEASUREMENTS_CONFIG[column].locations ? getMaxElementsLocationsNumber(test.properties.elements, column) : 1}
                        rowSpan={MEASUREMENTS_CONFIG[column].locations ? 1 : 2}
                      >
                        {MEASUREMENTS_CONFIG[column].title}&nbsp;
                        [{is(Object, test.properties.units) ? test.properties.units[column] : MEASUREMENTS_CONFIG[column].units[test.properties.units]}]
                      </TableCell>
                    )
                    )}
                    {showFileCell && (
                      <TableCell padding="none" align="center" rowSpan={2}>
                              File
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    {columnsToShow.map((column) => {
                      if (!MEASUREMENTS_CONFIG[column].locations) {
                        return null;
                      }

                      return MEASUREMENTS_CONFIG[column].locations.map((location) => {
                        const importedLocations = IMPORTED_LOCATIONS[location];

                        if (!test.properties.elements.some((element) => {
                          const measurement = element[column] || {};

                          return measurement[location] || importedLocations.find((il) => measurement[il]);
                        })) {
                          return null;
                        }

                        return (
                          <TableCell
                            align="center"
                            padding="none"
                            style={showFileCell ? {borderRight: "1px solid #E0E0E0"} : {}}
                          >
                            {LOCATIONS[location]}
                          </TableCell>
                        );
                      });
                    })}
                  </TableRow>
                  {test.properties.acceptance && (
                    <TableRow>
                      <TableCell padding="none" align="center" rowSpan={2} className={classNames(classes.stickyColumn, classes.firstStickyColumn)}>
                        <AcceptanceCriteriaLabel />
                      </TableCell>
                      <TableAcceptanceRow
                        acceptance={test.properties.acceptance}
                        measurements={columnsToShow}
                        units={test.properties.units}
                        acceptanceCriteria={test.properties.acceptanceCriteria}
                        poItem={poItemNumber}
                        isLocationVisible={(location, measurement) => {
                          return test.properties.elements.some((element) => {
                            const value = element[measurement] || {};

                            return value[location] || IMPORTED_LOCATIONS[location].some((il) => value[il]);
                          });
                        }}
                      />
                    </TableRow>
                  )}
                </TableHead>
                <TableBody>
                  {sortedElements.map((element) => (
                    <TableRow>
                      <TableCell align="center" padding="none" className={classes.stickyColumn}>
                        {element.productId}
                      </TableCell>
                      {columnsToShow.map((column) => {
                        const standardUnits = test.properties.units[column] || MEASUREMENTS_CONFIG[column].units[test.properties.units];
                        const acceptanceConfig = test.properties.acceptanceCriteria && test.properties.acceptanceCriteria[column] || {};

                        if (!MEASUREMENTS_CONFIG[column].locations) {
                          const value = element[column];
                          const exceptionsFormulas = CUSTOM_EXCEPTIONS[test.properties.acceptance]?.data[column] || {};

                          const isAcceptable = isValueAcceptable({
                            element,
                            value,
                            standardUnits,
                            ...acceptanceConfig,
                            ...exceptionsFormulas,
                            poItem: poItemNumber,
                            acceptanceCriteria: acceptanceConfig,
                          });

                          return (
                            <TableCell
                              align="center"
                              padding="none"
                              className={classNames({
                                [classes.info]: !acceptanceConfig,
                                [classes.success]: acceptanceConfig && isAcceptable,
                                [classes.error]: acceptanceConfig && !isAcceptable,
                              })}
                            >
                              {element[column] || "-"}
                            </TableCell>
                          );
                        }

                        return MEASUREMENTS_CONFIG[column].locations.map((location) => {
                          if (!test.properties.elements.some((element) => {
                            const measurement = element[column] || {};

                            return measurement[location] || IMPORTED_LOCATIONS[location].some((il) => measurement[il]);
                          })) {
                            return null;
                          }

                          const name = location === "body" ? "body" : "ends";

                          const locationAcceptanceConfig = acceptanceConfig[name] || {};
                          const measurementException = CUSTOM_EXCEPTIONS[test.properties.acceptance]?.data[column] || {};
                          const locationExceptionsFormulas = measurementException[name] || {};
                          const value = element[column] && element[column][location];
                          const valueMin = element[column] && element[column][`${location}Min`];
                          const valueMax = element[column] && element[column][`${location}Max`];

                          const isAcceptable = isValueAcceptable({
                            element,
                            value,
                            valueMin,
                            valueMax,
                            location,
                            standardUnits,
                            ...locationAcceptanceConfig,
                            ...locationExceptionsFormulas,
                            poItem: poItemNumber,
                            acceptanceCriteria: acceptanceConfig
                          });

                          if (location === "body" && element[column] && (element[column]["bodyMin"] || element[column]["bodyMax"])) return (
                            <TableCell
                              align="center"
                              padding="none"
                              className={classNames({
                                [classes.info]: !acceptanceConfig,
                                [classes.success]: acceptanceConfig && isAcceptable,
                                [classes.error]: acceptanceConfig && !isAcceptable,
                              })}
                            >
                              {formatRangeToValue(element[column]["bodyMin"], element[column]["bodyMax"])}
                            </TableCell>
                          );

                          if (location === "leftEnd" && element[column] && (element[column]["leftEndMin"] || element[column]["leftEndMax"])) return (
                            <TableCell
                              align="center"
                              padding="none"
                              className={classNames({
                                [classes.info]: !acceptanceConfig,
                                [classes.success]: acceptanceConfig && isAcceptable,
                                [classes.error]: acceptanceConfig && !isAcceptable,
                              })}
                            >
                              {formatRangeToValue(element[column]["leftEndMin"], element[column]["leftEndMax"])}
                            </TableCell>
                          );

                          if (location === "rightEnd" && element[column] && (element[column]["rightEndMin"] || element[column]["rightEndMax"])) return (
                            <TableCell
                              align="center"
                              padding="none"
                              className={classNames({
                                [classes.info]: !acceptanceConfig,
                                [classes.success]: acceptanceConfig && isAcceptable,
                                [classes.error]: acceptanceConfig && !isAcceptable,
                              })}
                            >
                              {formatRangeToValue(element[column]["rightEndMin"], element[column]["rightEndMax"])}
                            </TableCell>
                          );

                          return (
                            <TableCell
                              align="center"
                              padding="none"
                              className={classNames({
                                [classes.info]: !acceptanceConfig,
                                [classes.success]: acceptanceConfig && isAcceptable,
                                [classes.error]: acceptanceConfig && !isAcceptable,
                              })}
                            >
                              {element[column] && element[column][location] || "-"}
                            </TableCell>
                          );
                        });
                      })}
                      {showFileCell && (
                        <TableCell align="center">
                          {element.file && <File file={element.file} />}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </>
        )}

        {test.properties.file && (
          <Grid item xs={12} container alignItems="center" spacing={1}>
            <Grid item className={classes.value}>Attachment(s):</Grid>
            <Grid item><File file={test.properties.file} /></Grid>
          </Grid>
        )}

        {test.properties.notes && (
          <Grid item xs={12} className={classes.value}>
            <p
              dangerouslySetInnerHTML={{__html: test.properties.notes.split("\n").join("</br>")}}
            />
          </Grid>
        )}
      </Grid>
      <Dialog
        open={addDataOpen}
        onClose={dataDialogClose}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent>
          <TestFormDimensionalTest
            formRef={formRef}
            test={test}
            isProducerTest
            saveTest={updateTest}
            user={user}
            inspectedCertificates={[certificate]}
          />
        </DialogContent>
      </Dialog>
    </>
  );
});

export default withStyles(DimensionalTest, styles);
