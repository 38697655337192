import React, {useMemo} from "react";
import {observer} from "mobx-react-lite";
import useStores from "../../../../../../../../../../useStores";
import {STAGE_CONFIG} from "../../../../constants";
import {TOOLTIP_CONFIG, TOOLTIP_PROGRESS_BAR_HEIGHT} from "../../constants";
import LengthProgressBar from "../LengthProgressBar";
import TooltipContainer from "../TooltipContainer";
import Header from "./components/Header";
import PipeId from "./components/PipeId";
import PipeType from "./components/PipeType";
import CoatingType from "./components/CoatingType";

const PipeTooltip = ({node, scale, stage}) => {
  const {CampaignStore} = useStores();

  const coatingTypesByPipeNumber = CampaignStore.coatingTypesByPipeNumber;
  const baseMaterialsTypes = CampaignStore.baseMaterialsTypes;

  const {joint, pipe, type, length, totalLength} = node.data;

  const scaleBy = useMemo(() => {
    const scaleBy = (STAGE_CONFIG.MAX_SCALE - scale) || 0.1;

    return Math.max(TOOLTIP_CONFIG.SCALE_MIN, scaleBy);
  }, [scale]);

  return (
    <TooltipContainer
      node={node}
      scale={scaleBy}
      stage={stage}
      rows={[{
        component: <Header joint={joint} />,
        height: TOOLTIP_CONFIG.FONT_SIZE,
      }, {
        component: <PipeId pipe={pipe} />,
        height: TOOLTIP_CONFIG.GENERAL_TEXT_FONT_SIZE,
      }, {
        component: (
          <PipeType
            type={type}
            baseMaterialsTypes={baseMaterialsTypes}
          />
        ),
        height: TOOLTIP_CONFIG.FONT_SIZE,
      }, {
        component: <CoatingType coatingTypes={coatingTypesByPipeNumber[pipe]} />,
        height: TOOLTIP_CONFIG.FONT_SIZE,
      }, {
        component: (
          <LengthProgressBar
            length={length}
            totalLength={totalLength}
          />
        ),
        height: TOOLTIP_PROGRESS_BAR_HEIGHT,
      }]}
    />
  );
};

export default observer(PipeTooltip);