export default (theme) => ({
  tableCell: {
    whiteSpace: "nowrap"
  },
  container: {
    overflowX: "auto",
    overflowY: "hidden",
  },
  table: {
    width: "max-content"
  },
  selectableRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.backgroundColor.main
    }
  },
});