import AppliedFiltersList from "@core/components/AppliedFiltersList";
import FilterButton from "@core/components/FilterButton";
import {CAMPAIGN_SPECIMEN_LABELS} from "@core/constants/campaign";
import {FILTERS} from "@core/constants/filters";
import {Box, Chip, Grid, useTheme} from "@mui/material";
import {observer} from "mobx-react-lite";
import qs from "qs";
import {isEmpty, keys, uniq, values} from "ramda";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {AiOutlinePlus} from "react-icons/ai";
import {useHistory, useLocation} from "react-router-dom";
import useStores from "../../../../useStores";
import {getBaseProductsTitle} from "../../helpers";
import PageHeader from "../Header";

const CampaignPageSkeleton = ({componentByTab, pageTabs, subtabsByTab = {}, ...props}) => {
  const {CampaignStore, FilterStore} = useStores();

  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();

  const tab = useMemo(() => {
    const savedTab = qs.parse(location.search, {ignoreQueryPrefix: true}).tab || "";
    const formattedTab = savedTab.toUpperCase();

    return formattedTab || pageTabs.GENERAL_INFO;
  }, [location.search]);

  const [activeSubTab, setActiveSubTab] = useState(null);

  const campaign = CampaignStore.campaign;

  const {tests = [], rawMaterials = [], products = []} = campaign;

  const {filters} = FilterStore;

  const allowedTabs = useMemo(() => values(pageTabs), []);

  useEffect(() => {
    const subTabs = subtabsByTab[tab] || {};
    const subTab = keys(subTabs)[0];

    setActiveSubTab(subTab);

    if(allowedTabs.includes(tab) || !tab) return;

    setTab(pageTabs.GENERAL_INFO);
  }, [tab]);

  const Component = componentByTab[tab];

  const setTab = useCallback((tab) => {
    if(!tab) return;

    history.push({
      pathname: history.pathname,
      search: qs.stringify({tab: tab.toLowerCase()}),
    });
  }, []);

  const pageTabsLabels = useMemo(() => ({
    ...props.pageTabsLabels,
    [pageTabs.BASE_MATERIALS]: getBaseProductsTitle(products) || props.pageTabsLabels.BASE_MATERIALS
  }), [props.pageTabsLabels, products.length]);

  const {specimenIds, welds} = tests.reduce(({specimenIds, welds}, test) => {
    const activities = test.properties.activities || [];
    const specimens = activities.map((activity) => activity.specimen);

    if(test.specimenLabel === CAMPAIGN_SPECIMEN_LABELS.WELD) {
      welds.push(...specimens);
    }

    if(test.specimenLabel === CAMPAIGN_SPECIMEN_LABELS.PIPE || !test.specimenLabel) {
      specimenIds.push(...specimens);
    }

    return {specimenIds, welds};
  }, {specimenIds: [], welds: []});

  const batchNumbers = uniq(rawMaterials.map((material) => material.batchNumber));
  const uniqueWelds = uniq(welds);
  const uniqueSpecimenIds = uniq(specimenIds);

  const optionsByType = {
    [FILTERS.SPECIMEN_ID]: uniqueSpecimenIds,
    [FILTERS.BATCH_NUMBER]: batchNumbers,
    [FILTERS.WELD_ID]: uniqueWelds,
  };

  const displayOrder = [FILTERS.SPECIMEN_ID, FILTERS.WELD_ID, FILTERS.BATCH_NUMBER, FILTERS.DATE_CREATED];

  if(!allowedTabs.includes(tab)) return null;

  return (
    <>
      <PageHeader
        pageTabs={pageTabs}
        pageTabsLabels={pageTabsLabels}
        onTab={setTab}
        tab={tab}
        optionsByType={optionsByType}
        displayOrder={displayOrder}
        activeSubTab={activeSubTab}
        setActiveSubTab={setActiveSubTab}
        subtabsByTab={subtabsByTab}
      />
      <Box
        sx={{
          padding: theme.spacing(4),
          overflowX: "hidden",
        }}
      >
        <Grid container spacing={2}>
          {!isEmpty(filters) && (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item>
                  <FilterButton
                    optionsByType={optionsByType}
                    displayOrder={displayOrder}
                  >
                    <Chip
                      size="small"
                      onClick={() => {}}
                      label={(
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          <AiOutlinePlus />&nbsp;Add filter
                        </Box>
                      )}
                      variant="outlined"
                    />
                  </FilterButton>
                </Grid>
                <Grid item>
                  <AppliedFiltersList
                    optionsByType={optionsByType}
                    displayOrder={displayOrder}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Component
              {...props}
              id={tab}
              label={pageTabsLabels[tab]}
              activeSubTab={activeSubTab}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default observer(CampaignPageSkeleton);