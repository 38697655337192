import React from "react";
import {Text} from "react-konva";
import {STAGE_CONFIG} from "../../../../../../constants";
import {TOOLTIP_CONFIG, CONTENT_WIDTH} from "../../../../constants";

const Header = ({joint}) => {
  return (
    <>
      <Text
        width={CONTENT_WIDTH}
        align="left"
        text="JOINT"
        fill={TOOLTIP_CONFIG.LIGHT_TEXT_COLOR}
        fontSize={TOOLTIP_CONFIG.FONT_SIZE}
        fontFamily={STAGE_CONFIG.FONT_FAMILY}
      />
      <Text
        width={CONTENT_WIDTH}
        align="right"
        text={joint}
        fill={TOOLTIP_CONFIG.TEXT_COLOR}
        fontSize={TOOLTIP_CONFIG.FONT_SIZE}
        fontFamily={STAGE_CONFIG.FONT_FAMILY}
        fontStyle="bold"
      />
    </>
  );
};

export default Header;