import React, {useMemo} from "react";
import {Text} from "react-konva";
import {getIsTieInWeld, getWeldIsRepaired} from "../../../../../../../../helpers";
import {STAGE_CONFIG} from "../../../../../../constants";
import {REPAIRED_WELD_CONFIG, TIE_IN_WELD_CONFIG} from "../../../../../Stalk/constants";
import {TOOLTIP_CONFIG, CONTENT_WIDTH} from "../../../../constants";

const Header = ({weld}) => {

  const weldNumberColor = useMemo(() => {
    const isRepairedWeld = getWeldIsRepaired(weld);
    const isTieInWeld = getIsTieInWeld(weld);

    if(isRepairedWeld) return REPAIRED_WELD_CONFIG.BACKGROUND_COLOR;

    if(isTieInWeld) return TIE_IN_WELD_CONFIG.BACKGROUND_COLOR;

    return TOOLTIP_CONFIG.TEXT_COLOR;
  }, [weld]);

  return (
    <>
      <Text
        width={CONTENT_WIDTH}
        align="left"
        text="WELD"
        fill={TOOLTIP_CONFIG.LIGHT_TEXT_COLOR}
        fontSize={TOOLTIP_CONFIG.FONT_SIZE}
        fontFamily={STAGE_CONFIG.FONT_FAMILY}
      />
      <Text
        width={CONTENT_WIDTH}
        align="right"
        text={weld}
        fill={weldNumberColor}
        fontSize={TOOLTIP_CONFIG.FONT_SIZE}
        fontFamily={STAGE_CONFIG.FONT_FAMILY}
        fontStyle="bold"
      />
    </>
  );
};

export default Header;