import React, {useCallback, useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {useRouteMatch} from "react-router-dom";
import useStores from "../../useStores";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import {Grid, CircularProgress, Box, Typography} from "@mui/material";
import {withStyles} from "tss-react/mui";
import ColumnsSelect from "@core/components/ColumnsSelect";
import {map, prop} from "ramda";
import InputAdornment from "@mui/material/InputAdornment";
import {Input} from "@core/components/Form";
import Table from "@core/components/Table";
import TableFooter from "@core/components/TableFooter";
import getColumns from "./data";
import {useFetchedCertificateNorms} from "@core/hooks/useFetchedCertificateNorms";
import styles from "./styles";
import useFetchTableDataByQueryParam from "@core/hooks/useFetchTableDataByQueryParam";
import {STORE_DATA} from "@core/constants/storeDataKeys";
import {setRowsPerPage} from "@core/helpers";

const ArchiveList = observer(({classes}) => {
  const match = useRouteMatch();

  const savedSelectedColumns = JSON.parse(localStorage.getItem(match.path));
  const {normsByMaterial} = useFetchedCertificateNorms();
  const columns = getColumns(normsByMaterial);

  const [selectedColumns, setSelectedColumns] = useState(savedSelectedColumns || map(prop("dataIndex"), columns));

  const {ArchiveStore} = useStores();
  const {status, data: archives, total, fetch} = ArchiveStore.archives;

  const archivedCertificates = map(prop("entity"), archives);

  useEffect(() => {
    return () => ArchiveStore.archives.reset();
  }, []);
  
  useFetchTableDataByQueryParam({
    getStore: () => ArchiveStore,
    dataKey: STORE_DATA.archives,
    matchPath: match.path
  });

  const handleRowsPerPageChange = (limit) => {
    setRowsPerPage(match.path, limit);
    ArchiveStore.archives.load({limit, offset: 0});
  };

  const searchAPIDebounced = AwesomeDebouncePromise((search) => {
    ArchiveStore.archives.load({search, offset: 0});
  }, 750);

  const handleSearch = useCallback(async (event) => {
    await searchAPIDebounced(event.target.value);
  }, []);

  return (
    <div className={classes.page}>
      <Grid container className={classes.pageHeadline} spacing={3} justifyContent="space-between">
        <Grid item>
          <Typography variant="h4" fontSize="1.8rem">
            Archived certificates
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid item xs>
          <Input
            name='search'
            endAdornment={
              <InputAdornment position="start">
                {status.loading && <CircularProgress size={20} />}
              </InputAdornment>
            }
            placeholder={"Search Certificates"}
            onChange={handleSearch}
          />
        </Grid>
        <Grid item>
          <ColumnsSelect
            columns={columns}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
          />
        </Grid>
        <Box className={classes.pageTable}>
          <Table
            selectedColumns={selectedColumns}
            items={archivedCertificates}
            columns={columns}
            isLoaded={status.loaded}
            sort={fetch.sort}
            setSort={(sort) => ArchiveStore.archives.load({sort})}
            saveSortToQueryParams
            total={total}
          >
            <TableFooter
              saveTablePageToQueryParams
              isLoaded={status.loaded}
              items={archivedCertificates}
              total={total}
              limit={fetch.limit}
              offset={fetch.offset}
              onOffsetChange={(offset) => ArchiveStore.archives.load({offset})}
              onLimitChange={handleRowsPerPageChange}
            />
          </Table>
        </Box>
      </Grid>
    </div>
  );
});

export default withStyles(ArchiveList, styles);
