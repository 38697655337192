import React from "react";
import {Text} from "react-konva";
import {STAGE_CONFIG} from "../../../../../../constants";
import {TOOLTIP_CONFIG, CONTENT_WIDTH} from "../../../../constants";

const PipeId = ({pipe}) => {
  if(!pipe) return null;

  return (
    <Text
      width={CONTENT_WIDTH}
      align="right"
      text={`Pipe ID: ${pipe}`}
      fill={TOOLTIP_CONFIG.LIGHT_TEXT_COLOR}
      fontSize={TOOLTIP_CONFIG.GENERAL_TEXT_FONT_SIZE}
      fontFamily={STAGE_CONFIG.FONT_FAMILY}
      fontStyle="bold"
    />
  );
};

export default PipeId;