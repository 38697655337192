export const USER_STATUSES = {
  ENABLED: "ENABLED",
  DISABLED: "DISABLED",
  PENDING: "PENDING"
};

export const USER_STATUSES_LABELS = {
  [USER_STATUSES.ENABLED]: "Enabled",
  [USER_STATUSES.DISABLED]: "Disabled",
  [USER_STATUSES.PENDING]: "Pending"
};