import modules from "@core/constants/modules";
import {
  Button, Dialog, DialogContent, DialogTitle, Grid, Paper,
  Table, TableBody, TableCell, TableHead, TableRow, Tooltip,
  Skeleton
} from "@mui/material";
import {useParams} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {groupBy, values} from "ramda";
import React, {useEffect, useMemo, useState} from "react";
import {MdEditNote} from "react-icons/md";
import {withStyles} from "tss-react/mui";
import useStores from "../../../../useStores";
import TableSkeleton from "@core/components/TableSkeleton";
import ProductionDataCell from "./components/ProductionDataCell";
import BaseProductsList from "../../../components/BaseProductsList";
import BaseProductsUpload from "../../../components/BaseProductsUpload";
import BlockHeader from "../BlockHeader";
import styles from "./styles";
import {getBaseProductsTitle} from "../../helpers";

const BaseProducts = observer(({classes, id, label}) => {
  const {CampaignStore, UserStore} = useStores();

  const campaign = CampaignStore.campaign;
  const productionTransfers = CampaignStore.transfers;

  const {id: campaignId} = useParams();

  const [editOpen, setEditOpen] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [parsedProducts, setParsedProducts] = useState([]);

  const [module] = UserStore.user.data.company.modules;

  const isLoaded = campaignId === campaign._id;

  const title = useMemo(() => getBaseProductsTitle(campaign.products || []) || label, [campaign.products]);

  const groupedProducts = useMemo(() => values(groupBy((product) => product.productType + product.manufacturer + product.description, campaign.products || [])), [campaign.products]);

  const heats = useMemo(() => campaign.products ? campaign.products.map((product) => product.heat) : [], [campaign.products]);
  const productionData = useMemo(() => productionTransfers.filter((transfer) => transfer.certificates.some((certificate) => heats.includes(certificate.heat))), [productionTransfers]);

  useEffect(() => {
    if(!editOpen) return;

    setSelectedProducts(campaign.products);
    setParsedProducts(campaign.products);
  }, [editOpen]);

  return (
    <>
      <BlockHeader
        id={id}
      >
        {isLoaded ? title : <Skeleton width={200} />}
        {module.name !== modules.WITNESS && (
          <Tooltip title="Edit base products">
            <MdEditNote
              onClick={() => setEditOpen(true)}
              size="25"
            />
          </Tooltip>
        )}
      </BlockHeader>
      <Paper className={classes.paper}>
        <Table className="styled-table">
          <TableHead>
            <TableRow>
              <TableCell>Manufacturer</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Description</TableCell>
              {(module.name !== modules.WITNESS && !!productionData.length) && (
                <TableCell>Production Data</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoaded ? (
              <>
                {groupedProducts.map((chunk, index) => (
                  <TableRow key={index}>
                    <TableCell>{chunk[0]?.manufacturer}</TableCell>
                    <TableCell>{chunk.length}</TableCell>
                    <TableCell>{chunk[0]?.description || "-"}</TableCell>
                    {(module.name !== modules.WITNESS && !!productionData.length) && (
                      <ProductionDataCell
                        products={chunk}
                      />
                    )}
                  </TableRow>
                ))}
              </>
            ) : (
              <TableSkeleton
                columns={module.name !== modules.WITNESS ? 4 : 3}
                rows={1}
              />
            )}
          </TableBody>
        </Table>
      </Paper>
      <Dialog
        open={editOpen}
        onClose={() => setEditOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Edit base products
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className={classes.baseProductsList}>
                <BaseProductsList
                  products={parsedProducts}
                  selectedProducts={selectedProducts}
                  setSelectedProducts={setSelectedProducts}
                />
              </div>
            </Grid>
            <Grid item container justifyContent="space-between">
              <Grid item>
                <BaseProductsUpload
                  products={parsedProducts}
                  selectedProducts={selectedProducts}
                  setProducts={setParsedProducts}
                  setSelectedProducts={setSelectedProducts}
                />
              </Grid>
              <Grid item>
                <Button
                  disabled={!selectedProducts.length}
                  onClick={async () => {
                    await CampaignStore.updateCampaignById(campaign._id, {products: selectedProducts});
                    setEditOpen(false);
                  }}
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
});

export default withStyles(BaseProducts, styles);