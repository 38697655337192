import {TableCell} from "@mui/material";
import React, {useMemo} from "react";
import {equals} from "ramda";
import {HIGHLIGHTED_CELL_SX} from "@core/constants/transverseRuptureStrength";

const HeavyToRenderTableCell = ({items, calculateItems}) => {
  const standardDeviation = useMemo(() => calculateItems(items), [items]);

  return (
    <TableCell
      sx={{
        fontWeight: (theme) => theme.typography.fontWeightMedium,
        ...HIGHLIGHTED_CELL_SX
      }}
    >
      {!isNaN(standardDeviation) ? Math.round(standardDeviation) : null}
    </TableCell>
  );
};

export default React.memo(
  HeavyToRenderTableCell,
  (prevProps, nextProps) => equals(prevProps, nextProps)
);