import IconistsIcon from "@core/components/IconistsIcon";
import {ICONISTS_ICONS} from "@core/constants/iconistsIcons";
import {Tooltip, Box} from "@mui/material";
import React from "react";

const AcceptanceCriteriaLabel = ({min = false, max = false}) => {
  const label = min ? "A.C. min" : max ? "A.C. max" : "A.C.";

  return (
    <Tooltip title="Acceptance Criteria">
      <Box sx={{display: "inline-flex", alignItems: "center", textWrap: "nowrap", cursor: "pointer"}}>
        {label}
          &nbsp;
        <IconistsIcon icon={ICONISTS_ICONS.circleQuestionMark} />
      </Box>
    </Tooltip>
  );
};

export default AcceptanceCriteriaLabel;