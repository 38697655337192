import isExperimentalDesignEnabled from "@core/helpers/isExperimentalDesignEnabled";
import React, {useMemo, useRef} from "react";
import {observer} from "mobx-react";
import {Box, useTheme} from "@mui/material";
import Canvas from "./components/Canvas";
import useStores from "../../../../../../useStores";
import {useParams} from "react-router-dom";
import InfoBox from "./components/InfoBox";

const Welds = () => {
  const container = useRef(null);

  const {CampaignStore} = useStores();

  const theme = useTheme();

  const {id: campaignId} = useParams();

  const experimentalDesignEnabled = useMemo(() => isExperimentalDesignEnabled(), []);

  const campaign = CampaignStore.campaign;
  const {products = []} = campaign;

  const isLoaded = campaignId === campaign._id;

  return (
    <Box
      ref={container}
      sx={{
        position: "relative",
        height: experimentalDesignEnabled ? "calc(100vh - 155px)" : "calc(100vh - 195px)",
        width: "100%",
        background: theme.palette.backgroundColor.main,
      }}
    >
      {!products.length ? (
        <InfoBox
          text={isLoaded ? "Not enough data to visualize welds" : "Loading..."}
        />
      ) : (
        <Canvas
          container={container}
        />
      )}
    </Box>
  );
};

export default observer(Welds);