import React from "react";
import {ListItemText, listItemTextClasses} from "@mui/material";

const FilterDropdownItemText = ({text, sxPrimary = {}, fontSize = "0.89rem"}) => {
  return (
    <ListItemText
      primary={text}
      sx={{
        [`& .${listItemTextClasses.primary}`]: {
          fontSize,
          ...sxPrimary
        },
        margin: 0,
      }}
    />
  );
};

export default FilterDropdownItemText;