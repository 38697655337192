import {getColumns} from "@core/columns/transfer";
import Table from "@core/components/Table";
import {observer} from "mobx-react-lite";
import TableFooter from "@core/components/TableFooter";
import {STORE_DATA} from "@core/constants/storeDataKeys";
import {INTERFACE_TYPE} from "@core/constants/transfer";
import useFetchTableDataByQueryParam from "@core/hooks/useFetchTableDataByQueryParam";
import React, {useEffect} from "react";
import useStores from "../../../../../../useStores";

const AvailableOrders = ({setSelectedOrdersIds, selectedOrdersIds, selectedTransfers}) => {
  const {TransferStore} = useStores();

  const {status, data: transfers, total, fetch} = TransferStore.transfers;
  const columns = getColumns(INTERFACE_TYPE.RECEIVER);

  useEffect(() => {
    return () => TransferStore.transfers.reset();
  }, []);

  useFetchTableDataByQueryParam({
    getStore: () => TransferStore,
    dataKey: STORE_DATA.transfers,
    defaultParams: {
      type: INTERFACE_TYPE.RECEIVER,
      excludedIds: selectedTransfers.map((transfer) => transfer._id)
    }
  });

  return (
    <Table
      allowRowSelection
      setSelectedItems={setSelectedOrdersIds}
      selectedItems={selectedOrdersIds}
      items={transfers}
      columns={columns}
      isLoaded={status.loaded}
      sort={fetch.sort}
      setSort={(sort) => TransferStore.transfers.load({sort})}
      saveSortToQueryParams
      total={total}
      noDataText="No orders."
    >
      {status.loaded && transfers.length > 0 && (
        <TableFooter
          saveTablePageToQueryParams
          isLoaded={status.loaded}
          items={transfers}
          total={total}
          limit={fetch.limit}
          offset={fetch.offset}
          onOffsetChange={(offset) => TransferStore.transfers.load({offset})}
          onLimitChange={(limit) => TransferStore.transfers.load({limit, offset: 0})}
        />
      )}
    </Table>
  );
};

export default observer(AvailableOrders);