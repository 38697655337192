import {groupBy, indexBy, keys, partition, prop, test, sortWith, descend, ascend} from "ramda";
import {sortStrings} from "@core/helpers";
import {ORDERS} from "@core/constants/sort";

export const REPAIR_REGEX = /RW?\d*$/;

export const getWeldsByStalkNumber = (data, position) => {
  const [tieInWelds, welds] = partition((weld) => getIsTieInWeld(weld.weldNumber), data);

  const grouped = groupBy(prop("stalkNumber"), welds);
  const sorted = sortStrings(ORDERS.ASC, keys(grouped));

  const stalks = sorted.map((stalkName) => {
    const sorted = sortWith([ascend(prop("weldNumber")), descend(prop("status"))], grouped[stalkName]);

    return position ?
      sorted.map((weld) => ({...weld, position: Number(weld.jointNumberA.split("-")[1]) - 1})) :
      sorted;
  });

  const tieInWeldsByStalkNumber = indexBy(prop("stalkNumber"), tieInWelds);

  return {stalks, tieInWeldsByStalkNumber};
};

export const getWeldIsRepaired = (weldId) => test(REPAIR_REGEX, weldId);

export const getIsTieInWeld = (weldId) => weldId.includes("T");

export const getMaxActivityValue = (activities, activityName) => {
  const values = activities.reduce((acc, activity) => {
    if(getIsTieInWeld(activity.specimen)) return acc;

    if(activity.description === activityName) {
      acc.push(activity.results.values.map(({value}) => value));
    }

    return acc;
  }, []);

  return values.length ? Math.max(...values) : null;
};