import React, {useCallback, useMemo, useState} from "react";
import {observer} from "mobx-react-lite";
import {Table, TableHead, TableRow, TableCell, TableBody, Input, MenuItem, Select} from "@mui/material";
import TableFooter from "@core/components/TableFooter";
import EditableValue from "@core/components/EditableValue";
import FilterableSortableHeaderCell from "@core/components/FilterableSortableHeaderCell";
import {sort, filterElements} from "@core/helpers";
import useStores from "../../../../../../useStores";
import {useParams} from "react-router-dom";
import TableSkeleton from "@core/components/TableSkeleton";
import TestsDrawer from "../../../TestsDrawer";
import DrawerButtonTrigger from "@core/components/DrawerButtonTrigger";
import {CAMPAIGN_TYPES} from "@core/constants/campaign";
import {ENTITIES} from "../../../../constants";

const DetailedBaseMaterials = observer((props) => {
  const {CampaignStore, NotificationStore} = useStores();

  const {id: campaignId} = useParams();

  const [openPipe, setOpenPipe] = useState(null);
  const [limit, setLimit] = useState(30);
  const [offset, setOffset] = useState(0);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [filter, setFilter] = useState({});
  const [loadingQuarantineStatus, setLoadingQuarantineStatus] = useState(false);

  const campaign = CampaignStore.campaign;

  const isLoaded = campaignId === campaign._id;

  const filtered = useMemo(() => filterElements(filter, props.products), [filter, props.products]);
  const products = useMemo(() => sort(order, orderBy, filtered), [filtered, order, orderBy, filter]);

  const productsPaginated = useMemo(() => products.slice(offset, offset + limit), [products, offset, limit]);

  const updateProduct = useCallback(async (changes, productId) => {
    const product = campaign.products.find((product) => product._id === productId);

    await CampaignStore.updateCampaignById(campaign._id, {products: {...product, ...changes}});

    NotificationStore.showSuccess("Successfully updated!");
  }, [campaign.products]);

  const updateFilter = useCallback((filter) => {
    setFilter(filter);
    setOffset(0);
  }, []);

  return (
    <>
      <Table className="styled-table">
        <TableHead>
          <TableRow>
            <FilterableSortableHeaderCell
              id="productType"
              setOrder={setOrder}
              order={order}
              setOrderBy={setOrderBy}
              orderBy={orderBy}
              filter={filter}
              setFilter={updateFilter}
              elements={props.products}
            >
              Product Type
            </FilterableSortableHeaderCell>
            <FilterableSortableHeaderCell
              id="manufacturer"
              setOrder={setOrder}
              order={order}
              setOrderBy={setOrderBy}
              orderBy={orderBy}
              filter={filter}
              setFilter={updateFilter}
              elements={props.products}
            >
              Manufacturer
            </FilterableSortableHeaderCell>
            <FilterableSortableHeaderCell
              id="description"
              setOrder={setOrder}
              order={order}
              setOrderBy={setOrderBy}
              orderBy={orderBy}
              filter={filter}
              setFilter={updateFilter}
              elements={props.products}
            >
              Description
            </FilterableSortableHeaderCell>
            <FilterableSortableHeaderCell
              id="heat"
              setOrder={setOrder}
              order={order}
              setOrderBy={setOrderBy}
              orderBy={orderBy}
              filter={filter}
              setFilter={updateFilter}
              elements={props.products}
            >
              Heat
            </FilterableSortableHeaderCell>
            <FilterableSortableHeaderCell
              id="quarantine"
              setOrder={setOrder}
              order={order}
              setOrderBy={setOrderBy}
              orderBy={orderBy}
              filter={filter}
              setFilter={updateFilter}
              elements={props.products}
            >
              Quarantine
            </FilterableSortableHeaderCell>
            <FilterableSortableHeaderCell
              id="quarantineReason"
              setOrder={setOrder}
              order={order}
              setOrderBy={setOrderBy}
              orderBy={orderBy}
              filter={filter}
              setFilter={updateFilter}
              elements={props.products}
            >
              Quarantine reason
            </FilterableSortableHeaderCell>
            <FilterableSortableHeaderCell
              id="pipeNumber"
              setOrder={setOrder}
              order={order}
              setOrderBy={setOrderBy}
              orderBy={orderBy}
              filter={filter}
              setFilter={updateFilter}
              elements={props.products}
            >
              Pipe ID
            </FilterableSortableHeaderCell>
            {campaign.type === CAMPAIGN_TYPES.WELDING && (
              <FilterableSortableHeaderCell
                id="jointNumber"
                setOrder={setOrder}
                order={order}
                setOrderBy={setOrderBy}
                orderBy={orderBy}
                filter={filter}
                setFilter={updateFilter}
                elements={props.products}
              >
                Joint No
              </FilterableSortableHeaderCell>
            )}
            {campaign.type === CAMPAIGN_TYPES.WELDING && (
              <FilterableSortableHeaderCell
                id="stalkNumber"
                setOrder={setOrder}
                order={order}
                setOrderBy={setOrderBy}
                orderBy={orderBy}
                filter={filter}
                setFilter={updateFilter}
                elements={props.products}
              >
                Stalk No
              </FilterableSortableHeaderCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoaded ? productsPaginated.map((product) => (
            <TableRow key={product._id}>
              <TableCell>{product.productType || "-"}</TableCell>
              <TableCell>{product.manufacturer}</TableCell>
              <TableCell>{product.description || ""}</TableCell>
              <TableCell>{product.heat}</TableCell>
              <TableCell>
                <Select
                  disabled={loadingQuarantineStatus}
                  required
                  fullWidth
                  value={product.quarantine}
                  onChange={async (event) => {
                    setLoadingQuarantineStatus(true);
                    await updateProduct({quarantine: event.target.value === "Yes"}, product._id);
                    setLoadingQuarantineStatus(false);
                  }}
                  input={<Input />}
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </TableCell>
              <TableCell>
                <EditableValue
                  addDataText="Add reason"
                  value={product.quarantineReason}
                  onSave={(quarantineReason) => updateProduct({quarantineReason}, product._id)}
                  placeholder="Quarantine reason"
                />
              </TableCell>
              <TableCell>
                <DrawerButtonTrigger
                  onClick={() => {
                    setOpenPipe({
                      pipeNumber: product.pipeNumber,
                      _id: product._id,
                    });
                  }}
                  text={product.pipeNumber}
                />
              </TableCell>
              {campaign.type === CAMPAIGN_TYPES.WELDING && (
                <TableCell>
                  {product.jointNumber ? (
                    <DrawerButtonTrigger
                      onClick={() => {
                        setOpenPipe({
                          pipeNumber: product.jointNumber,
                          _id: product._id,
                          weldNumber: product.weldNumber,
                        });
                      }}
                      text={product.jointNumber}
                    />
                  ) : "-"}
                </TableCell>
              )}
              {campaign.type === CAMPAIGN_TYPES.WELDING && (
                <TableCell>{product.stalkNumber || "-"}</TableCell>
              )}
            </TableRow>
          )) : (
            <TableSkeleton columns={9} />
          )}
        </TableBody>
        <TableFooter
          isLoaded
          items={productsPaginated}
          total={products.length}
          limit={limit}
          offset={offset}
          onOffsetChange={setOffset}
          onLimitChange={setLimit}
        />
      </Table>
      <TestsDrawer
        index={0}
        open={!!openPipe}
        close={() => setOpenPipe(null)}
        pipes={[openPipe]}
        welds={[]}
        type={ENTITIES.PIPE}
      />
    </>
  );
});

export default DetailedBaseMaterials;