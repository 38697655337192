import {TABLE_FIELDS, TRACEABILITY_TABLE_DEFAULT_VALUES} from "./constants";
import {generateID} from "@core/helpers";

export function getValuesFromApiAsync(values = []) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(JSON.parse(JSON.stringify(values.length ? values : TRACEABILITY_TABLE_DEFAULT_VALUES)));
    }, 1000);
  });
}

export function getFilteredDataFromApiAsync(searchText) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(JSON.parse(JSON.stringify(!searchText.trim() == "" ?
        [
          {
            [TABLE_FIELDS.HEAT]: "Heat 23838",
            [TABLE_FIELDS.HEAT_LOT]: "Lot 123",
            [TABLE_FIELDS.WORK_ORDER]: "339",
            [TABLE_FIELDS.WORK_ORDER_ITEM]: "1",
            [TABLE_FIELDS.PO_ITEM]: "1",
            rowId: generateID()
          },
          {
            [TABLE_FIELDS.HEAT]: "Heat 9999",
            [TABLE_FIELDS.HEAT_LOT]: "Lot 777",
            [TABLE_FIELDS.WORK_ORDER]: "339",
            [TABLE_FIELDS.WORK_ORDER_ITEM]: "1",
            [TABLE_FIELDS.PO_ITEM]: "1",
            rowId: generateID()
          },
          {
            [TABLE_FIELDS.HEAT]: "Heat 000",
            [TABLE_FIELDS.HEAT_LOT]: "Lot 111",
            [TABLE_FIELDS.WORK_ORDER]: "339",
            [TABLE_FIELDS.WORK_ORDER_ITEM]: "1",
            [TABLE_FIELDS.PO_ITEM]: "1",
            rowId: generateID()
          },
        ] : TRACEABILITY_TABLE_DEFAULT_VALUES)));
    }, 1000);
  });
}