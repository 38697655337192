import EditableValue from "@core/components/EditableValue";
import ProgressBar from "@core/components/ProgressBar";
import TableSkeleton from "@core/components/TableSkeleton";
import {STATUSES} from "@core/constants/test";
import {INTERFACE_TYPE} from "@core/constants/transfer";
import {isCertificateTransferred, isTestCompleted} from "@core/helpers";
import {DEFAULT_ALL_NUMBER, PROGRESS_BAR_LABELS_BY_STATUS} from "@core/constants/progressBar";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  useTheme,
} from "@mui/material";
import {observer} from "mobx-react";
import React, {useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {withStyles} from "tss-react/mui";
import themeSettings from "../../../../theme-settings";
import useStores from "../../../../useStores";
import {PAGE_TABS} from "../constants";
import DetailedCertificate from "./DetailedCertificate";
import styles from "./styles";

const Row = observer(({
  setCertificatesToTransfer,
  certificatesToTransfer,
  certificate,
  deselectCertificate,
  interfaceType,
  setOrderCertificates,
  orderCertificates,
  classes
}) => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);

  const {TransferStore, CertificateStore} = useStores();
  const {transfer} = TransferStore;
  const senderId = useMemo(() => transfer.sender._id, [transfer.sender]);

  const completeTestsNumber = certificate.tests.filter((test) => test.status === STATUSES.APPROVED).length;

  const hasTests = !!certificate.tests.length;
  const isCompleted = certificate.tests.every((test) => isTestCompleted(test));
  const isTransferred = isCertificateTransferred(certificate, senderId);

  const onCheck = () => {
    let newCertificatesToTransfer = [];

    if (certificatesToTransfer.includes(certificate._id)) {
      newCertificatesToTransfer = certificatesToTransfer.filter((id) => id !== certificate._id);
    } else {
      newCertificatesToTransfer = [...certificatesToTransfer, certificate._id];
    }

    setCertificatesToTransfer(newCertificatesToTransfer);
  };
  
  const updateCertificate = (changes) => {
    CertificateStore.editCertificate(changes, certificate._id);
    const newCertificates = orderCertificates.map((c) => c._id === certificate._id ? {...certificate, ...changes} : c);
    setOrderCertificates(newCertificates);
  };

  const title = isTransferred ? "Already transferred" : !isCompleted ? "Is not completed" : !hasTests ? "Is empty" : "";

  return (
    <>
      <TableRow className={isTransferred ? classes.transferred : null}>
        <TableCell padding="none" width="75">
          <div className={classes.actionsColumn}>
            {(TransferStore.transfer.isDraft && interfaceType === INTERFACE_TYPE.SENDER) && (
              <Tooltip title={title}>
                <div>
                  <Checkbox
                    classes={{root: classes.tableAction}}
                    disabled={!hasTests || !isCompleted || isTransferred}
                    color="primary"
                    checked={certificatesToTransfer.includes(certificate._id)}
                    onClick={onCheck}
                  />
                </div>
              </Tooltip>
            )}
            <IconButton
              classes={{root: classes.tableAction}}
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </div>
        </TableCell>
        <TableCell>{certificate.company.name}</TableCell>
        <TableCell>{certificate.previous_owners.length ?
          certificate.previous_owners.map((company) => company.name).join(", ") : 
          certificate.company.name}
        </TableCell>
        <TableCell>
          <EditableValue
            disabled={isTransferred || interfaceType === INTERFACE_TYPE.RECEIVER}
            addDataText="Add PO Item"
            value={certificate.lineItem}
            onSave={(lineItem) => updateCertificate({lineItem})}
            placeholder="PO Item"
          />
        </TableCell>
        <TableCell>{certificate.properties.productType.name}</TableCell>
        <TableCell>{certificate.heat}</TableCell>
        <TableCell>{certificate.properties.lotId || "-"}</TableCell>
        <TableCell>
          {certificate.items.reduce((quantity, item) => Number(item.quantity) + quantity, 0)}
        </TableCell>
        <TableCell width={150}>
          <ProgressBar
            data={[{
              title: PROGRESS_BAR_LABELS_BY_STATUS.COMPLETE,
              color: themeSettings.palette.success.main,
              number: completeTestsNumber
            }, {
              title: PROGRESS_BAR_LABELS_BY_STATUS.AWAITING_DATA,
              color: theme.palette.awaitingData.backgroundColor,
              number: certificate.tests.length ? certificate.tests.length - completeTestsNumber : DEFAULT_ALL_NUMBER,
            }]}
            allNumber={certificate.tests.length}
          />
        </TableCell>
      </TableRow>
      {open && (
        <TableRow>
          <TableCell colSpan={9}>
            <DetailedCertificate
              interfaceType={interfaceType}
              certificate={certificate}
              deselectCertificate={deselectCertificate}
              handleExpand={() => setOpen(false)}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  );
});

const Certificates = observer(({
  classes,
  certificatesToTransfer,
  certificates,
  setCertificatesToTransfer,
  setOrderCertificates,
  orderCertificates,
  deselectCertificate,
  interfaceType,
}) => {
  const {id: transferId} = useParams();

  const {TransferStore} = useStores();

  const transfer = TransferStore.transfer;

  const isLoaded = transfer._id === transferId;

  const availableToTransfer = certificates.filter((certificate) => {
    const hasTests = certificate.tests.length;
    const isCompleted = certificate.tests.every((test) => isTestCompleted(test));
    const isTransferred = isCertificateTransferred(certificate, transfer.sender._id);

    return hasTests && isCompleted && !isTransferred;
  });

  const everythingSelected = certificatesToTransfer.length && certificatesToTransfer.length === availableToTransfer.length;

  if(!transfer.certificates?.length) return null;

  return (
    <div className={classes.content}>
      <h3
        id={PAGE_TABS.CERTIFICATES}
        className={classes.title}
      >
          Certificates
      </h3>
      <Table className="styled-table">
        <TableHead>
          <TableRow className={!availableToTransfer.length && classes.headerWithPadding}>
            <TableCell padding="none" width="75">
              {(!!availableToTransfer.length &&
                  transfer.isDraft &&
                  interfaceType === INTERFACE_TYPE.SENDER) && (
                <Checkbox
                  className={classes.allCheckbox}
                  classes={{root: classes.tableAction}}
                  onChange={() => {
                    if (everythingSelected) setCertificatesToTransfer([]);
                    else setCertificatesToTransfer(availableToTransfer.map((c) => c._id));
                  }}
                  color="primary"
                  checked={everythingSelected}
                  indeterminate={certificatesToTransfer.length && !everythingSelected}
                />
              )}
            </TableCell>
            <TableCell>Owner</TableCell>
            <TableCell>Manufacturer</TableCell>
            <TableCell>PO Item No.</TableCell>
            <TableCell>Product Type</TableCell>
            <TableCell>Heat</TableCell>
            <TableCell>Lot ID</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoaded ? (
            <>
              {certificates.length > 0 ? certificates.map((certificate) => (
                <Row
                  key={certificate._id}
                  classes={classes}
                  certificate={certificate}
                  deselectCertificate={deselectCertificate}
                  certificatesToTransfer={certificatesToTransfer}
                  setCertificatesToTransfer={setCertificatesToTransfer}
                  interfaceType={interfaceType}
                  setOrderCertificates={setOrderCertificates}
                  orderCertificates={orderCertificates}
                />
              )) : (
                <TableRow>
                  <TableCell colSpan={9}>No certificates.</TableCell>
                </TableRow>
              )}
            </>
          ) : (
            <TableSkeleton
              columns={8}
              allowRowSelection
            />
          )}
        </TableBody>
      </Table>
    </div>
  );
});

export default withStyles(Certificates, styles);
