import {ZONES as ZONES_CONFIG} from "@core/constants/test";
import {NORMS} from "./big-constants/norms";
import {getAcceptancesConfig} from "./big-constants/acceptances-config";

const norms = NORMS;
const TEST_STANDARDS = ["ASTM E1086-14", "ASTM E415-17", "ASTM E415-21", "ASTM E3047-16", "Element SOP 20-02", "EN 10315 (2006)", "EN ISO 15350 (2000)", "CR 10320 (2004)"];

const ANALYSIS_TYPES = {
  HEAT: "Heat",
  PRODUCT: "Product",
  WELD_JOINT: "Weld joint",
  WELD_OVERLAY: "Weld overlay",
};

const ANALYSIS_VALUES = {
  [ANALYSIS_TYPES.HEAT]: "heat",
  [ANALYSIS_TYPES.PRODUCT]: "product",
};

const DISTANCE_ANALYSIS = {
  FUSION_LINE: "fusion line",
  LAST_PASS: "last pass",
};

const POSITIONS_BY_ANALYSIS = {
  [ANALYSIS_TYPES.PRODUCT]: ["1/4 Thickness", "1/2 Thickness", "3/4 Thickness", "2 mm from OD", "2 mm from ID"],
  [ANALYSIS_TYPES.WELD_JOINT]: [ZONES_CONFIG.WELD_METAL, DISTANCE_ANALYSIS.FUSION_LINE],
  [ANALYSIS_TYPES.WELD_OVERLAY]: [ZONES_CONFIG.WELD_METAL, DISTANCE_ANALYSIS.FUSION_LINE, DISTANCE_ANALYSIS.LAST_PASS],
};
const ZONES = [ZONES_CONFIG.WELD_METAL, ZONES_CONFIG.HAZ, ZONES_CONFIG.BASE_METAL];

const STEELMAKING_PROCESSES = {
  BOF: "BOF",
  EAF: "EAF",
};

const acceptancesConfig = getAcceptancesConfig(STEELMAKING_PROCESSES);

const COLUMN_MIN_WIDTH = 80;

export {norms, acceptancesConfig, TEST_STANDARDS, ANALYSIS_TYPES, POSITIONS_BY_ANALYSIS, DISTANCE_ANALYSIS, ZONES, ANALYSIS_VALUES, STEELMAKING_PROCESSES, COLUMN_MIN_WIDTH};
