import DensityTestForm from "@core/components/TestForms/DensityTest";
import TestResultTableCell from "@core/components/TestResultTableCell";
import React from "react";
import {Dialog, DialogContent, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";

const DensityTest = ({addDataOpen, dataDialogClose, formRef, updateTest, test}) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" marginBottom={2}>Test Information</Typography>
          <Table className="styled-table">
            <TableHead>
              <TableRow>
                <TableCell>Material Specification</TableCell>
                <TableCell>Grade / UNS</TableCell>
                <TableCell>Test Standard</TableCell>
                {test.properties.weighingLiquid && <TableCell>Weighing Liquid</TableCell>}
                {test.properties.waterTemperature && <TableCell>Water Temperature [°C]</TableCell>}
                {test.properties.wireDiameter && (
                  <TableCell>Wire Diameter [mm]</TableCell>
                )}
                {test.properties.densityType && (
                  <TableCell>Type of Density</TableCell>
                )}
                {test.properties.acceptanceCriteria && (
                  <TableCell>Acceptance Criteria [g/cm^3]</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{test.norm}</TableCell>
                <TableCell>{test.grade}</TableCell>
                <TableCell>{test.properties.testStandard}</TableCell>
                {test.properties.weighingLiquid && <TableCell>{test.properties.weighingLiquid}</TableCell>}
                {test.properties.waterTemperature && <TableCell>{test.properties.waterTemperature}</TableCell>}
                {test.properties.wireDiameter && (
                  <TableCell>{test.properties.wireDiameter}</TableCell>
                )}
                {test.properties.densityType && (
                  <TableCell>{test.properties.densityType}</TableCell>
                )}
                {test.properties.acceptanceCriteria && (
                  <TableCell>{test.properties.acceptanceCriteria}</TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" marginBottom={2}>Test Result</Typography>
          <Table className="styled-table">
            <TableHead>
              <TableRow>
                {test.properties.massOfItemInAir && (
                  <TableCell>Mass of item in air [g]</TableCell>
                )}
                {test.properties.massOfSupportInWater && (
                  <TableCell>Mass of support in water [g]</TableCell>
                )}
                {test.properties.massOfItemInWater && (
                  <TableCell>Mass of support in water [g]</TableCell>
                )}
                {test.properties.oilViscosity && (
                  <TableCell>Oil Viscosity [m^2/s]</TableCell>
                )}
                {test.properties.impregnationMethod && (
                  <TableCell>Impregnation Method</TableCell>
                )}
                {test.properties.massOfOilImpregnatedPart && (
                  <TableCell>Mass of oil-impregnated part [g]</TableCell>
                )}
                {test.properties.massOfOilImpregnatedSupportPart && (
                  <TableCell>Mass of oil-impregnated part+ support in water [g]</TableCell>
                )}
                {test.properties.massOfOilImpregnatedSupportInWater && (
                  <TableCell>Mass of oil-impregnated support in water [g]</TableCell>
                )}
                {test.properties.massOfOilImpregnatedPartInWater && (
                  <TableCell>Mass of oil-impregnated part in water (support tared) [g]</TableCell>
                )}
                <TableCell>Density of Water [g/cm^3]</TableCell>
                <TableCell>Density [g/cm^3]</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {test.properties.massOfItemInAir && (
                  <TableCell>{test.properties.massOfItemInAir}</TableCell>
                )}
                {test.properties.massOfSupportInWater && (
                  <TableCell>{test.properties.massOfSupportInWater}</TableCell>
                )}
                {test.properties.massOfItemInWater && (
                  <TableCell>{test.properties.massOfItemInWater}</TableCell>
                )}
                {test.properties.oilViscosity && (
                  <TableCell>{test.properties.oilViscosity}</TableCell>
                )}
                {test.properties.impregnationMethod && (
                  <TableCell>{test.properties.impregnationMethod}</TableCell>
                )}
                {test.properties.massOfOilImpregnatedPart && (
                  <TableCell>{test.properties.massOfOilImpregnatedPart}</TableCell>
                )}
                {test.properties.massOfOilImpregnatedSupportPart && (
                  <TableCell>{test.properties.massOfOilImpregnatedSupportPart}</TableCell>
                )}
                {test.properties.massOfOilImpregnatedSupportInWater && (
                  <TableCell>{test.properties.massOfOilImpregnatedSupportInWater}</TableCell>
                )}
                {test.properties.massOfOilImpregnatedPartInWater && (
                  <TableCell>{test.properties.massOfOilImpregnatedPartInWater}</TableCell>
                )}
                <TableCell>{test.properties.densityOfWater || "-"}</TableCell>
                <TableCell>
                  <TestResultTableCell
                    value={test.properties.density}
                    min={test.properties.acceptanceCriteria}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <Dialog
        open={addDataOpen}
        onClose={dataDialogClose}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent>
          <DensityTestForm
            formRef={formRef}
            saveTest={updateTest}
            test={test}
            isProducerTest
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DensityTest;