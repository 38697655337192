import TestsDrawer from "@core/components/TestsDrawer";
import {TEST_CATEGORIES_LABELS} from "@core/constants/test";
import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import {Typography} from "@mui/material";
import TestSummaryTable from "@core/components/TestSummaryTable";
import {useParams} from "react-router";
import useStores from "../../../../../../useStores";

const TestsOverview = ({selectedGroup}) => {
  const [openTests, setOpenTests] = useState(null);
  const [loadedTestCategories, setLoadedTestCategories] = useState([]);

  const {SingleMaterialCampaignStore} = useStores();
  const {id} = useParams();

  const tests = SingleMaterialCampaignStore.testsByCategory[selectedGroup] || [];

  const getTestsByCategory = async () => {
    if(!selectedGroup || loadedTestCategories.includes(selectedGroup)) return;

    await SingleMaterialCampaignStore.getTestsByCategory(id, selectedGroup);
    setLoadedTestCategories((loadedTestCategories) => [...loadedTestCategories, selectedGroup]);
  };

  useEffect(() => {
    getTestsByCategory();
  }, [selectedGroup, id]);

  return (
    <>
      <Typography variant="h6" marginBottom={1}>
        {TEST_CATEGORIES_LABELS[selectedGroup]} Overview
      </Typography>
      <TestSummaryTable
        isLoaded={loadedTestCategories.includes(selectedGroup)}
        tests={tests}
        onTestClick={setOpenTests}
      />
      <TestsDrawer
        open={!!openTests}
        tests={openTests || []}
        close={() => setOpenTests(null)}
      />
    </>
  );
};

export default observer(TestsOverview);