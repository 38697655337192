import React, {memo} from "react";
import PipeTooltip from "./components/PipeTooltip";
import WeldTooltip from "./components/WeldTooltip";
const Tooltip = ({node = {}, scale, stage}) => {

  if (!node.id) return null;

  if(node.data.joint || node.data.pipe) {
    return (
      <PipeTooltip
        node={node}
        scale={scale}
        stage={stage}
      />
    );
  }

  if(node.data.weld) {
    return (
      <WeldTooltip
        node={node}
        scale={scale}
        stage={stage}
      />
    );
  }
  
  return null;
};

export default memo(Tooltip);