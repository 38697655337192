import Button from "@mui/material/Button";
import React, {Component} from "react";
import {withStyles} from "tss-react/mui";
import Grid from "@mui/material/Grid";
import {Input} from "@core/components/Form";
import {inject, observer} from "mobx-react";
import {Formik} from "formik";
import * as yup from "yup";
import userService from "@core/api/user-service";
import SetupTwoFactor from "@core/components/SetupTwoFactor";
import {AvatarUploader} from "@core/components/Uploaders";
import PersonalInfoService from "./components/PersonalInfoService";
import ResetSigningKey from "./components/ResetSigningKey";
import ChangePassModal from "../ChangePasswordModal";
import {Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel} from "@mui/material";
import {AUTH_TYPES} from "@core/constants/authTypes";
import {compose} from "ramda";
import NewDesignCheckbox from "./components/NewDesignCheckbox";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  avatarUpload: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .avatar": {
      width: 150,
      height: 150,
      margin: "auto",
    }
  },
  authButton: {
    padding: 4,
    "&:hover": {
      backgroundColor: "unset",
    }
  },
  footer: {
    paddingTop: theme.spacing(3),
  }
});

class EditPersonalInfo extends Component {

  constructor(props){
    super(props);

    this.state = {
      user: props.user,
      changes: {},
      spacing: 2,
      preview: false,
      showPassword: false,
      previewPath: "",
      changingPassword: false,
    };
  }

  saveUserChanges = (values) => {
    const {user} = this.state;
    const {NotificationStore} = this.props;

    return PersonalInfoService.update(values, user._id).then((response)=>{
      if(response.data.error) {
        NotificationStore.showError("The username already exists");
      } else {
        userService.getUserInfo()
          .then((user) => {
            this.setState({user});
            this.props.UserStore.setUser(user);
            NotificationStore.showSuccess("Your changes have been applied!");
          })
          .catch((error) => NotificationStore.showError(error).bind(this));
      }
    }).catch((error) => NotificationStore.showError(error));
  };

  openChangePassword = () => {
    this.setState({changingPassword: true});
  };

  closeChangePassword = () => {
    this.setState({changingPassword: false});
  };

  changePassword = async (values) => {
    const {NotificationStore} = this.props;
    await PersonalInfoService.changePassword(values)
      .then(()=> NotificationStore.showSuccess("Password has been successfully changed!"))
      .catch((error) => NotificationStore.showError(error.response.data.error));
  };

  render(){
    const {classes, formRef} = this.props;
    const {user, spacing, preview, previewPath, changingPassword} = this.state;
    let avatarPath;

    if(user.avatar && !preview){
      avatarPath = user.avatar.file.dir+user.avatar.file.name;
    }else{
      avatarPath = "";
    }

    const initialValues = {
      username: user.username || "",
      firstname: user.firstname || "",
      lastname: user.lastname || "",
      email: user.email || "",
      avatar: user.avatar?._id || null,
      defaultTotp: user.default_totp || AUTH_TYPES.AUTH_APP,
    };

    const validationSchema = yup.object().shape({
      username: yup.string().required("Username is required!"),
      firstname: yup.string().required("Name is required!"),
      lastname: yup.string().required("Last name is required!"),
      email: yup.string().email("Email has to be of type name@email.com").required("Email name is required!"),
      defaultTotp: yup.string().required(),
    });

    return (
      <Formik
        enableReinitialize
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => this.saveUserChanges(values)}
        render={(props) => {
          const {
            values: {
              username,
              firstname,
              lastname,
              email,
              defaultTotp,
            },
            errors,
            isValid,
            handleSubmit,
            handleChange,
            setFieldValue,
            touched,
            setFieldTouched
          } = props;
          const change = (name, e) => {
            handleChange(e);
            setFieldTouched(name, true, false);
          };

          const handleUploadedFile = async (avatar) => {
            const {user} = this.state;

            this.setState({
              preview: true,
              previewPath: avatar.file.dir+avatar.file.name,
              user: {...user, avatar}
            });

            await this.saveUserChanges({avatar: avatar._id});

            setFieldValue("avatar", avatar._id);
            setFieldTouched("avatar", true, false);
          };
          
          const removeAvatar = async () => {
            await this.saveUserChanges({avatar: ""});
            
            setFieldValue("avatar", null);
            this.setState({
              preview: false,
              previewPath: "",
              user: {...user, avatar: null}
            });
          };

          const handleResetPreview = () => {
            this.setState({previewPath: ""});
            setFieldValue("avatar", "");
          };

          return (
            <Grid container className={classes.root} spacing={spacing}>
              <Grid item xs={3}>
                <Input
                  label="Username"
                  name='username'
                  value={username}
                  className={classes.textField}
                  onChange={(e) => change("username", e)}
                  onBlur={() => setFieldValue("username", username.trim())}
                  error={Boolean(errors.username) && touched.username}
                  errorMessage={errors.username}
                />
                <Input
                  label="First name"
                  name='firstname'
                  value={firstname}
                  className={classes.textField}
                  onChange={(e) => change("firstname", e)}
                  onBlur={() => setFieldValue("firstname", firstname.trim())}
                  error={Boolean(errors.firstname) && touched.firstname}
                  errorMessage={errors.firstname}
                />
                <Input
                  label="Last name"
                  name='lastname'
                  value={lastname}
                  className={classes.textField}
                  onChange={(e) => change("lastname", e)}
                  onBlur={() => setFieldValue("lastname", lastname.trim())}
                  error={Boolean(errors.lastname) && touched.lastname}
                  errorMessage={errors.lastname}
                />
                <Input
                  label="Email"
                  name='email'
                  value={email}
                  className={classes.textField}
                  onChange={(e) => change("email", e)}
                  error={Boolean(errors.email) && touched.email}
                  errorMessage={errors.email}
                />
              </Grid>
              <Grid item xs={3}>
                <AvatarUploader
                  className={classes.avatarUpload}
                  preloadText="drop avatar file here"
                  file={preview ? previewPath : avatarPath}
                  fileId={user.avatar?._id}
                  handleUploadedFile={handleUploadedFile}
                  handleResetPreview={handleResetPreview}
                  justifyContent="center"
                  onRemove={removeAvatar}
                />
              </Grid>
              <Grid item container spacing={spacing}>
                <Grid item xs={12}>
                  <FormControl
                    variant="standard"
                    component="fieldset"
                    error={Boolean(props.errors.defaultTotp) && props.touched.defaultTotp}>
                    <FormLabel component="legend">Verification method</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            checked={defaultTotp === AUTH_TYPES.AUTH_APP}
                            onChange={(e) => {
                              if (e.target.checked) setFieldValue("defaultTotp", AUTH_TYPES.AUTH_APP);
                            }}
                            name={AUTH_TYPES.AUTH_APP}
                            color="primary"
                          />
                        )}
                        label={(
                          <Grid container spacing={1} alignItems="center">
                            <Grid item>
                              Authentication app
                            </Grid>
                            <Grid item>
                              (<SetupTwoFactor
                                buttonProps={{size: "small", color: "primary", className: classes.authButton}}
                                tempSecret={user.temp_secret || user.secret}
                                onVerified={() => {
                                  this.setState({
                                    user: {
                                      ...user,
                                      secret: user.temp_secret || user.secret
                                    }
                                  });
                                  setFieldValue("defaultTotp", AUTH_TYPES.AUTH_APP);
                                }}
                              />)
                            </Grid>
                          </Grid>
                        )}
                      />
                      <FormControlLabel
                        control={(
                          <Checkbox
                            checked={defaultTotp === AUTH_TYPES.EMAIL}
                            onChange={(e) => {
                              if (e.target.checked) setFieldValue("defaultTotp", AUTH_TYPES.EMAIL);
                            }}
                            name={AUTH_TYPES.EMAIL}
                            color="primary"
                          />
                        )}
                        label="Email"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
                <NewDesignCheckbox />
                <Grid item container direction="row" spacing={spacing}
                  classes={{container: classes.footer}}>
                  <Grid item>
                    <Button
                      margin="normal"
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      disabled={!isValid || !touched}
                    >
                      Save
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      margin="normal"
                      variant="contained"
                      color="primary"
                      onClick={this.openChangePassword}
                      disabled={changingPassword}
                    >
                      Change password
                    </Button>
                  </Grid>
                  <Grid item>
                    <ResetSigningKey />
                  </Grid>
                </Grid>
              </Grid>
              <ChangePassModal
                opened={changingPassword}
                onClose={this.closeChangePassword}
                handleChangePassword={this.changePassword}
              />
            </Grid>
          );
        }}
      />
    );
  }
}

export default compose(
  inject("NotificationStore", "UserStore"),
)(withStyles(observer(EditPersonalInfo), styles));
