import React from "react";
import {observer} from "mobx-react-lite";
import {useParams} from "react-router-dom";
import {Skeleton, Grid, Card, useTheme} from "@mui/material";
import {withStyles} from "tss-react/mui";
import {flatten, uniq} from "ramda";
import TestsProgressBar from "@core/components/TestsProgressBar";
import GlobalTestStatistics from "./components/GlobalTestStatistics";

import styles from "./styles";
import {PAGE_TABS} from "../constants";
import useStores from "../../../../useStores";

const StatusCard = observer(({title, number}) => {
  const theme = useTheme();

  const {id: transferId} = useParams();

  const {TransferStore} = useStores();

  const isLoaded = TransferStore.transfer._id === transferId;

  return (
    <Grid item xs>
      <Card
        sx={{
          textAlign: "center",
          padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
          "& h3": {
            marginBottom: theme.spacing(2),
          },
          "& span": {
            color: "#2d88d1",
            fontSize: 40,
            fontWeight: theme.typography.fontWeightMedium,
          }
        }}
      >
        <h3>{title}</h3>
        {isLoaded ? <span>{number}</span> :  <Skeleton />}
      </Card>
    </Grid>
  );
});

const OrderStatus = ({classes, certificates, campaigns, isLoaded}) => {
  const certificateHeats = certificates.map((certificate) => certificate.heat);
  const certificateLotIds = certificates.filter((certificate) => certificate.properties.lotId).map((certificate) => certificate.properties.lotId + certificate.heat);
  const certificatePoItems = certificates.filter((certificate) => certificate.lineItem).map((certificate) => certificate.lineItem);

  const campaignHeats = flatten(campaigns.map((campaign) => campaign.products.map((product) => product.heat)));
  const campaignPoItems = campaigns.reduce((acc, campaign) => {
    if(campaign.purchaseOrderItem) acc.push(campaign.purchaseOrderItem);

    return acc;
  }, []);
  
  const heats = uniq(certificateHeats.concat(campaignHeats));
  const lotIds = uniq(certificateLotIds.concat(campaignHeats));
  const poItems = uniq(certificatePoItems.concat(campaignPoItems));

  const certificateTests = flatten(certificates.map((certificate) => certificate.tests));
  const campaignTests = flatten(campaigns.map((campaign) => campaign.tests));

  const tests = certificateTests.concat(campaignTests);

  return (
    <div className={classes.content}>
      <h3
        id={PAGE_TABS.ORDER_STATUS}
        className={classes.title}
      >
          Order Status
      </h3>
      <Grid container spacing={7}>
        <StatusCard
          title="PO Items"
          number={poItems.length}
        />
        <StatusCard
          title="Heats"
          number={heats.length}
        />
        <StatusCard
          title="Lot IDs"
          number={lotIds.length}
        />
      </Grid>
      <GlobalTestStatistics
        certificates={certificates}
      />
      <div className={classes.progressBar}>
        <TestsProgressBar
          isLoaded={isLoaded}
          tests={tests}
        />
      </div>
    </div>
  );
};

export default withStyles(OrderStatus, styles);