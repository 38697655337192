import React, {useRef} from "react";
import {Group, Rect, Line, Text} from "react-konva";
import {STAGE_CONFIG} from "../../../../constants";
import {TOOLTIP_PROGRESS_BAR_CONFIG, TOOLTIP_CONFIG, CONTENT_WIDTH} from "../../constants";

const PROGRESS_TEXT_X_MIN = 0;

const getProgressTextX = (progressX, textRef, contentWidth) => {
  const textWidth = textRef?.width();
  
  const textMiddleX = progressX - textWidth / 2;
  
  if(textMiddleX < PROGRESS_TEXT_X_MIN) return PROGRESS_TEXT_X_MIN;

  if(contentWidth - textMiddleX < textWidth) return contentWidth - textWidth;
  
  return textMiddleX;
};

const LengthProgressBar = ({length, totalLength}) => {
  const text = useRef();

  if(!length || ! totalLength) return null;

  const progressX = CONTENT_WIDTH * length / totalLength;
 
  const progressTextX = getProgressTextX(progressX, text.current, CONTENT_WIDTH) || 0;

  return (
    <Group
      y={4 * TOOLTIP_CONFIG.MARGIN}
    >
      <Text
        ref={text}
        x={progressTextX}
        text={`${length}m`}
        fill={TOOLTIP_CONFIG.TEXT_COLOR}
        fontSize={TOOLTIP_CONFIG.GENERAL_TEXT_FONT_SIZE}
        fontFamily={STAGE_CONFIG.FONT_FAMILY}
        fontStyle="bold"
      />
      <Rect
        y={TOOLTIP_CONFIG.FONT_SIZE + TOOLTIP_CONFIG.MARGIN}
        fill={TOOLTIP_PROGRESS_BAR_CONFIG.BACKGROUND_COLOR}
        width={CONTENT_WIDTH}
        height={TOOLTIP_PROGRESS_BAR_CONFIG.HEIGHT}
        cornerRadius={TOOLTIP_PROGRESS_BAR_CONFIG.RADIUS}
      />
      <Line
        y={TOOLTIP_CONFIG.FONT_SIZE + TOOLTIP_CONFIG.MARGIN - TOOLTIP_PROGRESS_BAR_CONFIG.LINE_HEIGHT}
        stroke={TOOLTIP_PROGRESS_BAR_CONFIG.STROKE_COLOR}
        strokeWidth={TOOLTIP_PROGRESS_BAR_CONFIG.STROKE_WIDTH}
        points={[progressX, 0, progressX, TOOLTIP_PROGRESS_BAR_CONFIG.HEIGHT + 2 * TOOLTIP_PROGRESS_BAR_CONFIG.LINE_HEIGHT]}
        fill={TOOLTIP_PROGRESS_BAR_CONFIG.STROKE_COLOR}
      />
    </Group>
  );
};

export default LengthProgressBar;