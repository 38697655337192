import AcceptanceCriteriaLabel from "@core/components/AcceptanceCriteriaLabel";
import {getIsWithinRequirements} from "@core/helpers";
import React, {Fragment, useEffect, useState} from "react";
import axios from "axios";
import {head} from "ramda";
import {Grid, TableHead, TableRow, TableCell, Table, TableBody, Tooltip} from "@mui/material";
import {withStyles} from "tss-react/mui";
import File from "@core/components/File";
import EditableCell from "@core/components/EditableCell";
import {SPECIMEN_TYPES} from "@core/components/TestForms/TensileTest/data";
import TestResultTableCell from "@core/components/TestResultTableCell";
import {TensileResult} from "@core/services/testResult/tensile";
import {TYPES, POSITIONS_SHORT_FORM} from "@core/constants/test";
import styles from "./styles";
import classNames from "classnames";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";

const formatNumber = (number) => Math.round(number * 100) / 100;

const Requirement = ({defaultValue, value}) => {
  if (defaultValue && value === Number(defaultValue)) return value;

  return (
    <Tooltip title={`Default: ${defaultValue ? defaultValue : "unset"}`}>
      <div>{value}</div>
    </Tooltip>
  );
};

const TensileTest = ({classes, setTest, addDataOpen: editable, ...props}) => {
  const test = editable ? props.testUpdates : props.test;

  const [materialSpecifications, setMaterialSpecifications] = useState([]);

  const fetchMaterialSpecifications = async () => {
    const response = await axios.get(ROUTES.TEST_NORM[ACTIONS.ALL_BY_QUERY](TYPES.TENSILE));

    setMaterialSpecifications(response.data);
  };

  useEffect(() => {
    fetchMaterialSpecifications();
  }, []);

  const updateTestProperties = (changes) => {
    const properties = {...test.properties, ...changes};
    setTest({...test, properties});
  };

  const updateElement = (changes, index) => {
    const elements = test.properties.elements.map((element, idx) => idx === index ? {...element, ...changes} : element);
    const tensileResult = new TensileResult(elements);
    const result = tensileResult.getResult();

    updateTestProperties({elements, result});
  };

  const data = materialSpecifications.find((ms) => {
    return ms.Norm === test.properties.norm && ms.Material === test.properties.grade;
  }) || {};

  const hasUniformElongation = test.properties.elements.some((element) => element.requirements?.uniformElongationMin || element.uniformElongation);
  const hasReductionOfArea = test.properties.elements.some((element) => element.reductionOfArea);
  const hasGaugeLength = test.properties.elements.some((element) => element.gaugeLength);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <h3>Test Information:</h3>
          </Grid>
          <Table className={classNames("styled-table", classes.table)}>
            <TableHead>
              <TableRow>
                {(test.company?.name || test.properties.client) && (
                  <TableCell>Client</TableCell>
                )}
                {(test.assignee?.name || test.properties.lab) && (
                  <TableCell>Laboratory</TableCell>
                )}
                <TableCell>Material specification</TableCell>
                <TableCell>Grade / UNS</TableCell>
                <TableCell>Test Standard</TableCell>
                {test.properties.acceptance && <TableCell>QCP/ITP</TableCell>}
                {test.properties.acceptanceItem && <TableCell>QCP Item</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {(test.company?.name || test.properties.client) && (
                  <TableCell>{test.company?.name || test.properties.client}</TableCell>
                )}
                {(test.assignee?.name || test.properties.lab) && (
                  <TableCell>{test.assignee?.name || test.properties.lab}</TableCell>
                )}
                <TableCell>{test.norm}</TableCell>
                <TableCell>{test.grade}</TableCell>
                <TableCell>{test.properties.testStandard || "-"}</TableCell>
                {test.properties.acceptance && <TableCell>{test.properties.acceptance}</TableCell>}
                {test.properties.acceptanceItem && <TableCell>{test.properties.acceptanceItem}</TableCell>}
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <h3>Test Specimens:</h3>
          </Grid>
          <Table className={classNames("styled-table", classes.table)}>
            <TableHead>
              <TableRow>
                <TableCell>Spec. ID</TableCell>
                <TableCell>Shape</TableCell>
                {test.properties.specimen === SPECIMEN_TYPES.ROUND ? (
                  <TableCell>Dimension [mm]</TableCell>
                ) : (
                  <>
                    <TableCell>Dimension X [mm]</TableCell>
                    <TableCell>Dimension Y [mm]</TableCell>
                  </>
                )}
                {hasGaugeLength && <TableCell>Gauge length [mm]</TableCell>}
                <TableCell>Location</TableCell>
                <TableCell>Position</TableCell>
                <TableCell>Orientation</TableCell>
                <TableCell>Test temp (°C)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {test.properties.elements.map((element, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <EditableCell
                      required
                      disabled={!editable}
                      value={element.specimenId}
                      editable={editable}
                      setValue={(value) => updateElement({specimenId: value}, index)}
                    />
                  </TableCell>
                  <TableCell>{test.properties.specimen}</TableCell>
                  <TableCell>
                    <EditableCell
                      required
                      type="number"
                      disabled={!editable}
                      value={element.dimensionX}
                      editable={editable}
                      setValue={(value) => updateElement({dimensionX: value}, index)}
                      inputProps={{min: 0}}
                    />
                  </TableCell>
                  {test.properties.specimen !== SPECIMEN_TYPES.ROUND && (
                    <TableCell>
                      <EditableCell
                        required
                        type="number"
                        disabled={!editable}
                        value={element.dimensionY}
                        editable={editable}
                        setValue={(value) => updateElement({dimensionY: value}, index)}
                        inputProps={{min: 0}}
                      />
                    </TableCell>
                  )}
                  {element.gaugeLength && (
                    <TableCell>{element.gaugeLength}</TableCell>
                  )}
                  <TableCell>{element.zone}</TableCell>
                  <TableCell>{element.position}</TableCell>
                  <TableCell>{element.orientation}</TableCell>
                  <TableCell>
                    <EditableCell
                      type="number"
                      disabled={!editable}
                      value={element.temperature}
                      editable={editable}
                      setValue={(value) => updateElement({temperature: value}, index)}
                      inputProps={{min: 0}}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <h3>Test Result:</h3>
          </Grid>
          <Table className={classNames("styled-table", classes.table)}>
            <TableHead>
              <TableRow>
                <TableCell>Spec. ID</TableCell>
                <TableCell>
                  <AcceptanceCriteriaLabel />
                </TableCell>
                <TableCell>Yield strength [MPa]</TableCell>
                <TableCell>Tensile strength [MPa]</TableCell>
                {hasReductionOfArea && <TableCell>Reduction of area [%]</TableCell>}
                <TableCell>Elongation [%]</TableCell>
                <TableCell>Yield/Tensile ratio</TableCell>
                {hasUniformElongation && (
                  <TableCell>Uniform elongation [%]</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {test.properties.elements.map((element, index) => (
                <Fragment key={index}>
                  <TableRow>
                    <TableCell classes={{root: classes.tableCell}}/>
                    <TableCell>
                      <AcceptanceCriteriaLabel min/>
                    </TableCell>
                    <TableCell>
                      <Requirement
                        value={element.requirements.yeldMin || "-"}
                        defaultValue={data.YELDmin}
                      />
                    </TableCell>
                    <TableCell>
                      <Requirement
                        value={element.requirements.tensMin || "-"}
                        defaultValue={data.TENSmin}
                      />
                    </TableCell>
                    {hasReductionOfArea && (
                      <TableCell>
                        <Requirement
                          value={element.requirements.reductionOfArea || "-"}
                          defaultValue={data.ReductionOfArea}
                        />
                      </TableCell>
                    )}
                    <TableCell>
                      <Requirement
                        value={element.requirements.elongation || "-"}
                        defaultValue={data.ELONGATION}
                      />
                    </TableCell>
                    <TableCell>-</TableCell>
                    {hasUniformElongation && (
                      <TableCell>
                        <Requirement
                          value={element.requirements.uniformElongationMin || "-"}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell classes={{root: classes.tableCell}}>
                      {element.specimenId}<br />
                      {POSITIONS_SHORT_FORM[element.position]} / {head(element.orientation)}
                    </TableCell>
                    <TableCell/>
                    <TableCell>
                      <TestResultTableCell
                        min={element.requirements.yeldMin}
                        max={element.requirements.yeldMax}
                        value={element.rp}
                      >
                        <EditableCell
                          required={element.requirements.yeldMin || element.requirements.yeldMax}
                          error={!getIsWithinRequirements(element.rp, element.requirements.yeldMin, element.requirements.yeldMax)}
                          type="number"
                          disabled={!editable}
                          value={element.rp}
                          editable={editable}
                          setValue={(value) => updateElement({
                            rp: value,
                            yeildTensileRatio: value / element.rm
                          }, index)}
                          inputProps={{min: 0}}
                        />
                      </TestResultTableCell>
                    </TableCell>
                    <TableCell>
                      <TestResultTableCell
                        min={element.requirements.tensMin}
                        max={element.requirements.tensMax}
                        value={element.rm}
                      >
                        <EditableCell
                          required={element.requirements.tensMin || element.requirements.tensMax}
                          error={!getIsWithinRequirements(element.rm, element.requirements.tensMin, element.requirements.tensMax)}
                          type="number"
                          disabled={!editable}
                          value={element.rm}
                          editable={editable}
                          setValue={(value) => updateElement({
                            rm: value,
                            yeildTensileRatio: element.rp / value
                          }, index)}
                          inputProps={{min: 0}}
                        />
                      </TestResultTableCell>
                    </TableCell>
                    {hasReductionOfArea && (
                      <TableCell>
                        <TestResultTableCell
                          min={element.requirements.reductionOfArea}
                          value={element.reductionOfArea}
                        >
                          <EditableCell
                            required={element.requirements.reductionOfArea}
                            error={!getIsWithinRequirements(element.reductionOfArea, element.requirements.reductionOfArea)}
                            type="number"
                            disabled={!editable}
                            value={element.reductionOfArea}
                            editable={editable}
                            setValue={(value) => updateElement({reductionOfArea: value}, index)}
                            inputProps={{min: 0}}
                          />
                        </TestResultTableCell>
                      </TableCell>
                    )}
                    <TableCell>
                      <TestResultTableCell
                        min={element.requirements.elongation}
                        value={element.a}
                      >
                        <EditableCell
                          required={element.requirements.elongation}
                          error={!getIsWithinRequirements(element.a, element.requirements.elongation)}
                          type="number"
                          disabled={!editable}
                          value={element.a}
                          editable={editable}
                          setValue={(value) => updateElement({a: value}, index)}
                          inputProps={{min: 0}}
                        />
                      </TestResultTableCell>
                    </TableCell>
                    <TableCell>
                      {element.rp && element.rm ? (
                        <TestResultTableCell
                          max={element.requirements.yeildTensileRatio}
                          value={formatNumber(element.rp / element.rm)}
                        />
                      ) : "-"}
                    </TableCell>
                    {hasUniformElongation && (
                      <TableCell>
                        <TestResultTableCell
                          min={element.requirements.uniformElongationMin}
                          value={element.uniformElongation}
                        >
                          <EditableCell
                            required={element.requirements.uniformElongationMin}
                            error={!getIsWithinRequirements(element.uniformElongation, element.requirements.uniformElongationMin)}
                            type="number"
                            disabled={!editable}
                            value={element.uniformElongation}
                            editable={editable}
                            setValue={(value) => updateElement({uniformElongation: value}, index)}
                            inputProps={{min: 0}}
                          />
                        </TestResultTableCell>
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell/>
                    <TableCell>
                      <AcceptanceCriteriaLabel max />
                    </TableCell>
                    <TableCell>
                      <Requirement
                        value={element.requirements.yeldMax || "-"}
                        defaultValue={data.YELDmax}
                      />
                    </TableCell>
                    <TableCell>
                      <Requirement
                        value={element.requirements.tensMax || "-"}
                        defaultValue={data.TENSmax}
                      />
                    </TableCell>
                    {hasReductionOfArea && (
                      <TableCell>-</TableCell>
                    )}
                    <TableCell>-</TableCell>
                    <TableCell>
                      <Requirement
                        value={element.requirements.yeildTensileRatio}
                        defaultValue={data.YieldTensileRatio}
                      />
                    </TableCell>
                    {hasUniformElongation && (
                      <TableCell>-</TableCell>
                    )}
                  </TableRow>
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {test.properties.notes && (
          <Grid item xs={12} className={classes.notes}>
            <p
              className={classes.value}
              dangerouslySetInnerHTML={{__html: test.properties.notes.split("\n").join("</br>")}}
            />
          </Grid>
        )}
        {test.properties.files && (
          <Grid item xs={12} spacing={3} container>
            {test.properties.files.map((image) =>
              <Grid item xs={4} container alignItems="center" key={image}>
                <File file={image} preview />
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default withStyles(TensileTest, styles);
