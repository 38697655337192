import Field from "@core/components/CollapsibleTable/components/Field";
import AddRowButton from "@core/components/DynamicTestResultsTable/AddRowButton";
import RemoveButtonCell from "@core/components/DynamicTestResultsTable/RemoveButtonCell";
import {DIRECTIONS} from "@core/components/TestForms/PalmqvistTest/constants";
import TestResultTableCell from "@core/components/TestResultTableCell";
import {ORIENTATIONS, POSITIONS, ZONES} from "@core/constants/test";
import React from "react";
import {FieldArray, useFormikContext} from "formik";
import {Box, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {TEST_RESULT} from "../../constants";

const TestResults = ({editable}) => {

  const {values} = useFormikContext();

  const withLocation = values.testResults.some((result) => result.location);
  const withPosition = values.testResults.some((result) => result.position);
  const withOrientation = values.testResults.some((result) => result.orientation);

  return (
    <FieldArray name="testResults">
      {({push, remove}) => {
        return (
          <>
            <Table className="styled-table">
              <TableHead>
                <TableRow>
                  <TableCell>Specimen ID</TableCell>
                  {(editable || withLocation) && <TableCell>Location</TableCell>}
                  {(editable || withPosition) && <TableCell>Position</TableCell>}
                  {(editable || withOrientation) && <TableCell>Orientation</TableCell>}
                  <TableCell>Direction</TableCell>
                  <TableCell>
                    {values.palmqvist ? (
                      <span
                        dangerouslySetInnerHTML={{__html: values.palmqvist}}
                      />
                    ) : "Palmqvist"}
                  </TableCell>
                  <TableCell>
                    {values.acceptanceCriteria.min && (
                      <span>{values.acceptanceCriteria.min}&nbsp;&#8804;&nbsp;</span>
                    )}
                    K<sub>Ic</sub>
                    {values.acceptanceCriteria.max && (
                      <span>&nbsp;&#8804;&nbsp;{values.acceptanceCriteria.max}</span>
                    )}
                  </TableCell>
                  {editable && <TableCell>Action</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {values.testResults.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Field
                        required
                        name={`testResults.${index}.specimen`}
                        errorMessage={null}
                        editable={editable}
                      />
                    </TableCell>
                    {(editable || withLocation) && (
                      <TableCell>
                        <Field
                          name={`testResults.${index}.location`}
                          errorMessage={null}
                          editable={editable}
                          options={[ZONES.BASE_METAL, ZONES.HAZ, ZONES.WELD_METAL]}
                        />
                      </TableCell>
                    )}
                    {(editable || withPosition) && (
                      <TableCell>
                        <Field
                          name={`testResults.${index}.position`}
                          errorMessage={null}
                          editable={editable}
                          options={POSITIONS}
                        />
                      </TableCell>
                    )}
                    {(editable || withOrientation) && (
                      <TableCell>
                        <Field
                          name={`testResults.${index}.orientation`}
                          errorMessage={null}
                          editable={editable}
                          options={ORIENTATIONS}
                        />
                      </TableCell>
                    )}
                    <TableCell>
                      <Field
                        required
                        name={`testResults.${index}.direction`}
                        errorMessage={null}
                        editable={editable}
                        options={DIRECTIONS}
                      />
                    </TableCell>
                    <TableCell>
                      <Field
                        required
                        name={`testResults.${index}.palmqvist`}
                        errorMessage={null}
                        editable={editable}
                        type="number"
                        inputProps={{min: 0}}
                      />
                    </TableCell>
                    <TableCell>
                      <Box sx={{display: "flex", alignItems: "center"}}>
                        <TestResultTableCell
                          value={item.stressIntensityFactor}
                          min={values.acceptanceCriteria.min}
                          max={values.acceptanceCriteria.max}
                        >
                          <Field
                            required
                            name={`testResults.${index}.stressIntensityFactor`}
                            errorMessage={null}
                            editable={editable}
                            type="number"
                            inputProps={{min: 0}}
                          />
                        </TestResultTableCell>
                        &nbsp;
                        {values.stressIntensityFactorUnit}
                      </Box>
                    </TableCell>
                    {editable && (
                      <RemoveButtonCell
                        disabled={values.testResults?.length === 1}
                        onClick={() => remove(index)}
                      />
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {editable && (
              <AddRowButton
                push={push}
                rowCellsValue={TEST_RESULT}
              />
            )}
          </>
        );
      }}
    </FieldArray>
  );
};

export default TestResults;